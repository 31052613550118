import { useEffect, useState } from "react";
import styles from "./ConvertToEmiAccepted.module.scss";
import {
  formatAmount,
  formatDate,
  fallbackCopyTextToClipboard
} from "../../utils/functions";
import checkList from "../../assets/svgs/convertOutstandingToEmi/checkList.svg";
import vectorDownArrow from "../../assets/svgs/convertOutstandingToEmi/Vector.svg";
import copySvg from "../../assets/svgs/convertOutstandingToEmi/CopySimple.svg";
import { Button2 } from "../button/Button";
import { useSelector } from "react-redux";
import { toast } from "../toast/toast";
import { ToastType } from "../toast/toastTypes";
import _debounce from "lodash/debounce";
import { toast as toastLib } from "react-toastify";

const ConvertOutstandingToEmiAccepted = ({ setIsBottomSheetOpen }) => {
  const [openEmiOverview, setOpenEmiOverview] = useState(false);
  const [totalInterest, seTotalInterest] = useState(0);

  const outstandingToEmiStatus = useSelector(
    (state) => state.outstandingToEmi?.emiStatus
  );

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(outstandingToEmiStatus.extRefId);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Reference ID Copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(outstandingToEmiStatus.extRefId);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Reference ID Copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  useEffect(() => {
    const interest = outstandingToEmiStatus.schedules?.reduce((acc, ele) => {
      return acc + ele.interest;
    }, 0);
    seTotalInterest(interest);
  }, [outstandingToEmiStatus]);

  return (
    <div className={styles.convertToEmiResult}>
      {!openEmiOverview && (
        <>
          <div className={styles.convertToEmiResultImage}>
            <img src={checkList} alt="checklist" />
          </div>
          <div className={styles.text1}>
            <h1>Your request to convert</h1>
            <h1>
              {formatAmount(outstandingToEmiStatus.principal)} to EMI is
              accepted!
            </h1>
          </div>
          <div className={styles.refNumber1}>
            <span>Ref No:{outstandingToEmiStatus.extRefId}</span>
            <span onClick={debounceHandleCopy}>
              <img src={copySvg} alt="copy svg" />
            </span>
          </div>
        </>
      )}
      <hr className={styles.horizontalLine5}></hr>
      <p className={styles.paragraphTag1}>
        Amount of{" "}
        <span className={styles.text5}>
          {formatAmount(
            outstandingToEmiStatus.monthlyEmi +
              outstandingToEmiStatus.processingFees
          )}
        </span>{" "}
        <span className={styles.text6}>
          {" "}
          ({formatAmount(outstandingToEmiStatus.monthlyEmi)} emi +{" "}
          {formatAmount(outstandingToEmiStatus.processingFees)} processing fee)
        </span>{" "}
        will be added to your upcoming statement
      </p>
      <div className={styles.viewEmiOverview}>
        <div className={styles.viewEmiOverview2}>
          <span>View EMI Overview</span>
          <span onClick={() => setOpenEmiOverview(!openEmiOverview)}>
            <img src={vectorDownArrow} alt="downArrow" />
          </span>
        </div>
        {openEmiOverview && (
          <div className={styles.emiOverviewContainer2}>
            <hr className={styles.horizontalLine3}></hr>
            <div className={styles.emiAmount2}>
              <span>EMI Amount</span>
              <span>
                {formatAmount(outstandingToEmiStatus.monthlyEmi)}
                <span> / mo</span>
              </span>
            </div>
            <div>
              <span>Amount</span>
              <span>{formatAmount(outstandingToEmiStatus.principal)}</span>
            </div>
            <div>
              <span>Tenure</span>
              <span>{outstandingToEmiStatus.tenureInMonths} Months</span>
            </div>
            <div>
              <span className={styles.interestRate2}>
                <span>Interest</span>
                <span>{outstandingToEmiStatus.interestRate} p.a</span>
              </span>
              <span>{formatAmount(totalInterest)}</span>
            </div>
            <div>
              <span>Processing fee</span>
              <span>{formatAmount(outstandingToEmiStatus.processingFees)}</span>
            </div>
            <div>
              <span className={styles.preClosure}>
                <span>Requested on </span>
              </span>
              <span>{formatDate(outstandingToEmiStatus.createdOn)}</span>
            </div>
          </div>
        )}
      </div>
      <hr className={styles.horizontalLine3}></hr>
      <div className={styles.text3}>
        <p>If the Purchase Outstanding converted to EMI is less than</p>
        <p>
          Total Outstanding Due in the previous statement, the Cardholder is
          required to
        </p>
        <p>
          make payments toward the Balance Outstanding to avoid interest
          charges.
        </p>
      </div>
      <div>
        <div className={styles.btnContainer3}>
          <Button2
            text1="got it"
            customClassName={styles.repaymentScheduleBtn}
            onClick={() => setIsBottomSheetOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ConvertOutstandingToEmiAccepted;
