// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import { Button2 } from "../button/Button";
import InterMediateText from "./intermediateText/index";
import Bull from "../../assets/svgs/Bull1.svg";
import Home from "../../assets/svgs/Home.svg";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import AppApi from "../../apis/appApi/AppApi";
import dayjs from "dayjs";
import { API_REQUEST_DATE_FORMAT } from "../../utils/constants";
import {
  getCustomer,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";

interface InstallAppPromptComponentProps {
  setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAppInstallPrompt: any;
}

const InstallAppPrompt = (props: InstallAppPromptComponentProps) => {
  const { setIsBottomSheetOpen, handleAppInstallPrompt } = props;

  const account = useSelector(getSelectedAccount)!;
  const customer = useSelector(getCustomer)!;
  const selectedCard = useSelector(getSelectedCard)!;

  // const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  const handlecaptureInstallPrompt = (
    type: string,
    lagTimeForInstallPrompt: number
  ) => {
    let currentDate = dayjs().format(API_REQUEST_DATE_FORMAT);
    AppApi.captureEvent({
      eventName: EVENT_NAME.APP_INSTALL_PROMPT,
      hfAccountId: account.id,
      hfCardId: selectedCard.id,
      hfCustomerId: customer.id,
      primarySource: window.location.href,
      eventData: {
        eventType: type,
        lagTimeForInstallPrompt: lagTimeForInstallPrompt,
        date: currentDate
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(false);
    let lagTimeForInstallPrompt = Number(
      sessionStorage.getItem("lagTimeForInstallPrompt")
    );
    handlecaptureInstallPrompt(
      "app_install_prompt_close",
      lagTimeForInstallPrompt
    );
  };

  return (
    <>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetContainer}>
          <div className={styles.imageContainer}>
            <div className={styles.firstImage}></div>
            <div className={styles.middleImage}>
              <img src={Home} alt="Home Logo" />
            </div>
            <div className={styles.mobileScreen}>
              <div className={styles.upperNudge}></div>

              <div className={styles.blockDisplay}>
                <div className={styles.item1}></div>
                <div className={styles.item2}></div>
                <div className={styles.item3}></div>
                <div className={styles.item4}></div>
                <div className={styles.item5}></div>
                <div className={styles.item6}></div>
                <div className={styles.item7}></div>
                <div className={styles.item8}></div>
                <div className={styles.item9}>
                  <img src={Bull} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.phoneNumberContainer}>
            <InterMediateText
              icon="/images/home/titleIcon2.svg"
              text1="Instant Access, Infinite Possibilities"
              text2="Add a shortcut to your Home Screen to access and manage all your IndusInd cards in one click. "
            />
          </div>
          <div className={styles.confirmBtn1Container}>
            <Button2
              text1="Add Shortcut to Home Screen"
              text1Style={{ fontWeight: "bold" }}
              customClassName={styles.confirmBtn1}
              isLoading={false}
              onClick={handleAppInstallPrompt}
            />
          </div>
          <div onClick={handleCloseBottomSheet}>
            <div className={styles.text2}>
              <span className={styles.text}>i'll do it later</span>
            </div>
          </div>
        </div>
        <div id="scroll"></div>
      </div>
    </>
  );
};

export default InstallAppPrompt;
