import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import arrowCircle from "../../assets/svgs/ArrowCircleUpRight.svg";
import {
  isFestiveOffersUIEnabled,
  showRewardsRedeem
} from "../../utils/internalFlags";
import { captureEvent } from "../../utils/functions";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import cards from "../../utils/rewardsRedemption";
import { getCardDetails } from "../../features/user/userSlice";

interface RewardsProps {
  setRewardPointBottomSheet: React.Dispatch<React.SetStateAction<boolean>>;
  points: number | string;
}

const Index = (props: RewardsProps) => {
  const { points, setRewardPointBottomSheet } = props;

  const cardDetails = useSelector(getCardDetails);

  const cardType = cardDetails.cardType;

  const handleClick = () => {
    cardType in cards && showRewardsRedeem
      ? setRewardPointBottomSheet(true)
      : window.open("https://www.indusmoments.com/", "_blank");
    captureEvent({ eventName: EVENT_NAME.REWARDS_POINT_CLICK });
  };
  return (
    <div
      className={styles.rewards}
      style={{ marginTop: isFestiveOffersUIEnabled ? "107px" : "0px" }}
    >
      <div>
        <span className={styles["points-number-text"]}>
          {points.toLocaleString("en-IN")}
        </span>
        <img
          src={arrowCircle}
          alt="Arrow Up Right"
          className={styles["arrow-rewards"]}
          onClick={handleClick}
        ></img>
      </div>
      <div className={styles["points-text"]}>Reward Points</div>
    </div>
  );
};

export default Index;
