import { Env, Festivals } from "./enums";

let isPciEncryptionEnabled: boolean = true;
let areBlockCodesEnabled: boolean = true;
let P1: boolean = true;
let isFestiveOffersUIEnabled: boolean = false;
let showRewardsRedeem: boolean = false;
let isManualOtpAllowed = false;
let smsSendingPhoneNumber = "+917039000217";
let smsErroCardBlockTime = 24
let countryFromSessionStorage = "";

// dev
if (
  process.env.REACT_APP_NAME === Env.DEV ||
  process.env.REACT_APP_NAME === Env.DEV_MOBILE
) {
  isPciEncryptionEnabled = true;
  areBlockCodesEnabled = false;
  P1 = true;
  isManualOtpAllowed = sessionStorage.getItem("allowed") === "true" || false;
  countryFromSessionStorage = sessionStorage.getItem("country") || "";
  smsErroCardBlockTime = 0.5
}

// non dev
// WARNING: DO NOT CHANGE THESE FLAGS
// flags should be changed only for debugging purposes in dev env
if (process.env.REACT_APP_NAME === Env.UAT) {
  isPciEncryptionEnabled = true;
  areBlockCodesEnabled = false;
  P1 = true;
  isManualOtpAllowed = sessionStorage.getItem("allowed") === "true" || false;
  countryFromSessionStorage = sessionStorage.getItem("country") || "";
  smsErroCardBlockTime = 0.5
}

if (process.env.REACT_APP_NAME === Env.QA) {
  showRewardsRedeem = true;
  countryFromSessionStorage = sessionStorage.getItem("country") || "";
}

if (process.env.REACT_APP_NAME === Env.SANDBOX) {
  isPciEncryptionEnabled = true;
  areBlockCodesEnabled = false;
  countryFromSessionStorage = sessionStorage.getItem("country") || "";
  P1 = true;
}

if (process.env.REACT_APP_NAME === Env.STRESS) {
  isPciEncryptionEnabled = true;
  areBlockCodesEnabled = false;
  P1 = true;
}

if (process.env.REACT_APP_NAME === Env.PROD) {
  isPciEncryptionEnabled = true;
  areBlockCodesEnabled = true;
  P1 = true;
  smsSendingPhoneNumber = "+917039000218"
}

//Festive Offers Date Check
if (
  Festivals?.CURRENT_FESTIVAL?.startDate &&
  Festivals?.CURRENT_FESTIVAL?.endDate
) {
  const festivalStartDate = new Date(Festivals?.CURRENT_FESTIVAL?.startDate);
  const festivalEndDate = new Date(Festivals?.CURRENT_FESTIVAL?.endDate);
  const currentDate = new Date();

  if (currentDate >= festivalStartDate && currentDate <= festivalEndDate) {
    isFestiveOffersUIEnabled = true;
  }
}

export {
  isPciEncryptionEnabled,
  areBlockCodesEnabled,
  P1,
  isFestiveOffersUIEnabled,
  showRewardsRedeem,
  isManualOtpAllowed,
  smsSendingPhoneNumber,
  smsErroCardBlockTime,
  countryFromSessionStorage
};
