/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import { authenticateApi } from "../../auth/auth";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import ApiIds from "../../auth/ApiIds";
import logger from "../../utils/logger";
import cactus from "../../assets/svgs/cactus.svg";
import dayjs from "dayjs";
import { HOME_ROUTE, CONVERT_TO_EMI_ROUTE } from "../../routes/ScreenRoutes";
import { Button2, Header, ToastType, toast } from "../../components";
import styles from "./emiEligibleTransactions.module.scss"; // css for this page
import InterMediateText from "./intermediateText";
import chart from "../../assets/svgs/emiEligibleTransaction/chart.svg";
import EligibleTransaction from "../../components/eligibleTransaction/EligibleTransaction";
import AccountsApi from "../../apis/accountsApi/AccountsApi"; // importing account api
import {
  GetTransactionsRequest,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes"; // importing type of account api
import {
  getCustomer,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import {
  API_REQUEST_DATE_FORMAT,
  CORRELATION_ID_HEADER,
  EMI_ELIGIBLE_TRANSACTIONS_PER_PAGE_COUNT
} from "../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { captureEvent, getSortedTransactions } from "../../utils/functions";
import { EmiStatus, EventType } from "../../utils/enums";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TransactionProps {
  transaction: Transaction;
}

const EmiEligibleTransactions = () => {
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isTransactionsLoading, setIsTransactionsLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const account = useSelector(getSelectedAccount)!;
  const customer = useSelector(getCustomer)!;
  const card = useSelector(getSelectedCard)!;

  const [params] = useSearchParams();
  const from = params.get("from");
  const to = params.get("to");
  const minimumTransactionAmount = params.get("minimumTransactionAmount");
  const maximumTransactionAmount = params.get("maximumTransactionAmount");
  const txnType = params.get("txnType");
  const merchantCategoryCode = params.get("merchantCategoryCode");
  const txnNature = params.get("txnNature");
  const numberOfDaysBeforeCurrentDate = params.get(
    "numberOfDaysBeforeCurrentDate"
  );

  const handleInputChange = (data: any) => {
    setInputValue(data);
  };

  const offset = useRef<number>(0);
  const totalCount = useRef<number>(0);

  const areTransactionsLeftToFetch =
    offset.current === 0 || offset.current < totalCount.current;

  const getTransactions = async () => {
    setIsTransactionsLoading(true);
    let data: any = {
      count: EMI_ELIGIBLE_TRANSACTIONS_PER_PAGE_COUNT,
      offset: offset.current
    };

    const days: number = Number(numberOfDaysBeforeCurrentDate) || 90;

    data = {
      ...data,
      to: to || dayjs().format(API_REQUEST_DATE_FORMAT),
      from:
        from || dayjs().subtract(days, "day").format(API_REQUEST_DATE_FORMAT),
      customerIdList: card.isPrimary ? [] : [customer.id],
      checkEmiEligibility: true,
      emiAllowed: true,
      emiStatus: EmiStatus.Not_Created,
      showEligible: true,
      ...(maximumTransactionAmount && {
        ["maximumTransactionAmount"]: maximumTransactionAmount
      }),
      ...(minimumTransactionAmount && {
        ["minimumTransactionAmount"]: minimumTransactionAmount
      }),
      ...(txnType && { ["txnType"]: txnType.split(",") }),
      ...(merchantCategoryCode && {
        ["merchantCategoryCode"]: merchantCategoryCode.split(",")
      }),
      ...(txnNature && { ["txnNature"]: txnNature })
    };

    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        actionText: "to view transactions"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get transactions api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(HOME_ROUTE);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(HOME_ROUTE);
        return;
      }

      const response = await AccountsApi.getTransactions(
        account.id,
        authResult.apiToken,
        data as GetTransactionsRequest
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting ${"billed"} transactions`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display transaction details. Please try again"
        );
        navigate(HOME_ROUTE);
        return;
      }
      if (response?.data?.transactions) {
        setTransactions((transactions) => {
          const tempTransactions = [
            ...transactions,
            ...response.data.transactions
          ];

          // unbilled transactions also have auth transactions that are fetched separately
          // chronologically auth transactions may be spread across multiple api calls of unbilled transactions
          // since frontend persists the state, sorting will be done in frontend
          let sortedTransactions = getSortedTransactions(tempTransactions);
          handleInputChange(sortedTransactions[0]);
          return sortedTransactions;
        });
        offset.current += response.data.offset;
        totalCount.current = response.data.totalCount;
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting ${"billed"} transactions`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display transaction details. Please try again"
      );
      navigate(HOME_ROUTE);
    }
    setIsTransactionsLoading(false);
    setIsLoading(false);
  };

  const loadMoreTransactions = () => {
    getTransactions();
  };

  useEffect(() => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.VIEW_TXNTOEMI_SELECT_TRANSACTION_PAGE,
      eventData: { requestSource, eventType: EventType.PAGE_VIEW }
    });
    setIsLoading(true);
    getTransactions();
  }, []);

  const handleConvertToEmiClick = () => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.TXNTOEMI_SELECT_TRANSACTION_PAGE_CONVERT_NOW_CLICK,
      eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
    });
    navigate(CONVERT_TO_EMI_ROUTE, {
      state: { transaction: inputValue }
    });
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="Emi Eligible Transactions" />
    </div>
  ) : (
    <>
      <Header label="Convert to EMI" bgColor="#141414" />
      <hr className={styles.horizontalLine}></hr>

      {transactions?.length !== 0 ? (
        <>
          <div
            id="containerOfTransactions"
            className={styles.containerOfTransaction}
          >
            {transactions.length === 1 ? (
              <>
                <div className={styles.firstBox}>
                  <div className={styles.chartBox}>
                    <img src={chart} alt="Chart Image" />
                  </div>

                  <div>
                    <InterMediateText
                      icon="/images/home/titleIcon2.svg"
                      text1="one transaction eligible"
                      text2="to split to an easy EMI"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.firstBox}>
                  <div className={styles.chartBox}>
                    <img src={chart} alt="Chart Image" />
                  </div>

                  <div>
                    <InterMediateText
                      icon="/images/home/titleIcon2.svg"
                      text1="select a transaction"
                      text2="to split to an easy EMI"
                    />
                  </div>
                </div>
              </>
            )}

            <hr className={styles.horizontalLine2}></hr>

            <div className={styles.transactionContainer}>
              <InfiniteScroll
                dataLength={transactions.length}
                next={loadMoreTransactions}
                hasMore={areTransactionsLeftToFetch}
                loader={
                  isTransactionsLoading && (
                    <div style={{ height: "50vh" }}>
                      <Loader text1="loading" text2="transactions" />
                    </div>
                  )
                }
                scrollableTarget="containerOfTransactions"
              >
                {transactions.map((transaction, index) => {
                  return (
                    <EligibleTransaction
                      transaction={transaction}
                      selectedTransaction={inputValue}
                      onInputChange={handleInputChange}
                      key={index}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>

          <div className={styles.btnContainer}>
            <Button2
              text1="convert to "
              text1Style={{ fontWeight: "bold" }}
              text2="EMI"
              customClassName={styles.button}
              onClick={handleConvertToEmiClick}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.noTransactions}>
            <div>
              <img src={cactus} alt="cactus" />
            </div>
            <div>
              <span>you have</span> <br /> no emi eligible transactions for this
              cycle
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmiEligibleTransactions;
