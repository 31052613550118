/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../app/store";
import type { GetNudgesForAccountResponse } from "../../apis/nudgesApi/nudgesApiTypes";
import CampaignOffersSection from "./campaignOffersSection/CampaignOffersSection";
import MerchantOffersSection from "./merchantOffersSection/MerchantOffersSection";
import Header from "../header/cardHeader";
import Rewards from "../../components/rewards";
import HiddenCard from "../../components/hiddenCard";
import Nudges from "../../components/nudges";
import CVPOffer from "../../components/cvpOffers";
import CVPOfferCarousel from "../../components/CVPCarousel";
import InterMediateText from "./intermediateText";
import ripplePattern from "../../assets/svgs/category/merchantOffersCategoriesBackground.svg";
import Hyperface from "../../assets/svgs/Hyperface.svg";
import base from "../../assets/svgs/base.svg";
import boxWithCoins from "../../assets/svgs/box-with-coins.svg";
import baseForFetchError from "../../assets/svgs/baseForFetchError.svg";
import cardsForFetchError from "../../assets/svgs/cardForFetchError.svg";
import styles from "./homeStyles.module.scss";
import NudgesApi from "../../apis/nudgesApi/NudgesApi";
import {
  getCardDetails,
  getCustomer,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import Loader from "../../components/loader/loader";
import diamond from "../../assets/svgs/diamond.svg";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import {
  API_REQUEST_DATE_FORMAT,
  CORRELATION_ID_HEADER,
  MAX_NUDGES_TO_DISPLAY
} from "../../utils/constants";
import {
  Festivals,
  OsSupportForPrompt,
  RequestSources
} from "../../utils/enums";
import {
  capitalizeFirsLetter,
  deleteDeepLinkCookie,
  formatControlCharactersInJson,
  getDeepLinkCookieValue,
  getPWADisplayMode,
  captureEvent
} from "../../utils/functions";
import { isFestiveOffersUIEnabled } from "../../utils/internalFlags";
import Lottie from "lottie-react";
import DeliveryTracker from "../../components/deliveryTracker/DeliveryTracker";
import WelcomeOfferSection from "./welcomeOffersSection";
import ActivationPreHome from "../activationPreHome/activationPreHome";
import { EVENT_NAME, Journey } from "../../apis/appApi/appApiTypes";
import dayjs from "dayjs";
import { getUserOSAndVersion } from "../../utils/trackingFunctions";
import { getEvent } from "../../features/event/eventSlice";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import InstallAppPrompt from "../../components/installAppPrompt";
import IosInstallPrompt from "../../components/installAppPrompt/iosInstallPrompt";
import { ripplePattern3 } from "../../assets/svgs";
import RewardRedemption from "../../components/rewards/rewardBottomSheet/rewardRedemption";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import logger from "../../utils/logger";
import { setEmiStatusForOutstandingToEmi } from "../../features/outstandingToEmi/outstandingToEmiSlice";
import OutstandingEmiErrorScreen from "../../components/outstandingEmiErrorScreens/OutstandingEmiErrorScreen";
import ConvertOutstandingToEmiAccepted from "../../components/ConvertToEmiAccepted/ConvertToEmiAccepted";
import {
  OutstandingToEmiStatus,
  UserActivityForOutstandingEmi,
  UserActivityRequestType
} from "../../apis/accountsApi/accountsApiTypes";
import {
  CONVERT_OUTSTANDING_TO_EMI,
  EMI_ELIGIBLE_TRANSACTIONS
} from "../../routes/ScreenRoutes";

interface NudgesDelivery {
  [id: string]: {
    sessions: string[];
    clickCount: number;
  };
}

const Home = () => {
  const account = useSelector(getSelectedAccount)!;
  const customer = useSelector(getCustomer)!;
  const selectedCard = useSelector(getSelectedCard)!;
  const benefits = useSelector((state: RootState) => state.benefits);
  let installPrompt = useSelector(getEvent);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isParentLoading, setIsParentLoading] = useState<boolean>(false);
  const [nudges, setNudges] = useState<GetNudgesForAccountResponse>([]);
  const [visible, setVisible] = useState(false);
  const [isAppInstallPrompt, setIsAppInstallPrompt] = useState(false);
  const [isIosAppInstallPrompt, setIsIosAppInstallPrompt] = useState(false);
  const [rewardPointBottomSheet, setRewardPointBottomSheet] =
    useState<boolean>(false);
  const [outstandingEmiErrorScreenOpen, setOutstandingEmiErrorScreenOpen] =
    useState<boolean>(false);
  const [outstandingEmiSuccessScreenOpen, setOutstandingEmiSuccessScreenOpen] =
    useState<boolean>(false);
  const [
    firstTimeUserLoginOutstandingToEmi,
    setFirstTimeUserLoginOutstandingToEmi
  ] = useState<boolean>(false);

  const deliverySteps = useSelector(
    (state: RootState) => state.user.trackingDetails
  );

  const deliveredDate = useSelector(
    (state: RootState) => state.user.deliveredDate
  );
  const activationDays = useSelector(
    (state: RootState) => state.user.cardActivationDays
  );

  const token = useSelector((state: RootState) => state.session.token);
  const cardHolderName = useSelector(
    (state: RootState) => state.user.customer?.firstName
  );
  const cardDetails = useSelector(getCardDetails);
  const isActivationJourney =
    useSelector((state: RootState) => state.user.journey) ===
    Journey.NEW_ACTIVATION;
  const isCardTrackingEligible = useSelector(
    (state: RootState) => state.user.showCardTracking
  );
  const showWelcomeOffers = useSelector(
    (state: RootState) => state.user.showWelcomeOffers
  );

  const showActivationJourney = useSelector(
    (state: RootState) => state.miscellaneous.showActivationJourney
  );

  const eligibilityForOutstandingToEmi = useSelector(
    (state: RootState) => state.outstandingToEmi?.eligibility?.isEligible
  );

  const outstandingToEmiStatus = useSelector(
    (state: RootState) => state.outstandingToEmi?.emiStatus
  );

  const navigate = useNavigate();

  // to find device os to show install prompt accordingly
  const userAgent = navigator.userAgent;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deviceOs, osVersion] = getUserOSAndVersion(userAgent);

  const displayMode = getPWADisplayMode();

  //Festival UI data
  const FestiveImages = Festivals.CURRENT_FESTIVAL?.images;
  const FestiveText = Festivals.CURRENT_FESTIVAL?.text;

  useEffect(() => {
    captureEvent({ eventName: EVENT_NAME.HOME_PAGE_VIEW });
  }, [account.id, customer.id, selectedCard.id]);

  const getNudgesForAccount = useCallback(async () => {
    try {
      const response = await NudgesApi.getNudgesForAccount(account?.id);

      if (response.status === 200) {
        let removedNudges = JSON.parse(localStorage.getItem("removedNudges")!);
        if (removedNudges) {
          removedNudges = removedNudges[account.id!];
        }

        let nudgesDelivery = JSON.parse(
          localStorage.getItem("nudgesDelivery")!
        );

        if (nudgesDelivery) {
          nudgesDelivery = nudgesDelivery[account.id!];
        }
        let nudgesToBeRemoved: string[] = [];
        if (nudgesDelivery) {
          const nudgeValueInLocalStorage: NudgesDelivery = {
            ...nudgesDelivery
          };
          const nudgeIds = Object.keys(nudgesDelivery);
          nudgeIds.forEach((nudgeId) => {
            const nudgeDataFromResponse =
              response.data?.filter?.((nudge) => nudge.id === nudgeId)?.[0] ||
              {};

            const sessions = nudgeValueInLocalStorage[nudgeId]?.sessions || [];
            const clickCount =
              nudgeValueInLocalStorage[nudgeId]?.clickCount || 0;

            const nudgeDeliveryEligibilityList =
              nudgeDataFromResponse?.displayConstraints;
            if ((nudgeDeliveryEligibilityList || [])?.length > 0) {
              nudgeDeliveryEligibilityList?.forEach((nudgeDelivery) => {
                if (nudgeDelivery.entityType === "DELIVERY_LOGIC") {
                  if (nudgeDelivery.entityAttribute === "CTR_COUNT") {
                    if (nudgeDelivery.filter.type === "LT") {
                      if (clickCount >= nudgeDelivery.filter.value) {
                        nudgesToBeRemoved.push(nudgeId);
                      }
                    }
                    if (nudgeDelivery.filter.type === "LTE") {
                      if (clickCount + 1 > nudgeDelivery.filter.value) {
                        nudgesToBeRemoved.push(nudgeId);
                      }
                    }
                  }

                  if (nudgeDelivery.entityAttribute === "IMPRESSION_COUNT") {
                    if (nudgeDelivery.filter.type === "LT") {
                      if (sessions.length >= nudgeDelivery.filter.value) {
                        nudgesToBeRemoved.push(nudgeId);
                      }
                    }
                    if (nudgeDelivery.filter.type === "LTE") {
                      if (sessions.length + 1 > nudgeDelivery.filter.value) {
                        nudgesToBeRemoved.push(nudgeId);
                      }
                    }
                  }
                }
              });
            }
          });
        }

        if (nudgesToBeRemoved.length > 0) {
          nudgesToBeRemoved = Array.from(new Set(nudgesToBeRemoved));
        }

        if (removedNudges && removedNudges?.length > 100) {
          removedNudges = response.data?.filter((nudge) =>
            removedNudges.includes(nudge.id)
          );
          removedNudges.forEach((nudge: any) => {
            removedNudges.push(nudge.id);
          });
          localStorage.setItem("removedNudges", JSON.stringify(removedNudges));
        }
        const result = response.data?.filter(
          (nudge) =>
            !removedNudges?.includes(nudge.id) &&
            !nudgesToBeRemoved.includes(nudge.id)
        );

        setNudges(result);
      }
    } catch (error) {
      console.error("An error occurred while getting nudges. Error: \n", error);
    }

    setIsLoading(false);
  }, [account, token]);

  const handleInstallPromptIgnore = () => {
    setIsAppInstallPrompt(!isAppInstallPrompt);
    handlecaptureInstallPrompt(
      "app_install_prompt_ignored",
      Number(sessionStorage.getItem("lagTimeForInstallPrompt"))
    );
  };

  const handlecaptureInstallPrompt = (
    type: string,
    lagTimeForInstallPrompt: number
  ) => {
    let currentDate = dayjs().format(API_REQUEST_DATE_FORMAT);
    captureEvent({
      eventName: EVENT_NAME.APP_INSTALL_PROMPT,
      eventData: {
        eventType: type,
        lagTimeForInstallPrompt: lagTimeForInstallPrompt,
        date: currentDate
      }
    });
  };

  const handleInstallClick = async () => {
    // on click add to home page
    let lagTimeForInstallPrompt = Number(
      sessionStorage.getItem("lagTimeForInstallPrompt")
    );
    if (!installPrompt && deviceOs === OsSupportForPrompt.ios) {
      setIsIosAppInstallPrompt(true);
      setIsAppInstallPrompt(false);
      handlecaptureInstallPrompt(
        "app_install_prompt_click",
        lagTimeForInstallPrompt
      );
      return;
    }

    if (!installPrompt) {
      return;
    }
    const result = await installPrompt.event.prompt();
    // eslint-disable-next-line
    if (result.outcome == "accepted") {
      setIsAppInstallPrompt(false);
      handlecaptureInstallPrompt(
        "app_install_prompt_click",
        lagTimeForInstallPrompt
      );
    } else {
      handlecaptureInstallPrompt(
        "app_install_prompt_close",
        lagTimeForInstallPrompt
      );
    }

    installPrompt = null;
  };

  const handleAppInstallPrompt = () => {
    // to check showPrompt in sesionStorage and os compatibility
    let showPrompt = sessionStorage.getItem("showPrompt");
    let lagTimeForInstallPrompt = Number(
      sessionStorage.getItem("lagTimeForInstallPrompt")
    );

    if (
      deviceOs === OsSupportForPrompt.Android ||
      deviceOs === OsSupportForPrompt.ios
    ) {
      if (showPrompt === "true" && displayMode !== "app") {
        setTimeout(function () {
          setIsAppInstallPrompt(true);
          sessionStorage.setItem("showPrompt", "false");
        }, lagTimeForInstallPrompt);
        handlecaptureInstallPrompt(
          "app_install_prompt_view",
          lagTimeForInstallPrompt
        );
      }
    }
  };

  const nudgesArr =
    nudges.length > MAX_NUDGES_TO_DISPLAY
      ? nudges?.slice(0, MAX_NUDGES_TO_DISPLAY)
      : nudges;

  const getStatusForOutstandingToEmi = useCallback(async () => {
    try {
      setIsLoading(true);
      const getStatus = await AccountsApi.getStatusForOutstandingToEmi(
        account.id
      );

      if (getStatus.status !== 200) {
        logger.error({
          correlationId: getStatus.headers[CORRELATION_ID_HEADER],
          accountId: account?.id,
          responseData: getStatus.data,
          message: `An error occurred while getting status for outstanding to emi`
        });
        setIsLoading(false);
        return;
      }
      dispatch(setEmiStatusForOutstandingToEmi(getStatus.data));

      if (
        getStatus.data?.status === OutstandingToEmiStatus.SUCCESS &&
        getStatus.data?.userActivity?.loginCount === 1
      ) {
        setFirstTimeUserLoginOutstandingToEmi(true);
        setOutstandingEmiSuccessScreenOpen(true);
        setIsLoading(false);
        return;
      }

      if (
        getStatus.data?.status === OutstandingToEmiStatus.FAILED &&
        getStatus.data?.userActivity?.loginCount === 1
      ) {
        setFirstTimeUserLoginOutstandingToEmi(true);
        setOutstandingEmiErrorScreenOpen(true);
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting status for outstanding to emi`,
        error: error?.message,
        accountId: account?.id,
        stackTrace: error?.stack
      });
    }
    setIsLoading(false);
  }, []);

  const setUserActivity = async (data: UserActivityForOutstandingEmi) => {
    try {
      const response = await AccountsApi.setUserActivityForOutstandingToEmi(
        account.id,
        data
      );
      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while creating emi`
        });
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while creating emi`,
        error: error?.message,
        accountId: account.id,
        stackTrace: error.stack
      });
    }
  };

  useEffect(() => {
    if (
      (firstTimeUserLoginOutstandingToEmi &&
        !outstandingEmiSuccessScreenOpen &&
        outstandingToEmiStatus?.status === OutstandingToEmiStatus.SUCCESS) ||
      (firstTimeUserLoginOutstandingToEmi &&
        !outstandingEmiErrorScreenOpen &&
        outstandingToEmiStatus?.status === OutstandingToEmiStatus.FAILED)
    ) {
      if (outstandingToEmiStatus.userActivity.loginCount === 1) {
        setUserActivity({
          emiRefId: outstandingToEmiStatus.emiRefId,
          requestType: UserActivityRequestType.UPDATE_USER_ACTIVITY
        });
      }
    }
  }, [
    firstTimeUserLoginOutstandingToEmi,
    outstandingEmiErrorScreenOpen,
    outstandingEmiSuccessScreenOpen
  ]);

  useEffect(() => {
    const deepLinkCookie = getDeepLinkCookieValue();
    if (deepLinkCookie) {
      if (
        deepLinkCookie === CONVERT_OUTSTANDING_TO_EMI ||
        deepLinkCookie === EMI_ELIGIBLE_TRANSACTIONS
      ) {
        sessionStorage.setItem("requestSource", RequestSources.DEEPLINK);
      }
      navigate(deepLinkCookie);
      deleteDeepLinkCookie();
    }
  }, []);

  useEffect(() => {
    if (!eligibilityForOutstandingToEmi) {
      getStatusForOutstandingToEmi();
    }
  }, [eligibilityForOutstandingToEmi]);

  useEffect(() => {
    const scrollFn = () => {
      if (window.scrollY > 0) {
        sessionStorage.setItem("/", "" + window.scrollY);
      }
    };
    window.addEventListener("scroll", scrollFn);
    //remove scroll eventlistener on unmount
    return () => window.removeEventListener("scroll", scrollFn);
  }, []);

  useEffect(() => {
    if (!isLoading && !isParentLoading) {
      handleAppInstallPrompt();
    }
  }, [isLoading, isParentLoading]);

  useLayoutEffect(() => {
    const scrolledY = sessionStorage.getItem("/") ?? 0;
    window.scroll(0, +scrolledY);
  });

  useEffect(() => {
    getNudgesForAccount();
  }, [getNudgesForAccount]);

  const navigateToAllNudges = () => {
    navigate("/allNudges", {
      state: {
        allNudges: nudges
      }
    });
    captureEvent({ eventName: EVENT_NAME.ALL_NUDGES_CLICK });
  };

  const checkNudge = () => {
    let validNudges = 0;
    for (const nudge of nudgesArr) {
      const options = JSON.parse(
        formatControlCharactersInJson(nudge.displayOptions.nudgeDetails)
      );

      if (options.title && options.description) {
        validNudges++;
      }

      if (validNudges > 1) {
        return true;
      }
    }
    return false;
  };

  const handleState = (id: string) => {
    setNudges((current) => current.filter((nudge) => nudge.id !== id));
  };
  useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedHome");
    if (!visitedBefore) {
      const showTimeout = setTimeout(() => {
        setVisible(true);
        localStorage.setItem("visitedHome", "true");
      }, 100);
      const hideTimeout = setTimeout(() => setVisible(false), 3000);

      return () => {
        clearTimeout(showTimeout);
        clearTimeout(hideTimeout);
      };
    }
  }, []);

  const showActivation =
    showActivationJourney &&
    !deliveredDate &&
    isActivationJourney &&
    !localStorage.getItem("isFirstTimeLogin");

  const showRewardPoints = isActivationJourney
    ? Number(account?.rewardsSummary?.balance || 0) > 0 ||
      dayjs(selectedCard?.cardCreatedOn)
        .add(activationDays + 1, "day")
        .isBefore(dayjs())
    : true;

  //To add extra slide to the right in cvp offers carousel for one offer condition
  let cvpBenefits = [...benefits.cvpBenefits.benefits];
  if (cvpBenefits.length === 1) {
    cvpBenefits = [...cvpBenefits, ...cvpBenefits];
  }

  return isLoading || isParentLoading ? (
    <div className={styles.loader}>
      <Loader
        text1Style={{ fontWeight: "bold" }}
        text1="loading"
        text2="your IndusInd CardsHub"
        noTextStyle
      />
    </div>
  ) : (
    <>
      {showActivation ? (
        <ActivationPreHome />
      ) : (
        <div className={styles.container}>
          <style>{`.cvpBtn{
        margin-top:-25px;
      }`}</style>
          {!isFestiveOffersUIEnabled && (
            <img
              className={styles.rippleBackground}
              src={ripplePattern}
              alt="pattern"
            />
          )}
          <Header card={cardDetails!} setIsParentLoading={setIsParentLoading} />
          {isFestiveOffersUIEnabled && (
            <>
              <Lottie
                animationData={FestiveImages?.header?.url}
                className={styles.festiveAnimation}
              />
              <div className={styles.festiveText}>
                <div>{FestiveText?.header?.line1}</div>
                <div>{FestiveText?.header?.line2}</div>
              </div>
            </>
          )}
          <div
            className={`${styles.welcomeContainer} ${
              visible ? styles.show : ""
            }`}
          >
            <span className={styles.welcomeText1}>
              Welcome{localStorage.getItem("isFirstTimeLogin") ? " Back" : ""},
            </span>
            <span className={styles.welcomeText3}>
              {capitalizeFirsLetter(cardHolderName)} 👋
            </span>
          </div>
          {showRewardPoints && (
            <Rewards
              points={Number(account?.rewardsSummary?.balance || 0)}
              setRewardPointBottomSheet={setRewardPointBottomSheet}
            />
          )}

          <HiddenCard card={cardDetails!} />
          {nudges?.length > 0 && (
            <Nudges
              items={nudgesArr?.map((nudge) => {
                const options = JSON.parse(
                  formatControlCharactersInJson(
                    nudge.displayOptions.nudgeDetails
                  )
                );

                return {
                  illustrationUrl: options.illustrationUrl,
                  title: options.title,
                  description: options.description,
                  redirectionUrl: options.redirectionUrl,
                  id: nudge.id,
                  onRemoveClick: () => handleState(nudge.id),
                  nudge: nudge
                };
              })}
            />
          )}
          {checkNudge() && (
            <Button
              onClick={navigateToAllNudges}
              customClassName={styles.viewAllNudgeBtn}
              text1="view all"
            />
          )}
          {!isFestiveOffersUIEnabled && <hr className={styles.divider} />}
          {isActivationJourney && isCardTrackingEligible && !!deliverySteps && (
            <DeliveryTracker direction="horizontal" />
          )}
          {isActivationJourney &&
            showWelcomeOffers &&
            benefits.welcomeBenefits.benefits?.length > 0 && (
              <WelcomeOfferSection />
            )}
          {benefits.campaignBenefits.benefits?.length > 0 && (
            <CampaignOffersSection />
          )}
          {benefits.cvpBenefits.benefits?.length > 0 && (
            <div className={styles.cvpOffersSection}>
              {isFestiveOffersUIEnabled && (
                <>
                  <img
                    src={FestiveImages?.campaignAndCVPDivider?.top?.url}
                    alt={FestiveImages?.campaignAndCVPDivider?.top?.alt}
                    className={styles.festiveCampaignCvpDividerOne}
                  />
                  <img
                    src={FestiveImages?.campaignAndCVPDivider?.bottom?.url}
                    alt={FestiveImages?.campaignAndCVPDivider?.bottom?.alt}
                    className={styles.festiveCampaignCvpDivider}
                  />
                  <img
                    src={FestiveImages?.cvpOffers?.spotlightLeft?.url}
                    alt={FestiveImages?.cvpOffers?.spotlightLeft?.alt}
                    className={styles.festiveCvpSpotlightLeft}
                  />
                  <img
                    src={FestiveImages?.cvpOffers?.spotlightRight?.url}
                    alt={FestiveImages?.cvpOffers?.spotlightRight?.alt}
                    className={styles.festiveCvpSpotlightRight}
                  />
                </>
              )}

              <div className={styles.cvpOffersSectionText}>
                <InterMediateText
                  icon={isFestiveOffersUIEnabled ? "" : diamond}
                  text1={`${cardDetails?.cardType} benefits`}
                  text2={"on your card"}
                />
              </div>
              {benefits.cvpBenefits.benefits?.length > 0 && (
                <CVPOfferCarousel
                  items={cvpBenefits?.map((benefit) => {
                    const offerDetails = JSON.parse(benefit.offerDetails);

                    return (
                      <CVPOffer
                        id={benefit.benefitId}
                        title={benefit.displayTitle}
                        illustrationUrl={benefit.illustrationUrl}
                        logo={benefit.logoUrl}
                        background={benefit.displayColor}
                        ctaLink={offerDetails.ctaLink}
                        hasDetails={
                          offerDetails.redeemSteps || offerDetails.tncConditions
                        }
                        key={benefit.benefitId}
                      />
                    );
                  })}
                />
              )}
            </div>
          )}
          {benefits.merchantBenefits.benefits?.length > 0 && (
            <MerchantOffersSection />
          )}
          {benefits.campaignBenefits.benefits?.length === 0 &&
            benefits.cvpBenefits.benefits?.length === 0 &&
            benefits.merchantBenefits.benefits?.length === 0 &&
            !benefits.allOffersApiFailure && (
              <div>
                <InterMediateText
                  icon="/images/home/titleIcon2.svg"
                  text1="exclusive benefits"
                  text2="for you"
                />
                <div className={styles.noCampaignOffers}>
                  <div className={styles.imageContainer}>
                    <img src={base} alt="base disk" className={styles.base} />
                    <img
                      src={boxWithCoins}
                      alt="box with coins"
                      className={styles.boxWithCoins}
                    />
                  </div>
                  <div className={styles.noCampaignText1}>
                    Exciting offers coming your way
                  </div>
                  <div className={styles.noCampaignText2}>
                    Stay tuned for our latest offers, because they're going to
                    be
                    <div>un-put-down-able 🙇</div>
                  </div>
                </div>
              </div>
            )}

          {benefits.allOffersApiFailure && (
            <>
              <div className={styles.offersFetchError}>
                <div className={styles.offersFetchErrorContainer}>
                  <div className={styles.imageForFetchError}>
                    <img
                      src={baseForFetchError}
                      alt="base disk"
                      className={styles.baseForFetchError}
                    />
                    <img
                      className={styles.rippleBackgroundforFetchError}
                      src={ripplePattern3}
                      alt="pattern"
                    />
                    <img
                      src={cardsForFetchError}
                      alt="cardsinimage"
                      className={styles.cardsForFetchError}
                    />
                  </div>
                  <div className={styles.offersFetchErrorText}>
                    <div className={styles.offersFetchErrorText1}>
                      Sorry! We are unable to fetch offers for you right now.
                    </div>
                    <div className={styles.offersFetchErrorText2}>
                      We are on a mission to bring them back to you!
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className={`${styles.hyperfaceLogoContainer} ${
              isFestiveOffersUIEnabled ? styles.festiveHFLogoContainer : ""
            }`}
          >
            <div className={styles.hyperfaceLogoHeader}>Powered by</div>
            <img
              src={Hyperface}
              className={styles.hyperfaceLogo}
              alt="Hyperface-Logo"
            />
          </div>
          {isFestiveOffersUIEnabled && (
            <img
              src={FestiveImages?.footer?.url}
              alt={FestiveImages?.footer?.alt}
              className={styles.footerBgPattern}
            />
          )}
        </div>
      )}
      {isAppInstallPrompt && (
        <BottomsheetComponent
          isBottomSheetOpen={isAppInstallPrompt}
          setIsBottomSheetOpen={handleInstallPromptIgnore}
          render={
            <>
              <InstallAppPrompt
                setIsBottomSheetOpen={setIsAppInstallPrompt}
                handleAppInstallPrompt={handleInstallClick}
              />
            </>
          }
        />
      )}

      {isIosAppInstallPrompt && (
        <BottomsheetComponent
          isBottomSheetOpen={isIosAppInstallPrompt}
          setIsBottomSheetOpen={handleInstallPromptIgnore}
          render={
            <>
              <IosInstallPrompt
                setIsIosBottomSheetOpen={setIsIosAppInstallPrompt}
              />
            </>
          }
        />
      )}

      {rewardPointBottomSheet && (
        <BottomsheetComponent
          isBottomSheetOpen={rewardPointBottomSheet}
          setIsBottomSheetOpen={setRewardPointBottomSheet}
          render={
            <>
              <RewardRedemption
                setRewardPointBottomSheet={setRewardPointBottomSheet}
                points={Number(account?.rewardsSummary?.balance || 0)}
              />
            </>
          }
        />
      )}
      {outstandingToEmiStatus?.status === OutstandingToEmiStatus.FAILED &&
        outstandingToEmiStatus?.userActivity?.loginCount === 1 && (
          <BottomsheetComponent
            isBottomSheetOpen={outstandingEmiErrorScreenOpen}
            setIsBottomSheetOpen={setOutstandingEmiErrorScreenOpen}
            render={
              <OutstandingEmiErrorScreen
                setIsBottomSheetOpen={setOutstandingEmiErrorScreenOpen}
              />
            }
          />
        )}
      {outstandingToEmiStatus?.status === OutstandingToEmiStatus.SUCCESS &&
        outstandingToEmiStatus?.userActivity?.loginCount === 1 && (
          <BottomsheetComponent
            isBottomSheetOpen={outstandingEmiSuccessScreenOpen}
            setIsBottomSheetOpen={setOutstandingEmiSuccessScreenOpen}
            render={
              <ConvertOutstandingToEmiAccepted
                setIsBottomSheetOpen={setOutstandingEmiSuccessScreenOpen}
              />
            }
          />
        )}
    </>
  );
};

export default Home;
