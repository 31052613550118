import CommunicationApi from "../apis/communicaionApi/communicationApi";

export const sendNotification = async (
    actionType: string,
    payload: any = {}
  ) => {
    try {
      const response = await CommunicationApi.sendBifrostNotificationViaSms({
        ...payload,
        actionType: actionType
      });
    } catch (error) {
      console.log(error);
    }
  };