import { useSelector } from "react-redux";
import { Header } from "../../components";
import IntermediateText from "../home/intermediateText";
import WelcomeOffer from "../home/welcomeOffersSection/WelcomeOffer";
import styles from "./allWelcomeOffers.module.scss";
import { getCardDetails } from "../../features/user/userSlice";
import { capitalize } from "lodash";
import { RootState } from "../../app/store";

const AllWelcomeOffers = () => {
  const cardDetails = useSelector(getCardDetails);
  const benefits = useSelector(
    (state: RootState) => state.benefits.welcomeBenefits.benefits
  );
  return (
    <div>
      <Header label={`Exclusive Benefits`} />
      <div className={styles.container}>
        <IntermediateText
          text1="all welcome offers"
          text2={`on your ${capitalize(cardDetails?.cardType)} Credit Card`}
        />
      </div>
      <div className={styles.offerList}>
        {benefits?.map((benefit) => {
          const offerDetails = JSON.parse(benefit.offerDetails);
          return (
            <WelcomeOffer
              key={benefit.benefitId}
              id={benefit.benefitId}
              title={benefit.displayTitle}
              description={benefit.displayDescription}
              background={benefit.displayColor}
              offerImg={benefit.illustrationUrl}
              merchantLogoImg={benefit.logoUrl}
              hasDetails={
                offerDetails.redeemSteps || offerDetails.tncConditions
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllWelcomeOffers;
