/*  eslint-disable  react/no-unstable-nested-components */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { Header, Button2, Button, ToastType, toast } from "../../components";
import styles from "./deviceLimit.module.scss";
import DeviceLimitImage from "../../assets/svgs/deviceLimitError.svg";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import Chrome from "../../assets/svgs/chrome.svg";
import Safari from "../../assets/svgs/safari.svg";
import Other from "../../assets/svgs/other.svg";
import DeviceApi from "../../apis/deviceApi/deviceApi";
import {
  aesDecryptData,
  aesDecryptDeviceData
} from "../../utils/encryptionUtil";
import { toast as toastLib } from "react-toastify";
import AuthApi from "../../apis/authApi/AuthApi";
import { getUserOSAndVersion } from "../../utils/trackingFunctions";
import {
  autoReadOtp,
  focusOnInput,
  createCookie,
  getUniqueURLFromCookie,
  getFormattedTimestamp
} from "../../utils/functions";
import { setIsDeviceIdVerified } from "../../features/user/userSlice";
import { RESEND_OTP_TIME } from "../../utils/constants";
import { TextWithLinks } from "../../components/textWithLinks/TextWithLinks";
import Caret from "../../assets/svgs/Vector.svg";
import { isManualOtpAllowed } from "../../utils/internalFlags";
import { UserAction } from "../../utils/enums";
import { sendNotification } from "../../utils/notifyCustomer";

const DeviceLimit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [devices, setDevices] = useState<any>(location.state.registeredDevices);
  const [otp, setOtp] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState<boolean>(false);
  const [timeOut, setTimeOut] = useState<number>(RESEND_OTP_TIME);
  const [isDeviceDeregisteredSuccesfully, setIsDeviceDeregisteredSuccesfully] =
    useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);
  const [isOtpBottomSheetOpen, setIsOtpBottomSheetOpen] =
    useState<boolean>(false);
  const [isVerifyLoginOtpLoading, setIsVerifyLoginOtpLoading] =
    useState<boolean>(false);
  const [mobileVerificationRefId, setMobileVerificationRefId] =
    useState<string>("");
  const [isGenerateOtpLoading, setIsGenerateOtpLoading] =
    useState<boolean>(false);
  const [osVersion, setOsVersion] = useState<string>("");
  const [deviceName, setDeviceName] = useState<string>("");
  const [isOtpVerifyLoading, setIsOtpVerifyLoading] = useState<boolean>(false);
  const [os, version] = getUserOSAndVersion(navigator.userAgent);
  const [mobileNumber, setMobileNumber] = useState("");

  const handleAutoRead = () => {
    if (os !== "Android") {
      autoReadOtp((otpValue) => {
        setOtp(otpValue);
        handleInputResponsive();
        focusOnInput("loginOtpInputContainer", 5);
      });
    }
  };

  const handleChange = (code: string) => {
    handleInputResponsive();
    setOtp(code);
  };

  const handleInputResponsive = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const inputWidth = inputElement.offsetWidth;
      // Calculate the effective character width considering the font size
      const charWidth = inputWidth / 6;
      // Adjust the letterSpacing based on the calculated charWidth and desired spacing
      const letterSpacing = Math.max(Math.floor(charWidth * 0.7), 1); // Adjust multiplier (0.8 here) based on your needs
      inputElement.style.letterSpacing = letterSpacing + "px";
    }
  };

  const handleKeyDown = (event: any) => {
    if (/\d/g.test(event.key) && !isManualOtpAllowed) {
      // Prevent the default action (entering the number)
      event.preventDefault();
      setOtp("");
    }
  };

  const ImageFunction = (browserName: string) => {
    if (!browserName) {
      return Other;
    }
    if (browserName.split(" ").includes("Chrome")) {
      return Chrome;
    } else if (browserName.split(" ").includes("Safari")) {
      return Safari;
    } else {
      return Other;
    }
  };

  const tncText =
    "By entering OTP and proceeding, I agree to the (Terms & Conditions)[https://www.indusind.com/in/en/personal/terms-and-conditions.html] and (Privacy Policy)[https://www.indusind.com/in/en/personal/privacy-policy.html] associated with IndusInd Bank Credit Card setup";

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (otp.length === 6) {
          handleOtpVerifyClick(otp);
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [otp]);

  useEffect(() => {
    if (os === "Android") {
      (navigator as any).userAgentData
        .getHighEntropyValues(["model", "platformVersion"])
        .then((values: any) => {
          setDeviceName(values?.model);
          setOsVersion(values?.platformVersion?.split(".")[0]);
        });
    }

    let updatedDevices: any = [];
    for (let device of devices) {
      if (device?.metaData?.clientData) {
        device.metaData.clientData = aesDecryptDeviceData(
          process.env.REACT_APP_PII_ENCRYPTION_KEY!,
          process.env.REACT_APP_PII_ENCRYPTION_IV!,
          device.metaData.clientData
        );
      }
      if (device?.metaData?.deviceData) {
        device.metaData.deviceData = aesDecryptDeviceData(
          process.env.REACT_APP_PII_ENCRYPTION_KEY!,
          process.env.REACT_APP_PII_ENCRYPTION_IV!,
          device?.metaData?.deviceData
        );
      }
      if (device?.lastLoggedInLocation) {
        device.lastLoggedInLocation = aesDecryptDeviceData(
          process.env.REACT_APP_PII_ENCRYPTION_KEY!,
          process.env.REACT_APP_PII_ENCRYPTION_IV!,
          device?.lastLoggedInLocation
        );
      }
      updatedDevices.push(device);
    }
    setDevices(updatedDevices);
  }, []);

  useEffect(() => {
    // Start the interval
    let interval: any;
    if (isOtpBottomSheetOpen && timeOut > 0) {
      interval = setTimeout(() => {
        setTimeOut(timeOut - 1);
      }, 1000);
    }

    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut, isOtpBottomSheetOpen]);

  const OTPBottomSheet = () => (
    <div className={styles.bottomSheet1}>
      <div className={styles.bottomSheetContainer1}>
        <div className={styles.bottomSheetHeader}>verify with OTP</div>
        <div className={styles.bottomSheetText}>
          Please enter the 6 digit OTP sent to +91 ***** **
          {mobileNumber.slice(-3)}
        </div>
        <div id="loginOtpInputContainer" className={styles.otpContainer}>
          <div className={styles.phoneNumberInputContainer}>
            <input
              id="inputPhone"
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              placeholder="------"
              className={styles.phoneNumberInput}
              style={{ opacity: otp.length > 0 ? 1 : 0.5 }}
              value={otp}
              maxLength={6}
              onChange={(e: any) => handleChange(e.target.value)}
              onKeyDown={handleKeyDown}
              readOnly={os === "Android" && !isManualOtpAllowed}
              ref={inputRef}
              autoFocus={os !== "Android"}
              onFocus={handleAutoRead}
            />
          </div>
          <div className={styles.resendContainer}>
            <div>Didn't get the OTP?</div>
            <div className={styles.resendText}>
              {timeOut !== 0 ? (
                <span className={styles.retryText}>
                  Retry in 00:{timeOut.toString().padStart(2, "0")}s
                </span>
              ) : (
                <>
                  <span onClick={handleResendOtpClick}>Resend SMS</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.tncContainer}>
            <div className={styles.tncText}>
              <TextWithLinks text={tncText} linkClassName={styles.linkText} />
            </div>
          </div>
        </div>
        <div className={styles.confirmBtn1Container4}>
          <Button2
            text1="confirm"
            // text2="started"
            customClassName={styles.confirmBtn4}
            isLoading={isVerifyLoginOtpLoading}
            onClick={() => handleOtpVerifyClick(otp)}
            disabled={otp.length !== 6 || isOtpVerifyLoading}
          />
        </div>
      </div>
      <div id="scroll1"></div>
    </div>
  );

  const DeviceSelectedRender = () => (
    <>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetContainer}>
          <img
            src={ImageFunction(selectedDevice?.metaData?.clientData?.name)}
            alt=""
          />
          <div className={styles.deviceDetailsText}>
            {selectedDevice?.metaData?.clientData?.name}
            <span className={styles.deviceDetailsSubText}>
              {selectedDevice?.metadata?.deviceData?.vendor !== ""
                ? (selectedDevice?.metaData?.deviceData?.vendor || "") +
                  " " +
                  (selectedDevice?.metaData?.deviceData?.model === "K"
                    ? "Android"
                    : selectedDevice?.metaData?.deviceData?.model || "")
                : "unknown"}
            </span>
          </div>
        </div>

        <div
          className={styles.deviceBar}
          style={{ height: "8%", width: "85%", margin: "10px auto" }}
        >
          <div className={styles.contentContainer}>
            <div style={{ display: "flex" }}>
              <div className={styles.selectedDevicebarText}>
                Last Seen
                <span className={styles.selectedDevicebarSubText}>
                  {getFormattedTimestamp(selectedDevice?.lastLoggedInTime)}
                </span>
              </div>
            </div>
            <div className={styles.lastLoggedTextSelectedDevice}>
              {selectedDevice?.lastLoggedInLocation}
            </div>
          </div>
        </div>

        <hr className={styles.horizontalLine} style={{ marginTop: "10%" }}></hr>

        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="de-register"
            text1Style={{ fontWeight: "bold" }}
            text2="this device"
            customClassName={styles.confirmBtn1}
            onClick={handleDeregisterDeviceClick}
            isLoading={isGenerateOtpLoading}
            disabled={isGenerateOtpLoading}
          />
          <Button
            text1="close"
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.confirmBtn1}
            onClick={handleDeviceBottomSheetClose}
          />
        </div>
      </div>
    </>
  );

  const DeviceRegisteredSucessfully = () => (
    <>
      {isLoading ? (
        <>
          <div className={styles.deviceDeregisteredText}>
            Registering current device...
          </div>
          <Loader />
        </>
      ) : (
        <>
          <div className={styles.deviceDeregisteredText}>
            Device de-registered successfully ✅
          </div>
          <div className={styles.deviceBar2}>
            <div className={styles.contentContainer}>
              <div style={{ display: "flex" }}>
                <img
                  src={ImageFunction(
                    selectedDevice?.metaData?.clientData?.name
                  )}
                  alt="Browser Image"
                />
                <div className={styles.barText}>
                  {selectedDevice?.metaData?.clientData?.name}
                  <span
                    className={styles.barSubText}
                    style={{
                      display: "block",
                      color: "rgba(252, 252, 252, 0.5)"
                    }}
                  >
                    {selectedDevice?.metadata?.deviceData?.brand !== ""
                      ? (selectedDevice?.metaData?.deviceData?.brand || "") +
                        " " +
                        (selectedDevice?.metaData?.deviceData?.model === "K"
                          ? "Android"
                          : selectedDevice?.metaData?.deviceData?.model || "")
                      : "unknown"}
                  </span>
                </div>
              </div>
              <div className={styles.lastLoggedText}>
                {selectedDevice?.lastLoggedInLocation}

                <span style={{ display: "block" }}>
                  {getFormattedTimestamp(selectedDevice?.lastLoggedInTime)}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
  const handleResendOtpClick = async () => {
    setTimeOut(RESEND_OTP_TIME);
    setIsGenerateOtpLoading(true);
    try {
      const response = await AuthApi.generateChallengeSmsOtpPrelogin({
        uniqueURL: location.state.uniqueURL || "",
        otpType: "DEREGISTER_DEVICE"
      });

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);

        setMobileNumber(response?.data?.lastFour);

        setMobileVerificationRefId(response?.data?.mobileVerificationRefId);
      } else if (response.status === 400) {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
      } else {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, "The OTP generation is failed.");
        }, 1000);
      }
    } catch (error) {
      console.error(
        `An exception occurred while generating OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
      setIsGenerateOtpLoading(false);
    }
  };

  const handleOtpVerifyClick = async (otpValue: string) => {
    setIsOtpVerifyLoading(true);
    try {
      const response = await AuthApi.verifyChallengeSmsOtpPrelogin({
        uniqueURL: location.state?.uniqueURL || "",
        mobileVerificationRefId: mobileVerificationRefId,
        otp: "" + otpValue
      });

      if (response.status === 200) {
        setIsVerifyLoginOtpLoading(true);
        deregisterDevice();
      } else {
        console.error(
          `An exception occurred while verifying OTP. Error:\n${response}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
        setIsOtpVerifyLoading(false);
      }
    } catch (error) {
      console.error(
        `An exception occurred while verifying OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
      setIsOtpVerifyLoading(false);
    }
  };

  const handleDeviceBarClick = (item: any) => {
    setIsDeviceSelected(true);
    setSelectedDevice(item);
  };
  const handleDeviceBottomSheetClose = () => {
    setIsDeviceSelected(!isDeviceSelected);
  };

  const handleDeregisterDeviceClick = async () => {
    setIsGenerateOtpLoading(true);
    setOtp("");
    try {
      const response = await AuthApi.generateChallengeSmsOtpPrelogin({
        uniqueURL: location.state.uniqueURL || "",
        otpType: "DEREGISTER_DEVICE"
      });

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);

        setMobileNumber(response?.data?.lastFour);

        autoReadOtp((otpValue) => {
          setOtp(otpValue);
          handleInputResponsive();
          // handleOtpVerifyClick(otpValue);
          focusOnInput("loginOtpInputContainer", 5);
        });

        setMobileVerificationRefId(response?.data?.mobileVerificationRefId);
        setIsOtpBottomSheetOpen(true);
        handleDeviceBottomSheetClose();
      } else if (response.status === 400) {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
      } else {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, "The OTP generation is failed.");
        }, 1000);
      }
    } catch (error) {
      console.error(
        `An exception occurred while generating OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
    setIsGenerateOtpLoading(false);
  };
  const deregisterDevice = async () => {
    try {
      const response = await DeviceApi.RemoveDevice({
        uniqueURL: location.state.uniqueURL,
        removeDeviceId: selectedDevice.deviceId,
        addDevice: true,
        osVersion: osVersion,
        deviceName: deviceName
      });
      if (response.status === 200) {
        setIsVerifyLoginOtpLoading(false);
        setIsOtpBottomSheetOpen(false);
        setIsDeviceDeregisteredSuccesfully(true);
        sendNotification(UserAction.DE_REGISTER, {
          uniqueURL: location.state.uniqueURL || getUniqueURLFromCookie(),
          deviceId: selectedDevice.deviceId
        });
        setTimeout(() => {
          sendNotification(UserAction.REGISTER, {
            uniqueURL: location.state.uniqueURL || getUniqueURLFromCookie(),
            deviceId: response?.data?.deviceId
          });
          setIsDeviceIdVerified(true);
          createCookie(
            "deviceId",
            response?.data?.deviceId,
            "Tue, 19 Jan 2038 04:14:07 IST"
          );
          setIsLoading(true);
          setTimeout(() => {
            navigate(`/?uniqueURL=${location.state.uniqueURL}&step=1`);
          }, 1000);
        }, 3000);
        // setIsDeviceDeregisteredSuccesfully(false);
      } else {
        console.error(
          `An exception occurred while deregistering device. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to process your request. Please try again later"
          );
        }, 1000);
      }
    } catch (error) {
      console.error(
        `An exception occurred while deregistering device. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
    setIsOtpVerifyLoading(false);
  };

  return (
    <>
      <Header label="" bgColor="#141414" />

      <>
        <div className={styles.container}>
          <div className={styles.banner}>
            <img src={DeviceLimitImage} alt="" />
            <div className={styles.bannerText1Container}>
              device / browser limit reached
              <span className={styles.bannerText2}>
                You can have only{" "}
                <span
                  style={{ color: "var(--icon-colour, rgba(246, 163, 12, 1))" }}
                >
                  5 registered
                </span>{" "}
                devices/browsers
              </span>
            </div>
            <hr className={styles.horizontalLine}></hr>
            <div className={styles.bannerText2Container}>
              De-register one of these to continue
            </div>
          </div>

          <div className={styles.deviceContainer}>
            {devices.map((item: any, index: number) => {
              return (
                <>
                  <div
                    className={styles.deviceBar}
                    onClick={() => {
                      handleDeviceBarClick(item);
                    }}
                    key={index}
                  >
                    <div className={styles.contentContainer}>
                      <div style={{ display: "flex", width: "60%" }}>
                        <img
                          src={ImageFunction(item?.metaData?.clientData?.name)}
                          alt="Browser Image"
                        />
                        <div className={styles.barText}>
                          {item?.metaData?.clientData?.name}
                          <span
                            className={styles.barSubText}
                            style={{
                              display: "block",
                              color: "rgba(252, 252, 252, 0.5)"
                            }}
                          >
                            {item?.metadata?.deviceData?.vendor !== ""
                              ? (item?.metaData?.deviceData?.vendor || "") +
                                " " +
                                (item?.metaData?.deviceData?.model === "K"
                                  ? "Android"
                                  : item?.metaData?.deviceData?.model || "")
                              : "unknown"}
                          </span>
                        </div>
                      </div>
                      <div className={styles.lastLoggedText}>
                        {item?.lastLoggedInLocation}

                        <span style={{ display: "block" }}>
                          {getFormattedTimestamp(item?.lastLoggedInTime)}
                        </span>
                      </div>
                      <img src={Caret} alt="" style={{ marginLeft: "10px" }} />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <BottomsheetComponent
          isBottomSheetOpen={isDeviceSelected}
          setIsBottomSheetOpen={setIsDeviceSelected}
          render={DeviceSelectedRender()}
        />

        <BottomsheetComponent
          isBottomSheetOpen={isOtpBottomSheetOpen}
          setIsBottomSheetOpen={setIsOtpBottomSheetOpen}
          render={OTPBottomSheet()}
        />

        <BottomsheetComponent
          isBottomSheetOpen={isDeviceDeregisteredSuccesfully}
          setIsBottomSheetOpen={setIsDeviceDeregisteredSuccesfully}
          render={DeviceRegisteredSucessfully()}
        />
      </>
    </>
  );
};

export default DeviceLimit;
