import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { throttle } from "lodash";
import { clearSession } from "../features/session/sessionSlice";
import { clearUserIds } from "../features/user/userSlice";
import { LogoutProps } from "../apis/authApi/authApiTypes";
import AuthApi from "../apis/authApi/AuthApi";
import axios from "axios";
import { SEND_HEARTBEAT_ROUTE } from "../apis/ApiRoutes";
import { SESSION_TOKEN_HEADER } from "../utils/constants";

const sixSeconds = 6 * 1000;

const useIdle = (
  idleTime: number,
  isSessionLoaded: boolean,
  accountId: string,
  customerId: string,
  cardId: string
) => {
  const dispatch = useDispatch();
  const [isIdle, setIsIdle] = useState(false);

  const logout = async () => {
    try {
      const request: LogoutProps = {
        accountId: accountId,
        customerId: customerId,
        cardId: cardId,
        skipValidate: true
      };
      await AuthApi.logout(request);
    } catch (error) {
      console.error("An error occured while logging out. Error: \n", error);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const handleUserActivity = async () => {
      try {
        let response = null;
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars , @typescript-eslint/no-non-null-assertion
        const headers = {
          [SESSION_TOKEN_HEADER]: sessionStorage.getItem("sessionToken")
        };

        if (!!isSessionLoaded) {
          response = await axios({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + SEND_HEARTBEAT_ROUTE,
            data: null,
            headers: headers,
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            validateStatus: (status) => true // resolve for all status codes
          });
        }

        if (response && response?.status !== 200) {
          dispatch(clearSession());
          dispatch(clearUserIds());
        }
      } catch (error) {
        dispatch(clearSession());
        dispatch(clearUserIds());
      }

      if (!!isSessionLoaded) {
        !!timeout && clearTimeout(timeout);
        timeout = setTimeout(async () => {
          setIsIdle(true);
          await logout();
          dispatch(clearSession());
          dispatch(clearUserIds());
        }, idleTime);
      }
    };
    const throttleFun = throttle(handleUserActivity, sixSeconds);

    throttleFun();

    const eventListeners = [
      "mousedown",
      "mousemove",
      "keydown",
      "touchstart",
      "scroll",
      "keypress",
      "wheel"
    ];

    eventListeners.forEach((event) => {
      document.addEventListener(event, throttleFun);
    });

    return () => {
      eventListeners.forEach((event) => {
        document.removeEventListener(event, throttleFun);
      });

      clearTimeout(timeout);
    };
  }, [idleTime, isSessionLoaded]);

  return isIdle;
};

export default useIdle;
