/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import axios from "axios";
import store from "../app/store";
import { publicRoutePatterns, pciEncryptedRoutePatterns } from "./ApiRoutes";
import {
  INVALID_SESSION_STATUS_CODES,
  SESSION_TOKEN_HEADER
} from "../utils/constants";
import { aesEncryptData, aesDecryptData } from "../utils/encryptionUtil";
import * as InternalFlags from "../utils/internalFlags";
import { clearSession } from "../features/session/sessionSlice";
import { clearUserIds } from "../features/user/userSlice";

const isRoutePublic = (route: string): boolean =>
  publicRoutePatterns.filter((pattern) => pattern.test(route)).length > 0;

const isRoutePciEncrypted = (route: string): boolean =>
  pciEncryptedRoutePatterns.filter((pattern) => pattern.test(route)).length > 0;

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // eslint-disable-next-line
  validateStatus: function (status) {
    return true; // resolve for all status codes
  }
});

Api.interceptors.request.use(
  (req) => {
    const route = req.url;
    const data = req.data;
    const session = store.getState().session;

    // add session token
    if (!isRoutePublic(route!) && session.token) {
      req.headers[SESSION_TOKEN_HEADER] = session.token;
    }

    // encrypt request
    if (
      InternalFlags.isPciEncryptionEnabled &&
      data &&
      Object.keys(data).length > 0 &&
      isRoutePciEncrypted(route!)
    ) {
      const encryptedRequest = aesEncryptData(
        session.pciEncryptionKey!,
        session.pciEncryptionIv!,
        data
      );

      req.data = { encryptedRequest };
    }

    return req;
  },

  function (error) {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (res) => {
    const req = res.config;
    const route = req.url;
    const sessionToken = req.headers[SESSION_TOKEN_HEADER];
    const data = res.data;
    const session = store.getState().session;

    // check session
    if (INVALID_SESSION_STATUS_CODES.includes(res.status)) {
      store.dispatch(clearSession());
      store.dispatch(clearUserIds());
      return res;
    }

    // decrypt response
    if (
      InternalFlags.isPciEncryptionEnabled &&
      data &&
      Object.keys(data).length > 0 &&
      isRoutePciEncrypted(route!)
    ) {
      const encryptedResponse = data.encryptedResponse;

      if (!encryptedResponse) {
        throw new Error(
          `Failed to decrypt response for route ${route} and session token ${sessionToken}. Missing field 'encryptedResponse'.`
        );
      }

      const decryptedResponse = aesDecryptData(
        session.pciEncryptionKey!,
        session.pciEncryptionIv!,
        encryptedResponse
      );

      res.data = decryptedResponse;
    }

    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Api;
