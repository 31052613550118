import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./app/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { Env } from "./utils/enums";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (
  process.env.REACT_APP_NAME !== Env.DEV &&
  process.env.REACT_APP_NAME !== Env.DEV_MOBILE
) {
  console.log = () => {
    //
  };
}

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
