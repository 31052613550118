/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import type { AxiosResponse } from "axios";
import type {
  VerifiedChallengeReference,
  GetApiTokenRequest,
  GetApiTokenResponse,
  AuthenticateApiParams,
  AuthenticateApiResult
} from "./authTypes";
import store from "../app/store";
import Api from "../apis/Api";
import {
  setAuthState,
  setCurrentChallenge,
  resetAuthState
} from "../features/auth/authSlice";
import {
  AuthScope,
  AuthChallenge,
  Challenge,
  Flow
} from "../apis/appApi/appApiTypes";
import { AuthStatus } from "../features/auth/authSliceTypes";

// get api token
const getApiToken = async (
  apiId: string,
  flowId: string | null
): Promise<AxiosResponse<GetApiTokenResponse>> => {
  const authStore = store.getState().auth;
  const challenges = authStore.config![apiId].challenges;
  const conditional = authStore.config![apiId].conditional;
  const dynamicChallenges = authStore.config![apiId].dynamicChallenges || [];

  // get reference ids of challenges
  const deviceId = authStore.verifiedChallenges.deviceId;
  const smsOtpRefId = authStore.verifiedChallenges.smsOtpRefId;
  const emailOtpRefId = authStore.verifiedChallenges.emailOtpRefId;
  const mpinRefId = authStore.verifiedChallenges.mpinRefId;
  const customerRefId = authStore.verifiedChallenges.customerRefId;

  let challengesToUse = challenges || [];
  let requiredFlow = null;

  if (conditional) {
    requiredFlow = dynamicChallenges.find(
      (flow: Flow) =>
        flow.deviceTokenPresent === Boolean(deviceId) &&
        flow.mpinKnown === Boolean(mpinRefId) &&
        (flowId ? flow.flowId === flowId : true)
    );

    if (requiredFlow) {
      challengesToUse = requiredFlow.challenges;
    }
  }

  // get challenges
  const firstChallenge = challengesToUse.find(
    (data) => data.scope === AuthScope.FIRST_FACTOR
  );
  const secondChallenge = challengesToUse.find(
    (data) => data.scope === AuthScope.SECOND_FACTOR
  );

  const verifiedChallengeReference: VerifiedChallengeReference = {};

  // construct the 'verifiedChallengeReference' object according to required and completed challenges
  if (firstChallenge) {
    // if first challenge is required
    if (firstChallenge.challenge === AuthChallenge.OTP_SMS && smsOtpRefId) {
      // if first challenge's method is sms otp and an sms otp verification was done
      verifiedChallengeReference.firstFactorMethod = AuthChallenge.OTP_SMS;
      verifiedChallengeReference.firstFactorRefId = smsOtpRefId;
    }
    if (firstChallenge.challenge === AuthChallenge.OTP_EMAIL && emailOtpRefId) {
      verifiedChallengeReference.firstFactorMethod = AuthChallenge.OTP_EMAIL;
      verifiedChallengeReference.firstFactorRefId = emailOtpRefId;
    } else if (firstChallenge.challenge === AuthChallenge.MPIN && mpinRefId) {
      verifiedChallengeReference.firstFactorMethod = AuthChallenge.MPIN;
      verifiedChallengeReference.firstFactorRefId = mpinRefId;
    } else if (
      firstChallenge.challenge === AuthChallenge.VERIFY_CUSTOMER &&
      customerRefId
    ) {
      verifiedChallengeReference.firstFactorMethod =
        AuthChallenge.VERIFY_CUSTOMER;
      verifiedChallengeReference.firstFactorRefId = customerRefId;
    }
  }

  if (secondChallenge) {
    if (secondChallenge.challenge === AuthChallenge.OTP_SMS && smsOtpRefId) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenge.OTP_SMS;
      verifiedChallengeReference.secondFactorRefId = smsOtpRefId;
    } else if (
      secondChallenge.challenge === AuthChallenge.OTP_EMAIL &&
      emailOtpRefId
    ) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenge.OTP_EMAIL;
      verifiedChallengeReference.secondFactorRefId = emailOtpRefId;
    } else if (secondChallenge.challenge === AuthChallenge.MPIN && mpinRefId) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenge.MPIN;
      verifiedChallengeReference.secondFactorRefId = mpinRefId;
    } else if (
      secondChallenge.challenge === AuthChallenge.VERIFY_CUSTOMER &&
      customerRefId
    ) {
      verifiedChallengeReference.secondFactorMethod =
        AuthChallenge.VERIFY_CUSTOMER;
      verifiedChallengeReference.secondFactorRefId = customerRefId;
    }
  }

  // note: currently step up challenge doesn't have a cool off and hence it is not a part of the 'verifiedChallengeReference' object

  const req: GetApiTokenRequest = {
    apiId,
    verifiedChallengeReference
  };

  if (requiredFlow) {
    req.conditional = true;

    req.conditions = {
      flowId: requiredFlow.flowId
    };

    if (requiredFlow.deviceTokenPresent && deviceId) {
      req.conditions.deviceToken = deviceId;
    }

    if (requiredFlow.mpinKnown && mpinRefId) {
      req.conditions.mpinRefId = mpinRefId;
    }
  }

  const response: AxiosResponse<GetApiTokenResponse> = await Api.post(
    `/auth/apiToken`,
    req
  );

  return response;
};

// get the next challenge to be completed
// if no challenge is needed next then return null
const getNextChallenge = (challenges: Array<Challenge>): Challenge | null => {
  const authStore = store.getState().auth;

  // get challenges
  const firstChallenge = challenges.find(
    (data) => data.scope === AuthScope.FIRST_FACTOR
  );

  const secondChallenge = challenges.find(
    (data) => data.scope === AuthScope.SECOND_FACTOR
  );
  const stepUpChallenge = challenges.find(
    (data) => data.scope === AuthScope.STEP_UP
  );

  const currentChallenge = authStore.currentChallenge;
  let nextChallenge = null;

  if (currentChallenge === null) {
    // if current challenge isn't set
    // ideally preceding challenges should never be null, but handle that case
    nextChallenge =
      firstChallenge || secondChallenge || stepUpChallenge || null;
  } else if (currentChallenge.scope === AuthScope.FIRST_FACTOR) {
    // if current challenge's scope is first factor
    nextChallenge = secondChallenge || stepUpChallenge || null;
  } else if (currentChallenge.scope === AuthScope.SECOND_FACTOR) {
    nextChallenge = stepUpChallenge || null;
  }

  return nextChallenge;
};

// handle auth for api
export const authenticateApi = async ({
  apiId, // id of the api that is to be called
  actionText, // user friendly action text to be displayed in auth screen
  flowId = null, // id of the flow to be used in dynamic auth
  isAuthScreenOpaque = false
}: AuthenticateApiParams): Promise<AuthenticateApiResult> => {
  try {
    // check from config if auth is required
    const isAuthRequired = store.getState().auth.config![apiId].result;
    if (isAuthRequired) {
      // if auth is required then api token would be needed for the api call

      // get the api token
      const response = await getApiToken(apiId, flowId);

      if (response.status === 200) {
        const { apiToken, challengeMetadata } = response.data;

        if (
          challengeMetadata &&
          Array.isArray(challengeMetadata) &&
          challengeMetadata.length > 0
        ) {
          // if a challenge needs to be completed
          // eslint-disable-next-line
          return new Promise((resolve, reject) => {
            // set auth data in store
            store.dispatch(
              setAuthState({
                apiId,
                apiToken,
                challenges: challengeMetadata, // all challenges
                currentChallenge: getNextChallenge(challengeMetadata)!,
                actionText,
                isAuthScreenOpaque,
                finishAuth: (params) => {
                  store.dispatch(resetAuthState());

                  if (params.status === AuthStatus.SUCCESS) {
                    resolve({ ...params, apiToken });
                  } else {
                    resolve(params);
                  }
                }
              })
            );
          });
        } else {
          // no challenge required, return success status with api token
          return { status: AuthStatus.SUCCESS, apiToken };
        }
      } else {
        return {
          status: AuthStatus.FAILURE,
          error: response.data?.error,
          message:
            "We are unable to process your request. Please try again later"
        };
      }
    } else {
      // auth not required, return success status with api token as null
      return { status: AuthStatus.SUCCESS, apiToken: null };
    }
  } catch (error) {
    return {
      status: AuthStatus.FAILURE,
      error,
      message: "We are unable to process your request. Please try again later"
    };
  }
};

export const finishChallenge = (): void => {
  const authStore = store.getState().auth;

  // get next challenge
  const nextChallenge = getNextChallenge(authStore.challenges);

  if (nextChallenge) {
    // if another challenge is pending
    // update current challenge
    store.dispatch(setCurrentChallenge(null));
    setTimeout(() => {
      // first set current challenge as null and then asynchronously set it as next challenge
      // this is to handle the case that if same challenge is set in two consecutive scopes (which ideally shouldn't be configured) then state changes from null to that challenge and the challenge is retriggered
      store.dispatch(setCurrentChallenge(nextChallenge));
    });
    // a challenge's screen is shown if it is set as the current challenge
  } else {
    // else no more challenges to complete
    // return success status
    authStore.finishAuth!({ status: AuthStatus.SUCCESS });
  }
};
