import styles from "./styles.module.scss";
import IntermediateText from "../intermediateText";
import WelcomeOffer from "./WelcomeOffer";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { ALL_WELCOME_OFFERS_ROUTE } from "../../../routes/ScreenRoutes";
import { useSelector } from "react-redux";
import { getCardDetails } from "../../../features/user/userSlice";
import { RootState } from "../../../app/store";
import { capitalizeFirsLetter } from "../../../utils/functions";

const Index = () => {
  const navigate = useNavigate();
  const cardDetails = useSelector(getCardDetails);
  const benefits = useSelector(
    (state: RootState) => state.benefits.welcomeBenefits.benefits
  );
  const handleOnClick = () => {
    navigate(ALL_WELCOME_OFFERS_ROUTE);
  };
  return (
    <div className={styles.welcomeOffersSectionContainer}>
      <img
        className={styles.background}
        src={"/images/patterns/semicircleripple.svg"}
        alt="semicircle"
      />
      <div className={styles.background2}></div>
      <div className={styles.intermediateTextContainer}>
        <IntermediateText
          icon="/images/home/titleIcon2.svg"
          text1="welcome offers"
          text2={`on your ${capitalizeFirsLetter(
            cardDetails?.cardType
          )} Credit Card`}
        />
      </div>
      <div className={styles.offerList}>
        {// eslint-disable-next-line 
        benefits?.map((benefit, index) => {
          const offerDetails = JSON.parse(benefit.offerDetails);
          return (
            <WelcomeOffer
              key={benefit.benefitId}
              id={benefit.benefitId}
              title={benefit.displayTitle}
              description={benefit.displayDescription}
              background={benefit.displayColor}
              offerImg={benefit.illustrationUrl}
              merchantLogoImg={benefit.logoUrl}
              hasDetails={
                offerDetails.redeemSteps || offerDetails.tncConditions
              }
            />
          );
        })}
      </div>
      <div className={styles.categoriesBtnContainer}>
        <Button
          customClassName={styles.categoriesBtn}
          text1="all"
          text2="offers"
          onClick={handleOnClick}
        />
      </div>
    </div>
  );
};

export default Index;
