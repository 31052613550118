import type { ReactNode } from "react";
import { BottomSheetCustom } from "@cred/neopop-web/lib/components";

interface BottomSheet2Props {
  isOpen: boolean;
  handleClose?: () => void;
  children: ReactNode;
}
const style={
  borderTopLeftRadius:"40px",
  borderTopRightRadius:"40px",
  backgroundColor:"#141414",
  borderTop: "1px solid #9A9A9A",
  paddingTop:"16px",
  margin: "auto",
  maxWidth: "550px"
}

export const BottomSheet2 = (props: BottomSheet2Props) => {
  const { isOpen, handleClose, children } = props;

  return (
    // eslint-disable-next-line
    <BottomSheetCustom open={isOpen} handleClose={handleClose || (() => {})} sheetStyle={style}>
      {children}
    </BottomSheetCustom>
  );
};
