import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface EventState {
  installPrompt: any; // Define the type of installPrompt
}

const initialState: EventState = {
  installPrompt: ""
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<any>) => {
      state.installPrompt = action.payload;
    }
  }
});

export const { setEvent } = eventSlice.actions;

export default eventSlice.reducer;

export const getEvent = (state: RootState): any =>
  state.event.installPrompt;