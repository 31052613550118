import type { ReactElement } from "react";
import { toast as toastifyToast } from "react-toastify";
import styles from "./toastStyles.module.scss";
import type { ToastOptions } from "./toastTypes";
import { ToastType } from "./toastTypes";
import error from "../../assets/svgs/error.svg";
import info from "../../assets/svgs/info.svg";
import success from "../../assets/svgs/CheckCircle.svg";
import warning from "../../assets/svgs/warning-icon.svg";

const toast = (
  type: ToastType,
  content: string | ReactElement,
  icon?: string,
  options?: ToastOptions
) => {
  let iconToShow;

  if (icon) {
    iconToShow = icon;
  } else {
    switch (type) {
      case ToastType.SUCCESS: {
        iconToShow = success;
        break;
      }
      case ToastType.ERROR: {
        iconToShow = error;
        break;
      }
      case ToastType.INFO: {
        iconToShow = info;
        break;
      }
      case ToastType.WARNING: {
        iconToShow = warning;
        break;
      }
      // eslint-disable-next-line 
      default: {
      }
    }
  }

  const finalOptions = {
    position: toastifyToast.POSITION.BOTTOM_CENTER,
    autoClose: 1500,
    hideProgressBar: true,
    closeButton: false,
    className: styles.toastContainer,
    ...options
  };

  toastifyToast(
    <div className={styles.toastBody}>
      {iconToShow && (
        <img
          style={{ width: "25px", height: "25px" }}
          src={iconToShow}
          alt=""
        />
      )}
      <div className={styles.toastBodyText}>{content}</div>
    </div>,
    finalOptions
  );
};

export { toast };
