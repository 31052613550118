export enum NudgeScope {
  ISSUER = "ISSUER",
  CLIENT = "CLIENT",
  PROGRAM = "PROGRAM"
}

export enum NudgeType {
  SYSTEM = "SYSTEM",
  PROMOTION = "PROMOTION",
  OTHER = "OTHER"
}

export enum NudgeStatus {
  SCHEDULED = "SCHEDULED",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  SUSPENDED = "SUSPENDED"
}

export enum NudgeRequireType {
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL"
}

export interface NudgeDisplayOptions {
  id: string;
  displayOrder: number;
  requireType: NudgeRequireType;
  nudgeDetails: string;
}

export interface GetNudgesForAccountResponseElement {
  id: string;
  name: string;
  description: string;
  scope: NudgeScope;
  scopeId: string;
  nudgeType: NudgeType;
  status: NudgeStatus;
  requireType?: any;
  startDate: string;
  endDate: string;
  displayOptions: NudgeDisplayOptions;
  displayConstraints?: DisplayConstraints[];
}

export interface DisplayConstraints {
  type: string;
  entityType: string;
  entityAttribute: "IMPRESSION_COUNT" | "CTR_COUNT";
  filter: Filter;
}

export interface Filter {
  type: "EQ" | "GT" | "GTE" | "LT" | "LTE" | "GTE_PCT";
  value: number;
}

export type GetNudgesForAccountResponse =
  Array<GetNudgesForAccountResponseElement>;
