import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./rewardRedemption.module.scss";
import InterMediateText from "../../../pages/home/intermediateText";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button2 } from "../../button/Button";

import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";
import { Pagination } from "swiper";
import cards from "../../../utils/rewardsRedemption";
import { getCardDetails } from "../../../features/user/userSlice";

interface RewardRedemptionComponentProps {
  setRewardPointBottomSheet: React.Dispatch<React.SetStateAction<boolean>>;
  points: number;
}

type Item = {
  imageUrl: string;
  ctaText: string;
};

const RewardRedemption = (props: RewardRedemptionComponentProps) => {
  const { setRewardPointBottomSheet, points } = props;
  const [cardToDisplay, setCardToDisplay] = useState<Item[]>([]);
  const [redirectionUrl, setRedirectionUrl] = useState<string>("");
  const [redirectionButtonText, setRedirectionButtonText] =
    useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const cardDetails = useSelector(getCardDetails);

  const cardType = cardDetails.cardType;

  const handleRewardRedeemClick = () => {
    setRewardPointBottomSheet(false);
    window.open(`${redirectionUrl}`, "_blank");
  };

  useEffect(() => {
    if (cards[cardType as keyof typeof cards]) {
      setCardToDisplay(cards[cardType as keyof typeof cards]?.cardDetails);
      setRedirectionButtonText(
        cards[cardType as keyof typeof cards]?.redirectionButtonText
      );
      setRedirectionUrl(cards[cardType as keyof typeof cards]?.redirectionUrl);
    } else {
      props.setRewardPointBottomSheet(false);
    }
  }, []);

  return (
    <div className={styles.swipperBody}>
      <div className={styles["header-text"]}>
        <InterMediateText
          text1="Make the most of your"
          text2={`${points} pts`}
        />
      </div>

      <div className={styles.rewardCard}>
        <Swiper
          effect="cards"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1.7}
          spaceBetween={0}
          coverflowEffect={{
            rotate: 30,
            depth: 100,
            modifier: 1,
            slideShadows: false
          }}
          pagination={{
            dynamicBullets: true
          }}
          modules={[Pagination]}
          loop={false}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.7,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 20
            },
            450: {
              slidesPerView: 2.0,
              spaceBetween: 20
            },
            490: {
              slidesPerView: 2.0,
              spaceBetween: 30
            }
            // Removing Breakpoints beyond 0.6 as maximum width of application is 550px.
          }}
        >
          {cardToDisplay.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={styles.outerDiv}>
                <div
                  className={`${styles.card} ${
                    index === activeIndex
                      ? styles.swiperfixedwidth300
                      : styles.swiperfixedwidth100
                  }`}
                >
                  <div className={styles.cardText}>{item.ctaText}</div>
                  <div className={styles.cardImage}>
                    <img src={item.imageUrl} alt="Card Image" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={styles.confirmBtn1Container}>
        <Button2
          text1="redeem"
          text1Style={{ fontWeight: "bold" }}
          text2={`your ${redirectionButtonText}`}
          customClassName={styles.confirmBtn1}
          isLoading={false}
          onClick={handleRewardRedeemClick}
        />
      </div>
    </div>
  );
};

export default RewardRedemption;
