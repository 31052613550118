import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./browserNotSupportedScreen.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import NonCompatible from "../../assets/svgs/Browser & Version Non-Compatible/non-compatible-browser.svg";
import Chrome from "../../assets/svgs/Browser & Version Non-Compatible/chrome.svg";
import Safari from "../../assets/svgs/Browser & Version Non-Compatible/safari.svg";
import Opera from "../../assets/svgs/Browser & Version Non-Compatible/opera.svg";
import FireFox from "../../assets/svgs/Browser & Version Non-Compatible/firefox.svg";

const OsNotSupportedScreen = () => {
  const location = useLocation();
  const [imageArray, setImageArray] = useState<any>([]);
  useEffect(() => {
    const textImageAssigner = (text: any) => {
      switch (text) {
        case "Chrome": {
          return { img: Chrome, text: "Chrome" };
        }
        case "Mobile Safari": {
          return { img: Safari, text: "Safari" };
        }
        case "Opera": {
          return { img: Opera, text: "Opera" };
        }
        case "Firefox": {
          return { img: FireFox, text: "FireFox" };
        }
        default:{
          return Chrome
        }
      }
    };
    let arr = [];
    for (const key of location.state) {
      arr.push(textImageAssigner(key));
    }
    setImageArray(arr);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <img src={IndusInd_Logo} alt="IndusInd Logo" />
      </div>

      <div className={styles.body}>
        <div className={styles.nonCompatibleImage}>
          <img src={NonCompatible} alt="Image" />
          <div className={styles.bannerText}>
            This browser is not compatible{" "}
            <span style={{ color: "rgba(255, 255, 255, 1)", display: "block" }}>
              with IndusInd Bank CardsHub
            </span>
            <div className={styles.subText}>
              Access it securely using a supported browser.
            </div>
          </div>

          <div className={styles.browserContainer}>
            <div className={styles.browserContainerText}>
              recomended browsers
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <hr className={styles.horizontalLine2} />
            </div>
            <div className={styles.browserImage}>
              {imageArray.map((item: any, index: number) => {
                return (
                  <div key={index} className={styles.image}>
                    <img src={item.img} alt="" />
                    {item.text}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OsNotSupportedScreen;
