import React from "react";
import styles from "./contact.module.scss";
import buttonIcon from "../../assets/svgs/whiteBackgroundArrow.svg";

const Contact = ({ type, icon, text1, text2 }: any) => {
  const handleContactClick = ()=>{
    if (type==="Phone"){
      window.open("tel: 1860 267 7777", "_self");
    }
    else if (type==="Email"){
      window.open("mailto: premium.care@indusind.com", "_self");
    }
  }
  return (
    <>
      <div className={styles.bar}>
        <img src={icon} alt="" style={{ width: "18%" }} />
        <div className={styles.barText}>
          {text1}
          <span style={{ display: "block", color: "rgba(244, 179, 72, 1)" }}>
            {text2}
          </span>
        </div>
      </div>
      <img
        src={buttonIcon}
        alt=""
        style={{
          width: "8%",
          paddingRight:"15px"
        }}
        onClick={handleContactClick}
      />
    </>
  );
};

export default Contact;
