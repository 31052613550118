/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import styles from "./viewStatementStyles.module.scss";
import arrowDown from "../../assets/svgs/arrow-down.svg";
import { formatAmount } from "../../utils/functions";
import { getSelectedAccount } from "../../features/user/userSlice";
import {
  AnalyseButton,
  Button,
  Button2,
  Header,
  Selector
} from "../../components";
import { VIEW_TRANSACTIONS_ROUTE } from "../../routes/ScreenRoutes";
import type { Statement } from "../../apis/accountsApi/accountsApiTypes";
import type { BillingCycle } from "./viewStatementTypes";
import Loader from "../../components/loader/loader";
import { statementMinus, statementPlus } from "../../assets/svgs";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import { RootState } from "../../app/store";
import * as InternalFlags from "../../utils/internalFlags";
import ZeroStateCactus from "../../components/zeroStateCactus/ZeroStateCactus";

const getCorrespondingStatementIndex = (
  statements: Statement[],
  billingCycle: BillingCycle
) =>
  statements.findIndex(
    (statement) =>
      dayjs(statement.fromDate).format("YYYY-MM-DD") ===
      dayjs(billingCycle.from).format("YYYY-MM-DD")
  );
const ViewStatement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = useSelector(getSelectedAccount)!;
  const statementBillingCycle = useSelector(
    (state: RootState) => state.miscellaneous.statementBillingCycle
  )!;
  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([]);
  const misBillingCycle = useSelector(
    (state: RootState) => state.miscellaneous.selectedBillingCycle
  );
  const statements = useSelector(
    (state: RootState) => state.miscellaneous.statements
  )!;
  const billingCyclesSelector = useSelector(
    (state: RootState) => state.miscellaneous.billingCycles
  )!;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCycleSelectionOpen, setIsCycleSelectionOpen] =
    useState<boolean>(false);

  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle | null>(misBillingCycle || null);
  const [selectedStatement, setSelectedStatement] = useState<Statement | null>(
    null
  );

  const parsedDate = dayjs(account.currentCycleStartDate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm:ssZ");

  useEffect(() => {
    const selectedBillingCyclesCheck = !!selectedBillingCycle?.from
      ? dayjs(selectedBillingCycle?.to).format("DD-MM-YYYY") ===
        dayjs().format("DD-MM-YYYY")
        ? billingCyclesSelector[1]
        : selectedBillingCycle
      : billingCyclesSelector[1];
    setBillingCycles([...billingCyclesSelector].slice(1));
    setSelectedBillingCycle(selectedBillingCyclesCheck);
  }, [account.currentCycleStartDate]);

  const getStatements = useCallback(async () => {
    // earlier cycle change was trigger to get cycle of that statement, now all are fetched at once
    // if (!selectedBillingCycle) return;

    setIsLoading(true);

    let correspondingStatementIndex = -1;

    if (statementBillingCycle?.from) {
      correspondingStatementIndex = getCorrespondingStatementIndex(
        statements,
        statementBillingCycle
      );
    }

    if (correspondingStatementIndex > -1) {
      setSelectedStatement(statements[correspondingStatementIndex]);
      setSelectedBillingCycle(statementBillingCycle);
    } else {
      // setSelectedBillingCycle(billingCycles[0]);
      // setSelectedStatement(statements[0]);
      setSelectedStatement(null);
    }

    setIsLoading(false);
  }, [navigate, account.id]);

  useEffect(() => {
    getStatements();
  }, [getStatements]);

  const openCycleSelection = () => {
    setIsCycleSelectionOpen(true);
  };

  const closeCycleSelection = () => {
    setIsCycleSelectionOpen(false);
  };

  const handlePayBillClick = () => {
    window.open(
      "https://pay.billdesk.com/cardnet-instapay/induscard",
      "_blank"
    );
  };
  const handleViewBilledTransactionsClick = () => {
    navigate(VIEW_TRANSACTIONS_ROUTE, {
      state: {
        statementSelectedCycle: selectedBillingCycle
      }
    });
  };

  const onSelectedBillingCycleChange = (billingCycle: BillingCycle) => {
    // set billing cycle
    dispatch(setMiscellaneousState({ statementBillingCycle: billingCycle }));
    setSelectedBillingCycle(billingCycle);

    // set corresponding statement
    const correspondingStatementIndex = getCorrespondingStatementIndex(
      statements,
      billingCycle
    );

    if (correspondingStatementIndex > -1) {
      setSelectedStatement(statements[correspondingStatementIndex]);
    } else {
      // setSelectedBillingCycle(billingCycles[0]);
      setSelectedStatement(null);
    }

    // close the selection menu
    closeCycleSelection();
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="statement" />
    </div>
  ) : (
    <>
      <div className={styles.headerContiner}>
        <Header label="Statements" />
        {InternalFlags.P1 && (
          <div className={styles.analyse}>
            {" "}
            <AnalyseButton billingCycle={selectedBillingCycle} />
          </div>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.dateContainer}>
          <div>
            {/* <span className={styles.chooseText}>choose </span> */}
            <span className={styles.cycleText}>Select a Billing Cycle</span>
          </div>
          <div className={styles.dateContainer2} onClick={openCycleSelection}>
            <span className={styles.selectedCycleText}>
              {dayjs(selectedBillingCycle!.from).format("DD MMM ‘YY")} -{" "}
              {dayjs(selectedBillingCycle!.to).format("DD MMM ‘YY")}
            </span>
            <img src={arrowDown} alt="" />
          </div>
          <div className={styles.divider}></div>
          {!selectedStatement && (
            <ZeroStateCactus
              title1="You have no transactions "
              title2="for this billing cycle"
              subTitle=""
            />
          )}
          {selectedStatement && (
            <>
              <div className={styles.breakUp1Container}>
                <div>
                  <span className={styles.yourText}>your </span>
                  <span className={styles.statementText}>
                    statement summary
                  </span>
                </div>
                <div className={styles.amountTextContainer}>
                  <div className={styles.flexContainer}>
                    <div className={styles.amountTextContainerLeft}>
                      <span>Opening Balance</span>
                    </div>
                    <div className={styles.amountTextContainerRight}>
                      <span>
                        {formatAmount(
                          selectedStatement!.openingBalance?.totalBalance
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.flexContainer}>
                    <div className={styles.amountTextContainerLeft}>
                      <span>Purchase/Debits</span>
                    </div>
                    <div className={styles.iconCircle}>
                      <img className={styles.plus} src={statementPlus} />
                    </div>
                    <div className={styles.amountTextContainerRight}>
                      <span>
                        {formatAmount(selectedStatement!.totalDebits)}
                      </span>
                    </div>
                  </div>
                  <div className={styles.flexContainer}>
                    <div className={styles.amountTextContainerLeft}>
                      <span>Payment</span>
                    </div>
                    <div className={styles.iconCircle}>
                      <img className={styles.minus} src={statementMinus} />
                    </div>
                    <div className={styles.amountTextContainerRight}>
                      <span>{formatAmount(selectedStatement!.payments)}</span>
                    </div>
                  </div>
                  <div className={styles.flexContainer}>
                    <div className={styles.amountTextContainerLeft}>
                      <span>Refund/Credits</span>
                    </div>
                    <div className={styles.iconCircle}>
                      <img className={styles.minus} src={statementMinus} />
                    </div>
                    <div className={styles.amountTextContainerRight}>
                      <span>
                        {formatAmount(selectedStatement!.refundsAndCredits)}
                      </span>
                    </div>
                  </div>
                  <div className={styles.flexContainer}>
                    <div className={styles.amountTextContainerLeft}>
                      <span>Cashbacks</span>
                    </div>
                    <div className={styles.iconCircle}>
                      <img className={styles.minus} src={statementMinus} />
                    </div>
                    <div className={styles.amountTextContainerRight}>
                      <span>{formatAmount(selectedStatement!.cashback)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.breakUp2Container}>
                <div className={styles.flexContainer}>
                  <div className={styles.amountTextContainerLeft}>
                    <span>Total Amount Due</span>
                  </div>
                  <div className={styles.amountTextContainerRight}>
                    <span style={{ color: "#f4b348", fontWeight: "600" }}>
                      {formatAmount(selectedStatement!.totalAmountDue)}
                    </span>
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.amountTextContainerLeft}>
                    <span>Min. Amount Due</span>
                  </div>
                  <div className={styles.amountTextContainerRight}>
                    <span>
                      {formatAmount(selectedStatement!.minimumAmountDue)}
                    </span>
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.amountTextContainerLeft}>
                    <span>Due Date</span>
                  </div>
                  <div className={styles.amountTextContainerRight}>
                    <span>
                      {dayjs(selectedStatement!.dueDate).format("DD MMM YY")}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedStatement && (
        <div className={styles.btnContainer}>
          <Button2
            onClick={handlePayBillClick}
            customClassName={styles.btn}
            text1="pay"
            text2="bill"
          />
          <Button
            onClick={handleViewBilledTransactionsClick}
            customClassName={styles.btn}
            text1="view"
            text2="billed transactions"
          />
        </div>
      )}
      <Selector<BillingCycle>
        isOpen={isCycleSelectionOpen}
        title="Select a Billing Cycle"
        items={billingCycles}
        renderLabel={(billingCycle) =>
          `${dayjs(billingCycle.from).format("MMMM DD")} - ${dayjs(
            billingCycle.to
          ).format("MMMM DD")}`
        }
        isItemSelected={(billingCycle) =>
          billingCycle.from === selectedBillingCycle?.from
        }
        onSelect={onSelectedBillingCycleChange}
        handleClose={closeCycleSelection}
        footer="Please login to IndusMobile/IndusNet to view your statements prior to 6 months"
      />
    </>
  );
};

export default ViewStatement;
