/*  eslint-disable react/no-unstable-nested-components, */
import React, { useState } from "react";
import styles from "./invalidUniqueUrl.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import { Button, Button2 } from "../button/Button";
import BottomsheetComponent from "../bottomSheet/bottomsheet";
import Contact from "../contact/contact";
import { CONTACT_MOBILE_NUMBER } from "../../utils/constants";
import callContact from "../../assets/svgs/callContact.svg";
import emailContact from "../../assets/svgs/emailContact.svg";
import RectangleOverHeadLight from "../../assets/svgs/rectangleOverHeadLight.svg";
import UniqueUrlWrongImage from "../../assets/svgs/uniqueUrlWrongImg.svg";
import QuarterCirclePattern from "../../assets/svgs/patterns/quarterCirclePattern.svg";

const InvalidUniqueUrl = () => {
  const [isContactSupportOpen, setIsContactSupportOpen] =
    useState<boolean>(false);

  const handleContactClick = () => {
    setIsContactSupportOpen(!isContactSupportOpen);
  };

  const ContactSupport = () => (
    <>
      <div className={styles.contactbottomSheet}>
        <div className={styles.contactbottomSheetContainer}>
          <div className={styles.contactBar}>
            <div className={styles.contactphoneNumberBar}>
              <Contact
                icon={callContact}
                text1={"Call us here (24x7)"}
                text2={`${CONTACT_MOBILE_NUMBER}`}
                type="Phone"
              />
            </div>
            <div className={styles.contactbarSeperator}>
              <hr className={styles.contacthrLine} />
              <div className={styles.contacttextInSeperator}>or</div>
              <hr className={styles.contacthrLine} />
            </div>
            <div className={styles.contactphoneNumberBar}>
              <Contact
                icon={emailContact}
                text1={"Email us at"}
                text2={"premium.care@indusind.com"}
                type="Email"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactconfirmBtn1Container}>
        <Button2
          text1="close"
          customClassName={styles.contactconfirmBtn1}
          onClick={handleContactClick}
        />
      </div>
    </>
  );
  return (
    <>
      <div className={styles.preLoginContainer}>
        <img
          src={RectangleOverHeadLight}
          alt=""
          className={styles.overHeadLight}
        />
        <img
          src={QuarterCirclePattern}
          alt=""
          className={styles.overHeadLight}
        />
        <div className={styles.header}>
          <div>
            <img src={IndusInd_Logo} alt="IndusInd Bank" />
          </div>
        </div>

        <div className={styles.welcomeText}>IndusInd Bank CardsHub</div>

        <div className={styles.imageContainer}>
          <img src={UniqueUrlWrongImage} alt="App Install Image" />
        </div>

        <div className={styles.banner2}>
          <div className={styles.bottomtext1Container}>
            Looks like this URL is incorrect
          </div>

          <div className={styles.bottomtext2Container}>
            Access the CardsHub via{" "}
            <span className={styles.highlightedText}>secured login link</span>
            <span style={{ display: "block" }}>
              sent on your registered e-mail or mobile number
            </span>
          </div>
        </div>

        <div className={styles.contactcantFindText}>Can’t find the link?</div>
        <div className={styles.contactbtnContainer}>
          <Button
            text1="contact support"
            text1Style={{ fontWeight: "bold" }}
            // isLoading={isDeviceChecking}
            customClassName={styles.getStartedButton}
            onClick={handleContactClick}
          />
        </div>
      </div>

      <BottomsheetComponent
        isBottomSheetOpen={isContactSupportOpen}
        setIsBottomSheetOpen={setIsContactSupportOpen}
        render={ContactSupport()}
      />
    </>
  );
};

export default InvalidUniqueUrl;
