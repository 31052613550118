import { AxiosResponse } from "axios";
import Api from "../Api";
import { SEND_ACCOUNT_NOTIFICATION_VIA_SMS } from "../ApiRoutes";

const CommunicationApi = {
  sendBifrostNotificationViaSms: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(SEND_ACCOUNT_NOTIFICATION_VIA_SMS, data)
};

export default CommunicationApi;
