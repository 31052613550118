import React from "react";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import styles from "./ipBlockedScreen.module.scss";
import Satellite from "../../assets/svgs/satelite.svg";
import Globe from "../../assets/svgs/ip-blocked-desktop.svg";
import LottieView from "lottie-react";
import IpBlockedDesktop from "../../assets/LottieImages/ip-blocked-desktop.json";

const IpBlockedScreen = () => {
  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <img src={IndusInd_Logo} alt="" />
        </div>

        <div className={styles.satellite}>
          <img src={Satellite} alt="Satellite Image" />
        </div>

        <div className={styles.banner}>
          <div className={styles.text1}>
            IndusInd Bank CardsHub is accessible only within India
          </div>
          <div className={styles.subText}>
            Check in again once you’re back home :)
          </div>
        </div>

        <div className={styles.bottomImage}>
          <img src={Globe} alt="" />
        </div>
        <LottieView
          animationData={IpBlockedDesktop}
          loop={true}
          className={styles.cardLottie}
        />
      </div>
    </>
  );
};

export default IpBlockedScreen;
