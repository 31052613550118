export enum CustomerBlockCodeStatus {
  CARD_UNBLOCKED = "CARD_UNBLOCKED",
  MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER = "MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER",
  CARD_LOST_OR_SHREDDED = "CARD_LOST_OR_SHREDDED",
  NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE = "NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE",
  CIBIL_EXCEPTION_CASES = "CIBIL_EXCEPTION_CASES",
  FRAUD_BLOCK_CARDS = "FRAUD_BLOCK_CARDS",
  DELINQUENT_CUSTOMERS = "DELINQUENT_CUSTOMERS",
  CD_4 = "CD_4",
  CD_3 = "CD_3",
  COLLECTION = "COLLECTION",
  RE_CLASSIFIED_FOR_NPA = "RE_CLASSIFIED_FOR_NPA",
  CD_2 = "CD_2",
  RCU = "RCU",
  ACCOUNT_CLOSED_BY_BANK = "ACCOUNT_CLOSED_BY_BANK",
  ACCOUNT_CLOSED_BY_CUSTOMER = "ACCOUNT_CLOSED_BY_CUSTOMER",
  MOROTORIUM_CASES = "MOROTORIUM_CASES",
  FRAUD = "FRAUD",
  OVD_NOT_GIVEN = "OVD_NOT_GIVEN",

  // in case no block code combination matches
  NOT_FOUND = "NOT_FOUND"
}

export enum Action {
  RESET_CARD_PIN = "RESET_CARD_PIN",
  ENABLE_DOMESTIC_CARD_CONTROLS = "ENABLE_DOMESTIC_CARD_CONTROLS",
  DISABLE_DOMESTIC_CARD_CONTROLS = "DISABLE_DOMESTIC_CARD_CONTROLS",
  ENABLE_INTERNATIONAL_CARD_CONTROLS = "ENABLE_INTERNATIONAL_CARD_CONTROLS",
  DISABLE_INTERNATIONAL_CARD_CONTROLS = "DISABLE_INTERNATIONAL_CARD_CONTROLS",
  CONVERT_TRANSACTION_TO_EMI = "CONVERT_TRANSACTION_TO_EMI",
  LOCK_CARD = "LOCK_CARD",
  UNLOCK_CARD = "UNLOCK_CARD"
}

export enum ActionValue {
  YES = "YES",
  NO = "NO"
}

export type AccountBlockCode = string;
export type CardBlockCode = string;

export interface BlockCodeCustomerStatusMapping {
  customerBlockCodeStatus: CustomerBlockCodeStatus;
  accountBlockCodes: AccountBlockCode[] | "ANY";
  cardBlockCodes: CardBlockCode[] | "ANY";
}

export interface CustomerStatusActionMapping {
  customerBlockCodeStatus: CustomerBlockCodeStatus;
  isCardPrimary: boolean | "ANY";
  action: Action;
  actionValue: ActionValue;
}
