import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import styles from "./resetCardPinStyles.module.scss";
import { MANAGE_CARD_ROUTE } from "../../routes/ScreenRoutes";
import { Button2, Header, toast, ToastType } from "../../components";
import ApiIds from "../../auth/ApiIds";
import { authenticateApi } from "../../auth/auth";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { getCardDetails, getSelectedCard } from "../../features/user/userSlice";
import CardsApi from "../../apis/cardsApi/CardsApi";
import { CORRELATION_ID_HEADER, PIN_MASKING_CHAR } from "../../utils/constants";
import { capitalizeFirsLetter } from "../../utils/functions";

const getPin = (code: string, pin: string) => {
  const codeArray = code.split("");
  const pinArray = pin.split("");
  const changedIndex = codeArray.findIndex((char) => char !== PIN_MASKING_CHAR);
  if (changedIndex === -1) {
    pinArray.pop();
  }
  pinArray[changedIndex] = code[changedIndex];

  return pinArray.join("");
};

const getMaskedPin = (pin: string) => {
  return pin
    .split("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ?.map((char) => PIN_MASKING_CHAR)
    .join("");
};

const ResetCardPin = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const card = useSelector(getSelectedCard)!;
  const cardDetails = useSelector(getCardDetails);

  const confirmPinInputRef = useRef<OtpInput>(null);
  const pinInputRef = useRef<OtpInput>(null);

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");

  useEffect(() => {
    pinInputRef?.current?.focusInput(0);
  }, []);

  useEffect(() => {
    if (pin.length === 4) {
      confirmPinInputRef.current?.focusInput(0);
    }
  }, [pin, confirmPinInputRef]);

  const handlePinChange = (code: string) => {
    setPin(getPin(code, pin));
  };

  const handleConfirmPinChange = (code: string) => {
    setConfirmPin(getPin(code, confirmPin));
  };

  const handleSubmitClick = async () => {
    if (pin !== confirmPin) {
      toast(ToastType.ERROR, "PIN numbers do not match");
      return;
    }

    setIsSubmitLoading(true);

    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.SET_CARD_PIN,
        actionText: "to reset card pin"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: card.id,
          error: authResult.error,
          message: `An error occurred while authenticating reset card pin api`
        });
        toast(ToastType.ERROR, authResult.message);
        setIsSubmitLoading(false);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        setIsSubmitLoading(false);
        return;
      }

      const response = await CardsApi.resetCardPin(
        card.id,
        authResult.apiToken,
        { cardPin: pin }
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          cardId: card.id,
          responseData: response.data,
          message: `An error occurred while resetting card pin`
        });
        toast(
          ToastType.ERROR,
          "Your PIN generation request has failed. Please try again"
        );
        setIsSubmitLoading(false);
        return;
      }

      toast(ToastType.SUCCESS, "Your card pin has been changed successfully");
      navigate(MANAGE_CARD_ROUTE);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while resetting card pin`,
        error: error.message,
        cardId: card.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "Your PIN generation request has failed. Please try again"
      );
    }

    setIsSubmitLoading(false);
  };

  return (
    <>
      <Header bgColor="#141414" label="Change Card PIN" />
      <hr className={styles.horizontalLine}></hr>
      <div className={styles.resetPinContainer}>
        <div>
          <div className={styles.resetPinHeader}>
            Change Your Credit Card PIN
          </div>
          <div className={styles.resetPinSubHeader}>
            Change PIN for your IndusInd Bank{" "}
            {capitalizeFirsLetter(cardDetails?.cardType)} Credit Card
          </div>
        </div>
        <div className={styles.pinContainer}>
          <div className={styles.pinText}>Enter PIN</div>
          <div className={styles.pinContainer1}>
            {/* not setting "isInputSecure" as it doesn't show numeric only keypad */}
            <OtpInput
              value={getMaskedPin(pin)}
              onChange={handlePinChange}
              ref={pinInputRef}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum
              shouldAutoFocus
              inputStyle={{
                border: "0",
                borderBottom: "2px solid #353535",
                color: "white",
                width: "30px",
                padding: "0 5px",
                marginRight: "10px",
                textAlign: "center",
                fontSize: "2.5em",
                cursor: "pointer",
                willChange: "border",
                transition: "border .3s ease-in-out",
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                background: "transparent",
                outline: "none"
              }}
              containerStyle={{
                justifyContent: "space-between",
                width: "100%",
                display: "flex"
              }}
              focusStyle={{
                borderBottom: "1px solid #4e4e4e",
                outline: "none"
              }}
            />
          </div>
        </div>
        <div className={styles.pinContainer}>
          <div className={styles.pinText}>Confirm PIN</div>
          <div className={styles.pinContainer1}>
            <OtpInput
              value={getMaskedPin(confirmPin)}
              onChange={handleConfirmPinChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum
              shouldAutoFocus
              ref={confirmPinInputRef}
              inputStyle={{
                border: "0",
                borderBottom: "2px solid #353535",
                color: "white",
                width: "30px",
                padding: "0 5px",
                marginRight: "10px",
                textAlign: "center",
                fontSize: "2.5em",
                cursor: "pointer",
                willChange: "border",
                transition: "border .3s ease-in-out",
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                background: "transparent",
                outline: "none"
              }}
              containerStyle={{
                justifyContent: "space-between",
                width: "100%",
                display: "flex"
              }}
              focusStyle={{
                borderBottom: "1px solid #4e4e4e",
                outline: "none"
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <Button2
            isLoading={isSubmitLoading}
            onClick={handleSubmitClick}
            disabled={pin.length !== 4 || confirmPin.length !== 4}
            customClassName={styles.closeButton}
            text1Style={{ fontWeight: 700 }}
            text1="submit"
          />
        </div>
      </div>
    </>
  );
};

export default ResetCardPin;
