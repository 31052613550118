/*  eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from "react";
import LottieView from "lottie-react";
import styles from "./cardBlocked.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import { Button2 } from "../../components/button/Button";
import pattern from "../../assets/svgs/patterns/ripple4.svg";
import CardBlockedLottie from "../../assets/LottieImages/access-blocked.json";
import { arrow2 } from "../../assets/svgs";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import Contact from "../../components/contact/contact";
import callContact from "../../assets/svgs/callContact.svg";
import emailContact from "../../assets/svgs/emailContact.svg";
import { CookieType } from "../../utils/enums";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { convertToISTAndFormat } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { CONTACT_MOBILE_NUMBER } from "../../utils/constants";
dayjs.extend(duration);
dayjs.extend(utc);

const CardBlocked = () => {
  const [isContactSupportOpen, setIsContactSupportOpen] =
    useState<boolean>(false);
  const handleContactSupportClick = () => {
    setIsContactSupportOpen(true);
  };
  const [cardBlockedTime, setCardBlockedTime] = useState<string>("");
  const [timeOut, setTimeOut] = useState<number>(0);

  const handleCardBlockTime = (cardBlockedTime: string) => {
    const currentTime = dayjs();
    const targetTime = dayjs(cardBlockedTime);
    const diffMilliseconds = targetTime.diff(currentTime);
    const duration = dayjs.duration(diffMilliseconds);
    if (
      duration.hours() <= 0 &&
      duration.minutes() <= 0 &&
      duration.seconds() <= 0
    ) {
      return false;
    }
    const formattedTimeLeft = `${duration.hours()}h:${duration.minutes()}m:${duration.seconds()}s`;

    return formattedTimeLeft;
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Start the interval
    const interval = setTimeout(() => {
      handleCardBlockTime(cardBlockedTime);
      if (!handleCardBlockTime(convertToISTAndFormat(cardBlockedTime))) {
        navigate("/");
        return;
      }
      setTimeOut(timeOut + 1);
    }, 1000);

    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut]);

  useEffect(() => {
    let cookieArray = document.cookie.split("; ");

    for (let key of cookieArray) {
      let cookiePair = key.split("=");
      if (cookiePair[0] === CookieType.BLOCKED_CARD_TIME) {
        setCardBlockedTime(cookiePair[1]);
        return;
      }
    }
  });

  const handleCloseClick = () => {
    setIsContactSupportOpen(!isContactSupportOpen);
  };

  const ContactSupport = () => (
    <>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetContainer}>
          <div className={styles.contactBar}>
            <div className={styles.phoneNumberBar}>
              <Contact
                icon={callContact}
                text1={"Call us here (24x7)"}
                text2={`${CONTACT_MOBILE_NUMBER}`}
                type="Phone"
              />
            </div>
            <div className={styles.barSeperator}>
              <hr className={styles.hrLine} />
              <div className={styles.textInSeperator}>or</div>
              <hr className={styles.hrLine} />
            </div>
            <div className={styles.phoneNumberBar}>
              <Contact
                icon={emailContact}
                text1={"Email us at"}
                text2={"premium.care@indusind.com"}
                type="Email"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.confirmBtn1Container}>
        <Button2
          text1="close"
          customClassName={styles.confirmBtn1}
          onClick={handleCloseClick}
        />
      </div>
    </>
  );
  return (
    <>
      <div className={styles.preLoginContainer}>
        <div className={styles.header}>
          <div>
            <img src={IndusInd_Logo} alt="IndusInd Bank" />
          </div>
        </div>

        <div className={styles.cardContainer}>
          <img src={pattern} alt="App Install Image" />
          <div className={styles.cardBlockedLottie}>
            <LottieView
              animationData={CardBlockedLottie}
              loop={true}
              className={styles.cardBlockLottie}
            />
          </div>
        </div>

        <div className={styles.banner2}>
          <div className={styles.bottomtext1Container}>
            <span className={styles.text1}>Your access is</span>
            <span>temporarily blocked</span>
          </div>
          <hr className={styles.horizontalLine2}></hr>

          <div className={styles.bottomtext2Container}>
            We have temporarily blocked your access as there were multiple
            unsuccessful attempts while verifying your identity.
            <span style={{ color: "rgba(250, 250, 250, 1)", display: "block" }}>
              Please retry after
              <span
                style={{ color: "var(--icon-colour, rgba(246, 163, 12, 1))" }}
              >
                &nbsp;{" "}
                {handleCardBlockTime(convertToISTAndFormat(cardBlockedTime))}
              </span>
            </span>
          </div>
        </div>

        <div className={styles.btnContainer}>
          <Button2
            text1="contact support"
            text1Style={{
              fontWeight: "bold",
              color: "rgba(245, 245, 245, 1)"
            }}
            icon={arrow2}
            customClassName={styles.getStartedButton}
            onClick={handleContactSupportClick}
          />
        </div>
      </div>

      <BottomsheetComponent
        isBottomSheetOpen={isContactSupportOpen}
        setIsBottomSheetOpen={setIsContactSupportOpen}
        render={ContactSupport()}
      />
    </>
  );
};

export default CardBlocked;
