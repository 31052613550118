/* eslint-disable  @typescript-eslint/no-non-null-assertion */

import type { AxiosResponse } from "axios";
import {
  type GetDesignConfigResponse,
  type GetAuthConfigRequest,
  type GetAuthConfigResponse,
  type GetCustomerResponse,
  type GetAccountResponse,
  type GetCardResponse,
  type GetCardsResponse,
  type GenerateSessionRequest,
  type EventCaptureRequest,
  EVENT_NAME,
  GetConfigDataRequest
} from "./appApiTypes";
import Api from "../Api";
import {
  GET_CUSTOMER_ROUTE,
  GET_ACCOUNT_ROUTE,
  GET_CARD_ROUTE,
  GET_DESIGN_CONFIG_ROUTE,
  GET_AUTH_CONFIG_ROUTE,
  GET_OTHET_CARDS_ROUTE,
  GENERATE_SESSION_ROUTE,
  SEND_HEARTBEAT_ROUTE,
  CAPTURE_EVENT_ROUTE,
  GET_CONFIG_DATA_ROUTE
} from "../ApiRoutes";
import {
  VerifyLoginOtpFailureResponse,
  VerifyLoginOtpSuccessResponse
} from "../loginApi/loginApiTypes";
import {
  getParameterByName,
  getUserBrowserAndVersion,
  getUserOSAndVersion
} from "../../utils/trackingFunctions";
import { getPWADisplayMode } from "../../utils/functions";
import { aesEncryptData } from "../../utils/encryptionUtil";

const AppApi = {
  getCustomer: (
    customerId: string
  ): Promise<AxiosResponse<GetCustomerResponse>> =>
    Api.get(GET_CUSTOMER_ROUTE.replace(":customerId", customerId)),

  getAccount: (
    accountId: string,
    cardId: string
  ): Promise<AxiosResponse<GetAccountResponse>> =>
    Api.get(
      GET_ACCOUNT_ROUTE.replace(":accountId", accountId).replace(
        ":cardId",
        cardId
      )
    ),

  getCard: (cardId: string): Promise<AxiosResponse<GetCardResponse>> =>
    Api.get(GET_CARD_ROUTE.replace(":cardId", cardId)),

  getOtherCards: (data: {
    customerId: string;
  }): Promise<AxiosResponse<GetCardsResponse[]>> =>
    Api.post(GET_OTHET_CARDS_ROUTE, data),

  getDesignConfig: (): Promise<AxiosResponse<GetDesignConfigResponse>> =>
    Api.get(GET_DESIGN_CONFIG_ROUTE),

  getConfigData: (
    data: GetConfigDataRequest
  ): Promise<AxiosResponse<any>> =>
    Api.post(GET_CONFIG_DATA_ROUTE, data),

  getAuthConfig: (
    data: GetAuthConfigRequest
  ): Promise<AxiosResponse<GetAuthConfigResponse>> =>
    Api.post(GET_AUTH_CONFIG_ROUTE, data),

  generateSession: (
    data: GenerateSessionRequest
  ): Promise<
    AxiosResponse<VerifyLoginOtpSuccessResponse | VerifyLoginOtpFailureResponse>
  > => Api.post(GENERATE_SESSION_ROUTE, data),

  sendHeartbeat: (): Promise<AxiosResponse<any>> =>
    Api.post(SEND_HEARTBEAT_ROUTE),

  captureEvent: (
    data: Partial<EventCaptureRequest>
  ): Promise<AxiosResponse<any>> => {
    const userAgent = navigator.userAgent;
    const [deviceOs, osVersion] = getUserOSAndVersion(userAgent);
    const [browser, browserVersion] = getUserBrowserAndVersion(userAgent);
    let previousRoute;

    previousRoute = localStorage.getItem("pathsHistory");
    previousRoute = JSON.parse(previousRoute || "")?.current;

    if (data?.eventData?.mobileNumber) {
      data.eventData.mobileNumber = aesEncryptData(
        process.env.REACT_APP_PII_ENCRYPTION_KEY!,
        process.env.REACT_APP_PII_ENCRYPTION_IV!,
        data.eventData.mobileNumber
      );
    }

    return Api.post(CAPTURE_EVENT_ROUTE, {
      ...data,
      eventData: {
        ...data.eventData
      },
      sessionMetaData: {
        ...data.sessionMetaData,
        referrer:
          // eslint-disable-next-line no-nested-ternary
          data.eventName === EVENT_NAME.HOME_PAGE_VIEW
            ? !!data?.sessionMetaData?.referrer
              ? data?.sessionMetaData?.referrer
              : previousRoute
            : data?.sessionMetaData?.referrer,
        utmSource:
          getParameterByName("utm_source", data.primarySource!) ||
          sessionStorage.getItem("utm_source") ||
          (getPWADisplayMode() === "app" ? "savedApp" : ""),
        utmCampaign:
          getParameterByName("utm_campaign", data.primarySource!) ||
          sessionStorage.getItem("utm_campaign") ||
          (getPWADisplayMode() === "app" ? "savedApp" : ""),
        utmMedium:
          getParameterByName("utm_medium", data.primarySource!) ||
          sessionStorage.getItem("utm_medium") ||
          (getPWADisplayMode() === "app" ? "savedApp" : "")
      },
      deviceData: {
        deviceOs,
        osVersion,
        browser,
        browserVersion
      }
    });
  }
};

export default AppApi;
