import React from "react";
import styles from "./olderVersion.module.scss";
import { useLocation } from "react-router-dom";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import Chrome from "../../assets/svgs/Browser & Version Non-Compatible/chrome.svg";
import Safari from "../../assets/svgs/Browser & Version Non-Compatible/safari.svg";
import Opera from "../../assets/svgs/Browser & Version Non-Compatible/opera.svg";
import FireFox from "../../assets/svgs/Browser & Version Non-Compatible/firefox.svg";
import Timer from "../../assets/svgs/Browser & Version Non-Compatible/old-browser-version.svg";

const OlderVersion = () => {
  const location = useLocation();
  const ImageFunction = () => {
    if (location.state.split(" ").includes("Chrome")) {
      return Chrome;
    } else if (location.state.split(" ").includes("Safari")) {
      return Safari;
    } else if (location.state.split(" ").includes("Opera")) {
      return Opera;
    } else if (location.state.split(" ").includes("Firefox")) {
      return FireFox;
    }
  };
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <img src={IndusInd_Logo} alt="IndusInd Logo" />
      </div>

      <div className={styles.body}>
        <div className={styles.nonCompatibleImage}>
          <img
            src={ImageFunction()}
            alt="Image"
            className={styles.browserImage}
          />
          <img src={Timer} alt="" className={styles.timerImage} />
          <div className={styles.bannerText}>
            Looks like you’re on an{" "}
            <span
              style={{
                color: "var(--icon-colour, rgba(246, 163, 12, 1))",
                display: "block"
              }}
            >
              older version of {location?.state}
            </span>
          </div>

          <div className={styles.browserContainer}>
            Please update your browser to experience IndusInd Bank CardsHub
          </div>
        </div>
      </div>
    </div>
  );
};

export default OlderVersion;
