import Flights from "../assets/svgs/SpendAnalytics/Flights.svg";
import Apparel from "../assets/svgs/SpendAnalytics/Apparels.svg";
import HomeDecor from "../assets/svgs/SpendAnalytics/HomeDecor.svg";
import Beverages from "../assets/svgs/SpendAnalytics/Beverages.svg";
import FoodDining from "../assets/svgs/SpendAnalytics/Food&Dining.svg";
import Fuel from "../assets/svgs/SpendAnalytics/Fuel.svg";
import Groceries from "../assets/svgs/SpendAnalytics/Groceries.svg";
import DepartmentalStore from "../assets/svgs/SpendAnalytics/DepartmentalStore.svg";
import DutyFreeStores from "../assets/svgs/SpendAnalytics/DutyFreeStore.svg";
import Healthcare from "../assets/svgs/SpendAnalytics/Healtcare.svg";
import BeautyWellness from "../assets/svgs/SpendAnalytics/BeautyAndWellness.svg";
import Entertainment from "../assets/svgs/SpendAnalytics/Entertainment.svg";
import HotelTours from "../assets/svgs/SpendAnalytics/HotelsAndTours.svg";
import Wallets from "../assets/svgs/SpendAnalytics/Wallets.svg";
import Insurance from "../assets/svgs/SpendAnalytics/Insurance.svg";
import RealEstate from "../assets/svgs/SpendAnalytics/RealEstate.svg";
import BusinessServices from "../assets/svgs/SpendAnalytics/BusinessServices.svg";
import NonAirTravel from "../assets/svgs/SpendAnalytics/NonAirTravel.svg";
import GamingGambling from "../assets/svgs/SpendAnalytics/GamingAndGambling.svg";
import Education from "../assets/svgs/SpendAnalytics/Education.svg";
import JewelleryWearables from "../assets/svgs/SpendAnalytics/Jewellery &Wearables.svg";
import LocalTravel from "../assets/svgs/SpendAnalytics/LocalTravel.svg";
import Government from "../assets/svgs/SpendAnalytics/Government.svg";
import AgriculturalGoods from "../assets/svgs/SpendAnalytics/AgriculturalGoods.svg";
import CashWithdrawal from "../assets/svgs/SpendAnalytics/Cash Withdrawal.svg";
import AutomobileStores from "../assets/svgs/SpendAnalytics/AutomobileStores.svg";
import ConsumerDurables from "../assets/svgs/SpendAnalytics/ConsumerDurables.svg";
import OfficeGoods from "../assets/svgs/SpendAnalytics/OfficeGoods.svg";
import WholesaleItems from "../assets/svgs/SpendAnalytics/WholesaleItems.svg";
import Online from "../assets/svgs/SpendAnalytics/Online.svg";
import Utilities from "../assets/svgs/SpendAnalytics/Utilities.svg";
import Others from "../assets/svgs/SpendAnalytics/Others.svg";
import { Transaction } from "../apis/accountsApi/accountsApiTypes";

export interface SpendCategories {
  [key: string]: string;
}

export interface SpendGroup {
  groupId: string;
  groupName: string;
  totalSpend: number;
  countOfTxns: number;
}

export interface ApiResponse {
  accountId: string;
  startDate: string;
  endDate: string;
  spendGroups: SpendGroup[];
}

export interface TransactionsApiResponse {
  accountId: string;
  startDate: string;
  endDate: string;
  spendGroup: SpendGroup;
  transactions: Transaction[];
}

export const imageObj: SpendCategories = {
  Airlines: Flights,
  "SHOPPING - CLOTHING STORE": Apparel,
  "HOME FURNISHING & HOUSEHOLD": HomeDecor,
  SHOPPING_ALCOHOL_CIGAR: Beverages,
  "FOOD & DINING": FoodDining,
  FUEL: Fuel,
  GROCERY_AND_SUPERMARKET: Groceries,
  "VARIETY OR DEPT. STORES": DepartmentalStore,
  "DUTY FREE STORES": DutyFreeStores,
  "PHARMA & HEALTHCARE": Healthcare,
  "WELLNESS & BEAUTY": BeautyWellness,
  "ENTERTAINMENT & RECREATION": Entertainment,
  "HOTEL TOUR TRAVEL": HotelTours,
  "WALLET_P2P_PREPAID CARD": Wallets,
  INSURANCE: Insurance,
  "REAL ESTATE": RealEstate,
  "BUSINESS SERVICES": BusinessServices,
  TRANSPORT_Excluding_Air: NonAirTravel,
  "GAMBLING BETTING LOTTERY": GamingGambling,
  "EDUCATIONAL ENTITIES": Education,
  "DISCRETIONARY JEWELRY WATCH": JewelleryWearables,
  "LOCAL COMMUTE": LocalTravel,
  "GOVT PAYMENTS": Government,
  "AGRI PRODUCTION RELATED": AgriculturalGoods,
  "CASH WITHDRWAL": CashWithdrawal,
  "AUTO MOBILE STORES": AutomobileStores,
  "CONSUMER DURABLES": ConsumerDurables,
  "OFFICE SUPPLY AND ELECTRONICS": OfficeGoods,
  "EQUIPMENT WHOLESELLER": WholesaleItems,
  "OTHER WHOLESELLER": WholesaleItems,
  "DIGITAL GOODS & SERVICES": Online,
  UTILITIES: Utilities,
  Uncategorised: Others,
  Others
};

export const spendCategoryName: SpendCategories = {
  Airlines: "Flights",
  "SHOPPING - CLOTHING STORE": "Apparel",
  "HOME FURNISHING & HOUSEHOLD": "Home Decor",
  SHOPPING_ALCOHOL_CIGAR: "Beverages",
  "FOOD & DINING": "Food & Dining",
  FUEL: "Fuel",
  GROCERY_AND_SUPERMARKET: "Groceries",
  "VARIETY OR DEPT. STORES": "Departmental stores",
  "DUTY FREE STORES": "Duty free stores",
  "PHARMA & HEALTHCARE": "Healthcare",
  "WELLNESS & BEAUTY": "Beauty & Wellness",
  "ENTERTAINMENT & RECREATION": "Entertainment",
  "HOTEL TOUR TRAVEL": "Hotel & Tours",
  "WALLET_P2P_PREPAID CARD": "Wallet",
  INSURANCE: "Insurance",
  "REAL ESTATE": "Real estate",
  "BUSINESS SERVICES": "Business services",
  TRANSPORT_Excluding_Air: "Non air travel",
  "GAMBLING BETTING LOTTERY": "Gaming & gambling",
  "EDUCATIONAL ENTITIES": "Education",
  "DISCRETIONARY JEWELRY WATCH": "Jewelry & Watches",
  "LOCAL COMMUTE": "Local travel",
  "GOVT PAYMENTS": "Government",
  "AGRI PRODUCTION RELATED": "Agricultural goods",
  "CASH WITHDRWAL": "Cash withdrawal",
  "AUTO MOBILE STORES": "Automobile stores",
  "CONSUMER DURABLES": "Consumer Durables",
  "OFFICE SUPPLY AND ELECTRONICS": "Office goods",
  "EQUIPMENT WHOLESELLER": "Wholesale items",
  "OTHER WHOLESELLER": "Other wholesale items",
  "DIGITAL GOODS & SERVICES": "Online",
  UTILITIES: "Utilities",
  Uncategorised: "Others",
  Others: "Others"
};

export const colors: string[] = [
  "#B17CFF",
  "#FF81B8",
  "#32B1FE",
  "#0FB6B6",
  "#FFF0F1"
];

export const cardColors: string[] = [
  "#B17CFF",
  "#FF81B8",
  "#32B1FE",
  "#0FB6B6",
  "#FFF0F1"
];

export const maxSpendCategoryTextObj: any = {
  Airlines:
    "Your travel stories are so captivating, We’re hanging on your every word.",
  "FOOD & DINING":
    "Your choice of chole bhature is so on point, it could give the connoisseurs a run for their money.",
  "SHOPPING - CLOTHING STORE":
    "Your fashion is so fly, they could land a plane on their runways.",
  FUEL: "You're a natural behind the wheel!",
  "HOME FURNISHING & HOUSEHOLD":
    "Your home is so stylish, it could be featured in a magazine.",
  SHOPPING_ALCOHOL_CIGAR:
    "Tea is such a refreshing choice, but yours takes the cake!",
  GROCERY_AND_SUPERMARKET:
    "Your grocery list is so organized, it's like a work of art.",
  "VARIETY OR DEPT. STORES":
    "Who doesn’t love the feeling of browsing the aisles of a department store.",
  "DUTY FREE STORES":
    "Stuck at the airport? Treat yourself to some fine chocolates or liquor and pretend you're a high-roller",
  "PHARMA & HEALTHCARE":
    "You're a warrior against illness! ready to take on anything.",
  "WELLNESS & BEAUTY":
    "You are so up to date, looks like you just stepped out of a fashion magazine!",
  "ENTERTAINMENT & RECREATION":
    "movies are like a box of chocolates: you never know what you're going to get, but it's always something good.",
  "HOTEL TOUR TRAVEL":
    "you are a travel guru, we can’t wait to hear about your next tour!",
  "WALLET_P2P_PREPAID CARD":
    "You're a financial wizard Harry, always knowing where your money is",
  INSURANCE:
    "You're a knight in shining armor, not just for your loved ones, but for their financial future too",
  "REAL ESTATE":
    "You're a visionary investor, able to understand the hidden value in any property.",
  "BUSINESS SERVICES": "You're a business shark, always swimming with the tide",
  TRANSPORT_Excluding_Air:
    "Let the train take you away to a new world, where the possibilities are endless.",
  "GAMBLING BETTING LOTTERY":
    "You're a pro gamer, leading your team to victory with your drive",
  "EDUCATIONAL ENTITIES":
    "Your educational journey is a treasure map, leading you to the riches of your future.",
  "DISCRETIONARY JEWELRY WATCH":
    "You're a gem connoisseur, but you don't need a fancy title to know that you've got good taste.",
  "LOCAL COMMUTE":
    "You're a modern-day explorer, riding through the bustling streets, all you need is an auto ride",
  "GOVT PAYMENTS":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "AGRI PRODUCTION RELATED":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "CASH WITHDRWAL":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "AUTO MOBILE STORES":
    "Your love of cars is like a fire, burning bright and never extinguished.",
  "CONSUMER DURABLES":
    "Your trusty appliances are the unsung heroes, keeping it running smoothly day in and day out.",
  "OFFICE SUPPLY AND ELECTRONICS":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "EQUIPMENT WHOLESELLER":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "OTHER WHOLESELLER":
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  "DIGITAL GOODS & SERVICES":
    "your e-commerce game is top-notch, turning each click into a pixel-perfect purchase that could redefine online retail!",
  UTILITIES:
    "your utility bill payments are so efficient, they could power a grid of financial responsibility that lights up the responsible spender's path!",
  Others:
    "Your spends are like a work of art, each purchase a carefully curated masterpiece.",
  Uncategorised:
    "Your spends are like a work of art, each purchase a carefully curated masterpiece."
};

export const getFilteredSubgroup = (spendGroups: SpendGroup[]): any => {
  let flag = false;
  let filtered = spendGroups.filter((el: SpendGroup, ind: number) => {
    if (ind < 4 && el.groupName === "Uncategorised") {
      flag = true;
    }
    return (
      (ind < 4 &&
        !flag &&
        el.groupName !== "Uncategorised" &&
        !!el.totalSpend &&
        el.totalSpend !== 0) ||
      (ind < 5 &&
        flag &&
        el.groupName !== "Uncategorised" &&
        !!el.totalSpend &&
        el.totalSpend !== 0)
    );
  });
  return filtered;
};

export const getFilteredOthers = (spendGroups: SpendGroup[]): any => {
  let flag = false;
  let filtered = spendGroups.filter((el: SpendGroup, ind: number) => {
    if (ind < 4 && el.groupName === "Uncategorised") {
      flag = true;
    }
    return (
      (ind > 3 && !flag && !!el.totalSpend && el.totalSpend !== 0) ||
      (ind > 4 && flag && !!el.totalSpend && el.totalSpend !== 0) ||
      el.groupName === "Uncategorised"
    );
  });
  return filtered;
};

export const containsUncategorised = (spendGroups: SpendGroup[]) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let filtered = spendGroups.filter((el: SpendGroup, ind: number) => {
    return el.groupName === "Uncategorised";
  });
  return filtered.length !== 0;
};
