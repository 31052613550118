/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { RootState } from "../../app/store";
import Header from "../../components/header/header";
import styles from "./styles.module.scss";
import { Button2 } from "../../components";
import CircleProgressbar from "../home/campaignOffersSection/campaignOffer/circleProgressBar";
import { BenefitType } from "../../apis/benefitsApi/benefitsApiTypes";
import {
  getBenefitTexts,
  getOfferDetailsListSection
} from "../../utils/BenefitUtil";
import { PWA_MAX_WIDTH_IN_PX } from "../../utils/constants";

const CampaignOfferDetails = () => {
  let { benefitId } = useParams();

  const benefits = useSelector((state: RootState) => state.benefits);

  const benefit = benefits.campaignBenefits.benefits?.find(
    (benefit) => benefit.benefitId === benefitId
  );

  const offerDetails = JSON.parse(benefit!.offerDetails);
  const headerText = benefit!.name;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const background = benefit!.displayColor;
  const illustrationUrl = benefit!.illustrationUrl;
  const redeemSteps = offerDetails.redeemSteps;
  const tncConditions = offerDetails.tncConditions;
  const ctaTextArr = offerDetails?.ctaText?.split(" ");
  const ctaText1 = ctaTextArr?.[0];
  const ctaText2 = ctaTextArr?.slice(1)?.join(" ");

  const { daysLeft, progress, title1, title2, description } = getBenefitTexts(
    benefit!
  );

  const isPendingMilestone =
    benefit!.benefitType === BenefitType.MILESTONE && progress < 100;

  const isMilestoneBenefit = benefit!.benefitType === BenefitType.MILESTONE;

  const expiryText =
    daysLeft > 1
      ? `Expires in ${daysLeft} days`
      : daysLeft === 1
      ? `Expiring tomorrow`
      : daysLeft === 0
      ? `Expires today`
      : "";

  const handleCtaLinkClick = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div className={styles.offerDetailsWrapper}>
      <Header
        label={`Offer - ${headerText}`}
        bgColor="#141414"
        customClassName={styles.header}
      />
      <div className={styles.container}>
        <div className={styles.containerFill}>
          <div className={styles.completedOffersWrapper}>
            {isMilestoneBenefit ? (
              <>
                <div className={styles.completedProgressContainer}>
                  <CircleProgressbar
                    percentage={progress < 100 ? progress : 100}
                    width={160}
                    strokeWidth={7}
                  >
                    <div className={styles.completedText}>
                      <div>
                        {isPendingMilestone ? description : "Completed"}
                      </div>
                    </div>
                  </CircleProgressbar>
                </div>
                <img
                  className={styles.completedIllustrationImg}
                  src={illustrationUrl}
                  alt="svg"
                />{" "}
              </>
            ) : (
              <>
                <img
                  className={styles.completedIllustrationImg}
                  src={illustrationUrl}
                  alt="svg"
                />
                <div className={styles.concentricFirst}>
                  <div className={styles.concentricSecond}>
                    <div className={styles.concentricThird}>
                      <div className={styles.concentricFourth}></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.titleText}>{title1}</div>
        <div className={styles.descText}>{title2}</div>
        <div className={styles.expireTextContainer}>
          <img
            className={styles.hourglassSimpleMedium}
            src="/images/HourglassSimpleMedium.svg"
            alt="svg"
          ></img>
          <span className={styles.expText}>{expiryText}</span>
        </div>
      </div>
      {getOfferDetailsListSection("How to redeem", redeemSteps)}
      {getOfferDetailsListSection("Terms and Conditions", tncConditions)}
      <div className={styles.extraHeight}></div>
      {offerDetails.ctaLink && (
        <div
          className={styles.btnContainer}
          style={{ maxWidth: `${PWA_MAX_WIDTH_IN_PX}px` }}
        >
          <Button2
            customClassName={styles.redeemOfferBtn}
            text1={!!ctaText1 ? ctaText1 : "redeem"}
            text2={!!ctaText1 ? ctaText2 || "" : "offer"}
            onClick={() => handleCtaLinkClick(offerDetails.ctaLink)}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignOfferDetails;
