/*  eslint-disable react/jsx-key , react/no-unstable-nested-components, @typescript-eslint/no-non-null-assertion */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button2 } from "../button/Button";
import Carousel from "./carousel";
import OtpInput from "react-otp-input";
import styles from "./preLogin.module.scss";
import BottomsheetComponent from "../bottomSheet/bottomsheet";
import creditCard from "./CreditCard.svg";
import chartBar from "./ChartBar.svg";
import chartPie from "./ChartPie.svg";
import tag from "../../assets/svgs/tag.svg";
import LoginApi from "../../apis/loginApi/LoginApi";
import {
  GenerateLoginOtpSuccessResponse,
  VerifyLoginOtpSuccessResponse
} from "../../apis/loginApi/loginApiTypes";
import { ToastType, toast } from "../../components";
import { setSession } from "../../features/session/sessionSlice";
import { setUserIds } from "../../features/user/userSlice";
import { type SetSessionActionPayload } from "../../features/session/sessionSliceTypes";
import { type SetUserIdsActionPayload } from "../../features/user/userSliceTypes";
import PreferencesApi from "../../apis/prefrencesApi/PreferencesApi";
import {
  setAccountPreferences,
  setProgramPreferences
} from "../../features/benefits/benefitsSlice";
import { type RootState } from "../../app/store";
import {
  ProgramPreferences,
  type Tag
} from "../../apis/prefrencesApi/preferencesApiTypes";
import preLoginCard1 from "../../assets/svgs/preLoginCard.webp";
import {
  aesEncryptData,
  getPciEncryptionKeyAndIv
} from "../../utils/encryptionUtil";
import { TextWithLinks } from "../textWithLinks/TextWithLinks";
import { HOME_ROUTE, PREFETCH_FAILURE } from "../../routes/ScreenRoutes";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import { getIssuerId } from "../../data/config";
import {
  autoReadOtp,
  focusOnInput,
  parseUnicodeInString,
  getPWADisplayMode,
  getUniqueURLFromCookie
} from "../../utils/functions";

import AppApi from "../../apis/appApi/AppApi";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

const numberRegex = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
const isInputValid = (num: string) => {
  if (num.includes(".")) return false;
  return numberRegex.test(num);
};
declare const window: any;
const textRender = (
  text1: string,
  text2: string,
  text3?: string,
  text4?: string
) => {
  return (
    <div className={styles.textContainer}>
      <span className={styles.text1}>{text1}</span>
      <span className={styles.text2}>{" " + text2}</span>
      {text3 ? <span className={styles.text3}>{" " + text3}</span> : null}
      {text4 ? <span className={styles.text4}>{" " + text4}</span> : null}
    </div>
  );
};

const swiperContainer = (text: string, icon: string) => {
  return (
    <div className={styles.swiperContainer}>
      <div className={styles.swiperIconContainer}>
        <img src={icon} alt="icon" className={styles.swiperIcon} />
      </div>
      <div className={styles.swiperTextContainer}>
        <div className={styles.swiperText}>{text}</div>
      </div>
    </div>
  );
};

const FavouriteBottomSheet = ({
  programPreferences,
  handleTagClick,
  selectedTagIds,
  handleSkipPreferencesClick,
  isSavePreferencesLoading,
  handleSavePreferencesClick
}: any) => {
  return (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetOfferHeader}>
          <img src={tag} alt="Tag" />
          <div className={styles.bottomSheetOfferHeaderText}>
            pick your favorites
          </div>
        </div>
        <div className={styles.bottomSheetOfferText}>
          Personalized offers based on your favorite categories
        </div>
        <div className={styles.favouriteOfferContainer}>
          {programPreferences?.tags?.map((tag: Tag, index: number) => {
            return (
              <div
                key={index}
                onClick={() => handleTagClick(tag)}
                className={
                  selectedTagIds.includes(tag.id)
                    ? styles.offerBtnSelected
                    : styles.offerBtnNotSelected
                }
              >
                <div className={styles.offerText}>
                  {parseUnicodeInString(tag.name)}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.offerBtnContainer}>
          <Button2
            isLoading={isSavePreferencesLoading}
            onClick={handleSavePreferencesClick}
            customClassName={styles.saveBtn}
            text1Style={{ fontWeight: "bold" }}
            text1="save"
            text2="preferences"
          />
          <div onClick={handleSkipPreferencesClick} className={styles.skipBtn}>
            skip
          </div>
        </div>
      </div>
    </div>
  );
};

const PreLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const programPreferences = useSelector(
    (state: RootState) => state.benefits.programPreferences
  );

  const otpInputRef = useRef<OtpInput | null>(null);
  const [isGenerateLoginOtpLoading, setIsGenerateLoginOtpLoading] =
    useState<boolean>(false);
  const [isVerifyLoginOtpLoading, setIsVerifyLoginOtpLoading] =
    useState<boolean>(false);
  const [isSavePreferencesLoading, setSavePreferencesLoading] =
    useState<boolean>(false);
  const [resendCount, setResendCount] = useState(0);

  const [isDetailsBottomSheetOpen, setIsDetailsBottomSheetOpen] =
    useState<boolean>(false);
  const [isOtpBottomSheetOpen, setIsOtpBottomSheetOpen] =
    useState<boolean>(false);
  const [isFavouriteBottomSheetOpen, setIsFavouriteBottomSheetOpen] =
    useState<boolean>(false);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [otp, setOtp] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const mobileNumberRef = useRef<string>("");
  const [otpVerificationRefId, setOtpVerificationRefId] = useState<string>("");
  const [sessionState, setSessionState] =
    useState<SetSessionActionPayload | null>(null);
  const [userIdsState, setUserIdsState] =
    useState<SetUserIdsActionPayload | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockedUntil, setBlockedUntil] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let timer: any;

  const handleChange = (code: string) => setOtp(code);
  const handleCard = (num: string) => {
    if (isInputValid(num)) setCardNumber(num);
  };
  const handlePhone = (num: string) => {
    if (isInputValid(num)) setMobileNumber(num);
  };
  const showToast = () => {
    toastLib.dismiss();
    if (mobileNumber.length === 0 && cardNumber.length === 0) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter login details");
      }, 1000);
    } else if (
      mobileNumber.length >= 0 &&
      mobileNumber.length < 10 &&
      cardNumber.length >= 0 &&
      cardNumber.length < 4
    ) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter complete login details");
      }, 1000);
    } else if (cardNumber.length === 0) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter last 4 digits of Card Number");
      }, 1000);
    } else if (cardNumber.length > 0 && cardNumber.length < 4) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter last 4 digits of Card Number");
      }, 1000);
    } else if (mobileNumber.length === 0) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter 10 digit Mobile Number");
      }, 1000);
    } else if (mobileNumber.length > 0 && mobileNumber.length < 10) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter 10 digit Mobile Number");
      }, 1000);
    }
  };
  let divElement = document.getElementById("scroll");
  let divElement1 = document.getElementById("scroll1");
  const tncText =
    "By entering OTP and proceeding, I agree to the (Terms & Conditions)[https://www.indusind.com/in/en/personal/terms-and-conditions.html] and (Privacy Policy)[https://www.indusind.com/in/en/personal/privacy-policy.html] associated with IndusInd Bank Credit Card setup";

  const getMobileOperatingSystem = () => {
    // eslint-disable-next-line no-var
    let userAgent = navigator.userAgent || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  };

  const handleGetStartedButtonClick = () => {
    setIsDetailsBottomSheetOpen(true);
  };

  const handleTagClick = (tag: Tag) => {
    if (selectedTagIds.includes(tag.id)) {
      setSelectedTagIds(
        selectedTagIds.filter((selectedTagId) => selectedTagId !== tag.id)
      );
    } else {
      setSelectedTagIds([...selectedTagIds, tag.id]);
    }
  };

  const afterPreferencesSelection = () => {
    // set session data and user data in store
    dispatch(setSession(sessionState!));
    dispatch(setUserIds(userIdsState!));
    navigate(HOME_ROUTE);
  };

  const handleSkipPreferencesClick = () => {
    afterPreferencesSelection();
  };

  useEffect(() => {
    // clear session expiry flags
    localStorage.removeItem("visitedHome");
  }, []);

  const handleGenerateOtpClick = async () => {
    if (divElement) {
      divElement.style.margin = "0px";
    }
    sessionStorage.removeItem("/");
    setIsGenerateLoginOtpLoading(true);

    try {
      mobileNumberRef.current = mobileNumber;
      sessionStorage.setItem(
        "primaryCardMobileNumber",
        aesEncryptData(
          process.env.REACT_APP_PII_ENCRYPTION_KEY!,
          process.env.REACT_APP_PII_ENCRYPTION_IV!,
          mobileNumber
        )
      );
      const response = await LoginApi.generateLoginOtp({
        uniqueURL: getUniqueURLFromCookie() || "",
        cardLastFour: cardNumber
      });

      captureOtpGenerateEvent(
        mobileNumber,
        cardNumber,
        true,
        (response.data as any)?.code ||
          (response.data as any)?.status ||
          "OTP is sent successfully"
      );

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);
        const result = response.data as GenerateLoginOtpSuccessResponse;
        setOtpVerificationRefId(result.mobileVerificationRefId);
        setIsDetailsBottomSheetOpen(false);
        setIsOtpBottomSheetOpen(true);
        toastLib.dismiss();

        autoReadOtp((otp) => {
          setOtp(otp);
          focusOnInput("loginOtpInputContainer", 5);
        });
      } else if ((response.data as any)?.code === "IBL_001") {
        console.error("Rejected generate login OTP for add on card.");
        setTimeout(() => {
          toast(
            ToastType.INFO,
            "Please login with last 4 digits of Primary Card"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_002") {
        console.error("Rejected generate login OTP for this account.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_003") {
        console.error("Rejected generate login OTP for this customer.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_004") {
        console.error("Rejected generate login OTP for this card.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if (
        (response.data as any)?.status === "RETRIES_EXCEEDED" ||
        (response.data as any)?.status === "BLOCKED_TEMPORARILY"
      ) {
        setBlockedUntil((response.data as any)?.blockedUntil);

        const time = (response.data as any)?.failureReason
          ?.match(/Please retry after (.*)./)?.[1]
          ?.split(",");

        const isMinutesPresent =
          (response.data as any)?.failureReason?.includes("Minutes") ||
          (response.data as any)?.failureReason?.includes("minutes");

        const retryTime =
          !!time && isMinutesPresent ? time?.[0].split(" ")?.[0] : "1";

        console.error(
          `Failed to generate login OTP. Retries exceeded, ${
            (response.data as any)?.status
          }`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            (response.data as any)?.failureReason.includes("temporarily")
              ? `Maximum number of OTP attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
              : `Maximum number of login attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
          );
        }, 1000);
      } else {
        console.error("An error occurred while generating login OTP");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
        }, 1000);
      }
    } catch (error: any) {
      // logger.error({
      //   message: `An exception occurred while generating login otp`,
      //   error: error.message,
      //     mobileNumber: getMaskedMobileNumberForLogs(phone),
      //   stackTrace: error.stack
      // });
      console.error(
        `An exception occurred while generating login OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }

    setIsGenerateLoginOtpLoading(false);
  };

  const debounceSendOtp = _debounce(handleGenerateOtpClick, 500);

  const captureOtpGenerateEvent = (
    mobileNumber: string,
    cardNumber: string,
    status: boolean,
    message: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.OTP_GENERATE,
      primarySource: window.location.href,
      eventData: {
        otpGeneratedStatus: status,
        ...(status && { ["responseMessage"]: message }),
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureResendOtpEvent = (
    mobileNumber: string,
    cardNumber: string,
    resendCount: number
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.RESEND_OTP,
      primarySource: window.location.href,
      eventData: {
        resendOtpCount: resendCount,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureOtpVerifyClick = (mobileNumber: string, cardNumber: string) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.OTP_VERIFY,
      primarySource: window.location.href,
      eventData: {
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginFailureEvent = (
    loginFailureReason: string,
    mobileNumber: string,
    cardNumber: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.LOGIN_FAILURE,
      primarySource: window.location.href,
      eventData: {
        loginFailureReason: loginFailureReason,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginSuccessEvent = (
    accountId: string,
    selectedCardId: string,
    customerId: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.LOGIN_SUCCESS,
      hfAccountId: accountId,
      hfCardId: selectedCardId,
      hfCustomerId: customerId,
      primarySource: window.location.href,
      eventData: {
        loginMode: getPWADisplayMode() === "app" ? "App" : "URL"
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const handleOtpVerifyClick = async () => {
    setIsVerifyLoginOtpLoading(true);
    if (divElement1) {
      divElement1.style.margin = "0px";
    }

    captureOtpVerifyClick(mobileNumber, cardNumber); // capturing otp verify click
    try {
      const verifyLoginOtpresponse = (await LoginApi.verifyLoginOtp({
        mobileVerificationRefId: otpVerificationRefId,
        otp
      })) as any;
      toastLib.dismiss();
      if (verifyLoginOtpresponse.status !== 200) {
        captureLoginFailureEvent(
          verifyLoginOtpresponse?.data?.failureReason,
          mobileNumber,
          cardNumber
        ); //capturing Login failure event
        if (verifyLoginOtpresponse.data.failureReason === "INVALID_OTP") {
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP entered is incorrect. Please try again"
            );
          }, 1000);
          setTimeout(() => {
            setOtp("");
            if (otpInputRef.current) {
              otpInputRef.current.focusInput(0);
            }
          }, 1500);
        } else if (
          verifyLoginOtpresponse.data.failureReason === "OTP_EXPIRED"
        ) {
          setTimeout(() => {
            toast(ToastType.ERROR, "The OTP has expired. Please try again");
          }, 1000);
        } else {
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP verification has failed. Please try again"
            );
          }, 1000);
        }
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      const verifyLoginOtpResult =
        verifyLoginOtpresponse.data as VerifyLoginOtpSuccessResponse;

      if (
        !verifyLoginOtpResult.customerId ||
        !verifyLoginOtpResult.programId ||
        !verifyLoginOtpResult.accountId ||
        !verifyLoginOtpResult.cardId
      ) {
        console.log(
          "An error occurred while verifying login OTP. Required ids missing in response."
        );
        setIsVerifyLoginOtpLoading(false);
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP verification has failed. Please try again"
          );
        }, 1000);
        return;
      }

      const { token, metaData } = verifyLoginOtpResult;

      const { pciEncryptionKey, pciEncryptionIv } = getPciEncryptionKeyAndIv(
        token,
        metaData
      );

      const sessionData = {
        token,
        metaData,
        pciEncryptionKey,
        pciEncryptionIv
      };

      captureLoginSuccessEvent(
        verifyLoginOtpResult.customerId,
        verifyLoginOtpResult.cardId,
        verifyLoginOtpResult.accountId
      ); // capturing login success event

      if (
        !verifyLoginOtpresponse.data.lagTimeForInstallPrompt ||
        !verifyLoginOtpresponse.data.showPrompt
      ) {
        sessionStorage.setItem("showPrompt", "false");
      } else {
        sessionStorage.setItem(
          "showPrompt",
          verifyLoginOtpresponse.data.showPrompt
        );
        sessionStorage.setItem(
          "lagTimeForInstallPrompt",
          verifyLoginOtpresponse.data.lagTimeForInstallPrompt
        );
      }

      const userIdsData = {
        customerId: verifyLoginOtpResult.customerId,
        selectedProgramId: verifyLoginOtpResult.programId,
        selectedAccountId: verifyLoginOtpResult.accountId,
        selectedCardId: verifyLoginOtpResult.cardId
      };

      if (!verifyLoginOtpResult.isFirstTimeLogin) {
        // if not first login then set data in store instead of showing preferences bottom sheet
        localStorage.setItem("isFirstTimeLogin", "false");
        dispatch(setSession(sessionData!));
        dispatch(setUserIds(userIdsData!));
        if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
          navigate(PREFETCH_FAILURE);
          return;
        }
        navigate(HOME_ROUTE);
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      // save data in state but not yet in store to show favorites screen
      setSessionState(sessionData);
      setUserIdsState(userIdsData);
      if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
        navigate(PREFETCH_FAILURE);
        return;
      }

      // get program preferences
      const getProgramPreferencesResponse =
        await PreferencesApi.getProgramPreferences(verifyLoginOtpResult.token, {
          programId: verifyLoginOtpResult.programId,
          issuerId: getIssuerId()
        });

      if (getProgramPreferencesResponse.status !== 200) {
        console.log("An error occurred while getting program preferences");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to display your favourites. Please try again"
          );
        }, 1000);
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      let programPreferenceObj: ProgramPreferences = {
        id: "",
        programId: "",
        txnPostingDelayForComputation: 0,
        computeAndReverse: false,
        webHookEndPoint: "",
        webhookSecretKey: "",
        tags: getProgramPreferencesResponse?.data,
        createdAt: "",
        updatedAt: ""
      };

      dispatch(setProgramPreferences(programPreferenceObj));

      setIsOtpBottomSheetOpen(false);
      setIsFavouriteBottomSheetOpen(true);
    } catch (error: any) {
      toastLib.dismiss();
      console.error(
        `An exception occurred while verifying login OTP. Error:\n${error}`
      );
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to display your favourites. Please try again"
        );
      }, 1000);
    }

    setIsVerifyLoginOtpLoading(false);
  };

  const handleResendOtpClick = () => {
    toastLib.dismiss();
    if (resendCount < 3) {
      setResendCount((resendCount) => resendCount + 1);
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Resending OTP");
      }, 500);
    }

    captureResendOtpEvent(mobileNumber, cardNumber, resendCount + 1);
    debounceSendOtp();
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (isDetailsBottomSheetOpen || isOtpBottomSheetOpen) {
        setIsDetailsBottomSheetOpen(false);
        setIsOtpBottomSheetOpen(false);
      }
    };
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (isOtpBottomSheetOpen) {
          !isVerifyLoginOtpLoading &&
            otp.length === 6 &&
            handleOtpVerifyClick();
        } else if (isDetailsBottomSheetOpen) {
          !isGenerateLoginOtpLoading &&
            cardNumber.length === 4 &&
            mobileNumber.length === 10 &&
            debounceSendOtp();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [
    otp,
    cardNumber,
    mobileNumber,
    isDetailsBottomSheetOpen,
    isOtpBottomSheetOpen
  ]);

  const handleSavePreferencesClick = async () => {
    setSavePreferencesLoading(true);

    try {
      const selectedTagNames = programPreferences!.tags
        .filter((tag: Tag) => selectedTagIds.includes(tag.id))
        .map((selectedTag: Tag) => selectedTag.name);

      const response = await PreferencesApi.addAccounTags(sessionState!.token, {
        accountId: userIdsState!.selectedAccountId,
        programId: userIdsState!.selectedProgramId,
        tagNames: selectedTagNames,
        issuerId: getIssuerId()
      });

      if (response.status !== 200) {
        console.log("An error occurred while adding account tags");
        setSavePreferencesLoading(false);
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We could not save your favourites. Please try again"
          );
        }, 1000);
        return;
      }

      dispatch(setAccountPreferences(response.data));
      afterPreferencesSelection();
    } catch (error: any) {
      console.error("An exception occurred adding account tags");
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We could not save your favourites. Please try again"
        );
      }, 1000);
    }

    setSavePreferencesLoading(false);
  };

  //virtual keyboard & confirm button overlap fix
  useEffect(() => {
    if (getMobileOperatingSystem() === "Android") {
      if (
        document.activeElement ===
        (document.getElementById("inputPhone") ||
          document.getElementById("otp"))
      ) {
        if (divElement) {
          divElement.style.margin = "35px";
        }
      }
    }
    if (divElement1) {
      divElement1.style.margin = "50px";
    }
  }, [isOtpBottomSheetOpen, document.activeElement]);

  const DetailsBottomSheet = () => (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetHeader}>enter your details</div>
        <div className={styles.phoneNumberContainer}>
          <span className={styles.bottomSheetText}>
            Enter your registered mobile number
          </span>
          <div className={styles.phoneNumberInputContainer}>
            <span className={styles.phoneNumberInputPrefix}>+91</span>
            <input
              id="inputPhone"
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              placeholder="9999999999"
              className={styles.phoneNumberInput}
              style={{ opacity: mobileNumber.length > 0 ? 1 : 0.5 }}
              value={mobileNumber}
              maxLength={10}
              onChange={(e: any) => handlePhone(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.phoneNumberContainer}>
          <span className={styles.bottomSheetText}>
            Enter last 4 digits of your credit card
          </span>
          <div className={styles.phoneNumberInputContainer}>
            <input
              type="tel"
              placeholder="Type here"
              className={styles.cardNumberInput}
              value={cardNumber}
              maxLength={4}
              onChange={(e: any) => handleCard(e.target.value)}
            />
          </div>
        </div>
        <div onClick={showToast} className={styles.confirmBtn1Container}>
          <Button2
            text1="confirm"
            // text2="started"
            customClassName={styles.confirmBtn1}
            isLoading={isGenerateLoginOtpLoading}
            onClick={() => debounceSendOtp()}
            disabled={cardNumber.length !== 4 || mobileNumber.length !== 10}
          />
        </div>
      </div>
      <div id="scroll"></div>
    </div>
  );

  const OTPBottomSheet = () => (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetHeader}>verify with OTP</div>
        <div className={styles.bottomSheetText}>
          Please enter the 6 digit OTP sent to +91 ***** **
          {mobileNumber.slice(-3)}
        </div>
        <div id="loginOtpInputContainer" className={styles.otpContainer}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "0",
              borderBottom: "2px solid #353535",
              color: "white",
              width: "30px",
              padding: "0 5px",
              marginRight: "10px",
              textAlign: "center",
              fontSize: "2.5em",
              cursor: "pointer",
              willChange: "border",
              transition: "border .3s ease-in-out",
              WebkitAppearance: "none",
              MozAppearance: "textfield",
              background: "transparent",
              outline: "none"
            }}
            focusStyle={{
              borderBottom: "1px solid #4e4e4e",
              outline: "none"
            }}
            ref={otpInputRef}
          />
          <div className={styles.resendContainer}>
            <div>Didn't receive OTP?</div>
            <div className={styles.resendText} onClick={handleResendOtpClick}>
              Resend OTP
            </div>
          </div>
          <div className={styles.tncContainer}>
            <div className={styles.tncText}>
              <TextWithLinks text={tncText} linkClassName={styles.linkText} />
            </div>
          </div>
        </div>
        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="confirm"
            // text2="started"
            customClassName={styles.confirmBtn1}
            isLoading={isVerifyLoginOtpLoading}
            onClick={handleOtpVerifyClick}
            disabled={otp.length !== 6}
          />
        </div>
      </div>
      <div id="scroll1"></div>
    </div>
  );

  const items = [
    <div className={styles.mainContainer1}>
      <div className={styles.cardContainer1}>
        <div className={styles.bgContainer}>
          <img src="/images/patterns/ripple1.svg" alt="ripple" />
        </div>
        <picture>
          <source srcSet={preLoginCard1} type="image/webp" />
          <img src={preLoginCard1} alt="card" className={styles.card} />
        </picture>
      </div>
      <div className={styles.textRenderContainer1}>
        {textRender("Welcome", "to a card that puts", "YOU", "first")}
      </div>
    </div>,
    <div className={styles.mainContainer1}>
      <div className={styles.cardContainer2}>
        <div className={styles.bgContainer2}>
          <img src="/images/patterns/lightSpread.svg" alt="light" />
        </div>
        <img
          src="/images/shopWithCoin.svg"
          alt="card"
          className={styles.card2}
        />
      </div>
      <div className={styles.textRenderContainer2}>
        {textRender("Discover", "benefits waiting for you")}
      </div>
    </div>,
    <div className={styles.mainContainer1}>
      <div className={styles.cardContainer4}>
        <div className={styles.bgContainer2}>
          {/* <img src="/images/patterns/lightSpread.svg" alt="light" /> */}
        </div>
        <img
          src="/images/preLoginCards.svg"
          alt="card"
          className={styles.card3}
        />
      </div>
      <div className={styles.textRenderContainer2}>
        {textRender("Explore", "a world of exclusive offers")}
      </div>
    </div>,
    <div className={styles.mainContainer2}>
      <div className={styles.cardContainer3}>
        <div className={styles.bgContainer2}>
          {/* <img src="/images/patterns/lightSpread.svg" alt="light" /> */}
        </div>
        {swiperContainer("Simplified Bill Payments", creditCard)}
        <div className={styles.swiperContainer2}>
          {swiperContainer("Better insights on transactions", chartBar)}
        </div>
        {swiperContainer("Shop Now, Pay Later, Convert to EMI", chartPie)}
      </div>
      <div className={styles.textRenderContainer2}>
        {textRender("Manage", "your card like never before")}
      </div>
    </div>
  ];
  return (
    <>
      <div className={styles.preLoginContainer}>
        <style>{`
          .swiper2{
            height:87dvh;
          }
          @media (max-width:400px){
            .swiper2{height:85dvh;}
          }
          @media (orientation:landscape) and (max-height:700px){
            .swiper2{height:600px}
          }
          @media (orientation:landscape) and (min-height:701px){
            .swiper2{height:85dvh;}
          }
        `}</style>
        <Carousel items={items} />
        <div className={styles.btnContainer}>
          <Button2
            text1="get"
            text2="started"
            customClassName={styles.getStartedButton}
            onClick={handleGetStartedButtonClick}
          />
        </div>
      </div>
      <BottomsheetComponent
        isBottomSheetOpen={isDetailsBottomSheetOpen}
        setIsBottomSheetOpen={setIsDetailsBottomSheetOpen}
        render={DetailsBottomSheet()}
      />
      <BottomsheetComponent
        isBottomSheetOpen={isOtpBottomSheetOpen}
        setIsBottomSheetOpen={setIsOtpBottomSheetOpen}
        render={OTPBottomSheet()}
      />
      <BottomsheetComponent
        isBottomSheetOpen={isFavouriteBottomSheetOpen}
        setIsBottomSheetOpen={setIsFavouriteBottomSheetOpen}
        render={FavouriteBottomSheet({
          programPreferences,
          handleTagClick,
          selectedTagIds,
          handleSkipPreferencesClick,
          isSavePreferencesLoading,
          handleSavePreferencesClick
        })}
      />
    </>
  );
};

export default PreLogin;

export { FavouriteBottomSheet };
