import React from "react";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import styles from "./desktopScreen.module.scss";
import DesktopCarousal from "./desktopCarousal/desktopCarousal";
import callContact from "../../assets/svgs/callContact.svg";
import emailContact from "../../assets/svgs/emailContact.svg";
import { CONTACT_MOBILE_NUMBER } from "../../utils/constants";

const DesktopScreen = () => {
  return (
    <div className={styles.page}>
      <div className={styles.logoImage}>
        <img src={IndusInd_Logo} alt="" />
      </div>

      <div className={styles.banner}>
        <div className={styles.bannerContainer1}>
          < DesktopCarousal />
        </div>
        <div
          className={styles.bannerContainer1}
          style={
            {
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "space-around"
            }
          }
        >
          <div className={styles.bannerContainer2}>
            <div className={styles.welcomeText}>
              Welcome to IndusInd Bank CardsHub,
            </div>
            <div className={styles.text2}>
              One hub for all things credit card
            </div>
            {/* <div className={styles.scanner}>
              <img src={Scanner} alt="" />
            </div> */}

            <div className={styles.text3}>
              CardsHub is available only on mobile via a secured login link sent
              on your registered email ID or mobile number
            </div>

            
            <div className={styles.contactUs}>
              <div className={styles.contactUsText}>
                Can’t find the link?{" "}
                <span className={styles.contactUsSubText}>Reach out on</span>
              </div>
              <div className={styles.contactOptions}>
                <div className={styles.contactBar}>
                  <img
                    src={callContact}
                    alt=""
                    style={{ width: "20%", marginRight: "5px" }}
                  />{" "}
                  {`${CONTACT_MOBILE_NUMBER}`}
                </div>
                <div className={styles.contactBar}>
                  <img
                    src={emailContact}
                    alt=""
                    style={{ width: "15%", marginRight: "5px" }}
                  />{" "}
                  premium.care@indusind.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopScreen;
