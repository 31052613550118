import styles from "./styles.module.scss";
interface HiddenCardProps {
  text1: string;
  text2: string;
  text3: string;
}

const index = (props: HiddenCardProps) => {
  const { text1, text2, text3 } = props;
  return (
    <div className={styles["benefits-header"]}>
      <div className={styles.text2}>
        <span className={styles.text}>{text1}</span> {text3}
      </div>
      <div className={styles.text2}>{text2}</div>
    </div>
  );
};

export default index;
