import type { ReactElement, ReactNode } from "react";
import styles from "./selectorStyles.module.scss";
import { BottomSheet2, Button } from "../index";

interface SelectorProps<Type> {
  isOpen: boolean;
  title?: ReactNode;
  items: Array<Type>;
  renderLabel: (item: Type, index: number) => ReactNode;
  isItemSelected: (currentItem: Type, currentIndex: number) => boolean;
  onSelect: (selectedItem: Type, selectedIndex: number) => void;
  handleClose: () => void;
  footer?: ReactNode;
}

export function Selector<Type>(props: SelectorProps<Type>): ReactElement {
  const {
    isOpen,
    title,
    items,
    renderLabel,
    isItemSelected,
    onSelect,
    handleClose,
    footer
  } = props;

  return (
    <BottomSheet2 isOpen={isOpen} handleClose={handleClose}>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetHeader}>{title}</div>
        <hr className={styles.horizontalLine}></hr>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => onSelect(item, index)}
              className={
                isItemSelected(item, index)
                  ? styles.selectedRadioContainer
                  : styles.radioContainer
              }
            >
              <input
                type="radio"
                name="date"
                value="1"
                is="regular"
                checked={isItemSelected(item, index)}
              />
              <label className={styles.radioLabel} htmlFor="regular">
                {renderLabel(item, index)}
              </label>
            </div>
          );
        })}
        {Boolean(footer) &&
          (typeof footer === "string" ? (
            <div className={styles.footer}>{footer}</div>
          ) : (
            footer
          ))}
        <div className={styles.closeBtnContainer}>
          <Button
            onClick={handleClose}
            text1="close"
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.closeButton}
          />
        </div>
      </div>
    </BottomSheet2>
  );
}
