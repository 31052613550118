import React, { useEffect, useState } from "react";
import styles from "./SpendAnalyticsTransactions.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Header,
  ToastType,
  toast,
  Transaction as TransactionComponent
} from "../../components";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import { authenticateApi } from "../../auth/auth";
import { useSelector } from "react-redux";
import { getSelectedAccount } from "../../features/user/userSlice";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import Loader from "../../components/loader/loader";
import {
  SpendGroup,
  TransactionsApiResponse,
  imageObj,
  spendCategoryName
} from "../../utils/SpendAnalyticsUtil";
import { formatAmount3 } from "../../utils/functions";
import { Transaction } from "../../apis/accountsApi/accountsApiTypes";
import { RequestSources } from "../../utils/enums";

const SpendAnalyticsTransactions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const account = useSelector(getSelectedAccount)!;
  //Store transactions from api response
  const [data, setData] = useState<TransactionsApiResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const getSpendGroupTransactions = async (
    groupIds: string[],
    startDate: string,
    endDate: string,
    isOthersTransactions: boolean
  ) => {
    setIsLoading(true);

    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        actionText: "to see spend analytics transactions"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get spend groups transactions`
        });
        toast(ToastType.ERROR, authResult.message);
        navigate(-1);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(-1);
        return;
      }

      const response = await AccountsApi.getSpendGroupsTransactions(
        account.id,
        authResult.apiToken,
        {
          startDate,
          endDate,
          spendGroupIds: groupIds,
          dateType: "POSTING_DATE",
          checkEmiEligibility: true
        }
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting spend groups transactions`
        });
        toast(ToastType.ERROR, "An error occurred");
        navigate(-1);
        return;
      }

      if (response.data) {
        if (!isOthersTransactions) {
          setData(response.data.response);
        }
        response.data.response.forEach((el: any) => {
          setTransactions((prev: any) => [...prev, ...el.transactions]);
        });
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting spend groups transactions`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to process your request. Please try again later"
      );
      navigate(-1);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (location?.state?.groupId) {
      getSpendGroupTransactions(
        [location.state.groupId],
        location.state.startDate,
        location.state.endDate,
        false
      );
    } else {
      let groupId: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      location?.state?.filteredOthers.forEach((el: SpendGroup, ind: number) => {
        groupId.push(el.groupId);
      });
      getSpendGroupTransactions(
        groupId,
        location.state.startDate,
        location.state.endDate,
        true
      );
    }
  }, []);

  return isLoading ? (
    <div className={styles.loader}>
      <Loader text1="loading" text2="spend group transactions" />
    </div>
  ) : (
    <div>
      {data && (
        <>
          <Header
            label={
              data?.[0]?.spendGroup
                ? spendCategoryName[data[0].spendGroup.groupName]
                : "Others"
            }
            bgColor="rgba(20, 20, 20, 0.80)"
            customClassName={styles.header}
          />
          <div className={styles.categoryContainer}>
            <div className={styles.backgroundContainer1}></div>
            <div className={styles.backgroundContainer2}></div>
            <div>
              <div className={styles.imageContainer}>
                <img
                  src={
                    data?.[0]?.spendGroup
                      ? imageObj[data[0].spendGroup.groupName]
                      : imageObj["Others"]
                  }
                  alt="categoryImage"
                />
              </div>
              <div className={styles.textContainer}>
                <div>
                  <span>all</span>{" "}
                  {data?.[0]?.spendGroup
                    ? spendCategoryName[
                        data[0].spendGroup.groupName
                      ].toLocaleLowerCase()
                    : "others"}{" "}
                  transactions
                </div>
                <div>
                  {data?.[0]?.spendGroup
                    ? formatAmount3(data[0].spendGroup.totalSpend)
                    : formatAmount3(location?.state?.other)}{" "}
                  <span>spent</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.border}>
            <div className={styles.percentageTotalSpend}>
              <span>
                {" "}
                {(
                  ((data?.[0]?.spendGroup
                    ? data[0].spendGroup.totalSpend
                    : location.state.other) /
                    location.state.total) *
                  100
                ).toFixed(0)}
                %
              </span>{" "}
              &nbsp; of your spend were on{" "}
              {data?.[0]?.spendGroup
                ? spendCategoryName[
                    data[0].spendGroup.groupName
                  ].toLocaleLowerCase()
                : "others"}
            </div>
          </div>
        </>
      )}
      {transactions && (
        <div className={styles.transactionsContainer}>
          {transactions.map((el: Transaction, index) => {
            return (
              <TransactionComponent
                transaction={el}
                key={index}
                requestSource={RequestSources.SPEND_GROUP_TRANSACTIONS_PAGE}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SpendAnalyticsTransactions;
