/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useRef, useEffect, useCallback } from "react";
import type { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BottomSheetCustom } from "@cred/neopop-web/lib/components";
import type { RootState } from "../../../app/store";
import OtpInput from "react-otp-input";
import styles from "./smsOtpStyles.module.scss";
import { Button2, ToastType, toast } from "../../../components";
import { PWA_MAX_WIDTH_IN_PX, RESEND_OTP_TIME } from "../../../utils/constants";
import {
  GenerateChallengeSmsOtpResponseStatus,
  VerifyChallengeSmsOtpResponseFailureReason
} from "../../../apis/authApi/authApiTypes";
import AuthApi from "../../../apis/authApi/AuthApi";
import type { VerifiedChallenges } from "../../../features/auth/authSliceTypes";
import { AuthStatus } from "../../../features/auth/authSliceTypes";
import { setVerifiedChallenges } from "../../../features/auth/authSlice";
import { finishChallenge } from "../../auth";
import {
  autoReadOtp,
  focusOnInput,
  getMaskedMobileNumberForLastTwo,
  getUniqueURLFromCookie
} from "../../../utils/functions";
import { getConfig } from "../../../data/config";
import { TextWithLinks } from "../../../components/textWithLinks/TextWithLinks";
import {
  getCustomer,
  getSelectedAccount,
  getSelectedCard
} from "../../../features/user/userSlice";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import { aesDecryptData } from "../../../utils/encryptionUtil";
import AppApi from "../../../apis/appApi/AppApi";
import { EVENT_NAME } from "../../../apis/appApi/appApiTypes";
import { isManualOtpAllowed } from "../../../utils/internalFlags";
import { getUserOSAndVersion } from "../../../utils/trackingFunctions";
import {
  CONVERT_OUTSTANDING_TO_EMI_OVERVIEW,
  EMI_OVERVIEW_ROUTE
} from "../../../routes/ScreenRoutes";
import { EventType } from "../../../utils/enums";
import Loader from "../../../components/loader/loader";

interface SmsOtpProps {
  isOpen: boolean;
}

declare const window: any;
const SmsOtp = (props: SmsOtpProps): ReactElement => {
  const { isOpen } = props;

  const dispatch = useDispatch();

  const [timeOut, setTimeOut] = useState<number>(RESEND_OTP_TIME);
  const [os, version] = getUserOSAndVersion(navigator.userAgent);
  const user = useSelector((state: RootState) => state.user);
  const authStore = useSelector((state: RootState) => state.auth);
  const account = useSelector(getSelectedAccount)!;
  const customer = useSelector(getCustomer)!;
  const selectedCard = useSelector(getSelectedCard)!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [smsOtp, setSmsOtp] = useState<string>("");
  const [isResendOtp, setIsResendOtp] = useState(false);

  const smsOtpRefId = useRef<string>("");
  const divElement = document.getElementById("extraDiv");
  const otpInputRef = useRef<OtpInput | null>(null);

  const [configVal, setConfigVal] = useState<any>();
  const card = useSelector(getSelectedCard)!;
  useEffect(() => {
    getConfig().then(res => setConfigVal(res));
  }, [card]);


  const config =
    configVal?.smsOtp && authStore.apiId
      ? configVal?.smsOtp[authStore.apiId]
      : null;

  useEffect(() => {
    // reset state on close or open
    setSmsOtp("");
    smsOtpRefId.current = "";
  }, [isOpen]);

  const handleChange = (code: string) => {
    // window.alert(code);
    setSmsOtp(code);
  };
  const handleKeyDown = (event: any) => {
    if (/\d/g.test(event.key) && !isManualOtpAllowed) {
      // Prevent the default action (entering the number)
      event.preventDefault();
      setSmsOtp("");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const captureChallengeSmsOtpEvent = (
    responseMessage: string,
    isResend: boolean
  ) => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    const pathsHistory = !!localStorage.getItem("pathsHistory")
      ? JSON.parse(localStorage.getItem("pathsHistory")!)
      : {};
    let eventData: any = {
      responseMessage: "User clicked to generate OTP",
      eventType: EventType.GENERATE_OTP
    };
    let eventName = EVENT_NAME.GENERATE_CHALLENGE_SMS_OTP;
    if (
      requestSource &&
      pathsHistory.current === CONVERT_OUTSTANDING_TO_EMI_OVERVIEW
    ) {
      eventData.requestSource = isResend ? "via resend OTP" : requestSource;
      eventName = EVENT_NAME.GENERATE_CHALLENGE_SMS_OTP_OSTOEMI;
    } else if (requestSource && pathsHistory.current === EMI_OVERVIEW_ROUTE) {
      eventData.requestSource = isResend ? "via resend OTP" : requestSource;
      eventName = EVENT_NAME.GENERATE_CHALLENGE_SMS_OTP_TXNTOEMI;
    }
    // generate challenge sms verify
    AppApi.captureEvent({
      eventName: eventName,
      hfAccountId: account.id,
      hfCardId: selectedCard.id,
      hfCustomerId: customer.id,
      primarySource: window.location.href,
      eventData: eventData,
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureVerifyChallengeSmsOtp = (responseMessage: any) => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    const pathsHistory = !!localStorage.getItem("pathsHistory")
      ? JSON.parse(localStorage.getItem("pathsHistory")!)
      : {};
    let eventData: any = {
      responseMessage: responseMessage,
      eventType: EventType.VERIFY_OTP
    };
    let eventName = EVENT_NAME.VERIFY_CHALLENGE_SMS_OTP;
    if (
      requestSource &&
      pathsHistory.current === CONVERT_OUTSTANDING_TO_EMI_OVERVIEW
    ) {
      eventData.requestSource = isResendOtp ? "via resend OTP" : requestSource;
      eventName = EVENT_NAME.VERIFY_CHALLENGE_SMS_OTP_OSTOEMI;
    } else if (requestSource && pathsHistory.current === EMI_OVERVIEW_ROUTE) {
      eventData.requestSource = isResendOtp ? "via resend OTP" : requestSource;
      eventName = EVENT_NAME.VERIFY_CHALLENGE_SMS_OTP_TXNTOEMI;
    }
    // challenge sms verify capture
    AppApi.captureEvent({
      eventName: eventName,
      hfAccountId: account.id,
      hfCardId: selectedCard.id,
      hfCustomerId: customer.id,
      primarySource: window.location.href,
      eventData: eventData,
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureResendChallengeSmsOtpEvent = () => {
    // resend sms otp verify
    AppApi.captureEvent({
      eventName: EVENT_NAME.RESEND_CHALLENGE_SMS_OTP,
      hfAccountId: account.id,
      hfCardId: selectedCard.id,
      hfCustomerId: customer.id,
      primarySource: window.location.href,
      eventData: {
        responseMessage: "User clicked to resend OTP"
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const generateChallengeSmsOtp = useCallback(
    async (isResend = false) => {
      try {
        // generate sms otp for token and scope
        const response = await AuthApi.generateChallengeSmsOtp({
          apiToken: authStore.apiToken!,
          challengeScope: authStore.currentChallenge!.scope,
          uniqueURL: getUniqueURLFromCookie() || "",
          isPrimaryCustomerIdSame: user.primaryCustomerId === user.customerId,
          primaryCustomerId: user.primaryCustomerId!
        });

        const data = response.data;

        if (response.status === 200) {
          toastLib.dismiss();
          if (isResend) {
            setIsResendOtp(true);
          }
          captureChallengeSmsOtpEvent("OTP is sent successfully", isResend);
          setTimeout(() => {
            toastLib.dismiss();
            toast(ToastType.SUCCESS, "OTP is sent successfully");
          }, 1000);
          smsOtpRefId.current = data.mobileVerificationRefId;

          autoReadOtp((otp) => {
            setSmsOtp(otp);
            focusOnInput("smsOtpInputContainer", 5);
          });
        } else {
          // if sms otp generation failed return failure status
          captureChallengeSmsOtpEvent(response.data?.status, isResend);
          if (
            data.status ===
              GenerateChallengeSmsOtpResponseStatus.RETRIES_EXCEEDED ||
            data.status ===
              GenerateChallengeSmsOtpResponseStatus.BLOCKED_TEMPORARILY
          ) {
            const time = data.failureReason
              ?.match(/Please retry after (.*)./)?.[1]
              ?.split(",");

            const isMinutesPresent =
              data.failureReason?.includes("Minutes") ||
              data.failureReason?.includes("minutes");

            const retryTime =
              !!time && isMinutesPresent ? time?.[0].split(" ")?.[0] : "1";

            authStore.finishAuth!({
              status: AuthStatus.FAILURE,
              error: data.status,
              message: `Maximum number of OTP attempts exceeded. Please try again after ${retryTime} ${
                (Number(retryTime) ?? 0) < 2 ? "min" : "mins"
              }.`
            });
          } else {
            authStore.finishAuth!({
              status: AuthStatus.FAILURE,
              error: data.failureReason,
              message:
                "An error occurred while generating OTP.\nPlease try again later."
            });
          }
        }
      } catch (error) {
        // if exception occurred return failure status
        authStore.finishAuth!({
          status: AuthStatus.FAILURE,
          error,
          message:
            "An error occurred while generating OTP.\nPlease try again later."
        });
      }
    },
    [
      authStore.apiToken,
      authStore.currentChallenge,
      authStore.finishAuth,
      user.customer
    ]
  );

  const onOpen = useCallback(async () => {
    setIsLoading(true);
    await generateChallengeSmsOtp();
    setIsLoading(false);
  }, [generateChallengeSmsOtp]);

  useEffect(() => {
    if (isOpen) {
      // on open
      onOpen();
    }
  }, [isOpen, onOpen]);

  const verifyChallengeSmsOtp = async () => {
    if (divElement) {
      //virtual keyboard & confirm button overlap fix
      divElement.style.margin = "0px";
    }
    setIsSubmitLoading(true);

    try {
      // verify sms otp for token and scope
      const response = await AuthApi.verifyChallengeSmsOtp({
        apiToken: authStore.apiToken!,
        challengeScope: authStore.currentChallenge!.scope,
        uniqueURL: getUniqueURLFromCookie() || "",
        mobileVerificationRefId: smsOtpRefId.current,
        otp: smsOtp
      });

      const data = response.data;

      if (response.status === 200) {
        // update smsOtpRefId in verified challenges
        captureVerifyChallengeSmsOtp("Otp Verified");
        const verifiedChallenges: VerifiedChallenges = {
          ...authStore.verifiedChallenges,
          smsOtpRefId: smsOtpRefId.current
        };

        // set verified challenges
        dispatch(setVerifiedChallenges(verifiedChallenges));

        // finish challenge
        finishChallenge();
      } else {
        captureVerifyChallengeSmsOtp(
          data.failureReason ||
            "The OTP verification has failed. Please try again"
        );
        if (
          data.failureReason ===
          VerifyChallengeSmsOtpResponseFailureReason.INVALID_OTP
        ) {
          toastLib.dismiss();
          setTimeout(() => {
            toastLib.dismiss();
            toast(
              ToastType.ERROR,
              `The OTP entered is incorrect. Please try again`
            );
          }, 1000);
          setTimeout(() => {
            toastLib.dismiss();
            setSmsOtp("");
            if (otpInputRef.current) {
              otpInputRef.current.focusInput(0);
            }
          }, 1500);
        } else if (
          data.failureReason ===
          VerifyChallengeSmsOtpResponseFailureReason.OTP_EXPIRED
        ) {
          toastLib.dismiss();
          setTimeout(() => {
            toast(ToastType.ERROR, `The OTP has expired. Please try again`);
          }, 1000);
        } else {
          toastLib.dismiss();
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP verification has failed. Please try again"
            );
          }, 1000);
        }
      }
    } catch (error) {
      // if exception occurred return failure status
      authStore.finishAuth!({
        status: AuthStatus.FAILURE,
        error,
        message: "An error occurred. Please try again later."
      });
    }

    setIsSubmitLoading(false);
  };

  useEffect(() => {
    const keyDownHandle = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (isOpen) {
          handleVerifyOtpClick();
        }
      }
    };
    document.addEventListener("keydown", keyDownHandle);
    return () => {
      document.removeEventListener("keydown", keyDownHandle);
    };
  }, [isOpen, smsOtp]);

  useEffect(() => {
    if (divElement) {
      divElement.style.margin = "50px";
    }
  }, [document.activeElement]);

  const handleVerifyOtpClick = () => {
    verifyChallengeSmsOtp();
  };

  const sleep = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleAutoRead = () => {
    if (os !== "Android") {
      autoReadOtp((otpValue) => {
        setSmsOtp(otpValue);
        focusOnInput("loginOtpInputContainer", 5);
      });
    }
  };

  const handleResendClick = async () => {
    const isResend = true;
    setTimeOut(RESEND_OTP_TIME);
    toastLib.dismiss();
    toast(ToastType.SUCCESS, "Resending OTP");
    toastLib.dismiss();
    await sleep(1000);
    setSmsOtp("");
    generateChallengeSmsOtp(isResend);
    captureResendChallengeSmsOtpEvent();
  };

  useEffect(() => {
    // Start the interval
    let interval: any;
    if (isOpen && timeOut > 0) {
      interval = setTimeout(() => {
        setTimeOut(timeOut - 1);
      }, 1000);
    }
    if (!isOpen) {
      setIsResendOtp(false);
      setTimeOut(RESEND_OTP_TIME);
    }
    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut, isOpen]);

  const debounceHandleResendClick = _debounce(handleResendClick, 500);

  const handleCloseSheet = () => {
    authStore.finishAuth!({
      status: AuthStatus.CANCELLED
    });
  };

  const style = {
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    backgroundColor: "#141414",
    borderTop: "1px solid #9A9A9A",
    paddingTop: "16px",
    maxWidth: "550px",
    margin: "auto"
  };

  // const actionText = authStore.actionText;
  // const isOpaque = authStore.isAuthScreenOpaque;
  // eslint-disable-next-line
  return (
    <BottomSheetCustom
      sheetStyle={style}
      open={isOpen}
      handleClose={handleCloseSheet}
    >
      <div
        className={styles.bottomSheet}
        style={{ maxWidth: `${PWA_MAX_WIDTH_IN_PX}px` }}
      >
        {isLoading ? (
          <div className={styles.loaderStyles}><Loader /></div>
        ) : (
          <div className={styles.bottomSheetContainer}>
            <div className={styles.bottomSheetHeader}>verify with OTP</div>
            <div className={styles.bottomSheetText}>
              Please enter the 6 digit OTP sent to +91{" "}
              {getMaskedMobileNumberForLastTwo(user.customer?.lastFour) || ""}
            </div>
            <div id="smsOtpInputContainer" className={styles.otpContainer}>
              <input
                id="inputPhone"
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                placeholder="------"
                className={styles.phoneNumberInput1}
                style={{ opacity: smsOtp.length > 0 ? 1 : 0.5 }}
                value={smsOtp}
                maxLength={6}
                onChange={(e: any) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
                readOnly={os === "Android" && !isManualOtpAllowed}
                autoFocus={os !== "Android"}
                onFocus={handleAutoRead}
              />
              <div className={styles.resendContainer}>
                <div>Didn't receive OTP?</div>
                {timeOut !== 0 ? (
                  <span className={styles.retryText}>
                    Retry in 00:{timeOut.toString().padStart(2, "0")}s
                  </span>
                ) : (
                  <>
                    <div
                      className={styles.resendText}
                      onClick={debounceHandleResendClick}
                    >
                      Resend OTP
                    </div>
                  </>
                )}
              </div>
              {config?.tncText && (
                <div className={styles.tncContainer}>
                  <div className={styles.tncText}>
                    <TextWithLinks
                      text={config?.tncText || ""}
                      linkClassName={styles.linkText}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.confirmBtn1Container2}>
              <Button2
                text1="confirm"
                customClassName={styles.confirmBtn1}
                isLoading={isSubmitLoading}
                onClick={handleVerifyOtpClick}
                disabled={smsOtp.length !== 6}
              />
            </div>
          </div>
        )}
        <div id="extraDiv"></div>
      </div>
    </BottomSheetCustom>
  );
};

export default SmsOtp;
