import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type {
  PropertiesResetOnAuthFinish,
  AuthState,
  SetAuthConfigActionPayload,
  SetAuthStateActionPayload,
  SetCurrentChallengeActionPayload,
  SetVerifiedChallengesActionPayload
} from "./authSliceTypes";

const propertiesResetOnAuthFinish: PropertiesResetOnAuthFinish = {
  apiId: null, // "UR7HMFUogL"
  apiToken: null, // "api_HYPWREn8OrpMI"
  finishAuth: null,
  challenges: [],
  // [
  //   {
  //     scope: "FIRST_FACTOR",
  //     challenge: "OTP_SMS"
  //   },
  //   {
  //     scope: "SECOND_FACTOR",
  //     challenge: "MPIN"
  //   }
  // ]
  currentChallenge: null,
  // {
  //   scope: "FIRST_FACTOR",
  //   challenge: "OTP_SMS"
  // }
  actionText: "", // user friendly action text to be displayed in auth screen
  isAuthScreenOpaque: false // if auth screen should be opaque
};

const initialState: AuthState = {
  ...propertiesResetOnAuthFinish,

  // set after successful challenge completion
  verifiedChallenges: {
    deviceId: null, // "wXEEnNIleZABXk+q0qob3Q=="
    smsOtpRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    emailOtpRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    mpinRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    customerRefId: null // "cv_HYPBJ9ydfU0v9"
  },

  // set on app load
  config: null
  // {
  //   UR7HMFUogL: {
  //     result: true,
  //     challenges: [
  //       {
  //         scope: "FIRST_FACTOR",
  //         challenge: "OTP_SMS"
  //       },
  //       {
  //         scope: "SECOND_FACTOR",
  //         challenge: "OTP_SMS"
  //       }
  //     ],
  //     conditional: true,
  //     dynamicChallenges: [
  //       {
  //         flowId: "1",
  //         deviceTokenPresent: false,
  //         mpinKnown: false,
  //         challenges: [
  //           {
  //             scope: "FIRST_FACTOR",
  //             challenge: "OTP_SMS"
  //           },
  //           {
  //             scope: "SECOND_FACTOR",
  //             challenge: "OTP_EMAIL"
  //           },
  //           {
  //             scope: "STEP_UP",
  //             challenge: "VERIFY_CUSTOMER"
  //           }
  //         ]
  //       }
  //     ]
  //   },
  //   UR7HMFeogL: {
  //     result: false
  //   },
  //   isMpinSet: {
  //     result: false
  //   }
  // }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthConfig: (
      state,
      action: PayloadAction<SetAuthConfigActionPayload>
    ) => {
      state.config = action.payload;
    },
    setAuthState: (state, action: PayloadAction<SetAuthStateActionPayload>) => {
      state.apiId = action.payload.apiId;
      state.apiToken = action.payload.apiToken;
      state.challenges = action.payload.challenges;
      state.currentChallenge = action.payload.currentChallenge;
      state.actionText = action.payload.actionText;
      state.isAuthScreenOpaque = action.payload.isAuthScreenOpaque;
      state.finishAuth = action.payload.finishAuth;
    },
    setCurrentChallenge: (
      state,
      action: PayloadAction<SetCurrentChallengeActionPayload>
    ) => {
      state.currentChallenge = action.payload;
    },
    setVerifiedChallenges: (
      state,
      action: PayloadAction<SetVerifiedChallengesActionPayload>
    ) => {
      state.verifiedChallenges = action.payload;
    },
    resetAuthState: (state) => {
      state = { ...state, ...propertiesResetOnAuthFinish };
      return state;
    }
  }
});

export const {
  setAuthConfig,
  setAuthState,
  setCurrentChallenge,
  setVerifiedChallenges,
  resetAuthState
} = authSlice.actions;

export default authSlice.reducer;
