import styles from "./OutstandingEmiErrorScreen.module.scss";
import errorSheet from "../../assets/svgs/convertOutstandingToEmi/errorSheet.svg";
import copySvg from "../../assets/svgs/convertOutstandingToEmi/CopySimple.svg";
import { Button, Button2 } from "../button/Button";
import { useSelector } from "react-redux";
import {
  fallbackCopyTextToClipboard,
  formatAmount
} from "../../utils/functions";
import { toast } from "../toast/toast";
import { ToastType } from "../toast/toastTypes";
import { useNavigate } from "react-router-dom";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";

const failureReasonMapping = [
  {
    heading: "Account Overlimit",
    body: "Due to insufficient available credit limit \nduring EMI conversion."
  },
  {
    heading: "TRANSACTION PREVIOUSLY CONVERTED TO EMI",
    body: "The requested transaction has already \nbeen converted into EMI"
  },
  {
    heading: "Not eligible in this month",
    body: "Your eligibility status for EMI conversion was \nmodified by the bank during processing."
  },
  {
    heading: "Purchase principal O/S is not available",
    body: "Your outstanding amount was reduced from the eligible \namount requested by you during EMI conversion"
  },
  {
    heading: "NOT ALLOWED, MINIMUM TRANSACTION AMO",
    body: "Your eligible outstanding balance dropped below the \nminimum eligible amount for EMI conversion"
  },
  {
    heading: "NOT ALLOWED, ACCOUNT STATUS",
    body: "Your account status was blocked \nduring the processing period."
  },
  {
    heading: "Account status is other than normal",
    body: "Your account status was blocked \nduring the processing period."
  },
  {
    heading: "Card status is other than normal",
    body: "Your card was blocked during the \nprocessing period."
  },
  {
    heading: "Failed in Across File Dedupe check",
    body: "You have already requested EMI conversion \nthrough another channel"
  },
  {
    heading: "Failed in SAME DAY Dedupe check",
    body: "You have already requested EMI conversion \nthrough this channel"
  }
];

const OutstandingEmiErrorScreen = ({ setIsBottomSheetOpen }) => {
  const navigate = useNavigate();
  const [errorBody, setErrorBody] = useState([]);
  const outstandingToEmiStatus = useSelector(
    (state) => state.outstandingToEmi.emiStatus
  );

  useEffect(() => {
    const errorObject = failureReasonMapping.filter((ele) => {
      if (
        ele.heading.toLocaleLowerCase() ===
        outstandingToEmiStatus?.failureReason?.toLocaleLowerCase()
      ) {
        return ele;
      }
    });
    if (errorObject.length > 0) {
      setErrorBody(errorObject[0].body.split("\n"));
    }
  }, [outstandingToEmiStatus]);

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(outstandingToEmiStatus.extRefId);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Reference ID Copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(outstandingToEmiStatus.extRefId);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Reference ID Copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  const handleSupportButton = () => {
    navigate("/manageCard/customerCare");
    setIsBottomSheetOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.containerImage}>
        <img src={errorSheet} alt="error sheet" />
      </div>
      <p className={styles.headingText}>
        Request to convert outstanding of <br />{" "}
        {`${formatAmount(
          outstandingToEmiStatus?.principal
        )} to EMI is rejected :(`}
      </p>
      {errorBody.length > 0 && errorBody.map((line, index) => (
        <p key={index} className={styles.contentText}>{line}</p>
      ))}
      <div className={styles.copyText}>
        <span>Ref No:{outstandingToEmiStatus.extRefId}</span>
        <span onClick={debounceHandleCopy}>
          <img src={copySvg} alt="copy svg" />
        </span>
      </div>
      <hr className={styles.horizontalLine3}></hr>
      <div className={styles.buttonContainer}>
        <Button2
          customClassName={styles.btn}
          text1="back to home"
          onClick={() => setIsBottomSheetOpen(false)}
        />
        <Button
          customClassName={styles.btn}
          text1="contact support"
          onClick={handleSupportButton}
        />
      </div>
    </div>
  );
};

export default OutstandingEmiErrorScreen;
