import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  BenefitsState,
  SetCampaignBenefitsActionPayload,
  SetCvpBenefitsActionPayload,
  SetMerchantBenefitsActionpayload,
  SetProgramPreferences,
  SetAccountPreferences,
  SetWelcomeBenefitsActionPayload
} from "./benefitsSliceTypes";

const initialState: BenefitsState = {
  cvpBenefits: {
    benefits: []
  },
  campaignBenefits: {
    benefits: []
  },
  merchantBenefits: {
    benefits: []
  },
  welcomeBenefits: {
    benefits: []
  },
  programPreferences: null,
  accountPreferences: null,
  allOffersApiFailure: false
};

export const benefitsSlice = createSlice({
  name: "benefits",
  initialState,
  reducers: {
    setCvpBenefits: (
      state,
      action: PayloadAction<SetCvpBenefitsActionPayload>
    ) => {
      state.cvpBenefits.benefits = action.payload;
    },
    setCampaignBenefits: (
      state,
      action: PayloadAction<SetCampaignBenefitsActionPayload>
    ) => {
      state.campaignBenefits.benefits = action.payload;
    },
    setWelcomeBenefits: (
      state,
      action: PayloadAction<SetWelcomeBenefitsActionPayload>
    ) => {
      state.welcomeBenefits.benefits = action.payload;
    },
    setMerchantBenefits: (
      state,
      action: PayloadAction<SetMerchantBenefitsActionpayload>
    ) => {
      state.merchantBenefits.benefits = action.payload;
    },
    setProgramPreferences: (
      state,
      action: PayloadAction<SetProgramPreferences>
    ) => {
      state.programPreferences = action.payload;
    },
    setAccountPreferences: (
      state,
      action: PayloadAction<SetAccountPreferences>
    ) => {
      state.accountPreferences = action.payload;
    },
    setAllOffersApiFailure: (state, action: PayloadAction<boolean>) => {
      state.allOffersApiFailure = action.payload;
    }
  }
});

export const {
  setCvpBenefits,
  setCampaignBenefits,
  setWelcomeBenefits,
  setMerchantBenefits,
  setProgramPreferences,
  setAccountPreferences,
  setAllOffersApiFailure
} = benefitsSlice.actions;

export default benefitsSlice.reducer;
