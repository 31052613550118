import Bills from "../assets/images/rewardsRedeem/EazyDiner/1.png";
import Luggage from "../assets/images/rewardsRedeem/EazyDiner/2.png";
import Bowl from "../assets/images/rewardsRedeem/EazyDiner/3.png";
import Plane from "../assets/images/rewardsRedeem/Avios/1.png";
import Bag from "../assets/images/rewardsRedeem/Avios/2.png";
import Hotel from "../assets/images/rewardsRedeem/Avios/3.png";
import PetrolPump from "../assets/images/rewardsRedeem/Intermiles/2.png";
import Gadgets from "../assets/images/rewardsRedeem/Intermiles/3.png";
import Chair from "../assets/images/rewardsRedeem/Vistara/1.png";
import FlightPartnerImage from "../assets/images/rewardsRedeem/Vistara/3.png";
import { CobrandedCards } from "./enums";

export default {
  [CobrandedCards.EAZYDINER]: {
    redirectionButtonText: "EazyPoints",
    redirectionUrl: "https://www.eazydiner.com/redemption/",
    cardDetails: [
      { imageUrl: Bills, ctaText: "Pay Restaurant Bills with Points" },
      { imageUrl: Luggage, ctaText: "Avail free Hotel Stays and Meals" },
      { imageUrl: Bowl, ctaText: "Exclusive Access to Restaurant Events" }
    ]
  },
  [CobrandedCards.AVIOS]: {
    redirectionButtonText: "Avios points",
    redirectionUrl:
      "https://www.britishairways.com/content/executive-club/avios/spending-avios/",
    cardDetails: [
      {
        imageUrl: Plane,
        ctaText: "Fly smart with savings on Flights & Upgrades"
      },
      { imageUrl: Bag, ctaText: "Shop at Qatar Duty Free" },
      {
        imageUrl: Hotel,
        ctaText: "Snag exclusive deals on Hotels & Car Rentals "
      }
    ]
  },
  [CobrandedCards.INTERMILES]: {
    redirectionButtonText: "Intermiles",
    redirectionUrl: "https://www.intermiles.com/use-intermiles/",
    cardDetails: [
      { imageUrl: Plane, ctaText: "Swap InterMiles for Free Flights & Stays" },
      {
        imageUrl: PetrolPump,
        ctaText: "Fuel up at select Indian Oil Petrol Pumps"
      },
      { imageUrl: Gadgets, ctaText: "Snag cool gadgets, vouchers, and more!" }
    ]
  },
  [CobrandedCards.CLUB_VISTARA]: {
    redirectionButtonText: "CV points",
    redirectionUrl:
      "https://www.airvistara.com/in/en/club-vistara/redeem/award-flights/",
    cardDetails: [
      { imageUrl: Chair, ctaText: "Soar the skies with comfy seat upgrades" },
      { imageUrl: Plane, ctaText: "Fly free on Vistara with CV points" },
      {
        imageUrl: FlightPartnerImage,
        ctaText: "Swap your points for partner flights"
      }
    ],
  },
  ["pinnacle"]: {
    redirectionButtonText: "EazyPoints",
    redirectionUrl: "https://www.eazydiner.com/redemption/",
    cardDetails: [
      { imageUrl: Bills, ctaText: "Pay Restaurant Bills with Points" },
      { imageUrl: Luggage, ctaText: "Avail free Hotel Stays and Meals" },
      { imageUrl: Bowl, ctaText: "Exclusive Access to Restaurant Events" }
    ]
  }
};
