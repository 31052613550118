import styles from "./ErrorScreen.module.scss";
import cactus from "../../assets/svgs/cactus.svg";
import reloadIcon from "../../assets/svgs/ArrowClockwise.svg";
import { Button2 } from "../../components";

const ErrorScreen = () => {
  const onReloadClick = () => {
    window.location.href = "./";
  };

  return (
    <div className={styles.container}>
      <img src={cactus} alt="cactus" className={styles.image} />
      <div className={styles.text1Container}>
        <div className={styles.text1}>oops!</div>
        <div className={styles.text2}>something went wrong</div>
      </div>
      <Button2
        text1="reload"
        customClassName={styles.btn}
        icon={reloadIcon}
        onClick={onReloadClick}
      />
    </div>
  );
};

export default ErrorScreen;
