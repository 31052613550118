import { AuthScope } from "../appApi/appApiTypes";

export enum GenerateChallengeSmsOtpResponseStatus {
  RETRIES_EXCEEDED = "RETRIES_EXCEEDED",
  BLOCKED_TEMPORARILY = "BLOCKED_TEMPORARILY",
  INVALID_OTP = "INVALID_OTP",
  OTP_EXPIRED = "OTP_EXPIRED"
}

export enum VerifyChallengeSmsOtpResponseStatus {
  VERIFIED = "VERIFIED",
  FAILED = "FAILED"
}

export enum VerifyChallengeSmsOtpResponseFailureReason {
  INVALID_OTP = "INVALID_OTP",
  OTP_EXPIRED = "OTP_EXPIRED",
  INVALID_REF_ID = "INVALID_REF_ID",
  INVALID_API_SCOPE = "INVALID_API_SCOPE",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR"
}

export interface GenerateChallengeSmsOtpRequest {
  apiToken: string;
  challengeScope: AuthScope;
  isPrimaryCustomerIdSame: boolean;
  primaryCustomerId: string;
  uniqueURL: string;
}

export interface GenerateChallengeSmsOtpResponse {
  apiToken: string;
  challengeScope: AuthScope;
  mobileVerificationRefId: string;
  status: GenerateChallengeSmsOtpResponseStatus;
  failureReason?: string;
}

export interface VerifyChallengeSmsOtpRequest {
  apiToken: string;
  challengeScope: AuthScope;
  uniqueURL: string;
  mobileVerificationRefId: string;
  otp: string;
}

export interface VerifyChallengeSmsOtpResponse {
  apiToken: string;
  challengeScope: AuthScope;
  mobileVerificationRefId: string;
  verificationStatus: VerifyChallengeSmsOtpResponseStatus;
  failureReason?: VerifyChallengeSmsOtpResponseFailureReason;
}

export interface LogoutRequest {
  accountId: string;
  cardId: string;
  customerId: string;
  skipValidate?: boolean;
}

export interface LogoutSuccessResponse {
  status: string;
}

export interface LogoutFailureResponse {
  status: string;
}
export interface LogoutProps {
  accountId: string;
  customerId: string;
  cardId: string;
  skipValidate?: boolean;
}
