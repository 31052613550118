import styles from "./OtpFailureScreens.module.scss";
import errorSheet from "../../assets/svgs/convertOutstandingToEmi/errorSheet.svg";
import { Button2 } from "../button/Button";

const OtpFailureScreen = ({ onTryAgain, onBackToHome, showBackToHome }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.containerImage}>
        <img src={errorSheet} alt="error sheet" />
      </div>
      <p className={styles.headingText}>
        {showBackToHome
          ? "Sorry! The problem still persists"
          : "Sorry! We ran into a problem"}
      </p>
      <p className={styles.headingText2}>
        {showBackToHome
          ? "We are on it to resolve this issue."
          : "placing your request for conversion"}
      </p>
      <p className={styles.contentText}>
        {showBackToHome
          ? "Please try again after some time."
          : "Please try again to confirm request."}
      </p>
      <hr className={styles.horizontalLine3}></hr>
      <div className={styles.buttonContainer}>
        {showBackToHome ? (
          <Button2
            customClassName={styles.btn}
            text1="back to home"
            onClick={onBackToHome && onBackToHome}
          />
        ) : (
          <Button2
            customClassName={styles.btn}
            text1="try again"
            onClick={onTryAgain && onTryAgain}
          />
        )}
      </div>
    </div>
  );
};

export default OtpFailureScreen;
