import React from "react";
import styles from "./styles.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

interface CarouselProps {
  items: React.JSX.Element[];
}

export default function App(props: CarouselProps) {
  const { items } = props;

  return (
    <Swiper
      // slidesPerView={1.4}
      // spaceBetween={0}
      initialSlide={0}
      centeredSlides={false}
      pagination={false}
      modules={[Pagination]}
      className={styles.mySwiper}
      breakpoints={{
        "@0.00": {
          slidesPerView: 1.4,
          spaceBetween: 40
        }
        // Removing Breakpoints beyond 0.6 as maximum width of application is 550px.
      }}
    >
      {items?.map((item, index) => (
        <SwiperSlide
          key={index}
          style={{
            maxWidth: "250px",
            marginRight: "20%",
            paddingLeft: index === 0 ? "25px" : ""
          }}
        >
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
