import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "../../app/store";
import SmsOtp from "./smsOtp/SmsOtp";
import { AuthChallenge } from "../../apis/appApi/appApiTypes";
import { AuthStatus } from "../../features/auth/authSliceTypes";

const AuthScreens = (): ReactElement => {
  const authStore = useSelector((state: RootState) => state.auth);

  
  
  useEffect(() => {
    if (authStore.finishAuth) {
      const handleBackButton = () =>
      // eslint-disable-next-line
        authStore.finishAuth!({ status: AuthStatus.CANCELLED });

      window.addEventListener("popstate", handleBackButton);

      return () => {
        window.removeEventListener("popstate", handleBackButton);
      };
    }
  }, [authStore.finishAuth]);

  return (
    <>
      <SmsOtp
        isOpen={authStore.currentChallenge?.challenge === AuthChallenge.OTP_SMS}
      />
    </>
  );
};

export default AuthScreens;
