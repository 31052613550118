import { useEffect, useRef, useState } from "react";
import styles from "./convertOutstandingToEmi.module.scss";
import Loader from "../../components/loader/loader";
import { Button2, Header } from "../../components";
import disk from "../../assets/svgs/convertOutstandingToEmi/disk.svg";
import {
  default as CurrencyFormat,
  default as NumberFormat
} from "react-currency-format";
import Slider from "react-slider";
import sliderStar from "../../assets/svgs/sliderstar.svg";
import editSvg from "../../assets/svgs/convertOutstandingToEmi/edit.svg";
import info from "../../assets/svgs/info.svg";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import { useNavigate } from "react-router-dom";
import { CONVERT_OUTSTANDING_TO_EMI_TENURE_SELECTOR } from "../../routes/ScreenRoutes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setAmountSelectedForOutstandingToEmi } from "../../features/outstandingToEmi/outstandingToEmiSlice";
import { captureEvent } from "../../utils/functions";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { EventType } from "../../utils/enums";

const css = `
    .slider{
  z-index: 100;
  border-radius: 10px;
  height: 4px;
}

.sliderTrack{
  position: relative;
  background:#333333 ;
  height: 4px;
}

.sliderTrack-0{
    background: #F4B348 ;
    
    }
.sliderTrack-1{
  background: #333333 ;
  height: 4px;

}
.reactSwitch{
  height: 20px;
  width: 40px;
}
`;

const ConvertOutstandingToEmi = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currencyInputRef = useRef<HTMLDivElement>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
  const [infoBottomSheet, setInfoBottomSheet] = useState<boolean>(false);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [isInputButtonClicked, setIsInputButtonClicked] =
    useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isMoreThanOutstanding, setIsMoreThanOutStanding] =
    useState<boolean>(false);
  const [isLessThanOutstanding, setIsLessThanOutStanding] =
    useState<boolean>(false);
  const selectedAmountSelecter = useSelector(
    (state: RootState) => state.outstandingToEmi.selectedAmount
  );

  const eligibility = useSelector(
    (state: RootState) => state.outstandingToEmi.eligibility
  );

  const selectedAmount = selectedAmountSelecter;
  const [amount, setAmount] = useState<string>(selectedAmount.toFixed(2));

  //It will check if inner keyboard open functionality is there or not
  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpenNow = window.outerHeight - window.innerHeight > 250;
      setIsKeyboardOpen(isKeyboardOpenNow);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [inputFocused]);

  useEffect(() => {
    const moreThanOutstanding = eligibility.eligibleAmount < selectedAmount;
    const lessThanOutstanding =
      eligibility.minOutstandingAmount > selectedAmount;
    setIsLessThanOutStanding(lessThanOutstanding);
    setIsMoreThanOutStanding(moreThanOutstanding);
  }, [selectedAmount, eligibility]);

  useEffect(() => {
    const isSlider =
      eligibility.eligibleAmount - eligibility.minOutstandingAmount > 1000;
    setIsSliderOpen(isSlider);
  }, [eligibility]);

  useEffect(() => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.VIEW_OSTOEMI_SELECT_AMOUNT_PAGE,
      eventData: { requestSource, eventType: EventType.PAGE_VIEW }
    });
  }, []);

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleButtonClick = () => {
    setIsInputButtonClicked(true);
  };

  useEffect(() => {
    if (isInputButtonClicked) {
      const inputElement = currencyInputRef.current?.querySelector("input");
      if (inputElement) {
        inputElement.focus();
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length - 3);
      }
    }
  }, [isInputButtonClicked]);

  const handleClickOutsideInputTag = (event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node) &&
      currencyInputRef.current &&
      !currencyInputRef.current.contains(event.target as Node)
    ) {
      setIsInputButtonClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideInputTag);

    return () => {
      document.removeEventListener("click", handleClickOutsideInputTag);
    };
  }, []);

  const infoBottomSheetContent = () => {
    return (
      <div className={styles.infoBottomSheetContainer}>
        <div>
          <h1>
            Part of your outstanding <br />
            is non-eligible for conversion
          </h1>
          <p>
            Outstanding that is comprised of Cash transaction, Interest, Fee &
            Charges, EMIs or any transaction other than Purchase Transactions
            will not eligible for conversion to EMI under this facility.
          </p>
        </div>
        <Button2
          text1="close"
          customClassName={styles.infoCloseButton}
          onClick={() => setInfoBottomSheet(false)}
        />
      </div>
    );
  };

  const handleConvertNowButton = () => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.OSTOEMI_AMOUNT_PAGE_CONVERT_NOW_CLICK,
      eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
    });
    navigate(CONVERT_OUTSTANDING_TO_EMI_TENURE_SELECTOR, {
      state: { selectedAmount }
    });
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="convert to Emi" />
    </div>
  ) : (
    <div>
      <Header
        label="Convert Outstanding to EMI"
        bgColor="#141414"
        navigationPath={-1}
      />
      <hr className={styles.horizontalLine}></hr>
      <div
        className={
          inputFocused && isKeyboardOpen
            ? styles.contentContainer
            : styles.contentContainer2
        }
      >
        <div className={styles.middleSection}>
          <div className={styles.image}>
            <img src={disk} alt="disk" />
          </div>
          <div>
            <p className={styles.middleSectionText}>
              Choose an amount to convert
            </p>
            <p className={styles.middleSectionText1}>
              from your eligible outstanding
            </p>
          </div>
        </div>

        <div className={styles.selectAmountContainer}>
          <style>{css}</style>
          <div
            className={
              isSliderOpen
                ? styles.currencyContainer
                : isLessThanOutstanding || isMoreThanOutstanding
                ? styles.currencyContainerMinMaxAmount
                : styles.currencyContainerSliderNotOpen
            }
            ref={currencyInputRef}
          >
            <CurrencyFormat
              // isAllowed={(values) =>
              //   Number(values?.value) <= maximumEligibleAmount &&
              //   Number(values?.value) >= minimumEligibleAmount
              // }
              allowNegative={false}
              displayType={isInputButtonClicked ? "input" : "text"}
              decimalScale={2}
              placeholder="NA"
              thousandSpacing="2s"
              thousandSeparator={true}
              fixedDecimalScale={true}
              prefix={"₹ "}
              className={styles.currencyFormat}
              fontWeight="bold"
              value={amount}
              fontSize="32px"
              variant="underlined"
              renderText={(value) => (
                <span
                  className={styles.renderText}
                  style={{
                    width: `${(String(amount).length + 3) * 17.5}px`
                  }}
                >
                  {value}
                </span>
              )}
              onValueChange={(values) => {
                dispatch(
                  setAmountSelectedForOutstandingToEmi(
                    values.value ? parseFloat(values?.value) : 0
                  )
                );
                let amount = values?.value;

                if ((amount + "").charAt(0) === "-") {
                  let value = Number(amount);
                  value = value * -1;
                }
                setAmount(amount ? amount : "0.00");
                if (amount.length > 9) return false;
              }}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              style={{
                width: `${(String(amount).length + 3) * 17.5}px`,
                minWidth: "70px",
                padding: isInputButtonClicked ? "0px" : "6px 0px",
                textAlign: "left",
                maxWidth: "300px"
              }}
            />

            <div
              className={styles.editSvg}
              onClick={handleButtonClick}
              ref={buttonRef}
            >
              <img src={editSvg} alt="editSvg" />
            </div>
          </div>

          {isSliderOpen && (
            <div>
              <Slider
                defaultValue={selectedAmount}
                disabled={false}
                min={eligibility.minOutstandingAmount}
                max={eligibility.eligibleAmount}
                step={100}
                value={selectedAmount}
                onChange={(amount: number) => {
                  setAmount(amount.toFixed(2));
                  dispatch(
                    setAmountSelectedForOutstandingToEmi(
                      parseFloat(amount.toFixed(2))
                    )
                  );
                }}
                className={styles.slider}
                thumbClassName={styles.sliderThumb}
                trackClassName="sliderTrack"
                renderThumb={(props, state) => (
                  <div className={styles.sliderThumbContainer} {...props}>
                    <img
                      className={styles.sliderThumbImg}
                      src={sliderStar}
                      alt=""
                    />
                  </div>
                )}
              />
            </div>
          )}

          {!isSliderOpen &&
            !isMoreThanOutstanding &&
            !isLessThanOutstanding && (
              <div className={styles.minAndMaxAmountDiv}>
                <span className={styles.minAmount}> Min </span>
                <NumberFormat
                  flex="1"
                  value={eligibility.minOutstandingAmount}
                  displayType={"text"}
                  thousandSpacing="2s"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  prefix={"₹"}
                  className={styles.minAmount}
                />
                <span className={styles.minAmount}> & Max </span>
                <NumberFormat
                  flex="1"
                  value={eligibility.eligibleAmount}
                  displayType={"text"}
                  thousandSpacing="2s"
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"₹"}
                  className={styles.maxAmount}
                />
              </div>
            )}

          {!isSliderOpen && isMoreThanOutstanding && (
            <div className={styles.maxEligibleAmout}>
              amount can’t be more than your eligible outstanding
            </div>
          )}
          {!isSliderOpen && isLessThanOutstanding && (
            <div className={styles.maxEligibleAmout}>
              <span>amount can’t be less than the minimum of </span>
              <NumberFormat
                flex="1"
                value={eligibility.minOutstandingAmount}
                displayType={"text"}
                thousandSpacing="2s"
                thousandSeparator={true}
                fixedDecimalScale={false}
                decimalScale={0}
                prefix={"₹"}
                className={styles.maxEligibleAmout}
              />
            </div>
          )}

          {isSliderOpen && (
            <div className={styles.textContainer}>
              <div>
                <div className={styles.eligibleAmt}>
                  <p>Minimum</p>
                </div>
                <NumberFormat
                  flex="1"
                  value={eligibility.minOutstandingAmount}
                  displayType={"text"}
                  thousandSpacing="2s"
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  decimalScale={0}
                  prefix={"₹ "}
                  className={styles.minAmount}
                />
              </div>
              <div className={styles.maxAmountContainer}>
                <div className={styles.eligibleAmt}>
                  <p>Total Eligible Amt.</p>
                  <img
                    src={info}
                    alt="info"
                    onClick={() => setInfoBottomSheet(true)}
                  />
                </div>
                <NumberFormat
                  flex="1"
                  value={eligibility.eligibleAmount}
                  displayType={"text"}
                  thousandSpacing="2s"
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"₹ "}
                  className={styles.maxAmount}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.bottomSection}>
          <hr className={styles.horizontalLine}></hr>
          <p>
            Amount to be converted depends on your available credit limit.
            Fulfilment of this request may vary if your card account is
            debited/credited during processing
          </p>
          <Button2
            text1="convert now"
            customClassName={styles.convertButton}
            onClick={() => handleConvertNowButton()}
            disabled={
              isLessThanOutstanding ||
              isMoreThanOutstanding ||
              isNaN(selectedAmount)
            }
          />
        </div>
      </div>
      <BottomsheetComponent
        isBottomSheetOpen={infoBottomSheet}
        setIsBottomSheetOpen={setInfoBottomSheet}
        render={infoBottomSheetContent()}
      />
    </div>
  );
};

export default ConvertOutstandingToEmi;
