import React from "react";
import styles from "./checkbox.module.scss";
import checkBox from "../../assets/svgs/convertOutstandingToEmi/Checkboxes.svg"

type Props = {
  onChange?: (check: boolean) => void;
  isChecked?: boolean;
};

const Checkbox = ({ onChange, isChecked = false }: Props) => {
  const handleChange = (check: boolean) => {
    if (onChange) {
      onChange(check);
    }
  };

  return (
    <>
      {!isChecked && (
        <div
          className={styles.unchecked}
          onClick={() => handleChange(true)}
        ></div>
      )}
      {isChecked && (
        <div className={styles.checked} onClick={() => handleChange(false)}>
          <img src={checkBox} alt="checkbox" />
        </div>
      )}
    </>
  );
};

export default Checkbox;