import React from "react";
import styles from "./eligibleTransaction.module.scss";
import TransactionLogo from "../transaction/TransactionLogo";
import {
  capitalizeFirsLetter,
  formatAmount,
  getTransactionDate
} from "../../utils/functions";

const EligibleTransaction = ({
  onInputChange,
  transaction,
  selectedTransaction
}) => {
  const handleClick = (transaction) => {
    onInputChange(transaction);
  };
  return (
    <>
      <div
        className={`${styles.transaction} ${
          transaction?.id === selectedTransaction?.id
            ? styles.selectedColor
            : styles.originalColor
        }`}
        key={transaction.id}
        onClick={() => {
          handleClick(transaction);
        }}
      >
        <input
          type="radio"
          name="optionsGroup"
          value={transaction}
          id={transaction.id}
          checked={transaction?.id === selectedTransaction?.id ? true : false}
        />
        {/* ********logo and wrapper *********** */}

        <div className={styles.transactionLabelAndLogoWrapper}>
          {transaction?.identifiedMerchantLogo ? (
            <img
              className={styles.transactionLogo}
              src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
              alt="logo"
            />
          ) : (
            <TransactionLogo txnType={transaction?.txnType} />
          )}
          <div className={styles.transactionLabelAndDateWrapper}>
            <div className={styles.transactionLabel}>
              {capitalizeFirsLetter(transaction.description)}
            </div>
            <div className={styles.transactionDate}>
              {getTransactionDate(
                transaction.transactionDate,
                transaction.postingDate || ""
              )}
            </div>
          </div>
        </div>
        {/* ****************amount container************ */}

        <div className={styles.amountContainer}>
          <div
            className={`${styles.transactionAmount} ${
              transaction.txnNature === "CREDIT"
                ? styles.transactionAmountColor
                : ""
            }`}
          >
            {transaction.txnNature === "CREDIT" ? "+" : ""}
            {formatAmount(transaction.transactionAmount)}
          </div>
        </div>
      </div>
    </>
  );
};

export default EligibleTransaction;
