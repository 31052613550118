import { XmasData } from "../utils/FestivalData/Xmas.js";

export enum Env {
  DEV = "dev",
  DEV_MOBILE = "dev_mobile",
  UAT = "uat",
  SANDBOX = "sandbox",
  STRESS = "stress",
  PROD = "prod",
  QA = "qa"
}

export enum CampaignBenefitVariant {
  HOME_PAGE,
  ALL_BENEFITS_PAGE
}

export const Festivals = Object.freeze({
  CURRENT_FESTIVAL: XmasData
});

export enum OsSupportForPrompt {
  ios = "iOS",
  Android = "Android"
}

export enum EmiStatus {
  Not_Created = "NOT_CREATED"
}

export enum CobrandedCards {
  EAZYDINER = "eazydiner",
  AVIOS = "avios",
  INTERMILES = "intermiles",
  CLUB_VISTARA = "club vistara"
}

export enum CookieType {
  DEVICE_ID = "deviceId",
  BLOCKED_CARD_TIME = "blockedCardTime",
  UNIQUE_URL = "uniqueURL",
  DEEPLINK_ROUTE = "deeplinkRoute"
}

export enum DeviceApiErrorCode {
  DEVICE_ALREADY_REGISTERED = 405,
  DEVICE_NOT_REGISTERED = 406,
  DEVICE_LIMIT_REACHED = 407,
  UNIQUE_URL_NOT_FOUND = 408,
  DEVICE_VERSION_CHANGE = 409,
  SMS_NOT_FOUND = 410,
  SMS_NOT_MATCHING = 411
}

export enum OutStandingToEmiNavigation {
  OUTSTADING_TO_EMI_OVERVIEW_TO_CARD_SUMMERY = "OUTSTADING_TO_EMI_OVERVIEW_TO_CARD_SUMMERY"
}

export enum RequestSources {
  NUDGE = "via nudge",
  NOTIFICATION_CARD_PAGE = "via notification on cards homepage",
  DEEPLINK = "via deeplink URL",
  CARD_SUMMARY_PAGE = "via card summary page",
  SPEND_GROUP_TRANSACTIONS_PAGE = "via spend group transactions page",
  BILLED_TRANSACTIONS_PAGE = "via billed transactions page",
  UNBILLED_TRANSACTIONS_PAGE = "via unbilled transactions page",
}

export enum EventType {
  LOGIN_SUCCESS = "login_success",
  GENERATE_OTP = "Generate OTP",
  VERIFY_OTP = "Verify OTP",
  NUDGE_CLICK = "nudge_click",
  BUTTON_CLICK = "button_click",
  PAGE_VIEW = "pageview",
  OSTOEMI_REQUEST_SUCCESS = "OSTOEMI_request_success",
  OSTOEMI_REQUEST_FAILURE = "OSTOEMI_request_failure",
  TXNTOEMI_SUCCESS = "TXNTOEMI_success",
  TXNTOEMI_FAILURE = "TXNTOEMI_failure"
}

export enum UserAction {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  DE_REGISTER = "DE_REGISTER"
}
