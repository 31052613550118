import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./desktopCarousal.module.scss";
import { Pagination, Autoplay } from "swiper";
import Image1 from "../../../assets/svgs/Image1.svg";
import Image2 from "../../../assets/svgs/Image2.svg";
import Image3 from "../../../assets/svgs/Image3.svg";
// import { Carousel } from 'react-circular-carousel';

const DesktopCarousal = () => {
  const Card = [Image1, Image2, Image3];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  return (
    <div>
      <Swiper
        effect={"fade"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        initialSlide={0}
        loop={false}
        pagination={{
          clickable: true
        }}
        modules={[Autoplay, Pagination]}
        // cssMode={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true
        }}
      >
        {Card.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item}
              alt="Banner1"
              className={`${styles.card} ${
                index === activeIndex
                  ? styles.swiperfixedwidth300
                  : styles.swiperfixedwidth100
              }`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default DesktopCarousal;
