import React from "react";
import { BottomSheetCustom } from "@cred/neopop-web/lib/components";

interface BottomsheetComponentProps {
  isBottomSheetOpen: boolean;
  setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  render: React.JSX.Element;
  isOpenSheet?: boolean;
}

const BottomsheetComponent = (props: BottomsheetComponentProps) => {
  const {
    isBottomSheetOpen,
    setIsBottomSheetOpen,
    render,
    isOpenSheet = false
  } = props;

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(isOpenSheet);
  };

  const style = {
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    backgroundColor: "#141414",
    borderTop: "1px solid #9A9A9A",
    paddingTop: "16px",
    maxWidth: "550px",
    margin: "auto"
  };

  return (
    <BottomSheetCustom
      open={isBottomSheetOpen}
      handleClose={handleCloseBottomSheet}
      sheetStyle={style}
    >
      {render}
    </BottomSheetCustom>
  );
};

export default BottomsheetComponent;
