import { useNavigate } from "react-router-dom";
import styles from "./headerStyles.module.scss";

interface HeaderProps {
  customClassName?: string;
  label: string;
  navigationPath?: any;
  locationState?: any;
  icon?: string;
  iconOnClick?: () => void;
  bgColor?: string;
  onBackButtonClick?: () => void;
}

const Header = (props: HeaderProps) => {
  const {
    label,
    navigationPath = -1,
    locationState = {},
    icon,
    iconOnClick,
    customClassName,
    bgColor,
    onBackButtonClick
  } = props;
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (navigationPath) {
      navigate(navigationPath, locationState);
    } else if (onBackButtonClick) {
      onBackButtonClick();
    }
  };

  const handleOnClickIcon = () => {
    iconOnClick && iconOnClick();
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        className={`${styles.headerBg} ${customClassName}`}
        style={{ background: !!bgColor ? bgColor : "#212121" }}
      >
        <img
          className={styles.backIcon}
          src="/images/ArrowLeftWhite.svg"
          alt="go back"
          onClick={handleBackButtonClick}
        />
        <span className={styles.headerText}>{label}</span>
        {/* {isAnalyse && <AnalyseButton className={styles.analyseButton} />} */}
      </div>
      {icon ? (
        <img
          className={styles.imgContainer}
          src={icon}
          alt={"icon"}
          onClick={handleOnClickIcon}
        />
      ) : null}
    </div>
  );
};

export { Header };
export default Header;
