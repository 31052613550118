import type { AxiosResponse } from "axios";
import type {
  GetTransactionsRequest,
  GetTransactionsResponse,
  GetUnbilledTransactionsRequest,
  GetUnbilledTransactionsResponse,
  GetStatementsRequest,
  GetStatementsResponse,
  GetEmiConfigRequest,
  GetEmiConfigResponse,
  ConvertToEmiRequest,
  ConvertToEmiResponse,
  GetSpendGroupTransactionsRequest,
  GetSpendGroupsRequest,
  CheckEmiEligibility,
  UserActivityForOutstandingEmi
} from "./accountsApiTypes";
import Api from "../Api";
import { getApiConfig } from "../../utils/functions";
import {
  GET_TRANSACTIONS_ROUTE,
  GET_UNBILLED_TRANSACTIONS_ROUTE,
  GET_STATEMENTS_ROUTE,
  GET_EMI_CONFIG_ROUTE,
  CONVERT_TO_EMI_ROUTE,
  GET_SPEND_GROUPS,
  GET_SPEND_GROUPS_TRANSACTIONS,
  GET_TRANSACTION_BY_ID_ROUTE,
  GET_ELIGIBILITY_FOR_OUTSTANDING_EMI_ROUTE,
  GET_STATUS_OUTSTANDING_TO_EMI_ROUTE,
  SET_USER_ACTIVITY_FOR_OUTSTANDING_TO_EMI_ROUTE,
} from "../ApiRoutes";

const AccountsApi = {
  getTransactions: (
    accountId: string,
    apiToken: string | null,
    data: GetTransactionsRequest
  ): Promise<AxiosResponse<GetTransactionsResponse>> =>
    Api.post(
      GET_TRANSACTIONS_ROUTE.replace(":accountId", accountId),
      data,
      getApiConfig({ apiToken })
    ),

  getUnbilledTransactions: (
    accountId: string,
    apiToken: string | null,
    data: GetUnbilledTransactionsRequest
  ): Promise<AxiosResponse<GetUnbilledTransactionsResponse>> =>
    Api.post(
      GET_UNBILLED_TRANSACTIONS_ROUTE.replace(":accountId", accountId),
      data,
      getApiConfig({ apiToken })
    ),

  getStatements: (
    accountId: string,
    cardId: string,
    apiToken: string | null,
    data: GetStatementsRequest
  ): Promise<AxiosResponse<GetStatementsResponse>> =>
    Api.post(
      GET_STATEMENTS_ROUTE.replace(":accountId", accountId).replace(
        ":cardId",
        cardId
      ),
      data,
      getApiConfig({ apiToken })
    ),

  getEmiConfig: (
    accountId: string,
    apiToken: string | null,
    data: GetEmiConfigRequest
  ): Promise<AxiosResponse<GetEmiConfigResponse>> =>
    Api.get(GET_EMI_CONFIG_ROUTE.replace(":accountId", accountId), {
      params: data,
      ...getApiConfig({ apiToken })
    }),

  convertToEmi: (
    accountId: string,
    apiToken: string | null,
    data: ConvertToEmiRequest
  ): Promise<AxiosResponse<ConvertToEmiResponse>> =>
    Api.post(
      CONVERT_TO_EMI_ROUTE.replace(":accountId", accountId),
      data,
      getApiConfig({ apiToken })
    ),

  getSpendGroups: (
    accountId: string,
    apiToken: string | null,
    data: GetSpendGroupsRequest
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      GET_SPEND_GROUPS.replace(":accountId", accountId),
      data,
      getApiConfig({ apiToken })
    ),

  getSpendGroupsTransactions: (
    accountId: string,
    apiToken: string | null,
    data: GetSpendGroupTransactionsRequest
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      GET_SPEND_GROUPS_TRANSACTIONS.replace(":accountId", accountId),
      data,
      getApiConfig({ apiToken })
    ),

  getTransactionById: (
    transactionId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<any>> =>
    Api.get(
      GET_TRANSACTION_BY_ID_ROUTE.replace(":transactionId", transactionId),
      getApiConfig({ apiToken })
    ),

  getEligibilityForOutstandingToEmi: (
    accountId: string,
    data: CheckEmiEligibility
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      GET_ELIGIBILITY_FOR_OUTSTANDING_EMI_ROUTE.replace(":accountId", accountId),data
    ),

  getStatusForOutstandingToEmi: (
    accountId: string
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      GET_STATUS_OUTSTANDING_TO_EMI_ROUTE.replace(":accountId", accountId)
    ),

    setUserActivityForOutstandingToEmi: (
      accountId: string,
      data:UserActivityForOutstandingEmi
    ): Promise<AxiosResponse<any>> =>
      Api.post(
        SET_USER_ACTIVITY_FOR_OUTSTANDING_TO_EMI_ROUTE.replace(
          ":accountId",
          accountId
        ),
        data
    ),
};

export default AccountsApi;
