import styles from "./styles.module.scss";
import redError from "../../../assets/svgs/redError.svg";

const OtherCardsError = () => {
  return (
    <div className={styles.errorContainer}>
      <img src={redError} className={styles.errorImg} />
      <div className={styles.errorText1}>Oops! Trouble fetching your cards</div>
      <div className={styles.errorText2}>Try again in a few minutes.</div>
    </div>
  );
};

export default OtherCardsError;
