import AuthApi from "../apis/authApi/AuthApi";
import { countryFromSessionStorage } from "./internalFlags";

export const getUserOSAndVersion = (userAgent: string) => {
  let os = "Unknown";
  let version = "Unknown";

  if (/Windows/.test(userAgent)) {
    os = "Windows";
    const match = /Windows NT (\d+\.\d+)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/Mac OS X/.test(userAgent)) {
    if (/iOS|iPhone|iPad|iPod/.test(userAgent)) {
      os = "iOS";
      const match = /OS (\d+[_\.]\d+)/.exec(userAgent);
      version = match ? match[1].replace("_", ".") : "Unknown";
    } else {
      os = "Mac OS X";
      const match = /Mac OS X (\d+[\._]\d+)/.exec(userAgent);
      version = match ? match[1].replace("_", ".") : "Unknown";
    }
  } else if (/Linux/.test(userAgent)) {
    if (/Android/.test(userAgent)) {
      os = "Android";
      const match = /Android (\d+(\.\d+)?)/.exec(userAgent);
      version = match ? match[1] : "Unknown";
    } else {
      os = "Linux";
    }
  } else if (/Android/.test(userAgent)) {
    os = "Android";
    const match = /Android (\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/iOS|iPhone|iPad|iPod/.test(userAgent)) {
    os = "iOS";
    const match = /OS (\d+[_\.]\d+)/.exec(userAgent);
    version = match ? match[1].replace("_", ".") : "Unknown";
  }

  return [os, version];
};

export const getUserBrowserAndVersion = (userAgent: string) => {
  let browser = "Unknown";
  let version = "Unknown";

  if (/MSIE|Trident/.test(userAgent)) {
    browser = "Internet Explorer";
    const match = /(?:MSIE |rv:)(\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/Edg/.test(userAgent)) {
    browser = "Microsoft Edge";
    const match = /Edg(?:e|A)\/(\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
    const match = /Firefox\/(\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/Chrome/.test(userAgent)) {
    browser = "Google Chrome";
    const match = /Chrome\/(\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  } else if (/Safari/.test(userAgent)) {
    browser = "Safari";
    const match = /Version\/(\d+(\.\d+)?)/.exec(userAgent);
    version = match ? match[1] : "Unknown";
  }

  return [browser, version];
};

export const getParameterByName = (name: string, url: string) => {
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return undefined;
  if (results[2] === undefined) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};



export const getGeoLocation = async () => {
  let location = "";
  if (countryFromSessionStorage === "INDIA") {
    return countryFromSessionStorage;
  }
  try {
    const response = await AuthApi.getIP();
    if (response?.data?.location?.country === "IN") {
      return "INDIA";
    } else if (response?.data?.location) {
      return response?.data?.location?.country;
    } else {
      return "INDIA";
    }
  } catch (error) {
    return "Unknown";
  }
};

