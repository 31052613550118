import { useSelector } from "react-redux";
import type { RootState } from "../../../app/store";
import styles from "./campaignOffersSection.module.scss";
import IntermediateText from "../intermediateText";
import CompletedCampaignOffer from "./campaignOffer/CompletedCampaignOffer";
import PendingCampaignOffer from "./campaignOffer/PendingCampaignOffer";
import Carousel from "./campaignOffersCarousel";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_OFFERS_ROUTE } from "../../../routes/ScreenRoutes";
import { getBenefitTexts } from "../../../utils/BenefitUtil";
import { BenefitType } from "../../../apis/benefitsApi/benefitsApiTypes";
import { isFestiveOffersUIEnabled } from "../../../utils/internalFlags";
import { Festivals } from "../../../utils/enums";
import { EVENT_NAME } from "../../../apis/appApi/appApiTypes";
import { captureEvent } from "../../../utils/functions";

const CampaignOffersSection = () => {
  const navigate = useNavigate();

  const [benefits] = useSelector((state: RootState) => [state.benefits]);

  const handleOnClick = () => {
    captureEvent({ eventName: EVENT_NAME.ALL_CAMPAIGN_OFFERS_CLICK });
    navigate(CAMPAIGN_OFFERS_ROUTE);
  };

  const divider = Festivals?.CURRENT_FESTIVAL?.images?.nudgeAndCampaignDivider;

  return (
    <div className={styles.campaignOffersSectionContainer}>
      <div className={styles.intermediateTextContainer}>
        {isFestiveOffersUIEnabled && (
          <>
            <img
              src={divider?.left?.url}
              alt={divider?.left?.alt}
              className={styles.textImageLeft}
            />
            <img
              src={divider?.right?.url}
              alt={divider?.right?.alt}
              className={styles.textImageRight}
            />
          </>
        )}
        <IntermediateText
          icon={isFestiveOffersUIEnabled ? "" : "/images/home/titleIcon2.svg"}
          text1="exclusive benefits"
          text2="for you"
        />
      </div>
      <div className={styles.carouselWrapper}>
        <Carousel
          items={benefits.campaignBenefits.benefits?.map((benefit) => {
            const offerDetails = JSON.parse(benefit.offerDetails);
            const { daysLeft, progress, title1, title2, description } =
              getBenefitTexts(benefit);
            const isPendingMilestone =
              benefit.benefitType === BenefitType.MILESTONE && progress < 100;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const isCompletedMilestone =
              benefit.benefitType === BenefitType.MILESTONE && progress >= 100;

            return isPendingMilestone ? (
              <PendingCampaignOffer
                id={benefit.benefitId}
                background={benefit.displayColor}
                description={description}
                title1={title1}
                title2={title2}
                progress={progress}
                daysLeft={daysLeft}
                hasDetails={
                  offerDetails.redeemSteps || offerDetails.tncConditions
                }
                logoUrl={benefit.logoUrl}
              />
            ) : (
              <CompletedCampaignOffer
                id={benefit.benefitId}
                background={benefit.displayColor}
                title1={title1}
                title2={title2}
                description={description}
                hasDetails={
                  offerDetails.redeemSteps || offerDetails.tncConditions
                }
                logoUrl={benefit.logoUrl}
                benefitType={benefit.benefitType}
                daysLeft={daysLeft}
              />
            );
          })}
        />
      </div>
      <div className={styles.btnContainer}>
        <Button text1="all" text2="exclusives" onClick={handleOnClick} />
      </div>
    </div>
  );
};

export default CampaignOffersSection;
