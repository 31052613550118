/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import type {
  UserState,
  Program,
  Account,
  Card,
  SetUserIdsActionPayload,
  SetUserDataActionPayload,
  SetCardDetailsActionPayload,
  Customer,
  UpdateSelectedCardActionPayload
} from "./userSliceTypes";
import auraVisa from "../../assets/svgs/cards/auraVisa.jpg";
import auraMasterCard from "../../assets/svgs/cards/auraMasterCard.jpg";
import { GetCardsResponse, Journey } from "../../apis/appApi/appApiTypes";
import { CustomerBlockCodeStatus } from "../../data/blockCodes/BlockCodeTypes";
import { TrackingInfo } from "../../apis/cardsApi/cardsApiTypes";
const initialState: UserState = {
  cardDetails: {
    cardType: "Aura Edge",
    gradient:
      "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
    image: {
      Visa: auraVisa,
      Master: auraMasterCard
    }
  },
  customer: null,
  customerBlockCodeStatus: null,
  programs: null,
  customerId: null,
  selectedProgramId: null,
  selectedAccountId: null,
  selectedCardId: null,
  scheme: "Visa",
  primaryCustomerId: null,
  journey: Journey.OLD_ACTIVATION,
  deliveredDate: "",
  trackingDetails: null,
  showCardTracking: true,
  showWelcomeOffers: true,
  cardActivationDays: 0,
  cardWelcomeDays: 0,
  isDeviceIdVerified: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserIds: (state, action: PayloadAction<SetUserIdsActionPayload>) => {
      const {
        customerId,
        selectedProgramId,
        selectedAccountId,
        selectedCardId
      } = action.payload;

      sessionStorage.setItem("customerId", customerId);
      sessionStorage.setItem("selectedProgramId", selectedProgramId);
      sessionStorage.setItem("selectedAccountId", selectedAccountId);
      sessionStorage.setItem("selectedCardId", selectedCardId);

      state.customerId = action.payload.customerId;
      state.selectedProgramId = action.payload.selectedProgramId;
      state.selectedAccountId = action.payload.selectedAccountId;
      state.selectedCardId = action.payload.selectedCardId;
    },
    setPrimaryCustomerId: (state, action: PayloadAction<string>) => {
      state.primaryCustomerId = action.payload;
    },
    setTrackingDetails: (state, action: PayloadAction<TrackingInfo>) => {
      state.trackingDetails = action.payload;
    },
    clearUserIds: (state) => {
      sessionStorage.removeItem("customerId");
      sessionStorage.removeItem("selectedProgramId");
      sessionStorage.removeItem("selectedAccountId");
      sessionStorage.removeItem("selectedCardId");
      sessionStorage.removeItem("primaryCardMobileNumber");
      sessionStorage.removeItem("/");
      sessionStorage.removeItem("lagTimeForInstallPrompt");
      sessionStorage.removeItem("showPrompt");
      sessionStorage.removeItem("mobileNumberlastFour");

      localStorage.removeItem("isFirstTimeLogin");
      localStorage.removeItem("visitedHome");

      state.customerId = null;
      state.selectedProgramId = null;
      state.selectedAccountId = null;
      state.selectedCardId = null;
      state.primaryCustomerId = null;
    },
    setUserData: (state, action: PayloadAction<SetUserDataActionPayload>) => {
      state.customer = action.payload.customer;
      state.customerBlockCodeStatus = action.payload.customerBlockCodeStatus;
      state.programs = action.payload.programs;
      state.journey = action.payload.journey;
      state.deliveredDate = action.payload.deliveredDate;
      state.showCardTracking = action.payload.showCardTracking;
      state.showWelcomeOffers = action.payload.showWelcomeOffers;
      state.cardActivationDays = action.payload.cardActivationDays;
      state.cardWelcomeDays = action.payload.cardWelcomeDays;
    },
    setCardDetails: (
      state,
      action: PayloadAction<SetCardDetailsActionPayload>
    ) => {
      state.cardDetails = action.payload;
    },
    updateSelectedCard: (
      state,
      action: PayloadAction<UpdateSelectedCardActionPayload>
    ) => {
      const cardToUpdate = action.payload.card;
      const selectedAccountIndex = state.customer!.accounts.findIndex(
        (account) => account.id === state.selectedAccountId
      );
      if (selectedAccountIndex > -1) {
        const cardIndexToUpdate = state.customer!.accounts[
          selectedAccountIndex
        ].cards.findIndex((card) => card.id === state.selectedCardId);
        if (cardIndexToUpdate > -1) {
          state.customer!.accounts[selectedAccountIndex].cards[
            cardIndexToUpdate
          ] = cardToUpdate;
        }
      }
    },
    setCustomerBlockCodes: (
      state,
      action: PayloadAction<CustomerBlockCodeStatus | null>
    ) => {
      state.customerBlockCodeStatus = action.payload;
    },
    setIsDeviceIdVerified: (state, action: PayloadAction<boolean>) => {
      state.isDeviceIdVerified = action.payload;
    }
  }
});

export const {
  setUserIds,
  clearUserIds,
  setUserData,
  setCardDetails,
  updateSelectedCard,
  setPrimaryCustomerId,
  setCustomerBlockCodes,
  setTrackingDetails,
  setIsDeviceIdVerified
} = userSlice.actions;

export default userSlice.reducer;

export const getCustomer = (state: RootState): Customer | null =>
  state.user.customer;

export const getSelectedProgram = (state: RootState): Program | null =>
  state.user.programs?.find(
    (program) => program.id === state.user.selectedProgramId
  )!;

export const getSelectedAccount = (state: RootState): Account | null =>
  state.user.customer?.accounts.find(
    (account) => account.id === state.user.selectedAccountId
  )!;

export const getSelectedCard = (state: RootState): Card | null =>
  getSelectedAccount(state)?.cards.find(
    (card) => card.id === state.user.selectedCardId
  )!;

export const getOtherCards = (state: RootState): GetCardsResponse[] =>
  state.user.customer?.accounts[0]?.otherCards || [];

export const getCardDetails = (state: RootState): SetCardDetailsActionPayload =>
  state.user.cardDetails!;

export const getIsDeviceIdVerified = (
  state: RootState
): SetCardDetailsActionPayload => state.user.cardDetails!;
