export enum OfferType {
  PROGRAM_OFFERS = "PROGRAM_OFFERS",
  CAMPAIGN_OFFERS = "CAMPAIGN_OFFERS",
  WELCOME_OFFERS = "WELCOME_OFFER",
  THIRD_PARTY_OFFERS = "THIRD_PARTY_OFFERS",
  MERCHANT_OFFERS = "MERCHANT_OFFERS",
  BANK_OFFERS = "BANK_OFFERS",
  CROSS_SELL_OFFERS = "CROSS_SELL_OFFERS",
  INSTANT_DISCOUNT = "INSTANT_DISCOUNT"
}

export enum BenefitType {
  TRANSACTIONAL = "TRANSACTIONAL",
  MILESTONE = "MILESTONE",
  MERCHANT_OFFERS = "MERCHANT_OFFERS",
  WELCOME_OFFERS = "WELCOME_OFFER"
}

export enum BenefitOutcomeType {
  REWARDS = "REWARDS",
  CASHBACK = "CASHBACK",
  VOUCHER = "VOUCHER",
  INSTANT_DISCOUNT = "INSTANT_DISCOUNT"
}

export enum BenefitStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED"
}

export enum BenefitSortBy {
  ACTIVE_FROM = "activeFrom",
  ACTIVE_TO = "activeTo"
}

export enum BenefitSortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export interface GetBenefitsForAccountRequest {
  accountId: string;
  programId: string;
  offerType?: OfferType;
  benefitType?: BenefitType;
  status?: BenefitStatus;
  preferredTagsSort?: boolean;
  sortBy?: BenefitSortBy;
  order?: BenefitSortOrder;
  expiringInNextDays?: number;
  activatedWithinPreviousDays?: number;
}

export interface GetBenefitsForAccountResponseElement {
  benefitId: string;
  name: string;
  displayOrder: number;
  displayColor: string;
  displayTitle: string;
  displayDescription: string;
  illustrationUrl: string;
  logoUrl: string;
  offerDetails: string;
  outcomeType: BenefitOutcomeType;
  benefitType: BenefitType;
  offerType: OfferType;
  benefitExpiry: string;
  activeFrom: string;
  activeTo: string;
  threshold: number;
  currentValue: number;
  status: BenefitStatus;
  tags: string[];
  maxUsageValue: number;
  currentUsageValue: number;
  isUsageValueLimitSet: boolean;
  maxUsageCount: number;
  currentUsageCount: number;
  isUsageCountLimitSet: boolean;
  subscriptionId?: any;
  subscriptionExpiry?: any;
  usageTimePeriod?: any;
}

export type GetBenefitsForAccountResponse =
  Array<GetBenefitsForAccountResponseElement>;
