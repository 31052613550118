import styles from "./arrowStyles.module.scss";
import type { ArrowProps, Arrow2Props } from "./arrowTypes";
import { arrow, arrow2 } from "../../assets/svgs";

const Arrow = ({ className = "" }: ArrowProps): React.ReactElement => {
  return (
    <div className={`${styles.arrowBtn} ${className}`}>
      <img className={styles.arrowIcon} src={arrow} alt="arrow" />
    </div>
  );
};

const Arrow2 = ({ className = "" }: Arrow2Props): React.ReactElement => {
  return (
    <img
      className={`${styles.arrow2} ${className}`}
      src={arrow2}
      alt="arrow2"
    />
  );
};

export { Arrow, Arrow2 };
