import { type AxiosResponse } from "axios";
import {
  type GenerateLoginOtpRequest,
  type GenerateLoginOtpSuccessResponse,
  type GenerateLoginOtpFailureResponse,
  type VerifyLoginOtpRequest,
  VerifyLoginOtpSuccessResponse,
  VerifyLoginOtpFailureResponse
} from "./loginApiTypes";
import Api from "../Api";
import { GENERATE_LOGIN_OTP_ROUTE, VERIFY_LOGIN_OTP_ROUTE } from "../ApiRoutes";

const LoginApi = {
  generateLoginOtp: (
    data: GenerateLoginOtpRequest
  ): Promise<
    AxiosResponse<
      GenerateLoginOtpSuccessResponse | GenerateLoginOtpFailureResponse
    >
  > => Api.post(GENERATE_LOGIN_OTP_ROUTE, data),

  verifyLoginOtp: (
    data: VerifyLoginOtpRequest
  ): Promise<
    AxiosResponse<VerifyLoginOtpSuccessResponse | VerifyLoginOtpFailureResponse>
  > => Api.post(VERIFY_LOGIN_OTP_ROUTE, data)
};

export default LoginApi;
