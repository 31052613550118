import type { AxiosResponse } from "axios";
import type {
  CardDetailsProps,
  CardProps,
  CardControl,
  GetUnmaskedCardResponse,
  ResetCardPinRequest,
  TrackingInfoRes
} from "./cardsApiTypes";
import Api from "../Api";
import { getApiConfig } from "../../utils/functions";
import {
  GET_CARD_ROUTE,
  GET_CARD_CONTROLS_ROUTE,
  GET_UNMASKED_CARD_ROUTE,
  LOCK_CARD_ROUTE,
  RESET_CARD_PIN_ROUTE,
  UNLOCK_CARD_ROUTE,
  UPDATE_CARD_CONTROLS_ROUTE,
  GET_OTHET_CARDS_ROUTE,
  GET_CARD_TRACKING_ROUTE
} from "../ApiRoutes";

const CardsApi = {
  getUnmaskedCard: (
    cardId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<GetUnmaskedCardResponse>> =>
    Api.get(
      GET_UNMASKED_CARD_ROUTE.replace(":cardId", cardId),
      getApiConfig({ apiToken })
    ),

  getcardTracking: (cardId: string): Promise<AxiosResponse<TrackingInfoRes>> =>
    Api.get(GET_CARD_TRACKING_ROUTE.replace(":cardId", cardId)),

  resetCardPin: (
    cardId: string,
    apiToken: string | null,
    data: ResetCardPinRequest
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      RESET_CARD_PIN_ROUTE.replace(":cardId", cardId),
      data,
      getApiConfig({ apiToken })
    ),

  getCard: (
    cardId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<CardDetailsProps>> =>
    Api.get(
      GET_CARD_ROUTE.replace(":cardId", cardId),
      getApiConfig({ apiToken })
    ),

  getOtherCards: (
    data: { customerId: string },
    apiToken: string | null
  ): Promise<AxiosResponse<CardProps[]>> =>
    Api.post(GET_OTHET_CARDS_ROUTE, data, getApiConfig({ apiToken })),

  getCardControls: (
    cardId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<CardControl[]>> =>
    Api.get(
      GET_CARD_CONTROLS_ROUTE.replace(":cardId", cardId),
      getApiConfig({ apiToken })
    ),

  lockCard: (
    cardId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<any>> =>
    Api.put(
      LOCK_CARD_ROUTE.replace(":cardId", cardId),
      null,
      getApiConfig({ apiToken })
    ),

  unlockCard: (
    cardId: string,
    apiToken: string | null
  ): Promise<AxiosResponse<any>> =>
    Api.put(
      UNLOCK_CARD_ROUTE.replace(":cardId", cardId),
      null,
      getApiConfig({ apiToken })
    ),

  updateCardControls: (
    cardId: string,
    apiToken: string | null,
    data: any
  ): Promise<AxiosResponse<any>> =>
    Api.post(
      UPDATE_CARD_CONTROLS_ROUTE.replace(":cardId", cardId),
      data,
      getApiConfig({ apiToken })
    )
};

export default CardsApi;
