/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { RootState } from "../../app/store";
import Header from "../../components/header/header";
import styles from "./styles.module.scss";
import { Button2 } from "../../components";
import { toast } from "../../components/toast/toast";
import { ToastType } from "../../components/toast/toastTypes";
import { fallbackCopyTextToClipboard } from "../../utils/functions";
import { getOfferDetailsListSection } from "../../utils/BenefitUtil";
import { PWA_MAX_WIDTH_IN_PX } from "../../utils/constants";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";

const MerchantOfferDetails = () => {
  let { benefitId } = useParams();

  const benefits = useSelector((state: RootState) => state.benefits);

  const benefit = benefits.merchantBenefits.benefits?.find(
    (benefit) => benefit.benefitId === benefitId
  );

  const offerDetails = JSON.parse(benefit!.offerDetails);

  const headerText = benefit!.name;
  const title = benefit!.displayTitle;
  const description = benefit!.displayDescription;
  const bgColor = benefit!.displayColor;
  const logo = benefit!.logoUrl;
  const bgImage = benefit!.illustrationUrl;
  const redeemSteps = offerDetails.redeemSteps;
  const tncConditions = offerDetails.tncConditions;
  const promoCode = offerDetails.promoCode;
  const ctaTextArr = offerDetails?.ctaText?.split(" ");
  const ctaText1 = ctaTextArr?.[0];
  const ctaText2 = ctaTextArr?.slice(1)?.join(" ");

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(promoCode);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Promo code is copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(promoCode);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Promo code is copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  const handleCtaLinkClick = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <>
      <Header
        label={`Offer -  ${headerText} `}
        bgColor="#010101"
        customClassName={styles.header}
      />
      <div className={styles.container}>
        <div
          className={styles.containerFill}
          style={{
            background: bgColor
          }}
        >
          {logo && <img src={logo} alt="logo" className={styles.logo} />}
          {bgImage && (
            <img
              className={styles.bgImage}
              style={{ width: "100%", height: "350px" }}
              src={bgImage}
              alt="bgImage"
            />
          )}
          <div className={styles.subtractDiv1}> </div>
          <div className={styles.subtractDiv2}> </div>
        </div>

        <div className={styles.promoContainer}>
          <span className={styles.promoHeader}>{title}</span>
          <span className={styles.promoText}>{description}</span>
          {!!benefit?.activeTo ? (
            <div className={styles.validityContainer}>
              <img src="/images/HourglassGold.svg" alt="time" />
              <span className={styles.promoValidity}>
                Valid till{" "}
                {new Date(benefit.activeTo).toLocaleDateString("en-IN")}
              </span>
            </div>
          ) : null}
          {!!promoCode ? (
            <div className={styles.promoTextContainer}>
              <div className={styles.textContainer}>Promo Code</div>
              <div className={styles.promoText2}>
                <div className={styles.promoCode}>{promoCode}</div>
                <img
                  onClick={debounceHandleCopy}
                  src="/images/copy.svg"
                  alt="copy"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {getOfferDetailsListSection("How to redeem", redeemSteps)}
      {getOfferDetailsListSection("Terms and Conditions", tncConditions)}
      <div className={styles.extraHeight}></div>
      {offerDetails.ctaLink && (
        <div
          className={styles.btnContainer}
          style={{ maxWidth: `${PWA_MAX_WIDTH_IN_PX}px` }}
        >
          <Button2
            customClassName={styles.redeemOfferBtn}
            text1={!!ctaText1 ? ctaText1 : "redeem"}
            text2={!!ctaText1 ? ctaText2 || "" : "offer"}
            onClick={() => handleCtaLinkClick(offerDetails.ctaLink)}
          />
        </div>
      )}
    </>
  );
};

export default MerchantOfferDetails;
