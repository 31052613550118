import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  EligibilityActionPayload,
  OutStandingEmiStatusState,
  OutstandingToEmiState
} from "./outstandingToEmiSliceTypes";

const initialState: OutstandingToEmiState = {
  eligibility: {
    emiConfigId: "",
    eligibleAmount: 0,
    minOutstandingAmount: 0,
    maxOutstandingAmount: 0,
    isEligible: false,
    ineligibleReason: null
  },
  emiStatus: {
    emiRefId: "",
    currentCycle: 0,
    principal: 0,
    emiType: "",
    interestRate: "",
    tenureInMonths: 0,
    monthlyEmi: 0,
    processingFees: 0,
    extRefId: "",
    schedules: [
      {
        installmentNumber: 0,
        paymentDueDate: "",
        principal: 0,
        interest: 0,
        remainingBalance: 0
      }
    ],
    status: "FAILED",
    createdOn: "",
    failureReason: "",
    userActivity: {
      emiRefId: "",
      loginCount: 0,
      createdOn: ""
    }
  },
  selectedAmount: 0
};

export const outstandingToEmiSlice = createSlice({
  name: "outstandingToEmi",
  initialState,
  reducers: {
    setEligibilityForOutstandingToEmi: (
      state,
      action: PayloadAction<EligibilityActionPayload>
    ) => {
      state.eligibility = action.payload;
      state.selectedAmount = action.payload?.eligibleAmount || 0;
    },
    setEmiStatusForOutstandingToEmi: (
      state,
      action: PayloadAction<OutStandingEmiStatusState>
    ) => {
      state.emiStatus = action.payload;
    },
    setAmountSelectedForOutstandingToEmi: (
      state,
      action: PayloadAction<number>
    ) => {
      state.selectedAmount = action.payload;
    }
  }
});

export const {
  setEligibilityForOutstandingToEmi,
  setEmiStatusForOutstandingToEmi,
  setAmountSelectedForOutstandingToEmi
} = outstandingToEmiSlice.actions;

export default outstandingToEmiSlice.reducer;
