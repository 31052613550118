import type { AxiosResponse } from "axios";
import type {
  GenerateChallengeSmsOtpRequest,
  GenerateChallengeSmsOtpResponse,
  LogoutFailureResponse,
  LogoutRequest,
  LogoutSuccessResponse,
  VerifyChallengeSmsOtpRequest,
  VerifyChallengeSmsOtpResponse
} from "./authApiTypes";
import Api from "../Api";
import {
  GENERATE_CHALLENGE_SMS_OTP_ROUTE,
  LOGOUT_ROUTE,
  VERIFY_CHALLENGE_SMS_OTP_ROUTE,
  GENERATE_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE,
  VERIFY_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE,
  GET_IP
} from "../ApiRoutes";

const AuthApi = {
  generateChallengeSmsOtp: (
    data: GenerateChallengeSmsOtpRequest
  ): Promise<AxiosResponse<GenerateChallengeSmsOtpResponse>> =>
    Api.post(GENERATE_CHALLENGE_SMS_OTP_ROUTE, data),

  verifyChallengeSmsOtp: (
    data: VerifyChallengeSmsOtpRequest
  ): Promise<AxiosResponse<VerifyChallengeSmsOtpResponse>> =>
    Api.post(VERIFY_CHALLENGE_SMS_OTP_ROUTE, data),

  logout: (
    data: LogoutRequest
  ): Promise<AxiosResponse<LogoutSuccessResponse | LogoutFailureResponse>> =>
    Api.post(LOGOUT_ROUTE, data),

  generateChallengeSmsOtpPrelogin: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(GENERATE_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE, data),

  verifyChallengeSmsOtpPrelogin: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(VERIFY_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE, data),
  getIP: (): Promise<AxiosResponse<any>> => Api.get(GET_IP)
};

export default AuthApi;
