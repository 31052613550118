// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styles from "./DoughnutChartStyles.module.scss";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { formatAmount2 } from "../../utils/functions";
import MaxSpendText from "./MaxSpendText";
import SpendCategoryContainer from "./SpendCategoryContainer";
import {
  imageObj,
  colors,
  SpendGroup,
  getFilteredSubgroup,
  containsUncategorised,
  ApiResponse,
  getFilteredOthers
} from "../../utils/SpendAnalyticsUtil";

type Props = {
  Data: ApiResponse;
};

const DoughnutChart = ({ Data }: Props) => {
  let totalSpend = 0; //Total Amount Spend by the user
  let otherSpend = 0; //Other than top 4 categories and uncategorised spend

  //Sorting spend groups in descending order
  let spendGroups = Data.spendGroups.sort(
    (a: SpendGroup, b: SpendGroup) => b.totalSpend - a.totalSpend
  );

  //Function to check whether uncategorised transaction present
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const containUncategorised = containsUncategorised(spendGroups);

  //Function to filter out top four categories without uncategorised
  const filteredSubgroupArray = getFilteredSubgroup(spendGroups);

  //Function to filter out uncategorised and non top 4 transactions
  const filteredOthersArray = getFilteredOthers(spendGroups);

  //calculated total spend and others spend
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  spendGroups.forEach((el: SpendGroup, ind: number) => {
    totalSpend += el.totalSpend;
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filteredOthersArray.forEach((el: SpendGroup, ind: number) => {
    otherSpend += el.totalSpend;
  });

  //Labels array for chart
  let labels = filteredSubgroupArray.map((el: SpendGroup) => {
    return el.groupName;
  });
  if (otherSpend > 0) {
    labels.push("Others");
  }

  //Spend array for chart
  let spend = filteredSubgroupArray.map((el: SpendGroup) => {
    return ((el.totalSpend / totalSpend) * 100).toFixed(0);
  });
  if (otherSpend > 0) {
    spend.push(((otherSpend / totalSpend) * 100).toFixed(0));
  }

  //image urls for chart
  let imageUrls = filteredSubgroupArray.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (el: SpendGroup, ind: number) => imageObj[el.groupName]
  );
  if (otherSpend > 0) {
    imageUrls.push(imageObj.Others);
  }

  //To bring "Others" category to its required position
  let indicesArray = spend.map((el: number, index: number) => index);
  indicesArray.sort((a: number, b: number) => spend[b] - spend[a]);

  spend = indicesArray.map((index: number) => spend[index]);
  labels = indicesArray.map((index: number) => labels[index]);
  imageUrls = indicesArray.map((index: number) => imageUrls[index]);

  //Image elements array for chart
  const images = imageUrls.map((v: string) => {
    // eslint-disable-next-line no-var
    let image = new Image();
    if (v !== undefined) {
      image.src = v;
    } else {
      image.src = "";
    }
    return image;
  });

  //To add others in filtered subgroup array at required position
  if (otherSpend > 0) {
    filteredSubgroupArray.push({ groupName: "Others" });
  }

  let newColors: string[] = [];
  //Change the colors data for others position;
  labels.forEach((el: string, ind: number) => {
    if (el === "Others") {
      newColors.push("#FFF0F1");
    }
    newColors.push(colors[ind]);
  });

  //Chart data;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Percentage Expenditure",
        data: spend,
        borderColor: ["rgba(255,206,86,0)"],
        backgroundColor: newColors
      }
    ]
  };

  const sliceThickness = {
    id: "sliceThickness",
    beforeDraw(chart: any) {
      const inner = chart.getDatasetMeta(0).data[0].innerRadius;
      let sliceThicknessPx = chart.getDatasetMeta(0)._parsed;
      let prev = 0;
      sliceThicknessPx.forEach((sliceThickness: number, index: number) => {
        const thicknessFactor = [1, 0.8, 0.7, 0.6, 0.5];
        if (prev === sliceThickness) {
          thicknessFactor[index] = thicknessFactor[index - 1];
        }
        prev = sliceThickness;
        chart.getDatasetMeta(0).data[index].outerRadius =
          inner + (chart.chartArea.width / 2 - inner) * thicknessFactor[index];
      });
    }
  };

  const donutLabel = {
    id: "doughnutLabel",
    afterDraw(chart: any) {
      const {
        ctx,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        chartArea: { top, bottom, left, right, width, height }
      } = chart;
      const halfWidth = width / 2;
      const halfHeight = height / 2;
      ctx.shadowBlur = 15;
      ctx.shadowColor = "rgba(0,0,0,1)";
      chart.data.datasets.forEach((dataset: any, i: number) => {
        chart
          .getDatasetMeta(i)
          .data.forEach((datapoint: any, index: number) => {
            //console.log(chart.getDatasetMeta(0).data);
            const { x, y } = datapoint.tooltipPosition();
            // ctx.fillRect(x, y, 2, 2);
            const xLine = x >= halfWidth ? -0 : 0;
            const yLine = y >= halfHeight ? +0 : -0;
            ctx.shadowColor = "rgba(0,0,0,0)";
            // eslint-disable-next-line no-var
            let image = images[index];
            if (+chart.data.datasets[0].data[index] >= 5) {
              ctx.drawImage(
                image,
                x - image.width + xLine,
                y - image.height / 2 + yLine
              );
              ctx.fillText(
                `${chart.data.datasets[0].data[index]}%`,
                x + 2 + xLine,
                y + yLine
              );
            }
            // }
            ctx.font = "bolder 12px Arial";
            ctx.textBaseline = "middle";
            ctx.shadowColor = "rgba(0,0,0,0.4)";
          });
        ctx.restore();
      });
    }
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    rotation: filteredSubgroupArray.length === 1 ? 180 : 75 * Math.PI
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.doughnutChart}>
        <Doughnut
          data={data}
          options={options}
          plugins={[sliceThickness, donutLabel]}
        />
        <div className={styles.doughnutBgImage}>
          <img src="/images/patterns/wave2.svg" alt="wave-pattern" />
        </div>
        <div className={styles.centerTextContainer}>
          <div>Total Spends</div>
          <div>{formatAmount2(totalSpend)} </div>
          <div></div>
        </div>
        <div className={styles.backgroundContainer}></div>
      </div>
      <div className={styles.textContainer}>
        <MaxSpendText maxCategory={spendGroups[0].groupName} />
      </div>
      <div className={styles.spendCategories}>
        <div>
          <span>your</span> spend categories
        </div>
        <div>
          {filteredSubgroupArray.map((el: any, ind: number) => {
            if (el.groupName === "Others") {
              return (
                <SpendCategoryContainer
                  total={totalSpend}
                  other={otherSpend}
                  index={ind}
                  filteredOthers={filteredOthersArray}
                  startDate={Data.startDate}
                  endDate={Data.endDate}
                  key={ind}
                />
              );
            } else {
              return (
                <SpendCategoryContainer
                  category={el}
                  total={totalSpend}
                  index={ind}
                  startDate={Data.startDate}
                  endDate={Data.endDate}
                  key={el.groupId}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
