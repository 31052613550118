import styles from "./activationPreHome.module.scss";
import cardAndBtnBg from "../../assets/svgs/cardAndBtnBg.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardDetails,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import { useEffect, useState } from "react";
import { Button2, ToastType, toast } from "../../components";
import moneyMirror from "../../assets/svgs/moneyMirror.svg";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import CardsApi from "../../apis/cardsApi/CardsApi";
import ApiIds from "../../auth/ApiIds";
import logger from "../../utils/logger";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import { HOME_ROUTE, MANAGE_CARD_ROUTE } from "../../routes/ScreenRoutes";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import { authenticateApi } from "../../auth/auth";
import Loader from "../../components/loader/loader";
import { Account, Card } from "../../features/user/userSliceTypes";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import UnmaskedCard from "../cardSummary/UnmaskedCard";
import { RootState } from "../../app/store";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ActivationProps {}

const css = `
.reactSwitch{
  height: 20px;
  width: 40px;
}
`;

const OnlineTransactionToggle = ({
  controlEnabled,
  setControlEnabled,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  setIsOpen,
  card,
  account
}: {
  controlEnabled: boolean;
  setControlEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  account: Account;
  card: Card;
}) => {
  // const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const handleChange = async (controlEnabled: boolean) => {
    try {
      // always call update card controls api as auth will be configured using this
      const cardControlsAuthResult = await authenticateApi({
        apiId: ApiIds.UPDATE_CARD_CONTROLS,
        actionText: "to update card controls"
      });

      if (cardControlsAuthResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: cardControlsAuthResult.error,
          message: `An error occurred while updating card details`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        // setIsSubmitLoading(false);
        return;
      } else if (cardControlsAuthResult.status === AuthStatus.CANCELLED) {
        // setIsSubmitLoading(false);
        return;
      }

      const currentCardControls = [
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE"
          },
          isEnabled: controlEnabled
        },
        {
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE"
          },
          isEnabled: controlEnabled
        }
      ];

      const oldCardControls = [
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE"
          },
          isEnabled: !controlEnabled
        },
        {
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE"
          },
          isEnabled: !controlEnabled
        }
      ];

      const cardControlsResponse = await CardsApi.updateCardControls(
        account.cards[0]?.id,
        cardControlsAuthResult.apiToken,
        {
          updateCardControls: currentCardControls,
          oldCardControls: oldCardControls
        }
      );

      if (cardControlsResponse.status !== 200) {
        logger.error({
          correlationId: cardControlsResponse.headers[CORRELATION_ID_HEADER],
          cardId: card.id,
          responseData: cardControlsResponse.data,
          message: `An error occurred while updating card controls`
        });
        toast(
          ToastType.ERROR,
          "Update of transaction preferences/limits has failed. Please try again"
        );
        // setIsSubmitLoading(false);
        
      }

      // toast(ToastType.SUCCESS, "Your Transaction Usage Preferences is saved");
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while updating card controls`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "Update of transaction preferences/limits has failed. Please try again"
      );
    }

    // setIsSubmitLoading(false);
  };
  return (
    <div>
      <div className={styles.containerToggle}>
        <style>{css}</style>

        <div className={styles.textContainerToggle}>
          <div className={styles.cardIconContainerToggle}>
            <img
              src={moneyMirror}
              alt="icon"
              className={styles.cardIcon1Toggle}
            />
          </div>
          <div className={styles.innerTextContainerToggle}>
            <div className={styles.cardHeaderTextToggle}>
              Online Transactions
            </div>
          </div>
        </div>

        <div className={styles.switchContainerToggle}>
          <Switch
            checked={controlEnabled}
            onChange={() => {
              setControlEnabled(!controlEnabled);
              handleChange(!controlEnabled);
            }}
            handleDiameter={18}
            checkedIcon={false}
            offColor="#333333"
            uncheckedIcon={false}
            onColor="#F4B348"
            height={22}
            width={40}
            className={styles.reactSwitch}
            offHandleColor="#999999"
          />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const ActivationPreHome = (props: ActivationProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cardDetails = useSelector(getCardDetails);
  // eslint-disable-next-line
  const card = useSelector(getSelectedCard)!;
  //eslint-disable-next-line
  const account = useSelector(getSelectedAccount)!;
  const name = useSelector(
    (state: RootState) => state.user.customer?.firstName
  );
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showToggle, setShowToggle] = useState(true);
  const [controlEnabled, setControlEnabled] = useState(false);
  const [isUnmaskedCardOpen, setIsUnmaskedCardOpen] = useState<boolean>(false);
  const cardScheme = card?.scheme || "Visa";

  useEffect(() => {
    (async () => {
      try {
        const getCardAuthResult = await authenticateApi({
          apiId: ApiIds.FETCH_CARD,
          actionText: "to get card details"
        });

        if (getCardAuthResult.status === AuthStatus.FAILURE) {
          logger.error({
            accountId: account.id,
            error: getCardAuthResult.error,
            message: `An error occurred while authenticating get card details api`
          });
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
          navigate(MANAGE_CARD_ROUTE);
          return;
        } else if (getCardAuthResult.status === AuthStatus.CANCELLED) {
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        // const getCardResponse = await CardsApi.getCard(
        //   account?.cards[0]?.id,
        //   getCardAuthResult.apiToken
        // );

        // if (getCardResponse.status !== 200) {
        //   logger.error({
        //     correlationId: getCardResponse.headers[CORRELATION_ID_HEADER],
        //     accountId: account.id,
        //     responseData: getCardResponse.data,
        //     message: `An error occurred while getting card details`
        //   });
        //   toast(
        //     ToastType.ERROR,
        //     "We are unable to process your request. Please try again"
        //   );
        //   navigate(MANAGE_CARD_ROUTE);
        //   return;
        // }

        const getCardControlsAuthResult = await authenticateApi({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          actionText: "to view card controls"
        });

        if (getCardControlsAuthResult.status === AuthStatus.FAILURE) {
          logger.error({
            accountId: account.id,
            error: getCardControlsAuthResult.error,
            message: `An error occurred while getting card controls`
          });
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
          navigate(MANAGE_CARD_ROUTE);
          return;
        } else if (getCardControlsAuthResult.status === AuthStatus.CANCELLED) {
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        const getCardControlsResponse = await CardsApi.getCardControls(
          account?.cards[0]?.id,
          getCardAuthResult.apiToken
        );

        if (getCardControlsResponse.status !== 200) {
          logger.error({
            correlationId:
              getCardControlsResponse.headers[CORRELATION_ID_HEADER],
            accountId: account.id,
            responseData: getCardControlsResponse.data,
            message: `An error occurred while getting Card controls`
          });
          toast(
            ToastType.ERROR,
            "Can’t fetch transaction preferences. Please try again"
          );
          // navigate(MANAGE_CARD_ROUTE);
          return;
        }

        // const isCardLocked = getCardResponse.data?.isLocked;
        const cardControls = getCardControlsResponse.data;

        const domesticOnline = cardControls?.filter((item) => {
          return (
            item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
            item.labelsMap.MCC === "RETAIL" &&
            item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
          );
        })[0];
        const internationalOnline = cardControls?.filter((item) => {
          return (
            item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
            item.labelsMap.MCC === "RETAIL" &&
            item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
          );
        })[0];

        setShowToggle(
          !domesticOnline.isEnabled && !internationalOnline.isEnabled
        );
      } catch (error: any) {
        logger.error({
          message: `An exception occurred while getting card controls`,
          error: error.message,
          accountId: account.id,
          stackTrace: error.stack
        });
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
        navigate(HOME_ROUTE);
      }

      setIsLoading(false);
    })();
  }, []);

  const handleConfirmClick = () => {
    dispatch(setMiscellaneousState({ showActivationJourney: false }));
  };

  const handleViewCard = () => {
    setIsUnmaskedCardOpen(true);
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="your IndusInd CardsHub" noTextStyle />
    </div>
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.textContainer1}>
          <div className={styles.textContainer2}>
            <div className={styles.subTextContainer1}>
              {`Hey there ${name ? `, ${name}` : ""} 👋`}
            </div>
            <div className={styles.subTextContainer2}>
              while your physical card is on it’s way
            </div>
          </div>
          <div className={styles.textContainer3}>
            your<span> digital card is ready to use!</span>
          </div>
        </div>
        <div className={styles.cardContainer}>
          <img
            src={cardDetails?.image[cardScheme]}
            className={styles.card}
            alt="card"
          />
          <div className={styles.bgImage} onClick={handleViewCard}>
            <img src={cardAndBtnBg} className={styles.img} alt="card" />
          </div>
        </div>
        <div className={styles.toggleContainer}>
          {showToggle && (
            <div className={styles.cardHeaderText2Toggle}>
              Start using your card right away!
            </div>
          )}
          {showToggle &&
            OnlineTransactionToggle({
              controlEnabled,
              setControlEnabled,
              setIsOpen,
              card,
              account
            })}
        </div>
        <div className={styles.btnContainer}>
          <Button2
            text1="continue"
            // text2="started"
            customClassName={styles.confirmBtn1}
            // isLoading={isVerifyLoginOtpLoading}
            onClick={handleConfirmClick}
            // disabled={otp.length !== 6}
          />
        </div>
      </div>
      <UnmaskedCard
        isOpen={isUnmaskedCardOpen}
        setIsOpen={setIsUnmaskedCardOpen}
      />
    </>
  );
};

export default ActivationPreHome;
