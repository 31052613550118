export const NO_MATCH_ROUTE = `*`;
export const HOME_ROUTE = `/`;
export const PRE_LOGIN_ROUTE = `/preLogin`;
export const CVP_OFFERS_ROUTE = `/cvpOffers`;
export const CAMPAIGN_OFFERS_ROUTE = `/campaignOffers`;
export const MERCHANT_OFFERS_ROUTE = `/merchantOffers`;
export const CVP_OFFER_DETAILS_ROUTE = `${CVP_OFFERS_ROUTE}/:benefitId`;
export const CAMPAIGN_OFFER_DETAILS_ROUTE = `${CAMPAIGN_OFFERS_ROUTE}/:benefitId`;
export const MERCHANT_OFFER_DETAILS_ROUTE = `${MERCHANT_OFFERS_ROUTE}/:benefitId`;
export const CARD_SUMMARY_ROUTE = `/cardSummary`;
export const CONVERT_TO_EMI_ROUTE = `/convertToEmi`;
export const EMI_OVERVIEW_ROUTE = `/emiOverview`;
export const MANAGE_CARD_ROUTE = "/manageCard";
export const CUSTOMER_CARE_ROUTE = `${MANAGE_CARD_ROUTE}/customerCare`;
export const RESET_CARD_PIN_ROUTE = `${MANAGE_CARD_ROUTE}/resetPin`;
export const ERROR_SCREEN_ROUTE = `/error`;
export const VIEW_TRANSACTIONS_ROUTE = `/viewTransactions`;
export const VIEW_STATEMENTS_ROUTE = `${CARD_SUMMARY_ROUTE}/statements`;
export const CARD_CONTROLS_ROUTE = `${MANAGE_CARD_ROUTE}/cardControls`;
export const REGISTERED_DEVICES_ROUTE = `${MANAGE_CARD_ROUTE}/registeredDevices`;
export const ACCOUNT_CLOSED_ROUTE = `/accountClosed`;
export const ALL_NUDGES_ROUTE = `/allNudges`;
export const SPEND_ANALYTICS_ROUTE = "/spendAnalytics";
export const SPEND_ANALYTICS_TRANSACTIONS_ROUTE = `${SPEND_ANALYTICS_ROUTE}/transactions`;
export const SESSION_EXPIRED_ROUTE = `/session-expired`;
export const TRACK_CARD_DELIVERY = `/track-card-delivery`;
export const PREFETCH_FAILURE = `/prefetch-failure`;
export const ALL_WELCOME_OFFERS_ROUTE = `/all-welcome-offers`;
export const WELCOME_OFFER_DETAIL_ROUTE = `/welcome-offer-detail/:benefitId`;
export const ACTIVATION_HOME = "/activation-home";
export const EMI_ELIGIBLE_TRANSACTIONS = `/emi/eligible-transactions`;
export const CONVERT_OUTSTANDING_TO_EMI = "/convertOutstandingToEmi"
export const CONVERT_OUTSTANDING_TO_EMI_OVERVIEW = "/convertOutstandingToEmi/emiOverView"
export const CONVERT_OUTSTANDING_TO_EMI_TENURE_SELECTOR = "/convertOutstandingToEmi/tenureSelector"
export const INSTALL_SCREEN = `/app-install`;
export const CARD_BLOCKED = `/card-blocked`;
export const DEVICE_LIMIT = `/device-limit`;
export const BROWSER_NOT_SUPPORTED_SCREEN = `/browser/supported-browser`;
export const BROWSER_OLDER_VERSION = `/browser/supported-version`
export const INCOGNITO_DETECTED = `/incognito-detected`;
