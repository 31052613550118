import styles from "./Stepper.module.scss";

interface Steps {
  iconActive: string;
  iconInactive: string;
  active: boolean;
  status: string;
  currentStatusBoolean: boolean;
  text?: {
    regular?: string;
    error?: string;
    highlightText?: string;
  };
}

interface StepperProps {
  steps: Steps[];
  direction?: "horizontal" | "vertical";
}

const Stepper = (props: StepperProps) => {
  const { steps, direction = "horizontal" } = props;
  return direction === "horizontal" ? (
    <div className={styles.container}>
      {steps.map((step, i) => {
        return (
          <div className={styles.imageContainer} key={i}>
            <img src={step.active ? step.iconActive : step.iconInactive} />
            {i !== steps.length - 1 && (
              <div
                className={styles.line}
                style={{
                  background: step.active ? "#f4b348" : "white"
                }}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <div className={styles.verticalContainer}>
      {steps.map((step, i) => {
        return (
          <div className={styles.container2} key={i}>
            <div className={styles.verticalImageContainer}>
              <div className={styles.imgTextContainer}>
                <img src={step.active ? step.iconActive : step.iconInactive} />
                <div className={styles.imgText}>
                  <div className={styles.imgText1}>{step?.status}</div>
                  {step.currentStatusBoolean && step?.text?.regular ? (
                    <>
                      <div className={styles.imgText2}>
                        {step?.text?.regular}{" "}
                        <span style={{ color: "#f4b348" }}>
                          {step?.text?.highlightText}
                        </span>
                      </div>
                    </>
                  ) : (
                    !!step?.text?.error &&
                    step.currentStatusBoolean && (
                      <div className={styles.imgText4}>{step?.text?.error}</div>
                    )
                  )}
                </div>
              </div>
            </div>
            {i !== steps.length - 1 && (
              <div
                className={styles.verticalLine}
                style={{
                  background:
                    steps[i - 1]?.active || i === 0 ? "#f4b348" : "white"
                }}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
