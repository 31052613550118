/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import styles from "./DueDetials.module.scss";
import { Button2 } from "../button/Button";
import { formatAmount, getStatementDueDate } from "../../utils/functions";

interface BillDetailsProps {
  totalDue: number;
  minimumDue: number;
  dueDate: string;
}

const handlePayBillClick = () => {
  window.open("https://pay.billdesk.com/cardnet-instapay/induscard", "_blank");
};

const BillDetails = (props: BillDetailsProps) => {
  return (
    <div className={styles.dueDetailsWrapper}>
      <div className={styles.title}>
        <span>your</span> last statement
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <div>Total Due</div>
          <div>{formatAmount(props!.totalDue)}</div>
        </div>
        <div className={styles.contentContainer}>
          <div>Minimum Due</div>
          <div>{formatAmount(props!.minimumDue)}</div>
        </div>
        <div className={styles.contentContainer}>
          <div>Due Date</div>
          <div>{getStatementDueDate(props!.dueDate)}</div>
        </div>
      </div>
      <Button2
        text1Style={{ fontWeight: "bold" }}
        customClassName={styles.payBtn}
        text1="pay"
        text2="bill"
        onClick={handlePayBillClick}
      />
    </div>
  );
};

export default BillDetails;
