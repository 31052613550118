import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../../components/loader/loader";
import { Button2, Header, ToastType, toast } from "../../components";
import styles from "./tenureSelector.module.scss";
import CurrencyFormat from "react-currency-format";
import editSvg from "../../assets/svgs/convertOutstandingToEmi/edit.svg";
import emiGoldIcon from "../../assets/svgs/convertOutstandingToEmi/emiGoldIcon.svg";
import { captureEvent, formatAmount } from "../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CARD_SUMMARY_ROUTE,
  CONVERT_OUTSTANDING_TO_EMI_OVERVIEW
} from "../../routes/ScreenRoutes";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedAccount } from "../../features/user/userSlice";
import logger from "../../utils/logger";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import TimeSelector from "../../components/numberPicker/TimeSelector";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import { EmiPlan, EmiType } from "../../apis/accountsApi/accountsApiTypes";
import { setAmountSelectedForOutstandingToEmi } from "../../features/outstandingToEmi/outstandingToEmiSlice";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { EventType } from "../../utils/enums";

const TenureSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedAmount = parseFloat(location?.state?.selectedAmount);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currencyInputRef = useRef<HTMLDivElement>(null);
  const [selectedEmiPlan, setSelectedEmiPlan] = useState<any>(null);
  const [valueGroups, setValueGroups] = useState<{ months: string }>({
    months: ""
  });

  //remove the data from here
  const [emiPlans, setEmiPlans] = useState<EmiPlan[]>([]);

  const account = useSelector(getSelectedAccount)!;

  const getEmiConfig = useCallback(async () => {
    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.EMI_CONFIG,
        actionText: "to show emi plans"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get emi config api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }
      setIsLoading(true);
      const response = await AccountsApi.getEmiConfig(
        account.id,
        authResult.apiToken,
        {
          accountId: account.id,
          emiType: EmiType.TOTAL_OUTSTANDING,
          amount: selectedAmount
        }
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting emi config`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display your active EMIs. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      const emiPlans = response?.data?.emiPlans?.reverse();

      if (!emiPlans || emiPlans.length < 1) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting emi config. Emi config is invalid.`
        });
        toast(
          ToastType.ERROR,
          "Your request for EMI conversion could not be processed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      setEmiPlans(emiPlans);
      setSelectedEmiPlan(emiPlans[0]);

      // set first emi plan's tenure as value to be passed to tenure selector
      setValueGroups({
        // pass string as "x months"
        months: emiPlans[0].tenureInMonths + " months"
      });
      setIsLoading(false);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting emi config`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display your active EMIs. Please try again"
      );
      navigate(CARD_SUMMARY_ROUTE);
    }

    setIsLoading(false);
  }, [navigate, account.id]);

  useEffect(() => {
    getEmiConfig();
  }, []);

  useEffect(() => {
    const emiPlan = emiPlans.find(
      (emiPlan) =>
        // get tenure x from string "x months"
        emiPlan.tenureInMonths === Number(valueGroups.months.split(" ")[0])
    );
    setSelectedEmiPlan(emiPlan || null);
  }, [valueGroups]);

  useEffect(() => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.VIEW_OSTOEMI_SELECT_TENURE_PAGE,
      eventData: { requestSource, eventType: EventType.PAGE_VIEW }
    });
  }, []);

  useEffect(() => {
    if (selectedAmount) {
      dispatch(setAmountSelectedForOutstandingToEmi(selectedAmount));
    }
  }, []);

  const handleConfirmButton = () => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.OSTOEMI_SELECT_TENURE_PAGE_CONFIRM_BUTTON_CLICK,
      eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
    });
    navigate(CONVERT_OUTSTANDING_TO_EMI_OVERVIEW, {
      state: { selectedEmiPlan, selectedAmount }
    });
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="convert to Emi" />
    </div>
  ) : (
    <div className={styles.container}>
      <Header
        label="Convert Outstanding to EMI"
        bgColor="#141414"
        customClassName={styles.headers}
      />
      <hr className={styles.horizontalLine}></hr>
      <div className={styles.mainContainer}>
        <div className={styles.conversionAmmount}>
          <p>You're converting</p>
          <div className={styles.currencyContainer} ref={currencyInputRef}>
            <CurrencyFormat
              allowNegative={false}
              decimalScale={2}
              displayType="text"
              placeholder="NA"
              thousandSpacing="2s"
              thousandSeparator={true}
              fixedDecimalScale={true}
              prefix={"₹ "}
              className={styles.currencyFormat}
              fontWeight="bold"
              value={selectedAmount}
              fontSize="32px"
              variant="underlined"
              onValueChange={(values) => {
                let amount = values?.value;

                if (amount.length > 9) return false;
              }}
            />
            <div className={styles.editSvg} onClick={() => navigate(-1)}>
              <img src={editSvg} alt="editSvg" />
            </div>
          </div>
        </div>
        <div className={styles.headerText}>
          <span className={styles.headerText1}>{"Select "}</span>
          <span className={styles.headerText2}> tenure</span>
        </div>
        <div className={styles.amountContainer}>
          <img className={styles.icon} src={emiGoldIcon} />
          <div>
            <span className={styles.amountText1}>Amount Payable </span>
          </div>
          <div className={styles.valueContainer}>
            <span className={styles.amountText2}>
              {formatAmount(selectedEmiPlan?.emi)}
            </span>
            <span className={styles.amountText3}> / month</span>
          </div>
        </div>
      </div>
      <div className={styles.tenureContainer}>
        <div className={styles.divider}></div>
        <TimeSelector
          valueGroups={valueGroups}
          monthGroups={emiPlans.map(
            (emiPlan) => emiPlan.tenureInMonths + " months"
          )}
          interestRates={emiPlans.map((emiPlan) =>
            (emiPlan.interestRateBps / 100).toFixed(2)
          )}
          setValueGroups={setValueGroups}
        />
        <div className={styles.divider}></div>
      </div>
      <div className={styles.btnContainer}>
        <div>
          <div>
            <Button2
              text1="Confirm"
              customClassName={styles.button}
              onClick={() => handleConfirmButton()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenureSelector;
