/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./welcomeOffer.module.scss";
import { useNavigate } from "react-router-dom";
import { WELCOME_OFFER_DETAIL_ROUTE } from "../../../routes/ScreenRoutes";

interface WelcomeOfferProps {
  id: string;
  background: string;
  offerImg: string;
  merchantLogoImg: string;
  title: string;
  description: string;
  hasDetails: boolean;
}

const WelcomeOffer = ({
  id,
  background,
  offerImg,
  merchantLogoImg,
  title,
  description,
  hasDetails
}: WelcomeOfferProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (hasDetails) {
      navigate(WELCOME_OFFER_DETAIL_ROUTE.replace(":benefitId", id.toString()));
    }
  };
  return (
    <div className={styles.offerContainer} onClick={handleClick}>
      <div className={styles.imgContainer}>
        <img src={merchantLogoImg} alt="offer" className={styles.logoImg} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textheader}>{title}</div>
        <div className={styles.subtext}>{description}</div>
      </div>
      <div className={styles.subtractDiv1}> </div>
      <div className={styles.subtractDiv2}> </div>
      {/* <OnlineTransaction isOpen={isOpen} setIsOpen={setIsOpen} /> */}
    </div>
  );
};

export default WelcomeOffer;
