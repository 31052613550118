/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  CARD_SUMMARY_ROUTE,
  HOME_ROUTE,
  PREFETCH_FAILURE,
  PRE_LOGIN_ROUTE
} from "../../../routes/ScreenRoutes";
import indusIndLogo from "../../../assets/svgs/indusind-bank-logo.svg";
import { capitalizeFirsLetter, captureEvent } from "../../../utils/functions";
import { useState } from "react";
import cross from "../../../assets/svgs/cross.svg";
import cardImg from "../../../assets/svgs/varientCard.svg";
import check from "../../../assets/svgs/Check.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getOtherCards,
  getSelectedAccount,
  getSelectedCard,
  setUserIds
} from "../../../features/user/userSlice";
import { RootState } from "../../../app/store";
import AuthApi from "../../../apis/authApi/AuthApi";
import {
  clearSession,
  setSession
} from "../../../features/session/sessionSlice";
import addOn from "../../../assets/svgs/addOn.svg";
import { ToastType, toast } from "../../../components";
import { LogoutProps } from "../../../apis/authApi/authApiTypes";
import {
  GenerateSessionRequest,
  GetCardsResponse,
  EVENT_NAME
} from "../../../apis/appApi/appApiTypes";
import AppApi from "../../../apis/appApi/AppApi";
import { VerifyLoginOtpSuccessResponse } from "../../../apis/loginApi/loginApiTypes";
import { CardType } from "../../../data/config";
import { Env } from "../../../utils/enums";
import Loader from "../../../components/loader/loader";
import { getPciEncryptionKeyAndIv } from "../../../utils/encryptionUtil";
import * as InternalFlags from "../../../utils/internalFlags";
import OtherCardsError from "./OtherCardsError";
import { setMiscellaneousState } from "../../../features/miscellaneous/miscellaneousSlice";
interface HeaderCardProps {
  cardType: string;
  gradient: string;
  image: {
    [key: string]: any;
  };
}
interface HeaderProps {
  card: HeaderCardProps;
  backBtnEnabled?: boolean;
  setIsParentLoading?: any;
  customClassname?: string;
  text?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const items = [
  {
    image: cardImg,
    cardText: "Aura Edge   **** 6789",
    cardId: "AURA",
    isAddon: false
  },
  {
    image: cardImg,
    cardText: "Pioneer   **** 6789",
    cardId: "PIONEER",
    isAddon: true
  },
  {
    image: cardImg,
    cardText: "Pinnacle   **** 6789",
    cardId: "PINNACLE",
    isAddon: false
  }
];
const Index = (props: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    card,
    backBtnEnabled = false,
    setIsParentLoading,
    customClassname,
    text
  } = props;
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const account = useSelector(getSelectedAccount)!;
  const selectedCard = useSelector(getSelectedCard)!;
  const customerId = useSelector((state: RootState) => state.user.customerId!);
  const otherCardsData = useSelector(getOtherCards);
  const isFailure = otherCardsData.length === 1 && otherCardsData[0]?.failure;
  const otherCards: GetCardsResponse[] = [...otherCardsData]?.sort((a, b) => {
    return a?.isPrimary === b?.isPrimary ? 0 : a?.isPrimary ? -1 : 1;
  });
  // const otherCards: GetCardsResponse[] = selectedCard?.isPrimary
  //   ? [...otherCardsData]?.sort((a, b) => {
  //       return a?.isPrimary === b?.isPrimary ? 0 : a?.isPrimary ? -1 : 1;
  //     })
  //   : ([selectedCard] as unknown as GetCardsResponse[]);
  const [isSelectedValue, setIsSelectedValue] = useState<string>(
    selectedCard?.id
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const logout = async ({ accountId, customerId, cardId }: LogoutProps) => {
    try {
      const request: LogoutProps = {
        accountId: accountId,
        customerId: customerId,
        cardId: cardId
      };
      await AuthApi.logout(request);
    } catch (error) {
      console.error("An error occured while logging out. Error: \n", error);
    } finally {
      dispatch(clearSession());
      sessionStorage.removeItem("/");
      localStorage.removeItem("isFirstTimeLogin");
      localStorage.removeItem("visitedHome");
      sessionStorage.removeItem("selectedProgramId");
      sessionStorage.removeItem("selectedAccountId");
      sessionStorage.removeItem("selectedCardId");
      sessionStorage.removeItem("customerId");
      sessionStorage.removeItem("primaryCardMobileNumber");
      sessionStorage.removeItem("requestSource");
      sessionStorage.removeItem("mobileNumbelastFour");
      navigate(PRE_LOGIN_ROUTE);
    }
  };

  const handleLogout = () => {
    logout({
      accountId: account.id,
      customerId: customerId,
      cardId: selectedCard.id
    });
  };

  const handleCardVarientSelectionClick = () => {
    setIsOverlayOpen(true);
    captureEvent({ eventName: EVENT_NAME.OTHER_CARD_VIEW_CLICK });
  };
  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  const changeCardContext = async (cardId: string, isAddOn: boolean) => {
    try {
      const selectedCardValue = otherCards.find(
        (item: GetCardsResponse) => item?.id === cardId
      );

      setIsParentLoading(true);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, accountId, customerId, programId, primaryCustomerId } =
        selectedCardValue!;

      const request: GenerateSessionRequest = {
        cardId: id,
        customerId,
        accountId,
        programId
      };

      const response = await AppApi.generateSession(request);

      const { token, metaData, isPriorityRefreshSuccess } =
        response.data as VerifyLoginOtpSuccessResponse;

      const { pciEncryptionKey, pciEncryptionIv } = getPciEncryptionKeyAndIv(
        token,
        metaData
      );

      dispatch(
        setSession({
          token,
          metaData,
          pciEncryptionKey,
          pciEncryptionIv
        })
      );

      dispatch(
        setUserIds({
          customerId,
          selectedProgramId: programId,
          selectedAccountId: accountId,
          selectedCardId: id
        })
      );

      if (!isPriorityRefreshSuccess) {
        dispatch(
          setMiscellaneousState({
            prefetchLoadFailure: true
          })
        );
        setIsParentLoading(false);
        navigate(PREFETCH_FAILURE);
      }

      setIsParentLoading(false);
      //replace and navigate to card summary
      isAddOn
        ? navigate(CARD_SUMMARY_ROUTE, { replace: true })
        : navigate(HOME_ROUTE, { replace: true });
    } catch (error) {
      console.error(
        "An error occured while changing card context. Error: \n",
        error
      );
    }
  };

  const handleSelect = async (event: any, isAddOn: boolean, cardId: string) => {
    if (isSelectedValue === cardId) return;
    if (!InternalFlags.P1) {
      if (isAddOn) {
        event.stopPropagation();
        toast(ToastType.INFO, "Add-on cards are coming soon!");
      } else {
        await changeCardContext(cardId, isAddOn);
        setIsSelectedValue(cardId);
        captureEvent({
          eventName: EVENT_NAME.SWITCH_TO_OTHER_CARD_CLICK,
          eventData: { cardId: cardId }
        });
      }
    } else {
      await changeCardContext(cardId, isAddOn);
      setIsSelectedValue(cardId);
      captureEvent({
        eventName: EVENT_NAME.SWITCH_TO_OTHER_CARD_CLICK,
        eventData: { cardId: cardId }
      });
    }
  };

  const handleTnCClick = () => {
    window.open(
      "https://www.indusind.com/in/en/personal/terms-and-conditions.html",
      "_blank"
    );
  };
  const handlePrivacyClick = () => {
    window.open(
      "https://www.indusind.com/in/en/personal/privacy-policy.html",
      "_blank"
    );
  };
  const getCardType = (programId: string) => {
    return CardType[process.env.REACT_APP_NAME as Env][programId!];
  };

  return (
    <>
      {isLoading ? (
        <div style={{ height: "100vh" }}>
          <Loader text1="loading" text2="your IndusInd CardsHub" noTextStyle />
        </div>
      ) : (
        <>
          <div className={`${styles["header-container"]} ${customClassname}`}>
            <div className={styles["header-title"]}>
              {backBtnEnabled && (
                <div className={styles["header-back-btn"]}>
                  <img
                    src="/images/ArrowLeftWhite.svg"
                    alt="Back"
                    onClick={() => navigate(HOME_ROUTE)}
                  />
                </div>
              )}
              {text ? (
                <div className={styles.label}>{text}</div>
              ) : (
                <img
                  src={indusIndLogo}
                  alt="IndusInd Bank"
                  className={styles.induindLogo}
                ></img>
              )}
            </div>
            <div
              onClick={handleCardVarientSelectionClick}
              className={styles["header-card"]}
            >
              <div
                className={styles["arrow-rewards"]}
                style={{
                  background: card?.gradient
                }}
              />
              <span className={styles["card-name"]}>
                {capitalizeFirsLetter(card?.cardType)}
              </span>
            </div>
          </div>
          {isOverlayOpen && (
            <div onClick={handleCloseOverlay} className={styles.varientOverlay}>
              <div className={styles.varientContent}>
                <div className={styles.varientHeadingContainer}>
                  <div className={styles.varientHeading}>
                    Your IndusInd Bank Credit{" "}
                    {otherCards.length > 1 ? "Cards" : "Card"}
                  </div>
                  <img src={cross} width={"14px"} height={"14px"} />
                </div>
                /
                <div className={styles.varientContainer}>
                  {(otherCards || []).map((item, index) => {
                    const programId = item?.programId;
                    return (
                      <div
                        onClick={(e) =>
                          handleSelect(
                            e,
                            !item?.isPrimary || item?.isVariantUpgradeActive,
                            item?.id
                          )
                        }
                        className={
                          isSelectedValue === item?.id
                            ? styles.varientCardContainerSelected
                            : styles.varientCardContainer
                        }
                        key={index}
                      >
                        {(!item?.isPrimary || item.isVariantUpgradeActive) && (
                          <img src={addOn} className={styles.addOnImg} />
                        )}
                        <div className={styles.cardContainer}>
                          <div
                            style={{
                              background: getCardType(programId).gradient
                            }}
                            className={styles.cardImg}
                          />
                        </div>
                        <div className={styles.cardText}>
                          {!item?.isPrimary
                            ? "Add-on | "
                            : item.isVariantUpgradeActive
                            ? "Upgraded | "
                            : ""}{" "}
                          {capitalizeFirsLetter(
                            getCardType(programId)?.cardType
                          )}{" "}
                          {"  **** " + item?.maskedCardNumber?.slice(-4)}
                        </div>
                        {isSelectedValue === item?.id && (
                          <img src={check} className={styles.checkImg} />
                        )}
                      </div>
                    );
                  })}
                  {isFailure && <OtherCardsError />}
                  <hr className={styles.borderLine} />
                  <div className={styles.logoutContainer}>
                    <div onClick={handleLogout} className={styles.logoutText}>
                      Logout
                    </div>
                    <div className={styles.tncCondition}>
                      <span
                        className={styles.linkText}
                        onClick={handleTnCClick}
                      >
                        Terms & Conditions
                      </span>
                      <span className={styles.dot}>
                        <div className={styles.dotDiv}>.</div>
                      </span>{" "}
                      <span
                        className={styles.linkText}
                        onClick={handlePrivacyClick}
                      >
                        Privacy Policy
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Index;
