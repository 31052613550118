const ApiIds = {
  FETCH_CARD: "Z4Urm9PvRM",
  FETCH_CARD_CONTROLS: "e2i6G90sDq",
  HOTLIST_CARD: "E07SOyyF7R",
  REPLACE_CARD: "Q7Iq2dgxzT",
  LOCK_CARD: "LCxkITPK86",
  UNLOCK_CARD: "FUE5sITcpi",
  UPDATE_CARD_CONTROLS: "EPNj6BoN2L",
  SET_CARD_PIN: "WoASvNSruo",
  PCI_SET_PIN_URL: "DzwOtuSLeW",
  PCI_CARD_URL: "x1bIG1or2O",
  PWA_SESSION_INFO_FROM_ACTIVATION_CODE: "PvQgix0m3q",
  PWA_DESIGN_CONFIG: "TdMZQnZnoI",
  FETCH_UNMASKED_CARD: "uAetNDki25",
  ACTIVATE_CARD: "Z2ZYNuQOzX",
  ACCOUNT_SUMMARY: "ekPxH6a98L",
  EMI_CONFIG: "sOJJR7FwnW",
  CREATE_EMI: "QelSD81me6",
  LIST_EMI: "V73cLdsvZW",
  FORECLOSURE_DETAILS: "LXYgwQepcq",
  FORECLOSE_EMI: "SSsuox6dgY",
  SET_MPIN: "e8LdvPeQ9C",
  FETCH_STATEMENTS: "Wi7EfQFpip",
  FETCH_TRANSACTIONS: "ZtJVCztlmw"
};

export default ApiIds;
