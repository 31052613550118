import { useSelector } from "react-redux";
import type { RootState } from "../../../app/store";
import styles from "./merchantOffersSection.module.scss";
import IntermediateText from "../intermediateText";
import MerchantOffer from "./merchantOffer/MerchantOffer";
import { Arrow, Button } from "../../../components";
import Carousel from "./merchanOffersCarousel";
import { Category } from "../../../components/category/Category";
import {
  foodIcon,
  beauty,
  lifestyleIcon,
  musicIcon,
  travelIcon
} from "../../../assets/svgs";
import { MERCHANT_OFFERS_ROUTE } from "../../../routes/ScreenRoutes";
import { useNavigate } from "react-router-dom";
import { isFestiveOffersUIEnabled } from "../../../utils/internalFlags";
import { Festivals } from "../../../utils/enums";
import { EVENT_NAME } from "../../../apis/appApi/appApiTypes";
import { captureEvent } from "../../../utils/functions";

const FestivalData = Festivals?.CURRENT_FESTIVAL;
const FestiveTagIcons = FestivalData?.images?.merchantOffers?.tagIcons;

const displayTags: any[] = [
  {
    displayName: "Travel",
    filterName: "Travel",
    icon: isFestiveOffersUIEnabled ? FestiveTagIcons?.travel?.url : travelIcon
  },
  {
    displayName: "Electronics",
    filterName: "Electronics",
    icon: isFestiveOffersUIEnabled
      ? FestiveTagIcons?.electronics?.url
      : musicIcon
  },
  {
    displayName: "Beauty",
    filterName: "Wellness & Beauty",
    icon: isFestiveOffersUIEnabled ? FestiveTagIcons?.beauty?.url : beauty
  },
  {
    displayName: "Apparel",
    filterName: "Apparel",
    icon: isFestiveOffersUIEnabled
      ? FestiveTagIcons?.apparel?.url
      : lifestyleIcon
  },
  {
    displayName: "Food",
    filterName: "Food & Dining",
    icon: isFestiveOffersUIEnabled ? FestiveTagIcons?.food?.url : foodIcon
  }
];

const MerchantOffersSection = () => {
  const navigate = useNavigate();

  const [benefits] = useSelector((state: RootState) => [state.benefits]);
  const tagList = useSelector(
    (state: RootState) => state.benefits.accountPreferences?.tags
  );

  const handleOnClick = () => {
    captureEvent({ eventName: EVENT_NAME.ALL_OFFERS_CLICK });
    navigate(MERCHANT_OFFERS_ROUTE, {});
  };

  const handleFavouriteRedirectClick = () => {
    navigate(MERCHANT_OFFERS_ROUTE, {
      state: {
        openBottomSheet: true
      }
    });
  };

  const handleTagClick = (tag: any) => {
    navigate(MERCHANT_OFFERS_ROUTE, {
      state: {
        selectedTag: tag
      }
    });
  };

  return (
    <div className={styles.merchantOffersSectionContainer}>
      {isFestiveOffersUIEnabled ? (
        <img
          className={styles.festiveCampaignMerchantDivider}
          src={FestivalData?.images?.cvpAndMerchantDivider?.url}
          alt={FestivalData?.images?.cvpAndMerchantDivider?.alt}
        />
      ) : (
        <img
          className={styles.background}
          src={"/images/patterns/semicircleripple.svg"}
          alt="semicircle"
        />
      )}
      <div
        className={`${!isFestiveOffersUIEnabled ? styles.background2 : ""} `}
      ></div>
      <div className={styles.intermediateTextContainer}>
        {isFestiveOffersUIEnabled ? (
          <IntermediateText
            text1={FestivalData?.text?.merchantOffers?.line1}
            text2={FestivalData?.text?.merchantOffers?.line2}
          />
        ) : (
          <IntermediateText
            icon="/images/home/titleIcon1.svg"
            text1="offers"
            text2="handpicked for you"
          />
        )}
      </div>
      <div className={styles.carouselContainer}>
        <Carousel
          items={benefits.merchantBenefits.benefits?.map((benefit) => {
            const offerDetails = JSON.parse(benefit.offerDetails);

            return (
              <MerchantOffer
                key={benefit.benefitId}
                id={benefit.benefitId}
                title={benefit.displayTitle}
                description={benefit.displayDescription}
                background={benefit.displayColor}
                offerImg={benefit.illustrationUrl}
                merchantLogoImg={benefit.logoUrl}
                hasDetails={
                  offerDetails.redeemSteps || offerDetails.tncConditions
                }
              />
            );
          })}
        />
      </div>
      {(tagList || []).length === 0 && (
        <div
          className={styles.preferencesContainer1}
          onClick={handleFavouriteRedirectClick}
        >
          <div className={styles.preferencesContainer2}>
            <div className={styles.preferencesEllipse}></div>
            <div className={styles.preferences}>
              <img src="/images/home/preferences.svg" alt="preferences" />
              <div>
                <div className={styles.preferencesText1}>
                  Get the best out of offers
                </div>
                <div className={styles.preferencesText2}>
                  Provide preferences for offers
                </div>
              </div>
              <Arrow />
            </div>
          </div>
        </div>
      )}
      <div className={styles.categoriesContainer}>
        {/* <img
          className={styles.categoriesOverlay}
          src={categoriesOverlayImg}
          alt="overlay"
        /> */}
        <div>
          <div className={styles.categoriesList}>
            {displayTags?.map((displayTag) => {
              const { displayName, icon } = displayTag;

              return (
                <Category
                  key={displayName}
                  label={displayName}
                  icon={icon}
                  onClick={() => handleTagClick(displayTag)}
                />
              );
            })}
          </div>
          <div className={styles.categoriesBtnContainer}>
            <Button
              customClassName={styles.categoriesBtn}
              text1="all"
              text2="offers"
              onClick={handleOnClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantOffersSection;
