import React from "react";
import styled from "@emotion/styled";

interface GradientCircleProgressbarProps {
  percentage: number;
  width?: number;
  strokeWidth?: number;
  strokeLinecap?: "round" | "square" | "butt";
  fontSize?: string;
  fontColor?: string;
  fontFamily?: string;
  primaryColor?: string[];
  secondaryColor?: string;
  fill?: string;
  hidePercentageText?: boolean;
  children?: React.ReactNode;
}

const CircleContainer = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const PercentageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const StyledCircle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
// const CircleContainer = ({ children, style }) => <div className="react-super-progressbar__base" style={style}>{children}</div>
// const PercentageContainer = ({ children }) => <div className="react-super-progressbar__percentage-container">{children}</div>
// const StyledCircle = ({ children, ...props }) => <circle className="react-super-progressbar__styled-circle" {...props}>{children}</circle>

const GradientCircleProgressbar = ({
  percentage,
  hidePercentageText = true,
  width = 200,
  strokeWidth = 4,
  strokeLinecap = "round",
  fontSize = "inherit",
  fontColor = "inherit",
  fontFamily = "inherit",
  primaryColor = ["orange", "orange"],
  secondaryColor = "transparent",
  fill = "transparent",
  children
}: GradientCircleProgressbarProps) => {
  const PI = 3.14;
  const R = width / 2 - strokeWidth * 2;
  let circumference = 2 * PI * R;
  let offset = circumference - (percentage / 100) * circumference;
  let gradientId = `${primaryColor[0]}${primaryColor[1]}`.replace(/#/g, "");
  let gradientId1 = `${"#323232"}${"#323232"}`.replace(/#/g, "");

  return (
    <CircleContainer
      className="react-super-progressbar__base"
      style={{
        height: `${width}px`,
        width: `${width}px`
      }}
    >
      {children}
      {!hidePercentageText ? (
        <PercentageContainer className="react-super-progressbar__percentage-container">
          <span
            className="react-super-progressbars__percentage"
            style={{
              fontSize,
              fontFamily,
              color: fontColor
            }}
          >
            {percentage}%
          </span>
        </PercentageContainer>
      ) : null}

      <svg
        width="100%"
        height="100%"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={primaryColor[0]} />
          <stop offset="100%" stopColor={primaryColor[1]} />
        </linearGradient>
        <linearGradient id={gradientId1} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={"#171717"} />
          <stop offset="100%" stopColor={"#171717"} />
        </linearGradient>
        <circle
          strokeWidth={strokeWidth}
          fill="transparent"
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={secondaryColor}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <StyledCircle
          strokeWidth={strokeWidth}
          fill={fill}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={`url(#${gradientId1})`}
          strokeLinecap={strokeLinecap}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={0}
        />
        <StyledCircle
          strokeWidth={strokeWidth}
          fill={fill}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={`url(#${gradientId})`}
          strokeLinecap={strokeLinecap}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </svg>
    </CircleContainer>
  );
};
export default GradientCircleProgressbar;
