/* eslint-disable @typescript-eslint/no-unused-vars*/
import { CampaignBenefitVariant, Festivals } from "../../../../utils/enums";
import CircleProgressbar from "./circleProgressBar";
import styles from "./pendingCampaignOfferStyles.module.scss";
import { wave2 } from "../../../../assets/svgs";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_OFFER_DETAILS_ROUTE } from "../../../../routes/ScreenRoutes";
import { isFestiveOffersUIEnabled } from "../../../../utils/internalFlags";
import { pluralize } from "../../../../utils/BenefitUtil";

interface PendingCampaignOfferProps {
  id: string;
  background: string;
  title1: string;
  title2: string;
  description: string;
  progress: number;
  variant?: CampaignBenefitVariant;
  daysLeft?: number;
  hasDetails: boolean;
  logoUrl: string;
}

const PendingCampaignOffer = ({
  id,
  background,
  description,
  title1,
  title2,
  progress,
  variant = CampaignBenefitVariant.HOME_PAGE,
  daysLeft = 0,
  hasDetails,
  logoUrl
}: PendingCampaignOfferProps): React.ReactElement => {
  const navigate = useNavigate();

  const handleCampaignOfferCtaClick = () => {
    if (hasDetails) {
      navigate(
        CAMPAIGN_OFFER_DETAILS_ROUTE.replace(":benefitId", id.toString())
      );
    }
  };

  const FestiveImages = Festivals?.CURRENT_FESTIVAL?.images?.campaignOffers;
  const FestiveColors = Festivals?.CURRENT_FESTIVAL?.colors?.campaignOffers;

  return (
    <div
      className={styles.offerContainer}
      onClick={() => handleCampaignOfferCtaClick()}
    >
      <div
        className={`${styles.offerIllustrationOutline} ${
          isFestiveOffersUIEnabled ? styles.festiveOfferIllustrationOutline : ""
        }`}
      ></div>
      <div className={styles.offerIllustrationBackground}>
        <img
          className={styles.backgroundPattern}
          src={
            isFestiveOffersUIEnabled
              ? FestiveImages?.cardBackground?.url
              : wave2
          }
          alt={
            isFestiveOffersUIEnabled
              ? FestiveImages?.cardBackground?.alt
              : "wave"
          }
        />

        {daysLeft > 0 && (
          <div className={styles.daysLeft}>
            {pluralize(daysLeft, "day")} left
          </div>
        )}

        <div className={styles.contentContainer}>
          {isFestiveOffersUIEnabled && (
            <img
              src={FestiveImages?.cardProgressBackground?.url}
              alt={FestiveImages?.cardProgressBackground?.alt}
              className={styles.festiveCampaignCardBgPattern}
            />
          )}
          <CircleProgressbar
            percentage={progress}
            width={93}
            primaryColor={
              isFestiveOffersUIEnabled
                ? ["#60ECFF", "#FFFF"]
                : ["white", "white"]
            }
          >
            <div className={styles.offerImg}>
              <img src={logoUrl} alt="logo" />
            </div>
          </CircleProgressbar>

          <div className={styles.textContainer}>
            <div
              className={styles.description}
              style={{
                color: isFestiveOffersUIEnabled
                  ? FestiveColors?.cardChipTextColor
                  : "#fff",
                background: isFestiveOffersUIEnabled
                  ? FestiveColors?.cardChipBackground
                  : "#0c7e28",
                marginTop: isFestiveOffersUIEnabled ? "5px" : ""
              }}
            >
              {description}
            </div>
            <div className={styles.title1}>{title1}</div>
            <div className={styles.title2}>{title2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingCampaignOffer;
