// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styles from "./SpendCategoryContainerStyles.module.scss";
import arrow from "../../assets/svgs/button2Icon.svg";
import { formatAmount2 } from "../../utils/functions";
import {
  SpendGroup,
  cardColors,
  imageObj,
  spendCategoryName
} from "../../utils/SpendAnalyticsUtil";
import { useNavigate } from "react-router-dom";
import { SPEND_ANALYTICS_TRANSACTIONS_ROUTE } from "../../routes/ScreenRoutes";

type Props = {
  category?: SpendGroup;
  total: number;
  other?: number;
  index: number;
  startDate: string;
  endDate: string;
  key?: any;
  filteredOthers?: SpendGroup[];
};

const SpendCategoryContainer = ({
  category,
  total,
  other,
  index,
  startDate,
  endDate,
  filteredOthers
}: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (category) {
      navigate(SPEND_ANALYTICS_TRANSACTIONS_ROUTE, {
        state: { groupId: category.groupId, startDate, endDate, total }
      });
    } else {
      navigate(SPEND_ANALYTICS_TRANSACTIONS_ROUTE, {
        state: {
          filteredOthers: filteredOthers,
          startDate,
          endDate,
          total,
          other
        }
      });
    }
  };

  return (
    <div className={styles.spendContainer}>
      <div
        style={{
          background: other ? "#FFF0F1" : `${cardColors[index]}`
        }}
      >
        <div className={styles.categorySpend}>
          <div>
            <div className={styles.groupName}>
              {category ? `${spendCategoryName[category.groupName]}` : "Others"}
            </div>
            <div className={styles.totalSpend}>
              {category && `${formatAmount2(category.totalSpend)}`}
              {other && `${formatAmount2(other)}`}
            </div>
          </div>
          <div className={styles.arrowButton} onClick={handleClick}>
            {" "}
            <img src={arrow} alt="arrow" />{" "}
          </div>
        </div>
        <div className={styles.categoryPercentage}>
          {category && `${((category.totalSpend / total) * 100).toFixed(0)}`}
          {other && `${((other / total) * 100).toFixed(0)}`}%
        </div>

        <div className={styles.categoryImage}>
          <img
            src={
              category
                ? `${imageObj[category.groupName]}`
                : other
                ? `${imageObj.Others}`
                : ""
            }
            alt="categoryImage"
          />
        </div>
      </div>
    </div>
  );
};

export default SpendCategoryContainer;
