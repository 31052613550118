import styles from "./styles.module.scss";
interface HiddenCardProps {
  text1: string;
  text2: string;
  icon?: string;
}

const index = (props: HiddenCardProps) => {
  const { text1, text2, icon } = props;
  return (
    <div className={styles["benefits-header"]}>
      <div className={styles.text1}>
        <span className={styles.text}>{text1}</span>
        {!!icon ? <img src={icon} className={styles.icon} alt="icon" /> : null}
      </div>
      <div className={styles.text2}>{text2}</div>
    </div>
  );
};

export default index;
