import { useSelector } from "react-redux";
import styles from "./trackCardDelivery.module.scss";
import { getCardDetails, getSelectedCard } from "../../features/user/userSlice";
import { capitalizeFirsLetter } from "../../utils/functions";
import { RootState } from "../../app/store";
import dayjs from "dayjs";

const TrackCardDetails = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const card = useSelector(getSelectedCard)!;
  const cardDetails = useSelector(getCardDetails);
  const cardScheme = card?.scheme || "Visa";
  const cardImg = cardDetails?.image[cardScheme];
  const deliverySteps = useSelector(
    (state: RootState) => state.user.trackingDetails
  );

  return (
    <>
      <div className={styles.textContainer}>
        <span className={styles.cardTypeText1}>your </span>
        <span className={styles.cardTypeText2}>
          IndusInd {capitalizeFirsLetter(cardDetails.cardType)} Card
        </span>{" "}
        <span className={styles.cardTypeText1}>is on it's way!</span>
      </div>
      <div className={styles.cardWrapper}>
        <img
          className={styles.cardImg}
          src={cardImg}
          alt="Card"
          // onClick={handleViewCardClick}
        />
      </div>
      <div className={styles.detailContainer}>
        {deliverySteps?.status === "SUCCESS" && (
          <>
            <div className={styles.primaryText}>
              <div>Arriving On</div>
              <div className={styles.dateText}>
                {dayjs(deliverySteps?.expectedDeliveryDate).format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>
            <div className={styles.separator}></div>
          </>
        )}
        <div className={styles.primaryText}>
          <div>AWB Number</div>
          <div className={styles.AWBNumber}>{deliverySteps?.awbNumber}</div>
        </div>
      </div>
    </>
  );
};

export default TrackCardDetails;
