import React from "react";
import { useSelector } from "react-redux";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import type { RootState } from "../app/store";
import Home from "../pages/home/Home";
import AllCVPOffers from "../pages/allCVPOffers/allCVPOffers";
import AllCampaignOffers from "../pages/allCampaignOffers/allCampaignOffers";
import AllMerchantOffers from "../pages/allMerchantOffers/allMerchantOffers";
import CardSummary from "../pages/cardSummary/CardSummary";
import CvpOfferDetails from "../pages/cvpOfferDetails/cvpOfferDetails";
import CampaignOfferDetails from "../pages/campaignOfferDetails/campaignOfferDetails";
import MerchantOfferDetails from "../pages/merchantOfferDetails/merchantOfferDetails";
import PreLogin from "../components/preLogin/preLoginV2/preLogin";
import ManageCard from "../pages/manageCard/manageCard";
import ViewTransactions from "../pages/viewTransactions/ViewTransactions";
import ConvertToEmi from "../pages/convertToEMI/convertToEmi";
import EmiOverview from "../pages/convertToEMI/emiOverview";
import ResetCardPin from "../pages/resetCardPin/ResetCardPin";
import CustomerSupport from "../pages/customerSupport/CustomerSupport";
import ViewStatement from "../pages/viewStatement/ViewStatement";
import SessionExpired from "../pages/sessionExpired/SessionExpired";
import ErrorScreen from "../pages/errorScreen/ErrorScreen";
import CardControls from "../pages/manageCard/CardControls/CardControls";
import {
  PRE_LOGIN_ROUTE,
  HOME_ROUTE,
  CVP_OFFERS_ROUTE,
  CAMPAIGN_OFFERS_ROUTE,
  MERCHANT_OFFERS_ROUTE,
  CVP_OFFER_DETAILS_ROUTE,
  CAMPAIGN_OFFER_DETAILS_ROUTE,
  MERCHANT_OFFER_DETAILS_ROUTE,
  CARD_SUMMARY_ROUTE,
  NO_MATCH_ROUTE,
  CONVERT_TO_EMI_ROUTE,
  EMI_OVERVIEW_ROUTE,
  CUSTOMER_CARE_ROUTE,
  MANAGE_CARD_ROUTE,
  VIEW_TRANSACTIONS_ROUTE,
  VIEW_STATEMENTS_ROUTE,
  RESET_CARD_PIN_ROUTE,
  ERROR_SCREEN_ROUTE,
  CARD_CONTROLS_ROUTE,
  ACCOUNT_CLOSED_ROUTE,
  ALL_NUDGES_ROUTE,
  SPEND_ANALYTICS_ROUTE,
  SPEND_ANALYTICS_TRANSACTIONS_ROUTE,
  TRACK_CARD_DELIVERY,
  PREFETCH_FAILURE,
  ALL_WELCOME_OFFERS_ROUTE,
  WELCOME_OFFER_DETAIL_ROUTE,
  EMI_ELIGIBLE_TRANSACTIONS,
  CONVERT_OUTSTANDING_TO_EMI,
  CONVERT_OUTSTANDING_TO_EMI_TENURE_SELECTOR,
  CONVERT_OUTSTANDING_TO_EMI_OVERVIEW,
  INSTALL_SCREEN,
  CARD_BLOCKED,
  DEVICE_LIMIT,
  BROWSER_NOT_SUPPORTED_SCREEN,
  BROWSER_OLDER_VERSION,
  REGISTERED_DEVICES_ROUTE,
  INCOGNITO_DETECTED
} from "./ScreenRoutes";
import AccountClosed from "../pages/accountClosed/AccountClosed";
import AllNudges from "../components/nudges/allNudges";
import {
  getIsDeviceIdVerified,
  getSelectedAccount,
  getSelectedCard
} from "../features/user/userSlice";
import { AccountStatus } from "../apis/appApi/appApiTypes";
import SpendAnalytics from "../pages/spendAnalytics/SpendAnalytics";
import SpendAnalyticsTransactions from "../pages/spendAnalytics/SpendAnalyticsTransactions";
import * as InternalFlags from "../utils/internalFlags";
import TrackCardDelivery from "../pages/trackCardDelivery/TrackCardDelivery";
import PrefetchFailure from "../pages/preetchFailure/PrefetchFailure";
import AllWelcomeOffers from "../pages/allWelcomeOffers/AllWelcomeOffers";
import WelcomOfferDetails from "../pages/welcomeOfferDetails/welcomOfferDetails";
import EmiEligibleTransactions from "../pages/convertToEMI/emiEligibleTransactions";
import ConvertOutstandingToEmi from "../pages/convertOutstandingToEmi/ConvertOutstandingToEmi";
import TenureSelector from "../pages/convertOutstandingToEmi/TenureSelector";
import OutstandingEmiOverview from "../pages/convertOutstandingToEmi/EmiOverview";
import AppInstallScreen from "../pages/appInstallScreen/appInstallScreen";
import CardBlocked from "../pages/cardBlocked/cardBlocked";
import { getUserOSAndVersion } from "../utils/trackingFunctions";
import DeviceLimit from "../pages/deviceLimit/deviceLimit";
import BrowserNotSupportedScreen from "../pages/browserNotSupportedScreen/browserNotSupportedScreen";
import OlderVersion from "../pages/browserNotSupportedScreen/olderVersion";
import { getPWADisplayMode } from "../utils/functions";
import RegisteredDevices from "../pages/registeredDevices/registeredDevices";
import IncognitoScreen from "../pages/incognitoScreen/incognitoScreen";

const Routes = () => {
  const customer = useSelector((state: RootState) => state.user.customer);
  const isEligibleOutstandingToEMI = useSelector(
    (state: RootState) => state.outstandingToEmi?.eligibility?.isEligible
  );
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedCard = useSelector(getSelectedCard)!;

  let isRouteEnabled =
    selectedCard?.isPrimary &&
    !selectedCard?.isVariantUpgradeActive &&
    getPWADisplayMode() === "app";

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const account = useSelector(getSelectedAccount)!;
  const { token, wasLoggedIn } = useSelector(
    (state: RootState) => state.session
  );

  const userAgent = navigator.userAgent;

  const [deviceOs, osVersion] = getUserOSAndVersion(userAgent);
  const isDeviceIdVerified = useSelector(getIsDeviceIdVerified);

  return (
    <RouterRoutes>
      <>
        {!token && wasLoggedIn && isDeviceIdVerified ? (
          // user was logged in but later token was cleared due to session expiry
          <>
            <Route path={PRE_LOGIN_ROUTE} element={<PreLogin />} />
            <Route path={PREFETCH_FAILURE} element={<PrefetchFailure />} />
            <Route path={NO_MATCH_ROUTE} element={<SessionExpired />} />
            <Route path={CARD_BLOCKED} element={<CardBlocked />} />
          </>
        ) : (!token && !wasLoggedIn) ||
          // fresh visit on app
          (token && !customer) ? (
          // login is done but user store data is not yet fetched
          // (not showing non login screens yet as they require user store to be set)
          <>
            <Route path={PRE_LOGIN_ROUTE} element={<PreLogin />} />
            <Route path={PREFETCH_FAILURE} element={<PrefetchFailure />} />
            <Route path={NO_MATCH_ROUTE} element={<PreLogin />} />
            <Route path={CARD_BLOCKED} element={<CardBlocked />} />
            <Route path={DEVICE_LIMIT} element={<DeviceLimit />} />
            <Route
              path={BROWSER_NOT_SUPPORTED_SCREEN}
              element={<BrowserNotSupportedScreen />}
            />
            <Route path={BROWSER_OLDER_VERSION} element={<OlderVersion />} />
            <Route path={INSTALL_SCREEN} element={<AppInstallScreen />} />
            <Route path={INCOGNITO_DETECTED} element={<IncognitoScreen />} />
          </>
        ) : // login is done and user store data is fetched
        account && account.status === AccountStatus.CLOSED ? (
          <Route path={NO_MATCH_ROUTE} element={<AccountClosed />} />
        ) : (
          <>
            {isRouteEnabled && (
              <>
                <Route path={HOME_ROUTE} element={<Home />} />
                <Route
                  path={ALL_WELCOME_OFFERS_ROUTE}
                  element={<AllWelcomeOffers />}
                />
                <Route path={CVP_OFFERS_ROUTE} element={<AllCVPOffers />} />
                <Route
                  path={CAMPAIGN_OFFERS_ROUTE}
                  element={<AllCampaignOffers />}
                />
                <Route
                  path={MERCHANT_OFFERS_ROUTE}
                  element={<AllMerchantOffers />}
                />
                <Route
                  path={CVP_OFFER_DETAILS_ROUTE}
                  element={<CvpOfferDetails />}
                />
                <Route
                  path={WELCOME_OFFER_DETAIL_ROUTE}
                  element={<WelcomOfferDetails />}
                />
                <Route
                  path={TRACK_CARD_DELIVERY}
                  element={<TrackCardDelivery />}
                />
                <Route
                  path={CAMPAIGN_OFFER_DETAILS_ROUTE}
                  element={<CampaignOfferDetails />}
                />
                <Route
                  path={MERCHANT_OFFER_DETAILS_ROUTE}
                  element={<MerchantOfferDetails />}
                />
                {InternalFlags.P1 && (
                  <>
                    <Route
                      path={SPEND_ANALYTICS_ROUTE}
                      element={<SpendAnalytics />}
                    />
                    <Route
                      path={SPEND_ANALYTICS_TRANSACTIONS_ROUTE}
                      element={<SpendAnalyticsTransactions />}
                    />
                  </>
                )}
              </>
            )}
            {isRouteEnabled && (
              <>
                <Route path={CARD_SUMMARY_ROUTE} element={<CardSummary />} />
                <Route
                  path={CUSTOMER_CARE_ROUTE}
                  element={<CustomerSupport />}
                />
                <Route path={PREFETCH_FAILURE} element={<PrefetchFailure />} />
                <Route
                  path={ACCOUNT_CLOSED_ROUTE}
                  element={<AccountClosed />}
                />
                <Route
                  path={EMI_ELIGIBLE_TRANSACTIONS}
                  element={<EmiEligibleTransactions />}
                />
                <Route path={RESET_CARD_PIN_ROUTE} element={<ResetCardPin />} />
                <Route path={CARD_CONTROLS_ROUTE} element={<CardControls />} />
                <Route
                  path={REGISTERED_DEVICES_ROUTE}
                  element={<RegisteredDevices />}
                />
                {!selectedCard?.isVariantUpgradeActive && (
                  <Route
                    path={VIEW_TRANSACTIONS_ROUTE}
                    element={<ViewTransactions />}
                  />
                )}
                <Route path={MANAGE_CARD_ROUTE} element={<ManageCard />} />
                <Route
                  path={NO_MATCH_ROUTE}
                  element={isRouteEnabled ? <Home /> : <CardSummary />}
                />
              </>
            )}
            {isRouteEnabled && (
              <>
                <Route path={CONVERT_TO_EMI_ROUTE} element={<ConvertToEmi />} />
                <Route path={EMI_OVERVIEW_ROUTE} element={<EmiOverview />} />
              </>
            )}

            {isRouteEnabled && isEligibleOutstandingToEMI && (
              <>
                <Route
                  path={CONVERT_OUTSTANDING_TO_EMI}
                  element={<ConvertOutstandingToEmi />}
                />
                <Route
                  path={CONVERT_OUTSTANDING_TO_EMI_TENURE_SELECTOR}
                  element={<TenureSelector />}
                />
                <Route
                  path={CONVERT_OUTSTANDING_TO_EMI_OVERVIEW}
                  element={<OutstandingEmiOverview />}
                />
              </>
            )}
            <Route path={MANAGE_CARD_ROUTE} element={<ManageCard />} />

            {isRouteEnabled && (
              <Route path={VIEW_STATEMENTS_ROUTE} element={<ViewStatement />} />
            )}

            <Route path={ERROR_SCREEN_ROUTE} element={<ErrorScreen />} />

            {isRouteEnabled && (
              <Route path={ALL_NUDGES_ROUTE} element={<AllNudges />} />
            )}

            <Route path={INSTALL_SCREEN} element={<AppInstallScreen />} />
            <Route path={"/"} element={<AppInstallScreen />} />
            <Route path={"*"} element={<Navigate to={INSTALL_SCREEN} />} />
          </>
        )}
      </>
    </RouterRoutes>
  );
};

export default Routes;
