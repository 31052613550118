import { TouchEvent, useEffect, useRef, useState } from "react";
import styles from "./emiOverview.module.scss";
import { Button2, Header, ToastType, toast } from "../../components";
import {
  captureEvent,
  fallbackCopyTextToClipboard,
  formatAmount,
  formatAmount5
} from "../../utils/functions";
import ApiIds from "../../auth/ApiIds";
import { authenticateApi } from "../../auth/auth";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import checkList from "../../assets/svgs/convertOutstandingToEmi/checkList.svg";
import timer from "../../assets/svgs/convertOutstandingToEmi/Timer.svg";
import lightBulb from "../../assets/svgs/convertOutstandingToEmi/LightBulb.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { CARD_SUMMARY_ROUTE } from "../../routes/ScreenRoutes";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { useSelector } from "react-redux";
import { getSelectedAccount } from "../../features/user/userSlice";
import {
  ConvertToEmiRequest,
  UserActivityForOutstandingEmi,
  UserActivityRequestType
} from "../../apis/accountsApi/accountsApiTypes";
import OtpFailureScreen from "../../components/otpFailureScreen/OtpFailureScreens";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import copySvg from "../../assets/svgs/convertOutstandingToEmi/CopySimple.svg";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import { EventType, OutStandingToEmiNavigation } from "../../utils/enums";
import Checkbox from "../../components/checkbox/Checkbox";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

interface InstallmentSchedule {
  installmentNumber: number;
  interest: number;
  paymentDueDate: string;
  principal: number;
  remainingBalance: number;
}

const OutstandingEmiOverview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const account = useSelector(getSelectedAccount)!;

  const [isTncChecked, setIsTncChecked] = useState<boolean>(false);
  const [isPendingStateBottomSheet, setIsPendingStateBottomSheet] =
    useState<boolean>(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [otpFailureScreenBottomSheet, setOtpFailureScreenBottomSheet] =
    useState<boolean>(false);
  const [totalInterest, seTotalInterest] = useState<number>(0);
  const noOfTimesOtpSend = useRef<number>(1);
  const [isConvertToEmiRequestSuccess, setIsConvertToEmiRequestSuccess] =
    useState<boolean>(false);
  const [extRefId, setExtRefId] = useState<string>("");

  const { selectedEmiPlan, selectedAmount } = location.state;
  const repaymentSchedule = selectedEmiPlan.schedules;
  const requestSource = sessionStorage.getItem("requestSource") || "";

  const handleTncCheck = (value: boolean) => {
    setIsTncChecked(value);
  };

  const setUserActivity = async (data: UserActivityForOutstandingEmi) => {
    try {
      const response = await AccountsApi.setUserActivityForOutstandingToEmi(
        account.id,
        data
      );
      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while creating emi`
        });
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while creating emi`,
        error: error?.message,
        accountId: account.id,
        stackTrace: error.stack
      });
    }
  };

  function todayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      captureEvent({
        eventName: EVENT_NAME.OSTOEMI_OVERVIEW_PAGE_VERIFY_OTP_CLICK,
        eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
      });
      const authResult = await authenticateApi({
        apiId: ApiIds.CREATE_EMI,
        actionText: "to convert outstanding to EMI"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating create EMI api`
        });
        setOtpFailureScreenBottomSheet(true);
        setIsSubmitLoading(false);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        setIsSubmitLoading(false);
        return;
      }
      const emiRequestData: ConvertToEmiRequest = {
        tenureInMonths: selectedEmiPlan!.tenureInMonths,
        amount: selectedAmount,
        accountId: account.id,
        emiPostingDate: todayDate(),
        scope: "CURRENT_STATEMENT",
        emiStrategyId: selectedEmiPlan.emiStrategyId
      };

      const response = await AccountsApi.convertToEmi(
        account.id,
        authResult.apiToken,
        emiRequestData
      );

      if (response.status !== 200) {
        captureEvent({
          eventName: EVENT_NAME.OSTOEMI_REQUEST_PLACED_FAILURE,
          eventData: {
            requestSource,
            eventType: EventType.OSTOEMI_REQUEST_FAILURE
          }
        });
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while creating emi`
        });
        setIsSubmitLoading(false);
        setOtpFailureScreenBottomSheet(true);
        return;
      }

      captureEvent({
        eventName: EVENT_NAME.OSTOEMI_REQUEST_PLACED_SUCCESSFULLY,
        eventData: {
          requestSource,
          eventType: EventType.OSTOEMI_REQUEST_SUCCESS
        }
      });

      if (response.data?.extRefId) {
        setExtRefId(response.data?.extRefId);
      }
      setIsPendingStateBottomSheet(true);
      setIsConvertToEmiRequestSuccess(true);
      setUserActivity({
        emiRefId: response.data.emiRefId,
        requestType: UserActivityRequestType.ADD_USER_ACTIVITY
      });
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while creating emi`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      setOtpFailureScreenBottomSheet(true);
    }

    setIsSubmitLoading(false);
  };

  const handleOtpBackToHome = () => {
    noOfTimesOtpSend.current = 1;
    navigate(-3);
  };

  const handleOtpTryAgain = () => {
    setOtpFailureScreenBottomSheet(false);
    noOfTimesOtpSend.current = noOfTimesOtpSend.current + 1;
    handleSubmit();
  };
  const handleScroll = (event: TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const emiRepaymentSchedule = (repaymentSchedule: InstallmentSchedule[]) => {
    return (
      <div>
        <div className={styles.repaymentScheduleContainer}>
          <p>EMI Repayment Schedule</p>
          <hr className={styles.horizontalLine}></hr>

          <div>
            <div className={styles.repaymentScheduleHeader}>
              <span>Month</span>
              <span>EMI</span>
              <span>Interest</span>
              <span>Principal</span>
              <span>Balance</span>
            </div>
            <div
              className={styles.repaymentSchedule}
              onTouchMoveCapture={handleScroll}
            >
              {repaymentSchedule.map((ele, ind) => {
                return (
                  <div key={ind} className={styles.repaymentScheduleData}>
                    <span>
                      {ele.installmentNumber.toString().padStart(2, "0")}
                    </span>
                    <span className={styles.repaymentScheduleTd1}>
                      {formatAmount(selectedEmiPlan.emi)}
                    </span>
                    <span className={styles.repaymentScheduleTd2}>
                      {formatAmount(ele.interest)}
                    </span>
                    <span className={styles.repaymentScheduleTd3}>
                      {formatAmount(ele.principal)}
                    </span>
                    <span className={styles.repaymentScheduleTd4}>
                      {formatAmount(ele.remainingBalance)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.shadow}></div>
        </div>

        <div className={styles.btnContainer2}>
          <Button2
            text1="Close"
            customClassName={styles.repaymentScheduleBtn}
            onClick={() => setIsBottomSheetOpen(false)}
          />
        </div>
      </div>
    );
  };

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(extRefId);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Reference ID Copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(extRefId);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Reference ID Copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  const requestToConvertToEmiPendingState = () => {
    return (
      <div className={styles.convertToEmiResult}>
        <div className={styles.convertToEmiResultImage}>
          <img src={checkList} alt="checklist" />
        </div>
        <p className={styles.refNumber}>
          <span>Ref No:{extRefId}</span>
          <span onClick={debounceHandleCopy}>
            <img src={copySvg} alt="copy svg" />
          </span>
        </p>
        <div className={styles.text1}>
          <h1>Your request to convert</h1>
          <h1>outstanding to EMI is placed!</h1>
        </div>
        <div className={styles.timer}>
          <img src={timer} alt="timer" />
          <span>usually approved within 1-2 business days</span>
        </div>
        <hr className={styles.horizontalLine2}></hr>
        <div className={styles.text3}>
          <p>If the Purchase Outstanding converted to EMI is less than</p>
          <p>
            Total Outstanding Due in the previous statement, the Cardholder is
            required to
          </p>
          <p>
            make payments toward the Balance Outstanding to avoid interest
            charges.
          </p>
        </div>
        <div className={styles.bottomPart}>
          <hr className={styles.horizontalLine3}></hr>
          <div className={styles.maintainLimit}>
            <div className={styles.bulbImage}>
              <img src={lightBulb} alt="lightBulb" />
            </div>
            <div className={styles.maintainLimitDiv}>
              <p>
                Maintain available credit limit of{" "}
                <span>
                  {formatAmount(
                    selectedEmiPlan.emi + selectedEmiPlan.processingFees
                  )}
                </span>
              </p>
              <p>
                <span>
                  ({formatAmount(selectedEmiPlan.emi)} emi +{" "}
                  {formatAmount5(selectedEmiPlan.processingFees)} processing
                  fee)
                </span>{" "}
                to ensure conversion
              </p>
            </div>
          </div>
          <div>
            <div className={styles.btnContainer3}>
              <Button2
                text1="got it"
                customClassName={styles.repaymentScheduleBtn}
                onClick={() =>
                  navigate(CARD_SUMMARY_ROUTE, {
                    replace: true,
                    state: {
                      from: OutStandingToEmiNavigation.OUTSTADING_TO_EMI_OVERVIEW_TO_CARD_SUMMERY
                    }
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleTnCClick = () => {
    window.open(
      "https://www.indusind.com/in/en/personal/indus-easy-emi-tnc.html",
      "_blank"
    );
  };

  const HandleRepaymentScheduleBottomSheet = () => {
    captureEvent({
      eventName: EVENT_NAME.OSTOEMI_REPAYMENT_SHEDULE_CLICK,
      eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
    });
    setIsBottomSheetOpen(true);
  };

  useEffect(() => {
    const interest = repaymentSchedule?.reduce(
      (acc: number, ele: InstallmentSchedule) => {
        return acc + ele.interest;
      },
      0
    );
    seTotalInterest(interest);
  }, []);

  useEffect(() => {
    if (isBottomSheetOpen) {
      captureEvent({
        eventName: EVENT_NAME.VIEW_OSTOEMI_REPAYMENT_SCHEDULE_BOTTOM_SHEET,
        eventData: { requestSource, eventType: EventType.PAGE_VIEW }
      });
    }
    if (isPendingStateBottomSheet) {
      captureEvent({
        eventName: EVENT_NAME.VIEW_OSTOEMI_REQUEST_PLACED_BOTTOM_SHEET,
        eventData: { requestSource, eventType: EventType.PAGE_VIEW }
      });
    }
  }, [isBottomSheetOpen, isPendingStateBottomSheet]);

  useEffect(() => {
    captureEvent({
      eventName: EVENT_NAME.VIEW_OSTOEMI_OVERVIEW_PAGE,
      eventData: { requestSource, eventType: EventType.PAGE_VIEW }
    });
  }, []);

  useEffect(() => {
    if (!isPendingStateBottomSheet && isConvertToEmiRequestSuccess) {
      navigate(CARD_SUMMARY_ROUTE, {
        replace: true,
        state: {
          from: OutStandingToEmiNavigation.OUTSTADING_TO_EMI_OVERVIEW_TO_CARD_SUMMERY
        }
      });
    }
  }, [isPendingStateBottomSheet, isConvertToEmiRequestSuccess]);

  return (
    <div>
      <div className={styles.header}>
        <Header
          label="EMI Overview"
          bgColor="#141414"
          // customClassName={styles.header}
        />
        <hr className={styles.horizontalLine}></hr>
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.emiOverviewContainer}>
          <div className={styles.emiAmount}>
            <span>EMI Amount</span>
            <span>{formatAmount(selectedEmiPlan.emi)}</span>
          </div>
          <div>
            <span>Amount</span>
            <span>{formatAmount(selectedAmount)}</span>
          </div>
          <div>
            <span>Tenure</span>
            <span>{selectedEmiPlan.tenureInMonths} Months</span>
          </div>
          <div>
            <span className={styles.interestRate}>
              <span>Interest</span>
              <span>
                ({(selectedEmiPlan.interestRateBps / 100).toFixed(2)} % p.a)
              </span>
            </span>
            <span>{formatAmount(totalInterest)}</span>
          </div>
          <div>
            <span>Processing fee</span>
            <span>{formatAmount(selectedEmiPlan.processingFees)}</span>
          </div>
          <div>
            <span className={styles.preClosure}>
              <span>Pre-closure Fee </span>
            </span>
            <span> {(selectedEmiPlan?.foreclosureFees || 0).toFixed(2)}%</span>
          </div>
          <hr className={styles.horizontalLine5}></hr>
          <p
            className={styles.viewEmiRepayment}
            onClick={HandleRepaymentScheduleBottomSheet}
          >
            View EMI Repayment Schedule
          </p>
        </div>

        <div className={styles.btnContainer}>
          <div className={styles.tncContainer}>
            <Checkbox isChecked={isTncChecked} onChange={handleTncCheck} />
            <div className={styles.tncText}>
              I have read and agree to{" "}
              <span onClick={handleTnCClick}>Terms & Conditions</span> <br />
              and agree to convert this amount to EMI
            </div>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <div className={styles.btnContainer2}>
            <hr className={styles.horizontalLine2}></hr>
            <p>
              Govt. taxes will be applicable on the Interest Component of the
              EMI, Processing Fee and Pre-Closure Fee as notified by the Govt.
              of India.
            </p>
          </div>
          <Button2
            text1="verify"
            text2="OTP"
            customClassName={styles.button}
            onClick={handleSubmit}
            disabled={!isTncChecked}
            isLoading={isSubmitLoading}
          />
        </div>
      </div>
      <BottomsheetComponent
        isBottomSheetOpen={isBottomSheetOpen}
        setIsBottomSheetOpen={setIsBottomSheetOpen}
        render={emiRepaymentSchedule(repaymentSchedule)}
      />
      <BottomsheetComponent
        isBottomSheetOpen={isPendingStateBottomSheet}
        setIsBottomSheetOpen={setIsPendingStateBottomSheet}
        render={requestToConvertToEmiPendingState()}
      />
      <BottomsheetComponent
        isBottomSheetOpen={otpFailureScreenBottomSheet}
        setIsBottomSheetOpen={setOtpFailureScreenBottomSheet}
        render={
          <OtpFailureScreen
            onBackToHome={handleOtpBackToHome}
            onTryAgain={handleOtpTryAgain}
            showBackToHome={noOfTimesOtpSend.current === 2}
          />
        }
      />
    </div>
  );
};

export default OutstandingEmiOverview;
