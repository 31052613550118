import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  SessionState,
  SetSessionActionPayload
} from "./sessionSliceTypes";

const initialState: SessionState = {
  token: null,
  pciEncryptionKey: null,
  pciEncryptionIv: null,
  wasLoggedIn: false
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SetSessionActionPayload>) => {
      const { token, metaData, pciEncryptionKey, pciEncryptionIv } =
        action.payload;

      sessionStorage.setItem("sessionToken", token);
      sessionStorage.setItem("sessionMetadata", metaData);

      state.token = token;
      state.pciEncryptionKey = pciEncryptionKey;
      state.pciEncryptionIv = pciEncryptionIv;
    },
    clearSession: (state) => {
      sessionStorage.removeItem("sessionToken");
      sessionStorage.removeItem("sessionMetadata");

      state.token = null;
      state.pciEncryptionKey = null;
      state.pciEncryptionIv = null;
    },
    setWasLoggedIn: (state) => {
      state.wasLoggedIn = true;
    }
  }
});

export const { setSession, clearSession, setWasLoggedIn } =
  sessionSlice.actions;

export default sessionSlice.reducer;
