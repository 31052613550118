import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
import "swiper/swiper-bundle.css";
import styles from "./carousel.module.scss";

SwiperCore.use([Navigation]);

interface CarouselProps {
  items: React.JSX.Element[];
  slidesPerView?: number;
  initialSlide?: number;
}

const Carousel = (props: CarouselProps) => {
  const { items, slidesPerView, initialSlide = 1 } = props;
  return (
    <Swiper
      slidesPerView={items.length > 1 ? slidesPerView ?? 1.3 : 1.1}
      spaceBetween={10}
      navigation={false}
      initialSlide={initialSlide}
      // pagination={{ clickable: true }}
      pagination={false}
      className={styles.carousel}
      centeredSlides={true}
      loop={false}
    >
      <style>{`
      .swiper{
        overflow: visible
      }
      `}</style>
      {items?.map((item, index) => (
        <SwiperSlide key={index} className={styles.slide}>
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
