import { Header } from "../../components";
import DeliveryTracker from "../../components/deliveryTracker/DeliveryTracker";
import CustomerSupport from "./CustomerSupport";
import TrackCardDetails from "./TrackCardDetails";
import styles from "./trackCardDelivery.module.scss";

const TrackCardDelivery = () => {
  return (
    <div style={{ position: "relative", zIndex: "10" }}>
      <Header label={`Track Card Delivery`} bgColor="#141414" />
      <div className={styles.line}></div>
      <TrackCardDetails />
      <DeliveryTracker direction="vertical" />
      <CustomerSupport />
    </div>
  );
};

export default TrackCardDelivery;
