import React from "react";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import { Button2 } from "../button/Button";
import { CVP_OFFERS_ROUTE } from "../../routes/ScreenRoutes";
import { isFestiveOffersUIEnabled } from "../../utils/internalFlags";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { captureEvent } from "../../utils/functions";

interface CarouselProps {
  items: React.JSX.Element[];
  slidesPerView?: number;
  showButton?: boolean;
  banner?: boolean;
}
const App = (props: CarouselProps) => {
  const { items, slidesPerView, showButton = true, banner = false } = props;

  const navigate = useNavigate();

  const onButtonClick = () => {
    captureEvent({ eventName: EVENT_NAME.ALL_CVP_OFFERS_CLICK });
    navigate(CVP_OFFERS_ROUTE);
  };
  return (
    <>
      <Swiper
        effect={!banner ? "coverflow" : "slide"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={slidesPerView ?? "auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }}
        initialSlide={!!slidesPerView ? 0 : 1}
        loop={!banner}
        pagination={
          !banner
            ? false
            : {
                clickable: true
              }
        }
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper swiper1"
        slideActiveClass="activeSlide"
      >
        {items?.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide1">
            {item}
          </SwiperSlide>
        ))}
        {/* <div className="div-with-shadow"></div> */}
        {!banner && !isFestiveOffersUIEnabled ? (
          <div className="bottom-div"></div>
        ) : (
          <div className="empty-bottom-div"></div>
        )}
      </Swiper>
      {showButton ? (
        <div className="cvpBtn">
          <Button2 text1="all" text2="benefits" onClick={onButtonClick} />
        </div>
      ) : null}
    </>
  );
};

export default App;
