/* eslint-disable  react/no-unstable-nested-components , @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../app/store";
import { ToastType, ToggleButton, toast } from "../../components";
import Header from "../../components/header/header";
import SearchBarComponent from "../../components/searchBar/searchBar";
import styles from "./styles.module.scss";
import SingleOffer from "./singleOffer";
import type { GetBenefitsForAccountResponseElement } from "../../apis/benefitsApi/benefitsApiTypes";
import {
  BenefitOutcomeType,
  BenefitSortBy,
  BenefitSortOrder,
  BenefitStatus,
  BenefitType,
  OfferType
} from "../../apis/benefitsApi/benefitsApiTypes";
import downArrow from "../../assets/svgs/down.svg";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import cactus from "../../assets/svgs/cactus.svg";
import { Tag } from "../../apis/prefrencesApi/preferencesApiTypes";
import dayjs from "dayjs";
import {
  EXPIRING_SOON_OFFERS_DIFF_IN_DAYS,
  NEW_OFFERS_DIFF_IN_DAYS
} from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { FavouriteBottomSheet } from "../../components/preLogin/preLogin";
import PreferencesApi from "../../apis/prefrencesApi/PreferencesApi";
import {
  setAccountPreferences,
  setMerchantBenefits
} from "../../features/benefits/benefitsSlice";
import BenefitsApi from "../../apis/benefitsApi/BenefitsApi";
import { getIssuerId } from "../../data/config";
import { parseUnicodeInString } from "../../utils/functions";

enum FilterName {
  NONE = "NONE",
  NEW = "NEW",
  EXPIRING_SOON = "EXPIRING_SOON",
  OUTCOME_TYPE = "OUTCOME_TYPE",
  TAG = "TAG"
}

const AllMerchantOffers = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedAccountId = useSelector(
    (state: RootState) => state.user.selectedAccountId
  );
  const selectedProgramId = useSelector(
    (state: RootState) => state.user.selectedProgramId
  );
  const benefits = useSelector((state: RootState) => state.benefits);
  const programPreferences = useSelector(
    (state: RootState) => state.benefits.programPreferences
  );
  const accountPreferences = useSelector(
    (state: RootState) => state.benefits.accountPreferences
  );

  const [isFavoritesBottomSheetOpen, setIsFavoritesBottomSheetOpen] =
    useState<boolean>(location.state?.openBottomSheet || false);
  const [isSavePreferencesLoading, setIsSavePreferencesLoading] =
    useState<boolean>(false);
  const [selectedFilterName, setSelectedFilterName] = useState<FilterName>(
    FilterName.NONE
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredBenefits, setFilteredBenefits] = useState<
    GetBenefitsForAccountResponseElement[]
  >([]);
  const [filtersBottomSheetTrigger, setFiltersBottomSheetTrigger] =
    useState<FilterName | null>(null);
  const [isFiltersBottomSheetOpen, setIsFiltersBottomSheetOpen] =
    useState<boolean>(false);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(
    (accountPreferences?.tags || []).map((tag) => tag.id)
  );
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const [, /* selectedOutcomeType */ setSelectedOutcomeType] =
    useState<BenefitOutcomeType | null>(null);

  useEffect(() => {
    if (location?.state?.selectedTag) {
      const selectedTag = location?.state?.selectedTag;

      const filterTag = benefits.programPreferences!.tags.find((tag: Tag) =>
        tag.name.toLowerCase().includes(selectedTag.displayName.toLowerCase())
      );

      applyFilter(FilterName.TAG, null, filterTag);
    }
  }, [location?.state?.selectedTag?.filterName]);

  const merchantBenefits = benefits.merchantBenefits.benefits;

  const handleBottomSheetCloseClick = () => {
    setIsFiltersBottomSheetOpen(false);
  };

  const handleFiltersBottomSheetOpen = (filterName: FilterName) => {
    // note the filter that triggrered opening
    setFiltersBottomSheetTrigger(filterName);
    setIsFiltersBottomSheetOpen(true);
  };

  const handleFiltersBottomSheetClose = (
    outcomeType: BenefitOutcomeType | null,
    tag: Tag | null
  ) => {
    setFiltersBottomSheetTrigger(null);
    setIsFiltersBottomSheetOpen(false);

    // if something was selected, only then apply filter
    if (outcomeType) {
      applyFilter(FilterName.OUTCOME_TYPE, outcomeType);
    }

    if (tag) {
      applyFilter(FilterName.TAG, null, tag);
    }
  };

  const handleOutcomeTypeClick = (outcomeType: BenefitOutcomeType) => {
    handleFiltersBottomSheetClose(outcomeType, null);
  };

  const handleTagClick = (tag: Tag) => {
    handleFiltersBottomSheetClose(null, tag);
  };

  const clearFilter = () => {
    setSelectedFilterName(FilterName.NONE);
    setFilteredBenefits([]);
    setFiltersBottomSheetTrigger(null);
  };

  const applyFilter = (
    filterName: FilterName,
    outcomeType?: BenefitOutcomeType | null,
    tag?: Tag | null
  ) => {
    clearFilter();

    let filterResult: GetBenefitsForAccountResponseElement[] = [];

    switch (filterName) {
      case FilterName.NEW:
        filterResult = merchantBenefits.filter(
          (benefit) =>
            dayjs().diff(dayjs(benefit.activeFrom), "day") <
            NEW_OFFERS_DIFF_IN_DAYS
        );
        break;

      case FilterName.EXPIRING_SOON:
        filterResult = merchantBenefits.filter(
          (benefit) =>
            dayjs(benefit.benefitExpiry).diff(dayjs(), "day") <
            EXPIRING_SOON_OFFERS_DIFF_IN_DAYS
        );
        break;

      case FilterName.OUTCOME_TYPE:
        filterResult = merchantBenefits.filter(
          (benefit) => benefit.outcomeType === outcomeType
        );
        setSelectedOutcomeType(outcomeType!);
        break;

      case FilterName.TAG:
        if (!tag) return;
        filterResult = merchantBenefits.filter((benefit) =>
          benefit.tags.some((benefitTagName) => benefitTagName === tag!.name)
        );
        setSelectedTag(tag!);
        break;
    }

    // select filter only when it is to be applied
    setSelectedFilterName(filterName);
    setFilteredBenefits(filterResult);
  };

  const handleFilterNameClick = (filterName: FilterName) => {
    if (filterName === selectedFilterName) {
      // if filter is already applied
      clearFilter();
      return;
    }

    switch (filterName) {
      case FilterName.NEW:
        // no bottom sheet to open for selection, directly apply filter
        applyFilter(FilterName.NEW);
        break;

      case FilterName.EXPIRING_SOON:
        applyFilter(FilterName.EXPIRING_SOON);
        break;

      case FilterName.OUTCOME_TYPE:
        console.log(
          "opening outcome type current selectedfilter",
          selectedFilterName
        );
        // open bottom sheet for selection, apply filter on bottom sheet close only if something was selected
        handleFiltersBottomSheetOpen(filterName);
        break;

      case FilterName.TAG:
        handleFiltersBottomSheetOpen(filterName);
        break;
    }
  };

  const offerTypeBottomSheetOpen = () => {
    const title =
      filtersBottomSheetTrigger === FilterName.OUTCOME_TYPE
        ? "offer type"
        : filtersBottomSheetTrigger === FilterName.TAG
        ? "categories"
        : "";

    const description =
      filtersBottomSheetTrigger === FilterName.OUTCOME_TYPE
        ? "Select an offer type to view"
        : filtersBottomSheetTrigger === FilterName.TAG
        ? "Select a category to view"
        : "";

    const getBenefitsCountByOutcomeType = (outcomeType: BenefitOutcomeType) =>
      merchantBenefits.filter((benefit) => benefit.outcomeType === outcomeType)
        .length;

    const getBenefitsCountByTag = (tag: Tag) =>
      merchantBenefits.filter((benefit) =>
        benefit.tags.find((benefitTagName) => benefitTagName === tag.name)
      ).length;

    return (
      <div className={styles.offerBottomSheet}>
        <div className={styles.offerBottomSheetContainer}>
          <div className={styles.offerBottomSheetHeader}>{title}</div>
          <div className={styles.offerBottomSheetText}>{description}</div>
          {filtersBottomSheetTrigger === FilterName.OUTCOME_TYPE && (
            <>
              <div
                onClick={() =>
                  handleOutcomeTypeClick(BenefitOutcomeType.REWARDS)
                }
                className={styles.offerType}
              >
                <div className={styles.offerTypeText}>Rewards</div>
                <div className={styles.offerCount}>
                  {getBenefitsCountByOutcomeType(BenefitOutcomeType.REWARDS)}{" "}
                  offers
                </div>
              </div>
              <hr className={styles.horizontalLine}></hr>
              <div
                onClick={() =>
                  handleOutcomeTypeClick(BenefitOutcomeType.CASHBACK)
                }
                className={styles.offerType}
              >
                <div className={styles.offerTypeText}>Cashbacks</div>
                <div className={styles.offerCount}>
                  {getBenefitsCountByOutcomeType(BenefitOutcomeType.CASHBACK)}{" "}
                  offers
                </div>
              </div>
              <hr className={styles.horizontalLine}></hr>
              <div
                onClick={() =>
                  handleOutcomeTypeClick(BenefitOutcomeType.INSTANT_DISCOUNT)
                }
                className={styles.offerType}
              >
                <div className={styles.offerTypeText}>Discounts</div>
                <div className={styles.offerCount}>
                  {getBenefitsCountByOutcomeType(
                    BenefitOutcomeType.INSTANT_DISCOUNT
                  )}{" "}
                  offers
                </div>
              </div>
              <hr className={styles.horizontalLine}></hr>
              <div
                onClick={() =>
                  handleOutcomeTypeClick(BenefitOutcomeType.VOUCHER)
                }
                className={styles.offerType}
              >
                <div className={styles.offerTypeText}>Vouchers</div>
                <div className={styles.offerCount}>
                  {getBenefitsCountByOutcomeType(BenefitOutcomeType.VOUCHER)}{" "}
                  offers
                </div>
              </div>
              <hr className={styles.horizontalLine}></hr>
            </>
          )}
          {filtersBottomSheetTrigger === FilterName.TAG &&
            benefits.programPreferences!.tags.map((tag: Tag) => (
              <div key={tag.id}>
                <div
                  onClick={() => handleTagClick(tag)}
                  className={styles.offerType}
                >
                  <div className={styles.offerTypeText}>
                    {parseUnicodeInString(tag.name)}
                  </div>
                  <div className={styles.offerCount}>
                    {getBenefitsCountByTag(tag)} offers
                  </div>
                </div>
                <hr className={styles.horizontalLine}></hr>
              </div>
            ))}
        </div>
        <div className={styles.closeButtonContainer}>
          <div
            onClick={() => handleBottomSheetCloseClick()}
            className={styles.closeButton}
          >
            <div className={styles.closeButtonText}>close</div>
          </div>
        </div>
      </div>
    );
  };

  let benefitsToDisplay =
    selectedFilterName === FilterName.NONE
      ? merchantBenefits
      : filteredBenefits;

  if (searchQuery.trim()) {
    benefitsToDisplay = benefitsToDisplay.filter((benefit) => {
      const offerDetails = JSON.parse(benefit.offerDetails);

      return (
        benefit.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // benefit.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        offerDetails?.text1
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        offerDetails?.text2?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }

  const handleFavoriteTagClick = (tag: Tag) => {
    if (selectedTagIds.includes(tag.id)) {
      setSelectedTagIds(
        selectedTagIds.filter((selectedTagId) => selectedTagId !== tag.id)
      );
    } else {
      setSelectedTagIds([...selectedTagIds, tag.id]);
    }
  };

  const handleSkipPreferencesClick = () => {
    setIsFavoritesBottomSheetOpen(false);
  };

  const handleSavePreferencesClick = async () => {
    setIsSavePreferencesLoading(true);

    try {
      const selectedTagNames = programPreferences!.tags
        .filter((tag: Tag) => selectedTagIds.includes(tag.id))
        .map((selectedTag: Tag) => selectedTag.name);

      const response = await PreferencesApi.addAccounTags(null, {
        accountId: selectedAccountId!,
        programId: selectedProgramId!,
        tagNames: selectedTagNames,
        issuerId: getIssuerId()
      });

      if (response.status !== 200) {
        console.log("An error occurred while adding account tags");
        setIsSavePreferencesLoading(false);
        toast(
          ToastType.ERROR,
          "We could not save your favourites. Please try again"
        );
        return;
      }

      const merchantResponse = await BenefitsApi.getBenefitsForAccount({
        accountId: selectedAccountId!,
        programId: selectedProgramId!,
        status: BenefitStatus.ACTIVE,
        preferredTagsSort: true,
        sortBy: BenefitSortBy.ACTIVE_TO,
        order: BenefitSortOrder.ASC,
        offerType: OfferType.MERCHANT_OFFERS,
        benefitType: BenefitType.MERCHANT_OFFERS
      });

      if (merchantResponse.status !== 200) {
        console.log("An error occurred while getting merchant benefits");
        setIsSavePreferencesLoading(false);
        toast(
          ToastType.ERROR,
          "We are unable to display your favourites. Please try again"
        );
        return;
      }

      dispatch(setMerchantBenefits(merchantResponse.data));
      dispatch(setAccountPreferences(response.data));
    } catch (error: any) {
      console.error("An exception occurred adding account tags");
      toast(
        ToastType.ERROR,
        "We are unable to process your request. Please try again later"
      );
    }

    setIsSavePreferencesLoading(false);
    setIsFavoritesBottomSheetOpen(false);
  };

  const NoOffer = () => {
    return (
      <div className={styles.noOfferContainer}>
        <img src={cactus} alt="No Offers" className={styles.noOfferImage} />
        <span className={styles.noOfferSpan}>no offers found</span>
      </div>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.topContainer}>
          <Header
            label="Explore Offers"
            icon="/images/fav.svg"
            iconOnClick={() => setIsFavoritesBottomSheetOpen(true)}
          />
          <SearchBarComponent
            placeholder="Search offers"
            onSearch={(query) => {
              setSearchQuery(query);
            }}
            icon="images/MagnifyingGlass.svg"
          />
          <div className={styles.btnContainer}>
            <div className={styles.btnStyle}>
              <ToggleButton
                text={
                  selectedFilterName === FilterName.TAG
                    ? parseUnicodeInString(selectedTag!.name)
                    : "Categories"
                }
                isSelected={selectedFilterName === FilterName.TAG}
                onClick={() => handleFilterNameClick(FilterName.TAG)}
                iconRight={
                  selectedFilterName === FilterName.TAG ? undefined : downArrow
                }
              />
            </div>
            {/* <div className={styles.btnStyle}>
            <ToggleButton
              text={
                selectedFilterName === FilterName.OUTCOME_TYPE
                  ? selectedOutcomeType!
                  : "Offer Type"
              }
              isSelected={selectedFilterName === FilterName.OUTCOME_TYPE}
              onClick={() => handleFilterNameClick(FilterName.OUTCOME_TYPE)}
              iconRight={
                selectedFilterName === FilterName.OUTCOME_TYPE
                  ? undefined
                  : downArrow
              }
            />
          </div> */}
            <div className={styles.btnStyle}>
              <ToggleButton
                text="New"
                isSelected={selectedFilterName === FilterName.NEW}
                onClick={() => handleFilterNameClick(FilterName.NEW)}
                icon="/images/sample/starr.svg"
              />
            </div>
            <div className={styles.btnStyle}>
              <ToggleButton
                text="Expiring Soon"
                isSelected={selectedFilterName === FilterName.EXPIRING_SOON}
                onClick={() => handleFilterNameClick(FilterName.EXPIRING_SOON)}
                icon="/images/HourglassHigh.svg"
              />
            </div>
          </div>
          <div className={styles.divider} />
          {/* {benefitsToDisplay?.length === 0 ? (
            <div className={styles.divider} />
          ) : null} */}
        </div>
        {benefitsToDisplay?.length > 0 ? (
          <div className={styles.singleOffersListContainer}>
            {benefitsToDisplay?.map((benefit) => {
              const offerDetails = JSON.parse(benefit.offerDetails);

              return (
                <SingleOffer
                  key={benefit.benefitId}
                  id={benefit.benefitId}
                  bgImage={benefit.illustrationUrl}
                  logo={benefit.logoUrl}
                  title={benefit.displayTitle}
                  description={benefit.displayDescription}
                  validity={new Date(benefit.activeTo).toLocaleDateString(
                    "en-IN"
                  )}
                  hasDetails={
                    offerDetails.redeemSteps || offerDetails.tncConditions
                  }
                />
              );
            })}
          </div>
        ) : (
          <NoOffer />
        )}
        <BottomsheetComponent
          isBottomSheetOpen={isFiltersBottomSheetOpen}
          setIsBottomSheetOpen={setIsFiltersBottomSheetOpen}
          render={offerTypeBottomSheetOpen()}
        />
        <BottomsheetComponent
          isBottomSheetOpen={isFavoritesBottomSheetOpen}
          setIsBottomSheetOpen={setIsFavoritesBottomSheetOpen}
          render={FavouriteBottomSheet({
            programPreferences,
            handleTagClick: handleFavoriteTagClick,
            selectedTagIds,
            handleSkipPreferencesClick,
            isSavePreferencesLoading,
            handleSavePreferencesClick
          })}
        />
      </div>
    </>
  );
};

export default AllMerchantOffers;
