import type { AxiosResponse } from "axios";
import type {
  GetBenefitsForAccountRequest,
  GetBenefitsForAccountResponse
} from "./benefitsApiTypes";
import Api from "../Api";
import { GET_BENEFITS_FOR_ACCOUNT_ROUTE } from "../ApiRoutes";

const BenefitsApi = {
  getBenefitsForAccount: (
    data: GetBenefitsForAccountRequest
  ): Promise<AxiosResponse<GetBenefitsForAccountResponse>> =>
    Api.post(GET_BENEFITS_FOR_ACCOUNT_ROUTE, data)
};

export default BenefitsApi;
