/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./convertToEmiStyles.module.scss";
import { Button2, Header, ToastType, toast } from "../../components";
import {
  captureEvent,
  formatAmount,
  getTransactionDate
} from "../../utils/functions";
import {
  CARD_SUMMARY_ROUTE,
  EMI_OVERVIEW_ROUTE
} from "../../routes/ScreenRoutes";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import { getSelectedAccount } from "../../features/user/userSlice";
import { emiGold as emiGoldIcon } from "../../assets/svgs";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import {
  type EmiPlan,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes";
import Loader from "../../components/loader/loader";
import TransactionLogo from "../../components/transaction/TransactionLogo";
import TimeSelector from "../../components/numberPicker/TimeSelector";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { EventType } from "../../utils/enums";

export interface NavigateToConvertToEmiState {
  transaction: Transaction;
}

const ConvertToEmi = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const account = useSelector(getSelectedAccount)!;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emiPlans, setEmiPlans] = useState<EmiPlan[]>([]);
  const [selectedEmiPlan, setSelectedEmiPlan] = useState<EmiPlan | null>(null);

  const [valueGroups, setValueGroups] = useState<{ months: string }>({
    months: ""
  });

  const [transaction, setTransaction] = useState<Transaction | null>(
    location?.state?.transaction
  );

  const getTransactionById = async () => {
    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.EMI_CONFIG,
        actionText: "To get transaction by Id"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get transaction by id api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      const response = await AccountsApi.getTransactionById(
        location?.state?.transactionId,
        authResult.apiToken
      );
      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting transaction with transaction id ${location.state.transactionId}`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display transaction details. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }
      if (response?.data) {
        setTransaction(response.data[0]);
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting transaction`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display transaction details. Please try again"
      );
      navigate(CARD_SUMMARY_ROUTE);
    }
  };

  const getEmiConfig = useCallback(async () => {
    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.EMI_CONFIG,
        actionText: "to show emi plans"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get emi config api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      const response = await AccountsApi.getEmiConfig(
        account.id,
        authResult.apiToken,
        {
          accountId: account.id,
          amount: Number(transaction!.amount),
          txnRefId: transaction!.id
        }
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting emi config`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display your active EMIs. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      const emiPlans = response?.data?.emiPlans?.reverse();

      if (!emiPlans || emiPlans.length < 1) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting emi config. Emi config is invalid.`
        });
        toast(
          ToastType.ERROR,
          "Your request for EMI conversion could not be processed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      setEmiPlans(emiPlans);
      setSelectedEmiPlan(emiPlans[0]);

      // set first emi plan's tenure as value to be passed to tenure selector
      setValueGroups({
        // pass string as "x months"
        months: emiPlans[0].tenureInMonths + " months"
      });
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting emi config`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display your active EMIs. Please try again"
      );
      navigate(CARD_SUMMARY_ROUTE);
    }

    setIsLoading(false);
  }, [navigate, account.id, transaction]);

  useEffect(() => {
    // if location.state is not defined then navigate
    if (!location.state) {
      navigate(CARD_SUMMARY_ROUTE);
      return;
    }
    if (location?.state?.transactionId) {
      getTransactionById();
    }

    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.VIEW_TXNTOEMI_SELECT_TENURE_PAGE,
      eventData: { requestSource, eventType: EventType.PAGE_VIEW }
    });
  }, []);

  useEffect(() => {
    if (transaction) {
      getEmiConfig();
    }
  }, [getEmiConfig, transaction]);

  // when a tenure is selected in selector
  useEffect(() => {
    const emiPlan = emiPlans.find(
      (emiPlan) =>
        // get tenure x from string "x months"
        emiPlan.tenureInMonths === Number(valueGroups.months.split(" ")[0])
    );

    setSelectedEmiPlan(emiPlan || null);
  }, [valueGroups]);

  const handleSubmit = () => {
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.TXNTOEMI_SELECT_TENURE_PAGE_PROCEED_BUTTON_CLICK,
      eventData: { requestSource, eventType: EventType.BUTTON_CLICK }
    });
    navigate(EMI_OVERVIEW_ROUTE, {
      state: { transaction, emiPlan: selectedEmiPlan }
    });
  };

  localStorage.setItem("transactionPostingDate", transaction?.postingDate!);

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="convert to Emi" />
    </div>
  ) : (
    <div>
      <Header label="Convert to EMI" bgColor="#141414" />
      <div className={styles.contentContainer}>
        <div className={styles.overlapContainer}>
          {!!transaction!.identifiedMerchantLogo ? (
            <img
              className={styles.logo}
              src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
              alt="logo"
            />
          ) : (
            <TransactionLogo txnType={transaction!.txnType} />
          )}
          <div className={styles.txnDetail}>
            <div className={styles.txnDetail1}>{transaction!.description}</div>
            <div className={styles.txnDetail2}>
              {getTransactionDate(
                transaction!.transactionDate,
                transaction!.postingDate || ""
              )}
            </div>
          </div>
          <div className={styles.amount}>
            {formatAmount(transaction!.amount)}
          </div>
        </div>
        <div className={styles.headerText}>
          <span className={styles.headerText1}>Select </span>
          <span className={styles.headerText2}>tenure</span>
        </div>
        <div className={styles.amountContainer}>
          <img className={styles.icon} src={emiGoldIcon} />
          <div>
            <span className={styles.amountText1}>Amount Payable </span>
          </div>
          <div className={styles.valueContainer}>
            <span className={styles.amountText2}>
              {formatAmount(selectedEmiPlan?.emi || 0)}
            </span>
            <span className={styles.amountText3}> / month</span>
          </div>
        </div>
        <div className={styles.tenureContainer}>
          <div className={styles.divider}></div>
          <TimeSelector
            valueGroups={valueGroups}
            monthGroups={emiPlans.map(
              (emiPlan) => emiPlan.tenureInMonths + " months"
            )}
            setValueGroups={setValueGroups}
          />
          <div className={styles.divider1}></div>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button2
          text1="Proceed"
          customClassName={styles.button}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ConvertToEmi;
