import styles from "./loaderStyles.module.scss";
import loader from "../../assets/svgs/loader.svg";
interface LoaderProps {
  icon?: string;
  text1?: string;
  text2?: string;
  noTextStyle?: boolean;
  text1Style?: React.CSSProperties;
  text2Style?: React.CSSProperties;
}

const Loader = ({
  icon,
  text1,
  text2,
  text1Style,
  text2Style,
  noTextStyle = false
}: LoaderProps) => {
  return (
    <div className={styles.loader}>
      <div className={styles.loaderContent}>
        <img
          src={icon || loader}
          className={`${styles.loaderImg} ${styles.bounce2}`}
          alt="loader"
        />
        <div style={text1Style} className={styles.loaderText1}>
          {noTextStyle ? text1 : text1?.toLowerCase()}
        </div>
        <div style={text2Style} className={styles.loaderText2}>
          {noTextStyle ? text2 : text2?.toLowerCase()}
        </div>
      </div>
    </div>
  );
};

export default Loader;
