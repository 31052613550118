import styles from "./DeliveryTracker.module.scss";
import arrowCircle1 from "../../assets/svgs/ArrowCircleUpRight1.svg";

import bankActive from "../../assets/svgs/Tracking/Active/bank-active.svg";
import boxActive from "../../assets/svgs/Tracking/Active/box-active.svg";
import houseActive from "../../assets/svgs/Tracking/Active/house-active.svg";
import truckActive from "../../assets/svgs/Tracking/Active/truck-active.svg";
import bankInActive from "../../assets/svgs/Tracking/Inactive/bank-inactive.svg";
import boxInActive from "../../assets/svgs/Tracking/Inactive/box-inactive.svg";
import houseInactive from "../../assets/svgs/Tracking/Inactive/house-inactive.svg";
import truckInactive from "../../assets/svgs/Tracking/Inactive/truck-inactive.svg";
import Stepper from "./Stepper";
import { useNavigate } from "react-router-dom";
import { TRACK_CARD_DELIVERY } from "../../routes/ScreenRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import DeliveryZeroState from "./DeliveryZeroState";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

interface DeliveryTrackerProps {
  direction: "horizontal" | "vertical";
}

const DeliveryTracker = (props: DeliveryTrackerProps) => {
  const { direction } = props;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(TRACK_CARD_DELIVERY);
  };

  const iconSelector = (status: string, isActive: boolean) => {
    if (status === "Dispatched") {
      return isActive ? bankActive : bankInActive;
    }
    if (status === "In Transit") {
      return isActive ? truckActive : truckInactive;
    }
    if (status === "Out for Delivery") {
      return isActive ? boxActive : boxInActive;
    }
    if (status === "Delivered") {
      return isActive ? houseActive : houseInactive;
    }
    return isActive ? bankActive : bankInActive;
  };

  const subtext = (
    status: string,
    obj: { regular?: string; error?: string },
    deliveryDate: string
  ) => {
    if (status !== "Out for Delivery") return obj;
    if (!!obj?.error) return obj;
    return {
      error: obj?.error,
      regular: "your card will be delivered",
      highlightText: dayjs(deliveryDate).isToday()
        ? "Today"
        : dayjs(deliveryDate).isTomorrow()
        ? "Tomorrow"
        : `${dayjs(deliveryDate).format("by DD MMM")}`
    };
  };
  const deliverySteps = useSelector(
    (state: RootState) => state.user.trackingDetails
  );
  const steps =
    deliverySteps?.statusTimeline?.map((data) => {
      return {
        active: data.checkpointDone,
        text: subtext(
          data.status,
          data.subtext,
          deliverySteps?.expectedDeliveryDate
        ),
        iconActive: iconSelector(data.status, true),
        iconInactive: iconSelector(data.status, false),
        status: data.status,
        currentStatusBoolean: data.currentStatus
      };
    }) || [];

  return direction === "horizontal" ? (
    <>
      {deliverySteps?.status === "SUCCESS" ? (
        <div className={styles.horizontalContainer}>
          <div className={styles.horizontalTitleContainer}>
            <div>Track your Credit Card delivery</div>
            <img src={arrowCircle1} alt="Arrow" onClick={handleNavigate} />
          </div>
          <Stepper steps={steps} />
          <div className={styles.deliveryStatusContainer}>
            <div className={styles.currentStatusContainer}>
              <div>Current Status</div>
              <span>{deliverySteps?.currentStatus}</span>
            </div>
            <div className={styles.estimatedContainer}>
              <div>Estimated Delivery</div>
              <span>
                {dayjs(deliverySteps?.expectedDeliveryDate).format(
                  "DD MMM YYYY"
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <DeliveryZeroState awbNumber={deliverySteps?.awbNumber} />
      )}
    </>
  ) : (
    <>
      {deliverySteps?.status === "SUCCESS" ? (
        <>
          <div className={styles.verticalContainer}>
            <div className={styles.horizontalTitleContainer}>
              <div>Shipment Status</div>
            </div>
            <Stepper steps={steps} direction={direction} />
          </div>
        </>
      ) : (
        <DeliveryZeroState />
      )}
    </>
  );
};

export default DeliveryTracker;
