export const PWA_MAX_WIDTH_IN_PX = 550;
export const INTEGER_REGEX = /^\d*?\d*$/;
export const API_REQUEST_DATE_FORMAT = "YYYY-MM-DD";
export const TRANSACTIONS_PER_PAGE_COUNT = 20; // should be long enough to add scrolling in screen
export const NEW_OFFERS_DIFF_IN_DAYS = 15;
export const EXPIRING_SOON_OFFERS_DIFF_IN_DAYS = 15;
export const PCI_ENCRYPTION_IV_LENGTH = 16;
export const PIN_MASKING_CHAR = "•";
export const MILESTONE_TEXT_DECIMAL_PRECISION = 0;
export const EMI_ELIGIBLE_TRANSACTIONS_PER_PAGE_COUNT = 20;
// status codes
export const INVALID_SESSION_STATUS_CODES = [401, 428];

// headers
export const SESSION_TOKEN_HEADER = "sessionKey";
export const CORRELATION_ID_HEADER = "X-Correlation-Id";
export const MAX_NUDGES_TO_DISPLAY = 4;
export const RESEND_OTP_TIME = 30;
export const SMS_RETRY_ATTEMPTS = 2;
export const ADD_DEVICE_API_CALL_DELAY = 5*1000; // 5 sec
export const ADD_DEVICE_API_CALL_TIMEOUT = 6*10000;  // 60 sec
export const CONTACT_MOBILE_NUMBER = "1860 267 7777";
export const OS_TO_EMI_SUCCESS_NUDGE_NUMBER_OF_IMPRESSIONS = 3;
export const UNIQUE_URL_LENGTH = 24;

//Deep link support
export const COOKIE_EXPIRATION_TIME_DEEP_LINK = 5*60*1000 // 5 minutes