import React, { useEffect, useState } from "react";
import styles from "./searchBarStyles.module.scss";

interface SearchBarProps {
  onSearch: (query: string) => void;
  placeholder?: string;
  icon: string;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  const { onSearch, placeholder, icon } = props;
  const [query, setQuery] = useState("");

  useEffect(() => {
    onSearch(query);
  }, [query]);

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   onSearch(query);
  // };

  return (
    <div className={styles.container}>
      <img src={icon} alt="icon" className={styles.iconSearch} />
      <input
        type="text"
        placeholder={placeholder || "Search"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        // onKeyDown={handleKeyDown}
        className={styles.input}
      ></input>
    </div>
  );
};

export default SearchBar;
