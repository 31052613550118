// login routes
export const GENERATE_LOGIN_OTP_ROUTE = "/v1/otp/generate";
export const VERIFY_LOGIN_OTP_ROUTE = "/v1/otp/verify";
export const LOGOUT_ROUTE = "/logout";
export const GENERATE_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE =
  "device/challenge/otp/generatePreLogin";
export const VERIFY_CHALLENGE_SMS_OTP_PRELOGIN_ROUTE = `device/challenge/otp/verifyPreLogin`;
export const GET_IP = `/device/getIP`;

// app routes
export const GET_DESIGN_CONFIG_ROUTE = "/pwaDesignConfig";
export const GET_CONFIG_DATA_ROUTE = "/fetchConfigData";
export const GET_AUTH_CONFIG_ROUTE = "/auth/apiToken/prefetch";
export const SEND_HEARTBEAT_ROUTE = "/auth/heartbeat";

//event routes
export const CAPTURE_EVENT_ROUTE = "/event/capture";

// auth routes
export const GENERATE_CHALLENGE_SMS_OTP_ROUTE = "/challenge/otp/generate";
export const VERIFY_CHALLENGE_SMS_OTP_ROUTE = "/challenge/otp/verify";

// benefits routes
export const BENEFITS_ROUTE_PREFIX = "/benefits";
export const GET_BENEFITS_FOR_ACCOUNT_ROUTE =
  BENEFITS_ROUTE_PREFIX + "/pwa/account";

// preferences
export const PREFERENCES_ROUTE_PREFIX = "/preferences";
export const GET_PROGRAM_PREFERENCES_ROUTE =
  PREFERENCES_ROUTE_PREFIX + "/program";
export const ADD_ACCOUNT_TAGS_ROUTE =
  PREFERENCES_ROUTE_PREFIX + "/account/tags/add";
export const GET_ACCOUNT_PREFERENCES_ROUTE =
  PREFERENCES_ROUTE_PREFIX + "/account";

// nudges routes
export const NUDGES_ROUTE_PREFIX = "/nudges";
export const GET_NUDGES_FOR_ACCOUNT_ROUTE =
  NUDGES_ROUTE_PREFIX + "/account/:accountId";

// customer routes
export const GET_CUSTOMER_ROUTE = "/customers/:customerId";

// accounts routes
export const ACCOUNTS_ROUTE_PREFIX = "/accounts";
export const GET_ACCOUNT_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId?cardId=:cardId";
export const GET_TRANSACTIONS_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/transactions";
export const GET_UNBILLED_TRANSACTIONS_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/unbilled";
export const GET_STATEMENTS_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/statements?cardId=:cardId";
export const GET_EMI_CONFIG_ROUTE = ACCOUNTS_ROUTE_PREFIX + "/:accountId/emi";
export const CONVERT_TO_EMI_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/emi/create";
export const GET_SPEND_GROUPS =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/spendGroups";
export const GET_SPEND_GROUPS_TRANSACTIONS =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/spendGroups/transactions";
export const GET_TRANSACTION_BY_ID_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/customer/:transactionId";
export const GET_ELIGIBILITY_FOR_OUTSTANDING_EMI_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/emi/eligibleOutstandingAmount";
export const GET_STATUS_OUTSTANDING_TO_EMI_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/emi";
export const SET_USER_ACTIVITY_FOR_OUTSTANDING_TO_EMI_ROUTE =
  ACCOUNTS_ROUTE_PREFIX + "/:accountId/outstandingToEmi/setUserActivity";

// cards routes
export const CARDS_ROUTE_PREFIX = "/cards";
export const GET_CARD_ROUTE = CARDS_ROUTE_PREFIX + "/:cardId";
export const GET_OTHET_CARDS_ROUTE = "/customers/otherCards";
export const GENERATE_SESSION_ROUTE = "/generateSession";
export const GET_UNMASKED_CARD_ROUTE =
  CARDS_ROUTE_PREFIX + "/:cardId/unmaskCard";
export const RESET_CARD_PIN_ROUTE = CARDS_ROUTE_PREFIX + "/:cardId/pin";

export const GET_CARD_CONTROLS_ROUTE =
  CARDS_ROUTE_PREFIX + "/:cardId/cardControls";
export const LOCK_CARD_ROUTE = CARDS_ROUTE_PREFIX + "/:cardId/lock";
export const UNLOCK_CARD_ROUTE = CARDS_ROUTE_PREFIX + "/:cardId/unlock";
export const UPDATE_CARD_CONTROLS_ROUTE =
  CARDS_ROUTE_PREFIX + "/:cardId/cardControls";
export const GET_CARD_TRACKING_ROUTE = CARDS_ROUTE_PREFIX + "/:cardId/tracking";

export const FETCH_URL = "/device/fetchUrl";
export const ADD_DEVICE = "/device/addDevice";
export const CHECK_DEVICE = "/device/checkDevice";
export const REMOVE_DEVICE = "/device/removeDevice";
export const LIST_DEVICES = `device/listDevices`;
export const CHECK_URL = `device/validateUniqueUrl`;
export const SEND_ACCOUNT_NOTIFICATION_VIA_SMS = `communication/sms/v1/messages`;

// public routes
export const publicRoutes: Array<string> = [];
const getRoutePatterns = (routes: string[]): RegExp[] =>
  routes.map((route) => {
    route = route.replace(":customerId", "(.+)");
    route = route.replace(":accountId", "(.+)");
    route = route.replace(":cardId", "(.+)");
    return new RegExp(`^${route}$`);
  });

// encrypted route patterns
export const pciEncryptedRoutePatterns = getRoutePatterns([
  GET_UNMASKED_CARD_ROUTE,
  RESET_CARD_PIN_ROUTE
]);

// public route patterns
export const publicRoutePatterns = getRoutePatterns([
  GENERATE_LOGIN_OTP_ROUTE,
  VERIFY_LOGIN_OTP_ROUTE
]);
