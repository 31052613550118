import store from "../../app/store";
import { getSelectedCard } from "../../features/user/userSlice";
import {
  AccountBlockCode,
  CardBlockCode,
  CustomerBlockCodeStatus,
  Action,
  ActionValue
} from "./BlockCodeTypes";
import {
  blockCodeCustomerStatusMappings,
  customerStatusActionMappings
} from "./BlockCodeMapping";
import * as InternalFlags from "../../utils/internalFlags";

interface GetCustomerStatusFromBlockCodeArgs {
  accountBlockCodes: AccountBlockCode[] | null;
  cardBlockCodes: CardBlockCode[] | null;
}

export const getCustomerBlockCodeStatus = ({
  accountBlockCodes: currentAccountBlockCodes,
  cardBlockCodes: currentCardBlockCodes
}: GetCustomerStatusFromBlockCodeArgs): CustomerBlockCodeStatus => {
  if (!currentAccountBlockCodes || !currentCardBlockCodes) {
    return CustomerBlockCodeStatus.NOT_FOUND;
  }
  const matchedMappings = blockCodeCustomerStatusMappings.filter((mapping) => {
    const hasAccountBlockCode =
      mapping.accountBlockCodes === "ANY" ||
      (mapping.accountBlockCodes.length === 0 &&
        currentAccountBlockCodes.length === 0) ||
      mapping.accountBlockCodes.filter((mappingAccountBlockCode) =>
        Boolean(
          currentAccountBlockCodes.find(
            (currentAccountBlockCode) =>
              currentAccountBlockCode === mappingAccountBlockCode
          )
        )
      ).length > 0;

    const hasCardBlockCode =
      mapping.cardBlockCodes === "ANY" ||
      (mapping.cardBlockCodes.length === 0 &&
        currentCardBlockCodes.length === 0) ||
      mapping.cardBlockCodes.filter((mappingCardBlockCode) =>
        Boolean(
          currentCardBlockCodes.find(
            (currentCardBlockCode) =>
              currentCardBlockCode === mappingCardBlockCode
          )
        )
      ).length > 0;

    return hasAccountBlockCode && hasCardBlockCode;
  });

  return matchedMappings.length > 0
    ? matchedMappings[0].customerBlockCodeStatus
    : CustomerBlockCodeStatus.NOT_FOUND;
};

export const isBlockCodeActionEnabled = (action: Action) => {
  if (!InternalFlags.areBlockCodesEnabled) return true;

  const storeState = store.getState();
  const customerBlockCodeStatus = storeState.user.customerBlockCodeStatus;

  if (customerBlockCodeStatus === CustomerBlockCodeStatus.NOT_FOUND) {
    return false;
  }
  
  //eslint-disable-next-line
  const card = getSelectedCard(storeState)!;
  const isCardPrimary = card.isPrimary;

  let tempAction = action;

  // some actions have same value mapping
  tempAction = [
    Action.RESET_CARD_PIN,
    Action.ENABLE_DOMESTIC_CARD_CONTROLS,
    Action.DISABLE_DOMESTIC_CARD_CONTROLS,
    Action.ENABLE_INTERNATIONAL_CARD_CONTROLS,
    Action.DISABLE_DOMESTIC_CARD_CONTROLS
  ].includes(tempAction)
    ? Action.RESET_CARD_PIN
    : tempAction;

  const mapping = customerStatusActionMappings.find(
    (mapping) =>
      mapping.customerBlockCodeStatus === customerBlockCodeStatus &&
      mapping.action === tempAction
  );

  return Boolean(
    mapping &&
      (mapping.isCardPrimary === "ANY" ||
        mapping.isCardPrimary === isCardPrimary) &&
      mapping.actionValue === ActionValue.YES
  );
};
