import deliveryZeroState from "../../assets/svgs/deliveryZeroState.svg";
import styles from "./deliveryZeroState.module.scss";
const DeliveryZeroState = (props: { awbNumber?: string | number }) => {
  return (
    <div className={styles.container}>
      {props?.awbNumber && (
        <div className={styles.awbContainer}>
          <div className={styles.awbText1}>AWB Number</div>
          <div className={styles.awbText2}>{props?.awbNumber}</div>
          <hr className={styles.divider} />
        </div>
      )}
      <img src={deliveryZeroState} alt="img" />
      <div className={styles.text1}>Oops! Something’s not right</div>
      <div className={styles.text2}>
        We’re facing some issues in tracking your delivery Please check back
        here in sometime
      </div>
    </div>
  );
};

export default DeliveryZeroState;
