/*  eslint-disable  react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from "react";
import styles from "./registeredDevices.module.scss";
import { Button, Button2, Header, ToastType, toast } from "../../components";
import Info from "../../assets/svgs/Info (1).svg";
import Chrome from "../../assets/svgs/chrome.svg";
import Safari from "../../assets/svgs/safari.svg";
import Other from "../../assets/svgs/other.svg";
import AuthApi from "../../apis/authApi/AuthApi";
import {
  aesDecryptData,
  aesDecryptDeviceData
} from "../../utils/encryptionUtil";
import {
  autoReadOtp,
  focusOnInput,
  getDeviceIdCookie,
  getMaskedMobileNumberForLastTwo,
  getUniqueURLFromCookie,
  getFormattedTimestamp
} from "../../utils/functions";
import { toast as toastLib } from "react-toastify";
import DeviceApi from "../../apis/deviceApi/deviceApi";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import Loader from "../../components/loader/loader";
import { MANAGE_CARD_ROUTE } from "../../routes/ScreenRoutes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { RESEND_OTP_TIME } from "../../utils/constants";
import Caret from "../../assets/svgs/Vector.svg";
import { isManualOtpAllowed } from "../../utils/internalFlags";
import { getUserOSAndVersion } from "../../utils/trackingFunctions";
import Bulb from "../../assets/svgs/Union.svg";
import { UserAction } from "../../utils/enums";
import { sendNotification } from "../../utils/notifyCustomer";

interface DeviceData {
  id: string;
  deviceId: string;
  deviceStatus: string;
  metaData: {
    osData: any;
    clientData: any;
    deviceData: any;
    isiOSChromeStatus: string;
  };
  lastLoggedInTime: string;
  lastLoggedInLocation: string;
}

const RegisteredDevices = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [registeredDevices, setRegisteredDevices] = useState<string[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>([]);
  const [isDeviceSelected, setIsDeviceSelected] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [isOtpBottomSheetOpen, setIsOtpBottomSheetOpen] =
    useState<boolean>(false);
  const [isVerifyLoginOtpLoading, setIsVerifyLoginOtpLoading] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mobileVerificationRefId, setMobileVerificationRefId] =
    useState<string>("");
  const [currentDevice, setCurrentDevice] = useState<DeviceData>();
  const [timeOut, setTimeOut] = useState<number>(RESEND_OTP_TIME);
  const [isGenerateOtpLoading, setIsGenerateOtpLoading] =
    useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [userOs, setUserOs] = useState<string>("");
  const [isRegisteredDeviceInfoOpen, setIsRegisteredDeviceInfoOpen] =
    useState<boolean>(false);

  const getListOfDevices = async () => {
    setIsLoading(true);
    try {
      const response = await DeviceApi.listDevice({
        uniqueURL: getUniqueURLFromCookie()
      });
      if (response.status === 200) {
        filterCurrentDevice(response?.data?.registeredDevices);
        setIsLoading(false);
      } else {
        console.error(
          `An exception occurred while getting registered device. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to process your request. Please try again later"
          );
        }, 1000);
        navigate(MANAGE_CARD_ROUTE);
      }
    } catch (error) {
      console.error(
        `An exception occurred while getting registered device. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
      navigate(MANAGE_CARD_ROUTE);
    }
  };

  useEffect(() => {
    getListOfDevices();
  }, []);

  useEffect(() => {
    const [os, version] = getUserOSAndVersion(navigator.userAgent);
    setUserOs(os);
  });

  useEffect(() => {
    // Start the interval
    let interval: any;
    if (isOtpBottomSheetOpen && timeOut > 0) {
      interval = setTimeout(() => {
        setTimeOut(timeOut - 1);
      }, 1000);
    }

    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut, isOtpBottomSheetOpen]);

  const handleAutoRead = () => {
    if (userOs !== "Android") {
      autoReadOtp((otpValue) => {
        setOtp(otpValue);
        handleInputResponsive();
        focusOnInput("loginOtpInputContainer", 5);
      });
    }
  };

  const handleKeyDown = (event: any) => {
    if (/\d/g.test(event.key) && !isManualOtpAllowed) {
      // Prevent the default action (entering the number)
      event.preventDefault();
      setOtp("");
    }
  };

  const handleInputResponsive = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const inputWidth = inputElement.offsetWidth;
      // Calculate the effective character width considering the font size
      const charWidth = inputWidth / 6;
      // Adjust the letterSpacing based on the calculated charWidth and desired spacing
      const letterSpacing = Math.max(Math.floor(charWidth * 0.7), 1); // Adjust multiplier (0.8 here) based on your needs
      inputElement.style.letterSpacing = letterSpacing + "px";
    }
  };

  const handleDeregisterDeviceClick = async () => {
    try {
      setIsGenerateOtpLoading(true);
      const response = await AuthApi.generateChallengeSmsOtpPrelogin({
        uniqueURL: getUniqueURLFromCookie(),
        otpType: "DEREGISTER_DEVICE"
      });

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);

        autoReadOtp((otpValue) => {
          setOtp(otpValue);
          // handleOtpVerifyClick(otpValue);
          handleInputResponsive();
          focusOnInput("loginOtpInputContainer", 5);
        });

        setMobileVerificationRefId(response?.data?.mobileVerificationRefId);
        setIsOtpBottomSheetOpen(true);
        handleDeviceBottomSheetClose();
      } else if (response.status === 400) {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
      } else {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, "The OTP generation is failed.");
        }, 1000);
      }
      setIsGenerateOtpLoading(false);
    } catch (error) {
      console.error(
        `An exception occurred while generating OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
      setIsGenerateOtpLoading(false);
    }
  };

  const handleDeviceBottomSheetClose = () => {
    setIsDeviceSelected(!isDeviceSelected);
  };

  const handleOtpBottomSheetClose = () => {
    setIsOtpBottomSheetOpen(false);
  };

  const handleOtpBottomsheetClose = () => {
    setOtp("");
    setIsOtpBottomSheetOpen(!isOtpBottomSheetOpen);
  };

  const handleChange = (code: string) => {
    handleInputResponsive();
    setOtp(code);
  };

  const handleResendOtpClick = async () => {
    setTimeOut(RESEND_OTP_TIME);
    try {
      const response = await AuthApi.generateChallengeSmsOtpPrelogin({
        uniqueURL: getUniqueURLFromCookie(),
        otpType: "DEREGISTER_DEVICE"
      });

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);

        setMobileVerificationRefId(response?.data?.mobileVerificationRefId);
      } else if (response.status === 400) {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
      } else {
        console.error(
          `An exception occurred while generating OTP. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, "The OTP generation is failed.");
        }, 1000);
      }
    } catch (error) {
      console.error(
        `An exception occurred while generating OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
  };

  const handleOtpVerifyClick = async (otpValue: string) => {
    setIsVerifyLoginOtpLoading(true);
    setOtp("");
    try {
      const response = await AuthApi.verifyChallengeSmsOtpPrelogin({
        uniqueURL: getUniqueURLFromCookie() || "",
        mobileVerificationRefId: mobileVerificationRefId,
        otp: "" + otpValue
      });
      setOtp("");

      if (response.status === 200) {
        setIsVerifyLoginOtpLoading(true);
        deregisterDevice();
      } else {
        console.error(
          `An exception occurred while verifying OTP. Error:\n${response}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(ToastType.ERROR, response?.data?.failureReason);
        }, 1000);
        setIsVerifyLoginOtpLoading(false);
      }
    } catch (error) {
      console.error(
        `An exception occurred while verifying OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
      setIsVerifyLoginOtpLoading(false);
      setOtp("");
    }
  };

  const handleDeviceBarClick = (item: any) => {
    setIsDeviceSelected(true);
    setSelectedDevice(item);
  };

  const handleToolTipClick = () => {
    setIsRegisteredDeviceInfoOpen(!isRegisteredDeviceInfoOpen);
  };

  const deregisterDevice = async () => {
    try {
      const response = await DeviceApi.RemoveDevice({
        uniqueURL: getUniqueURLFromCookie(),
        removeDeviceId: selectedDevice.deviceId,
        addDevice: false
      });
      if (response.status === 200) {
        setIsVerifyLoginOtpLoading(false);
        setIsOtpBottomSheetOpen(false);
        getListOfDevices();
        toastLib.dismiss();
        sendNotification(UserAction.DE_REGISTER, {
          uniqueURL: getUniqueURLFromCookie(),
          deviceId: selectedDevice.deviceId
        });
        setTimeout(() => {
          toast(
            ToastType.SUCCESS,
            `${
              selectedDevice?.metaData?.deviceData?.vendor || "Device"
            } was successfully de-registered`
          );
        }, 1000);
      } else {
        console.error(
          `An exception occurred while deregistering device. Error:\n${response.status}`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to process your request. Please try again later"
          );
        }, 1000);
      }
    } catch (error) {
      console.error(
        `An exception occurred while deregistering device. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
  };

  const filterCurrentDevice = (devices: any) => {
    const deviceId = getDeviceIdCookie();
    const filteredDevice = devices.filter((device: any) => {
      if (deviceId === device?.deviceId) {
        if (device?.metaData?.clientData) {
          device.metaData.clientData = aesDecryptDeviceData(
            process.env.REACT_APP_PII_ENCRYPTION_KEY!,
            process.env.REACT_APP_PII_ENCRYPTION_IV!,
            device.metaData.clientData
          );
        }
        if (device?.metaData?.deviceData) {
          device.metaData.deviceData = aesDecryptDeviceData(
            process.env.REACT_APP_PII_ENCRYPTION_KEY!,
            process.env.REACT_APP_PII_ENCRYPTION_IV!,
            device?.metaData?.deviceData
          );
        }
        setCurrentDevice(device);
        return false;
      } else {
        return true;
      }
    });
    setRegisteredDevices(filteredDevice);
  };

  const DeviceSelectedRender = () => (
    <>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetContainer}>
          <img
            src={ImageFunction(selectedDevice?.metaData?.clientData?.name)}
            alt=""
          />
          <div className={styles.deviceDetailsText}>
            {selectedDevice?.metaData?.clientData?.name}
            <span className={styles.deviceDetailsSubText}>
              {selectedDevice?.metaData?.deviceData?.vendor !== ""
                ? (selectedDevice?.metaData?.deviceData?.vendor || "") +
                  " " +
                  (selectedDevice?.metaData?.deviceData?.model === "K"
                    ? "Android"
                    : selectedDevice?.metaData?.deviceData?.model || "")
                : "unknown"}
            </span>
          </div>
        </div>

        <div
          className={styles.deviceBar}
          style={{ height: "8%", width: "85%", margin: "10px auto" }}
        >
          <div className={styles.contentContainer}>
            <div style={{ display: "flex" }}>
              <div className={styles.selectedDevicebarText}>
                Last Seen
                <span className={styles.selectedDevicebarSubText}>
                  {getFormattedTimestamp(selectedDevice?.lastLoggedInTime)}
                </span>
              </div>
            </div>
            <div className={styles.lastLoggedTextSelectedDevice}>
              {selectedDevice?.lastLoggedInLocation}
            </div>
          </div>
        </div>

        <hr className={styles.horizontalLine} style={{ marginTop: "10%" }}></hr>

        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="de-register"
            text1Style={{ fontWeight: "bold" }}
            text2="this device"
            customClassName={styles.confirmBtn1}
            onClick={handleDeregisterDeviceClick}
            isLoading={isGenerateOtpLoading}
          />
          <Button
            text1="close"
            showArrow={false}
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.confirmBtn1}
            onClick={handleDeviceBottomSheetClose}
          />
        </div>
      </div>
    </>
  );

  const ImageFunction = (browserName: string = "") => {
    if (browserName.split(" ").includes("Chrome")) {
      return Chrome;
    } else if (browserName.split(" ").includes("Safari")) {
      return Safari;
    } else {
      return Other;
    }
  };

  const OTPBottomSheet = () => (
    <div className={styles.bottomSheet1}>
      <div className={styles.bottomSheetContainer1}>
        <div className={styles.bottomSheetHeader}>verify with OTP</div>
        <div className={styles.bottomSheetText}>
          Please enter the 6 digit OTP sent to +91{" "}
          {getMaskedMobileNumberForLastTwo(user.customer?.lastFour) || ""}
        </div>
        <div id="loginOtpInputContainer" className={styles.otpContainer}>
          <input
            id="inputPhone"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            placeholder="------"
            className={styles.phoneNumberInput}
            style={{ opacity: otp.length > 0 ? 1 : 0.5 }}
            value={otp}
            maxLength={6}
            onChange={(e: any) => handleChange(e.target.value)}
            onKeyDown={handleKeyDown}
            readOnly={userOs === "Android" && !isManualOtpAllowed}
            ref={inputRef}
            autoFocus={userOs !== "Android"}
            onFocus={handleAutoRead}
          />
          <div className={styles.resendContainer}>
            <div>Didn't get the OTP?</div>
            <div className={styles.resendText}>
              {timeOut !== 0 ? (
                <span className={styles.retryText}>
                  Retry in 00:{timeOut.toString().padStart(2, "0")}s
                </span>
              ) : (
                <>
                  <span onClick={handleResendOtpClick}>Resend OTP</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.tncContainer}>
            {/* <div className={styles.tncText}>
              <TextWithLinks text={tncText} linkClassName={styles.linkText} />
            </div> */}
          </div>
        </div>
        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="confirm"
            customClassName={styles.confirmBtnOtp}
            isLoading={isVerifyLoginOtpLoading}
            onClick={() => handleOtpVerifyClick(otp)}
            disabled={otp.length !== 6}
          />
          <Button
            text1="cancel"
            showArrow={false}
            text1Style={{ fontWeight: "bold" }}
            text2="de-registration"
            customClassName={styles.confirmBtnOtp}
            onClick={handleOtpBottomSheetClose}
          />
        </div>
      </div>
      <div id="scroll1"></div>
    </div>
  );

  const RegisterdDeviceInfo = () => (
    <>
      <div className={styles.bottomSheet1}>
        <div className={styles.bottomSheetContainerInfo}>
          <div className={styles.bottomSheetHeaderInfo}>Registered Devices</div>
          <div className={styles.bottomSheetTextInfo}>
            These are the unique device and browser combinations you've
            registered and used to log into IndusInd Bank CardsHub.
          </div>
          <div className={styles.bottomSheetTextInfo2}>
            <img src={Bulb} alt="" /> You can register up to 5 device & browser
            combinations
          </div>
          <div className={styles.confirmBtn1Container2}>
            <Button
              text1="close"
              text1Style={{ fontWeight: "bold" }}
              showArrow={false}
              customClassName={styles.confirmBtnInfo}
              onClick={handleToolTipClick}
            />
          </div>
        </div>
        <div id="scroll1"></div>
      </div>
    </>
  );

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="registered devices" />
    </div>
  ) : (
    <>
      <Header
        label="Active Sessions"
        bgColor="rgba(20, 20, 20, 0.8)"
        customClassName={styles.header}
      />

      <div className={styles.registeredDeviceBody}>
        <div className={styles.registerdDevice}>
          <span className={styles.yellowColor}>
            {registeredDevices.length + 1}{" "}
          </span>{" "}
          <span className={styles.greyColor}>/ 5</span> Registered Devices
          <img src={Info} alt="" onClick={handleToolTipClick} />
        </div>

        <hr className={styles.horizontalLine} />

        <div className={styles.deviceContainer}>
          <div className={styles.deviceBar}>
            <div className={styles.contentContainer}>
              <div style={{ display: "flex", width: "50%" }}>
                <img
                  src={ImageFunction(currentDevice?.metaData?.clientData?.name)}
                  alt="Browser Image"
                />
                <div className={styles.barText}>
                  {currentDevice?.metaData?.clientData?.name}
                  <span
                    className={styles.barSubText}
                    style={{
                      display: "block",
                      color: "rgba(252, 252, 252, 0.5)"
                    }}
                  >
                    {currentDevice?.metaData?.deviceData?.vendor !== ""
                      ? (currentDevice?.metaData?.deviceData?.vendor || "") +
                        " " +
                        (currentDevice?.metaData?.deviceData?.model === "K"
                          ? "Android"
                          : currentDevice?.metaData?.deviceData?.model || "")
                      : "unknown"}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={styles.currentBox}>current</div>
              </div>
            </div>
          </div>
          {registeredDevices.map((item: any, index: number) => {
            if (item?.metaData?.clientData) {
              item.metaData.clientData = aesDecryptDeviceData(
                process.env.REACT_APP_PII_ENCRYPTION_KEY!,
                process.env.REACT_APP_PII_ENCRYPTION_IV!,
                item.metaData.clientData
              );
            }
            if (item?.metaData?.deviceData) {
              item.metaData.deviceData = aesDecryptDeviceData(
                process.env.REACT_APP_PII_ENCRYPTION_KEY!,
                process.env.REACT_APP_PII_ENCRYPTION_IV!,
                item?.metaData?.deviceData
              );
            }
            if (item?.lastLoggedInLocation) {
              item.lastLoggedInLocation = aesDecryptDeviceData(
                process.env.REACT_APP_PII_ENCRYPTION_KEY!,
                process.env.REACT_APP_PII_ENCRYPTION_IV!,
                item?.lastLoggedInLocation
              );
            }
            return (
              <>
                <div
                  className={styles.deviceBar}
                  onClick={() => {
                    handleDeviceBarClick(item);
                  }}
                  key={index}
                >
                  <div className={styles.contentContainer}>
                    <div style={{ display: "flex", width: "60%" }}>
                      <img
                        src={ImageFunction(item?.metaData?.clientData?.name)}
                        alt="Browser Image"
                      />
                      <div className={styles.barText}>
                        {item?.metaData?.clientData?.name}
                        <span
                          className={styles.barSubText}
                          style={{
                            display: "block",
                            color: "rgba(252, 252, 252, 0.5)"
                          }}
                        >
                          {item?.metadata?.deviceData?.vendor !== ""
                            ? (item?.metaData?.deviceData?.vendor || "") +
                              " " +
                              (item?.metaData?.deviceData?.model === "K"
                                ? "Android"
                                : item?.metaData?.deviceData?.model || "")
                            : "unknown"}
                        </span>
                      </div>
                    </div>
                    <div className={styles.lastLoggedText}>
                      {item?.lastLoggedInLocation}

                      <span style={{ display: "block" }}>
                        {getFormattedTimestamp(item?.lastLoggedInTime)}
                      </span>
                    </div>
                    <img src={Caret} alt="" style={{ marginLeft: "10px" }} />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <BottomsheetComponent
        isBottomSheetOpen={isDeviceSelected}
        setIsBottomSheetOpen={setIsDeviceSelected}
        render={DeviceSelectedRender()}
      />

      <BottomsheetComponent
        isBottomSheetOpen={isOtpBottomSheetOpen}
        setIsBottomSheetOpen={handleOtpBottomsheetClose}
        render={OTPBottomSheet()}
      />

      <BottomsheetComponent
        isBottomSheetOpen={isRegisteredDeviceInfoOpen}
        setIsBottomSheetOpen={setIsRegisteredDeviceInfoOpen}
        render={RegisterdDeviceInfo()}
      />
    </>
  );
};

export default RegisteredDevices;
