import {
  Action,
  ActionValue,
  CustomerBlockCodeStatus,
  BlockCodeCustomerStatusMapping,
  CustomerStatusActionMapping
} from "./BlockCodeTypes";

export const blockCodeCustomerStatusMappings: BlockCodeCustomerStatusMapping[] =
  [
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_UNBLOCKED,
      accountBlockCodes: [],
      cardBlockCodes: []
    },
    {
      customerBlockCodeStatus:
        CustomerBlockCodeStatus.MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER,
      accountBlockCodes: [],
      cardBlockCodes: ["M"]
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_LOST_OR_SHREDDED,
      accountBlockCodes: [],
      cardBlockCodes: ["L", "A"]
    },
    {
      customerBlockCodeStatus:
        CustomerBlockCodeStatus.NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE,
      accountBlockCodes: [],
      cardBlockCodes: ["U"]
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CIBIL_EXCEPTION_CASES,
      accountBlockCodes: [],
      cardBlockCodes: ["O"]
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD_BLOCK_CARDS,
      accountBlockCodes: [],
      cardBlockCodes: ["B"]
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.DELINQUENT_CUSTOMERS,
      accountBlockCodes: ["Z", "N", "K"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CD_4,
      accountBlockCodes: ["T"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CD_3,
      accountBlockCodes: ["Q"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.COLLECTION,
      accountBlockCodes: ["G"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.RE_CLASSIFIED_FOR_NPA,
      accountBlockCodes: ["E"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.CD_2,
      accountBlockCodes: ["P"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.RCU,
      accountBlockCodes: ["S"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_BANK,
      accountBlockCodes: ["I"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus:
        CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_CUSTOMER,
      accountBlockCodes: ["X"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.MOROTORIUM_CASES,
      accountBlockCodes: ["J"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD,
      accountBlockCodes: ["F"],
      cardBlockCodes: "ANY"
    },
    {
      customerBlockCodeStatus: CustomerBlockCodeStatus.OVD_NOT_GIVEN,
      accountBlockCodes: ["V"],
      cardBlockCodes: "ANY"
    }
  ];

export const customerStatusActionMappings: CustomerStatusActionMapping[] = [
  // reset card pin
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_UNBLOCKED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_LOST_OR_SHREDDED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CIBIL_EXCEPTION_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD_BLOCK_CARDS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.DELINQUENT_CUSTOMERS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_4,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_3,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.COLLECTION,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RE_CLASSIFIED_FOR_NPA,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_2,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RCU,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_BANK,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.MOROTORIUM_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.RESET_CARD_PIN,
    customerBlockCodeStatus: CustomerBlockCodeStatus.OVD_NOT_GIVEN,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },

  // convert transaction to emi
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_UNBLOCKED,
    isCardPrimary: true,
    actionValue: ActionValue.YES
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER,
    isCardPrimary: true,
    actionValue: ActionValue.YES
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_LOST_OR_SHREDDED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CIBIL_EXCEPTION_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD_BLOCK_CARDS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.DELINQUENT_CUSTOMERS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_4,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_3,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.COLLECTION,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RE_CLASSIFIED_FOR_NPA,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_2,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RCU,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_BANK,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.MOROTORIUM_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD,
    isCardPrimary: true,
    actionValue: ActionValue.YES
  },
  {
    action: Action.CONVERT_TRANSACTION_TO_EMI,
    customerBlockCodeStatus: CustomerBlockCodeStatus.OVD_NOT_GIVEN,
    isCardPrimary: true,
    actionValue: ActionValue.YES
  },

  // lock card
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_UNBLOCKED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_LOST_OR_SHREDDED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CIBIL_EXCEPTION_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD_BLOCK_CARDS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.DELINQUENT_CUSTOMERS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_4,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_3,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.COLLECTION,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RE_CLASSIFIED_FOR_NPA,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_2,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RCU,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_BANK,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.MOROTORIUM_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.LOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.OVD_NOT_GIVEN,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },

  // unlock card
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_UNBLOCKED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.MANUAL_TEMPORARY_BLOCK_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CARD_LOST_OR_SHREDDED,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus:
      CustomerBlockCodeStatus.NEW_CARDS_WHICH_ARE_IN_INACTIVE_STATE,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CIBIL_EXCEPTION_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.YES
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD_BLOCK_CARDS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.DELINQUENT_CUSTOMERS,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_4,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_3,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.COLLECTION,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RE_CLASSIFIED_FOR_NPA,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.CD_2,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.RCU,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_BANK,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.ACCOUNT_CLOSED_BY_CUSTOMER,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.MOROTORIUM_CASES,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.FRAUD,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  },
  {
    action: Action.UNLOCK_CARD,
    customerBlockCodeStatus: CustomerBlockCodeStatus.OVD_NOT_GIVEN,
    isCardPrimary: "ANY",
    actionValue: ActionValue.NO
  }
];
