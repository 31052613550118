import styles from "./categoryStyles.module.scss";
import type { CategoryProps } from "./categoryTypes";

const Category = (props: CategoryProps): React.ReactElement => {
  const { icon, label, onClick } = props;

  return (
    <div className={styles.categoryContainer} onClick={onClick}>
      <div className={styles.category}>
        <img src={icon} alt="categoryIcon" />
      </div>
      <span className={styles.categoryLabel}>{label}</span>
    </div>
  );
};

export { Category };
