/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { RootState } from "../../app/store";
import Header from "../../components/header/header";
import styles from "./styles.module.scss";
import { Button2 } from "../../components";
import { handleOfferRedirectionLinkClick } from "../../utils/functions";

import { BenefitType } from "../../apis/benefitsApi/benefitsApiTypes";
import {
  getBenefitTexts,
  getOfferDetailsListSection
} from "../../utils/BenefitUtil";
import { PWA_MAX_WIDTH_IN_PX } from "../../utils/constants";
const CvpOfferDetails = () => {
  let { benefitId } = useParams();

  const benefits = useSelector((state: RootState) => state.benefits);
  const benefit = benefits.cvpBenefits.benefits?.find(
    (benefit) => benefit.benefitId === benefitId
  );
  const offerDetails = JSON.parse(benefit!.offerDetails);
  const headerText = benefit!.name;
  const title = benefit!.displayTitle;
  const illustrationUrl = benefit!.illustrationUrl;
  const logo = benefit!.logoUrl;
  const background = benefit!.displayColor;
  const redeemSteps = offerDetails.redeemSteps;
  const tncConditions = offerDetails.tncConditions;
  const benefitType = benefit!.benefitType;
  const ctaTextArr = offerDetails?.ctaText?.split(" ");
  const ctaText1 = ctaTextArr?.[0];
  const ctaText2 = ctaTextArr?.slice(1)?.join(" ");
  const { description } = getBenefitTexts(benefit!);
  const milestoneProgress = Math.min(
    (benefit!.currentValue / benefit!.threshold) * 100,
    100
  );

  return (
    <>
      <Header
        label={`Offer - ${headerText} `}
        bgColor="#010101"
        customClassName={styles.header}
      />
      <div className={styles.container}>
        <div className={styles.containerFill} style={{ background }}>
          {logo && <img src={logo} alt="logo" className={styles.logo} />}
          <img
            src="/images/patterns/cvpOffersBg.svg"
            alt="ripplePattern"
            className={styles.pattern}
          />
          {illustrationUrl && (
            <img
              className={styles.illustrationImage}
              src={illustrationUrl}
              alt="illustration"
            />
          )}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.titleText}>{title}</div>
        {benefitType === BenefitType.MILESTONE && (
          <div className={styles.progressBar}>
            <span
              style={{ width: `${milestoneProgress}%` }}
              className={styles.milestoneCompleted}
            ></span>
            <span
              style={{
                borderTopLeftRadius: milestoneProgress === 0 ? "6px" : "0px",
                borderBottomLeftRadius: milestoneProgress === 0 ? "6px" : "0px",
                width: `${100 - milestoneProgress}%`
              }}
              className={styles.milestoneRemaining}
            ></span>
            <div className={styles.milestoneText}>
              {milestoneProgress >= 100 ? "All done!" : description}
            </div>
          </div>
        )}
      </div>
      {getOfferDetailsListSection("How to redeem", redeemSteps)}
      {getOfferDetailsListSection("Terms and Conditions", tncConditions)}
      <div className={styles.extraHeight}></div>
      {offerDetails.ctaLink && (
        <div
          className={styles.btnContainer}
          style={{ maxWidth: `${PWA_MAX_WIDTH_IN_PX}px` }}
        >
          <Button2
            customClassName={styles.redeemOfferBtn}
            text1={!!ctaText1 ? ctaText1 : "redeem"}
            text2={!!ctaText1 ? ctaText2 || "" : "offer"}
            onClick={(e) =>
              handleOfferRedirectionLinkClick(e, offerDetails.ctaLink)
            }
          />
        </div>
      )}
    </>
  );
};

export default CvpOfferDetails;
