import React, { useState, useEffect, useRef } from "react";
import styles from "./appInstallScreen.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import { Button2 } from "../../components/button/Button";
import appInstallImg from "../../assets/images/appInstallImg.png";
import { useSelector } from "react-redux";
import { getEvent } from "../../features/event/eventSlice";
import { OsSupportForPrompt } from "../../utils/enums";
import { getUserOSAndVersion } from "../../utils/trackingFunctions";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import dayjs from "dayjs";
import { captureEvent } from "../../utils/functions";
import { API_REQUEST_DATE_FORMAT } from "../../utils/constants";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import IosInstallPrompt from "../../components/installAppPrompt/iosInstallPrompt";
import AppInstallSuccess from "../../components/installAppPrompt/appInstallSuccess";
import LottieView from "lottie-react";
import SpaceSetUpData from "../../assets/LottieImages/space-set-up-ram.json";

const AppInstallScreen = () => {
  const [isIosAppInstallPrompt, setIsIosAppInstallPrompt] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState<boolean>(false);
  const [isAnimationEnded, setIsAnimationEnded] = useState<boolean>(false);
  const spaceSetUpLottieRef = useRef<HTMLInputElement>(null);

  const userAgent = navigator.userAgent;
  const [deviceOs, osVersion] = getUserOSAndVersion(userAgent);
  let installPrompt = useSelector(getEvent);
  const SpaceSetUp = JSON.parse(JSON.stringify(SpaceSetUpData));

  useEffect(() => {
    return () => {
      if (spaceSetUpLottieRef.current) {
        spaceSetUpLottieRef.current.innerHTML = "";
      }
    };
  }, [isAnimationEnded]);

  const handleInstallPromptIgnore = () => {
    setIsIosAppInstallPrompt(!isIosAppInstallPrompt);
    handlecaptureInstallPrompt(
      "app_install_prompt_ignored",
      Number(sessionStorage.getItem("lagTimeForInstallPrompt"))
    );
  };

  const handleOnAnimationEnd = () => {
    setIsAnimationEnded(true);
  };

  const handlecaptureInstallPrompt = (
    type: string,
    lagTimeForInstallPrompt: number
  ) => {
    let currentDate = dayjs().format(API_REQUEST_DATE_FORMAT);
    captureEvent({
      eventName: EVENT_NAME.APP_INSTALL_PROMPT,
      eventData: {
        eventType: type,
        lagTimeForInstallPrompt: lagTimeForInstallPrompt,
        date: currentDate
      }
    });
  };

  const handleInstallClick = async () => {
    // on click add to home page
    let lagTimeForInstallPrompt = Number(
      sessionStorage.getItem("lagTimeForInstallPrompt")
    );
    if (!installPrompt && deviceOs === OsSupportForPrompt.ios) {
      setIsIosAppInstallPrompt(true);
      handlecaptureInstallPrompt(
        "app_install_prompt_click",
        lagTimeForInstallPrompt
      );
      return;
    }

    if (!installPrompt) {
      return;
    }
    const result = await installPrompt.event.prompt();
    // eslint-disable-next-line
    if (result.outcome == "accepted") {
      // setIsAppInstallPrompt(false);
      setIsAppInstalled(true);
      handlecaptureInstallPrompt(
        "app_install_prompt_click",
        lagTimeForInstallPrompt
      );
    } else {
      handlecaptureInstallPrompt(
        "app_install_prompt_close",
        lagTimeForInstallPrompt
      );
    }

    // installPrompt = null;
  };
  return isAnimationEnded ? (
    isAppInstalled ? (
      <AppInstallSuccess />
    ) : (
      <>
        <div className={styles.preLoginContainer}>
          <div className={styles.header}>
            <div>
              <img src={IndusInd_Logo} alt="IndusInd Bank" />
            </div>
          </div>

          <div className={styles.welcomeContainer}>
            <div className={styles.headerTextContainer}>one last thing ...</div>
            <span className={styles.text1}>Secure your access</span>
            to IndusInd Bank CardsHub
          </div>

          <div className={styles.cardContainer}>
            <img src={appInstallImg} alt="App Install Image" />
          </div>

          <div className={styles.banner2}>
            <div className={styles.bottomtext1Container}>
              <span
                className={styles.text1}
                style={{ color: "rgba(250, 250, 250, 1)" }}
              >
                Add CardsHub as a shortcut
              </span>
              <span style={{ color: "rgb(100, 98, 98)" }}>
                on your mobile homescreen
              </span>
            </div>
            <hr className={styles.horizontalLine2}></hr>

            <div className={styles.bottomtext2Container}>
              This is required to securely access CardsHub
            </div>
          </div>

          <div className={styles.btnContainer}>
            <Button2
              text1="add shortcut"
              text1Style={{ fontWeight: "bold" }}
              text2="to homescreen"
              customClassName={styles.getStartedButton}
              onClick={handleInstallClick}
            />
          </div>
        </div>

        <BottomsheetComponent
          isBottomSheetOpen={isIosAppInstallPrompt}
          setIsBottomSheetOpen={handleInstallPromptIgnore}
          render={
            <>
              <IosInstallPrompt
                setIsIosBottomSheetOpen={setIsIosAppInstallPrompt}
              />
            </>
          }
        />
      </>
    )
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <img src={IndusInd_Logo} alt="IndusInd Bank" />
          </div>
        </div>

        <div className={styles.spaceSetUpLottie} ref={spaceSetUpLottieRef}>
          <LottieView
            animationData={SpaceSetUp}
            loop={false}
            onComplete={handleOnAnimationEnd}
            className={styles.spaceSetUpLottie}
          />
        </div>
        <div className={styles.spaceSetUpText}>
          Your Space has been
          <span style={{ display: "block" }}>set up!</span>
        </div>
      </div>
    </>
  );
};

export default AppInstallScreen;
