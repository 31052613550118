import type {
  Challenge,
  GetAuthConfigResponse
} from "../../apis/appApi/appApiTypes";

export enum AuthStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELLED = "CANCELLED"
}

export interface FinishAuthWithSuccessParams {
  status: AuthStatus.SUCCESS;
}

export interface FinishAuthWithFailureParams {
  status: AuthStatus.FAILURE;
  error: any;
  message: string;
}

export interface FinishAuthWithCancelledParams {
  status: AuthStatus.CANCELLED;
}

export type FinishAuthParams =
  | FinishAuthWithSuccessParams
  | FinishAuthWithFailureParams
  | FinishAuthWithCancelledParams;

export interface PropertiesResetOnAuthFinish {
  apiId: string | null;
  apiToken: string | null;
  finishAuth: ((params: FinishAuthParams) => void) | null;
  challenges: Array<Challenge>;
  currentChallenge: Challenge | null;
  actionText: string;
  isAuthScreenOpaque: boolean;
}

export interface VerifiedChallenges {
  deviceId: string | null;
  smsOtpRefId: string | null;
  emailOtpRefId: string | null;
  mpinRefId: string | null;
  customerRefId: string | null;
}

export interface AuthState extends PropertiesResetOnAuthFinish {
  verifiedChallenges: VerifiedChallenges;
  config: GetAuthConfigResponse | null;
}

export type SetAuthConfigActionPayload = GetAuthConfigResponse;

export interface SetAuthStateActionPayload {
  apiId: string;
  apiToken: string;
  challenges: Array<Challenge>;
  currentChallenge: Challenge;
  actionText: string;
  isAuthScreenOpaque: boolean;
  finishAuth: (params: FinishAuthParams) => void;
}

export type SetCurrentChallengeActionPayload = Challenge | null;

export type SetVerifiedChallengesActionPayload = VerifiedChallenges;
