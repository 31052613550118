/*  eslint-disable react/no-unstable-nested-components, */
import React from "react";
import styles from "./incognitoScreen.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import RectangleOverHeadLight from "../../assets/svgs/rectangleOverHeadLight.svg";
import UniqueUrlWrongImage from "../../assets/svgs/inconitoModeImage.svg";
import QuarterCirclePattern from "../../assets/svgs/patterns/quarterCirclePattern.svg";

const IncognitoScreen = () => {
  return (
    <>
      <div className={styles.preLoginContainer}>
        <img
          src={RectangleOverHeadLight}
          alt=""
          className={styles.overHeadLight}
        />
        <img
          src={QuarterCirclePattern}
          alt=""
          className={styles.overHeadLight}
        />
        <div className={styles.header}>
          <div>
            <img src={IndusInd_Logo} alt="IndusInd Bank" />
          </div>
        </div>

        <div className={styles.welcomeText}>IndusInd Bank CardsHub</div>

        <div className={styles.imageContainer}>
          <img src={UniqueUrlWrongImage} alt="App Install Image" />
        </div>

        <div className={styles.banner2}>
          <div className={styles.bottomtext1Container}>
            CardsHub cannot be accessed in{" "}
            <span style={{ display: "block" }}>
              incognito mode for security reasons
            </span>
          </div>

          <div className={styles.bottomtext2Container}>
            You can access CardsHub on your normal tabs
            <span style={{ display: "block" }}>
              using your{" "}
              <span className={styles.highlightedText}>secured login link</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncognitoScreen;
