/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useState } from "react";
import Header from "../header/cardHeader";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import call from "../../assets/svgs/call.svg";
import button2Icon from "../../assets/svgs/button2Icon.svg";
import MultipleCards from "../../assets/svgs/MultipleCards.svg";
import lockImage from "../../assets/svgs/LockImage.svg";
import warningImage from "../../assets/svgs/warningImage.svg";
import sms from "../../assets/svgs/sms.svg";
import { useSelector } from "react-redux";
import { getCardDetails } from "../../features/user/userSlice";
import { CONTACT_MOBILE_NUMBER } from "../../utils/constants";

const AccountClosed = () => {
  const location = useLocation();
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [showText, setShowText] = useState<string>(
    location.state && location.state.type
  );
  const cardDetails = useSelector(getCardDetails);
  return (
    <div className={styles.accountClosed}>
      <Header card={cardDetails!} />
      <hr className={styles.topDivider} />
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.backgroundEllipse}></div>
          <div className={styles.bottomEllipse}></div>
          <img src={MultipleCards} alt="cards" className={styles.cards} />
          {showText === "account closed" ? (
            <img
              src={warningImage}
              alt="warningImage"
              className={styles.warning}
            />
          ) : (
            <img src={lockImage} alt="lockImage" className={styles.lock} />
          )}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.message}>
            <div>{showText}</div>
            <div>For more information, contact customer care</div>
          </div>
          <div className={styles.contactUs}>
            <div className={styles.contactContainer}>
              <div className={styles.contactIconContainer}>
                <img src={call} alt="icon" className={styles.contactIcon} />
              </div>
              <div className={styles.contactTextContainer}>
                <div className={styles.contactText1}>
                  Call us on these numbers (24x7)
                </div>
                <div className={styles.contactText2}>
                  {`${CONTACT_MOBILE_NUMBER}`}<span> / </span>022 4220 7777
                </div>
              </div>
              <div className={styles.btn}>
                <img src={button2Icon} alt="icon" className={styles.btnIcon} />
              </div>
            </div>

            <div className={styles.divider}>
              <hr />
              or
              <hr />
            </div>

            <div className={styles.contactContainer}>
              <div className={styles.contactIconContainer}>
                <img src={sms} alt="icon" className={styles.contactIcon} />
              </div>
              <div className={styles.contactTextContainer}>
                <div className={styles.contactText1}>Email us at</div>
                <div className={styles.contactText2}>
                  premium.care@indusind.com
                </div>
              </div>
              <div className={styles.btn}>
                <img src={button2Icon} alt="icon" className={styles.btnIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountClosed;
