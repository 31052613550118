import auraVisa from "../assets/svgs/cards/auraVisa.jpg";
import auraMasterCard from "../assets/svgs/cards/auraMasterCard.jpg";
import pinnacleVisa from "../assets/svgs/cards/pinnacleVisa.jpg";
import pinnacleMasterCard from "../assets/svgs/cards/pinnacleMasterCard.jpg";
import legendVisa from "../assets/svgs/cards/legendVisa.jpg";
import clubVistaraVisa from "../assets/svgs/cards/clubVistaraExplorerVisa.jpg";
import platinumSelectVisa from "../assets/svgs/cards/platinumSelectVisa.png";
import indulgeVisa from "../assets/svgs/cards/indulgeVisa.png";
import iconiaVisa from "../assets/svgs/cards/iconiaVisa.png";
import iconiaAmex from "../assets/svgs/cards/iconicaAmex.png";
import odysseyVisa from "../assets/svgs/cards/IntermilesOdysseyVisa.png";
import odysseyAmex from "../assets/svgs/cards/intermilesOdysseyAmex.png";
import voyageVisa from "../assets/svgs/cards/intermilesVoyageVisa.png";
import voyageAmex from "../assets/svgs/cards/intermilesVoyageAmex.png";
import legendMastercard from "../assets/svgs/cards/legendMastercard.png";
import duoIndusInd from "../assets/svgs/cards/duoVisa.png";
import crestMastercard from "../assets/svgs/cards/crestMastercard.png";
import nexxtMastercard from "../assets/svgs/cards/nexxtMastercard.png";
import pioneerHeritageVisa from "../assets/svgs/cards/pioneerHeritageVisa.jpg";
import pioneerHeritageMastercard from "../assets/svgs/cards/pioneerHeritageMastercard.png";
import pioneerLegacyVisa from "../assets/svgs/cards/pioneerLegacyVisa.jpg";
import pioneerLegacyMastercard from "../assets/svgs/cards/pioneerLegacyMastercard.jpg";
import celestaAmex from "../assets/svgs/cards/celestaAmex.png";
import signatureVisa from "../assets/svgs/cards/signatureVisa.png";
import auraMaster from "../assets/svgs/cards/auraMaster.png";
import eazydinerVisa from "../assets/svgs/cards/eazydinerVisa.png";
import avoisBritishAirways from "../assets/svgs/cards/avoisBritishAirways.svg";
import bajajCapitalVisa from "../assets/svgs/cards/bajajCapitalVisa.jpg";
import genericPlatinum from "../assets/svgs/cards/genericPlatinum.png";
import sammanRupay from "../assets/svgs/cards/sammanRupay.svg";
import platinumRupay from "../assets/svgs/cards/platinumRupay.svg";

import blurredAuraEdgeMasterCard from "../assets/svgs/config/blurredAuraEdgeMasterCard.png";
import blurredAuraEdgeVisa from "../assets/svgs/config/blurredAuraEdgeVisa.png";
import blurredPinnacleMasterCard from "../assets/svgs/config/blurredPinnacleMasterCard.png";
import blurredPinnacleVisa from "../assets/svgs/config/blurredPinnacleVisa.png";
import blurredLegendVisa from "../assets/svgs/config/blurredLegendVisa.png";
import blurredPlatinumRupay from "../assets/svgs/config/blurredPlatinumRupay.svg";
import blurredSammanRupay from "../assets/svgs/config/blurredSammanRupay.svg";
import blurredGenericPlatinum from "../assets/svgs/config/blurredGenericPlatinum.svg";
import blurredBajajCapitalVisa from "../assets/svgs/config/blurredBajajCapitalVisa.svg";
import blurredAvoidBritishAirways from "../assets/svgs/config/blurredAvoisBritishAirways.svg";
import blurredEazydinerVisa from "../assets/svgs/config/blurredEazydinerVisa.svg";
import blurredAuraMaster from "../assets/svgs/config/blurredAuraMaster.svg";
import blurredCelestaAmex from "../assets/svgs/config/blurredCelesta.svg";
import blurredSignatureVisa from "../assets/svgs/config/blurredVisaSignature.svg";
import blurredPioneerLegacyMastercard from "../assets/svgs/config/blurredPioneerLegacyMastercard.png";
import blurredPioneerLegacyVisa from "../assets/svgs/config/blurredPioneerLegacyVisa.png";
import blurredPioneerHeritageVisa from "../assets/svgs/config/blurredPioneerHeritageVisa.svg";
import blurredPioneerHeritageMastercard from "../assets/svgs/config/blurredPioneerHeritageMastercard.svg";
import blurredNexxtMastercard from "../assets/svgs/config/blurredNexxtMastercard.png";
import blurredCrestMastercard from "../assets/svgs/config/blurredCrestMastercard.png";
import blurredDuoIndusInd from "../assets/svgs/config/blurredDuoVisa.png";
import blurredVoyageVisa from "../assets/svgs/config/blurredIntermilesVoyageVisa.png";
import blurredVoyageAmex from "../assets/svgs/config/blurredIntermilesVoyageAmex.png";
import blurredIconiaAmex from "../assets/svgs/config/blurredIconicaAmex.svg";
import blurredIconiaVisa from "../assets/svgs/config/blurredIconiaVisa.svg";
import blurredIndulgeVisa from "../assets/svgs/config/blurredIndulgeVisa.png";
import blurredPlatinumSelectVisa from "../assets/svgs/config/blurredPlatinumSelectVisa.svg";
import blurredClubVistaraVisa from "../assets/svgs/config/blurredClubVistaraVisa.png";
import blurredOdysseyVisa from "../assets/svgs/config/blurredIntermilesOdysseyVisa.png";
import blurredOdysseyAmex from "../assets/svgs/config/blurredIntermilesOdysseyAmex.png";
import blurredLegendMastercard from "../assets/svgs/config/blurredLegendMastercard.png";

import ApiIds from "../auth/ApiIds";
import store from "../app/store";
import { getSelectedProgram } from "../features/user/userSlice";
import { Env } from "../utils/enums";
import AppApi from "../apis/appApi/AppApi";

const UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 15;
const SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 15;
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const STRESS_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 15;
const PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 2;
const QA_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 5;

const customerSupport = {
  email: {
    text: "Email us at",
    link: "premium.care@indusind.com"
  }
};

const smsOtpApi = {
  tncText: ""
};

const smsOtpCreateEmiApi = {
  tncText:
    "By entering OTP and proceeding, I agree to the (Terms & Conditions)[https://www.indusind.com/in/en/personal/terms-and-conditions.html] and (Privacy Policy)[https://www.indusind.com/in/en/personal/privacy-policy.html] associated with EMI Conversion on IndusInd Bank Credit Card"
};

const smsOtp = {
  [ApiIds.UPDATE_CARD_CONTROLS]: smsOtpApi,
  [ApiIds.SET_CARD_PIN]: smsOtpApi,
  [ApiIds.FETCH_UNMASKED_CARD]: smsOtpApi,
  [ApiIds.CREATE_EMI]: smsOtpCreateEmiApi
};

type CardTypeInterface = {
  [key in Env]: {
    [key: string]: {
      cardType: string;
      gradient: string;
      image: {
        // Visa?: string;
        // Mastercard?: string;
        // AmericanExpress?: string;
        // Rupay?: string;
        [key: string]: string;
      };
      blurredImage: {
        // Visa?: string;
        // Mastercard?: string;
        // AmericanExpress?: string;
        // Rupay?: string;
        [key: string]: string;
      };
      embossedName?: {
        gradient: string;
        color: string;
      };
      customerSupport: {
        email: {
          text: string;
          link: string;
        };
      };
      smsOtp: {
        [key: string]: {
          tncText: string;
        };
      };
      sessionInactivityTimeoutInMinutes: number;
    };
  };
};

const devConfig = {
  //INDUSIND PLATINUM RUPAY logoId:802
  ccp_HYPmfj3dG6MoEDPS1wE: {
    cardType: "platinum rupay",
    gradient:
      "linear-gradient(97.25deg, #102232 6.83%, #0A1B26 59.05%, #07121A 88.75%)",
    image: {
      Rupay: platinumRupay
    },
    blurredImage: {
      Rupay: blurredPlatinumRupay
    },
    customerSupport: {
      email: {
        text: "Email us at",
        link: "premium.care@indusind.com"
      }
    },
    smsOtp,
    sessionInactivityTimeoutInMinutes:
      PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  },
  ccp_HYPcEpFHWV95sq06RXE: {
    cardType: "pinnacle",
    gradient:
      "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
    image: {
      Visa: pinnacleVisa,
      Mastercard: pinnacleMasterCard
    },
    blurredImage: {
      Visa: blurredPinnacleVisa,
      Mastercard: blurredPinnacleMasterCard
    },
    embossedName: {
      gradient:
        "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
      color: "#FFF"
    },
    customerSupport,
    smsOtp,
    sessionInactivityTimeoutInMinutes: UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  },
  ccp_HYPvkxELpihgbHV9WH2: {
    cardType: "aura edge",
    gradient:
      "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
    image: {
      Visa: auraVisa,
      Mastercard: auraMasterCard
    },
    blurredImage: {
      Visa: blurredAuraEdgeVisa,
      Mastercard: blurredAuraEdgeMasterCard
    },
    embossedName: {
      gradient:
        "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
      color: "#FFF"
    },
    customerSupport,
    smsOtp,
    sessionInactivityTimeoutInMinutes: UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  },
  ccp_HYPrDDNkuxNVz7UKqMr: {
    cardType: "legend",
    gradient:
      "linear-gradient(160deg, #3B3F40 9.52%, #393E3F 50.15%, #201F1F 100%)",
    image: {
      Visa: legendVisa
    },
    blurredImage: {
      Visa: blurredLegendVisa
    },
    embossedName: {
      gradient:
        "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
      color: "#FFF"
    },
    customerSupport,
    smsOtp,
    sessionInactivityTimeoutInMinutes: UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  },
  ccp_HYPvlSkLW9Q85mWrNIo: {
    cardType: "pinnacle",
    gradient:
      "linear-gradient(160deg, #3B3F40 9.52%, #393E3F 50.15%, #201F1F 100%)",
    image: {
      Visa: pinnacleVisa
    },
    blurredImage: {
      Visa: blurredPinnacleVisa
    },
    embossedName: {
      gradient:
        "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
      color: "#FFF"
    },
    customerSupport,
    smsOtp,
    sessionInactivityTimeoutInMinutes: UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  },
  ccp_HYP9LamnhstSq8tIblK: {
    cardType: "club vistara",
    gradient:
      "linear-gradient(163deg, #1C1912 9.52%, #312918 43.23%, #443E14 100%);",
    image: {
      Visa: clubVistaraVisa
    },
    blurredImage: {
      Visa: blurredClubVistaraVisa
    },
    embossedName: {
      gradient:
        "linear-gradient(97deg, rgba(29, 26, 19, 0.64) 23.34%, rgba(53, 45, 25, 0.64) 48.87%, rgba(61, 55, 23, 0.64) 88.76%)",
      color: "#FFF"
    },
    customerSupport,
    smsOtp,
    sessionInactivityTimeoutInMinutes: UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
  }
};

const uatConfig = {
    //INDUSIND PLATINUM RUPAY logoId:802
    ccp_HYPmfj3dG6MoEDPS1wE: {
      cardType: "platinum rupay",
      gradient:
        "linear-gradient(97.25deg, #102232 6.83%, #0A1B26 59.05%, #07121A 88.75%)",
      image: {
        Rupay:"https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/platinumRupay.svg"
      },
      blurredImage: {
        Rupay: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredPlatinumRupay.svg"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPcEpFHWV95sq06RXE: {
      cardType: "pinnacle",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/pinnacleVisa.jpg",
        Mastercard: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/pinnacleMasterCard.jpg"
      },
      blurredImage: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredPinnacleVisa.png",
        Mastercard: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredPinnacleMasterCard.png"
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPvkxELpihgbHV9WH2: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/auraVisa.jpg",
        Mastercard: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/auraMasterCard.jpg"
      },
      blurredImage: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredAuraEdgeVisa.png",
        Mastercard: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredAuraEdgeMasterCard.png"
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPrDDNkuxNVz7UKqMr: {
      cardType: "legend",
      gradient:
        "linear-gradient(160deg, #3B3F40 9.52%, #393E3F 50.15%, #201F1F 100%)",
      image: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/legendVisa.jpg"
      },
      blurredImage: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredLegendVisa.png"
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPvlSkLW9Q85mWrNIo: {
      cardType: "pinnacle",
      gradient:
        "linear-gradient(160deg, #3B3F40 9.52%, #393E3F 50.15%, #201F1F 100%)",
      image: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/pinnacleVisa.jpg"
      },
      blurredImage: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredPinnacleVisa.png"
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYP9LamnhstSq8tIblK: {
      cardType: "club vistara",
      gradient:
        "linear-gradient(163deg, #1C1912 9.52%, #312918 43.23%, #443E14 100%);",
      image: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/clubVistaraExplorerVisa.jpg"
      },
      blurredImage: {
        Visa: "https://pwa-assets.uat.hyperface.co/pwa/ibl/program_configs/blurredClubVistaraVisa.png"
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(29, 26, 19, 0.64) 23.34%, rgba(53, 45, 25, 0.64) 48.87%, rgba(61, 55, 23, 0.64) 88.76%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
      UAT_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    }
  };

export const CardType: CardTypeInterface = {
  dev: devConfig,
  dev_mobile: devConfig,
  uat: uatConfig,
  sandbox: {
    ccp_HYPKBTVWNlWoPPirlet: {
      cardType: "platinum rupay",
      gradient:
        "linear-gradient(97.25deg, #102232 6.83%, #0A1B26 59.05%, #07121A 88.75%)",
      image: {
        Rupay: platinumRupay
      },
      blurredImage: {
        Rupay: blurredPlatinumRupay
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYP04oD7UhscvIeyjaT: {
      cardType: "pinnacle",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: pinnacleVisa,
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Visa: blurredPinnacleVisa,
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPhKAsMz4BEpcEKc4N: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa,
        Mastercard: auraMasterCard
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa,
        Mastercard: blurredAuraEdgeMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYP4mIC4CcCNgmWjIhl: {
      cardType: "legend visa",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: legendVisa
      },
      blurredImage: {
        Visa: blurredLegendVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPvGzOU2rSmKGzVOkA: {
      cardType: "pinnacle world",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    }
  },
  stress: {
    // dummy config
    ccp_HYPhKAsMz4BEpcEKc4N: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa,
        Mastercard: auraMasterCard
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa,
        Mastercard: blurredAuraEdgeMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        SANDBOX_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    }
  },
  prod: {
    //INDUSIND PLATINUM AURA EDGE VISA logoId:651
    ccp_HYPS0gv26zoeIuBtczF: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //CREST MASTER logoId:663
    ccp_HYP5QmcLYUVM9J99uKv: {
      cardType: "crest",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: crestMastercard
      },
      blurredImage: {
        Mastercard: blurredCrestMastercard
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "crest.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM CONTACTLESS logoId:676
    ccp_HYPcZ2daRxlQI2b712h: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDULGE CONTACTLESS logoId:677
    ccp_HYPX9ckxCaYJq7ZWVQg: {
      cardType: "indulge",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: indulgeVisa
      },
      blurredImage: {
        Visa: blurredIndulgeVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "indulge.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //CELESTA logoId:683
    ccp_HYPeF0SzUzLiMnthmhe: {
      cardType: "celesta",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        AmericanExpress: celestaAmex
      },
      blurredImage: {
        AmericanExpress: blurredCelestaAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "celesta.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PINNACLE WORLD CONTACTLESS logoId:685
    ccp_HYPgVOS8ctcQdA0rGx9: {
      cardType: "pinnacle world",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //VISA CLUB VISTARA EXPLORER logoId:705
    ccp_HYPCL2UN1aenpvHNqKR: {
      cardType: "vistara visa",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: clubVistaraVisa
      },
      blurredImage: {
        Visa: blurredClubVistaraVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(29, 26, 19, 0.64) 23.34%, rgba(53, 45, 25, 0.64) 48.87%, rgba(61, 55, 23, 0.64) 88.76%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND EAZYDINER SIGNATURE logoId:706
    ccp_HYPsLB2nIkeF42EbOvO: {
      cardType: "eazydiner",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: eazydinerVisa
      },
      blurredImage: {
        Visa: blurredEazydinerVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //AVIOS CREDIT CARD BRITISH ARIWAYS logoId:760
    ccp_HYP9kW8HmxEmzuUk7OA: {
      cardType: "avios",
      gradient: "", //To be updated
      image: {
        Visa: avoisBritishAirways
      },
      blurredImage: {
        Visa: blurredAvoidBritishAirways
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //CUG LIST 2nd Revision
    //INDUSIND PLATINUM logoId:607
    ccp_HYPmO3eta9oj4mLQBtD: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM SELECT logoId:617
    ccp_HYPe2IbqtDL49jpmv1i: {
      cardType: "platinum select",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: platinumSelectVisa
      },
      blurredImage: {
        Visa: blurredPlatinumSelectVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND SIGNATURE logoId:620
    ccp_HYP2bWPRGaotvqbBQMV: {
      cardType: "signature",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: signatureVisa
      },
      blurredImage: {
        Visa: blurredSignatureVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDULGE logoId:623
    ccp_HYPthmqoIGTvojhD9S3: {
      cardType: "indulge",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: indulgeVisa
      },
      blurredImage: {
        Visa: blurredIndulgeVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "indulge.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM logoId:624
    ccp_HYPWWTtCVWqWhQIcdRh: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA VISA logoId:627
    ccp_HYPWzY5BRnJJi66aqFg: {
      cardType: "iconia visa",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Visa: iconiaVisa
      },
      blurredImage: {
        Visa: blurredIconiaVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA AMEX logoId:628
    ccp_HYP5XW9l32SrspRsY2J: {
      cardType: "iconia amex",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        AmericanExpress: iconiaAmex
      },
      blurredImage: {
        AmericanExpress: blurredIconiaAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND SIGNATURE logoId:632
    ccp_HYPhm10FZnZ0tOxzLqe: {
      cardType: "signature",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: signatureVisa
      },
      blurredImage: {
        Visa: blurredSignatureVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ODYSSEY VISA logoId: 634
    ccp_HYPRxGVOemKOFf6tqOh: {
      cardType: "odyssey visa",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)",
      image: {
        Visa: odysseyVisa
      },
      blurredImage: {
        Visa: blurredOdysseyVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM SELECT logoId:638
    ccp_HYP6VcJwvMUjqwIJ8oZ: {
      cardType: "platinum select",
      gradient:
        "linear-gradient(109deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: platinumSelectVisa
      },
      blurredImage: {
        Visa: blurredPlatinumSelectVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA VISA logoId:639
    ccp_HYPFlsI9MZxVWJcroJj: {
      cardType: "iconia visa",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Visa: iconiaVisa
      },
      blurredImage: {
        Visa: blurredIconiaVisa
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA AMEX logoId:642
    ccp_HYP9AJ3iRhkQHuPoM3i: {
      cardType: "iconia amex",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        AmericanExpress: iconiaAmex
      },
      blurredImage: {
        AmericanExpress: blurredIconiaAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM logoId:644
    ccp_HYPt1DHKKComKtbtpeA: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: genericPlatinum
      },
      blurredImage: {
        Mastercard: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDULGE logoId:646
    ccp_HYP8MWHZTkTfUs5qIQV: {
      cardType: "indulge",
      gradient:
        "linear-gradient(109.04deg, #1C1912 22.11%, #312918 48.74%, #443E14 93.6%)",
      image: {
        Visa: indulgeVisa
      },
      blurredImage: {
        Visa: blurredIndulgeVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "indulge.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLAT AURA EDGE MASTER logoId:652
    ccp_HYPOr6tm7EAyJl45uW7: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Mastercard: auraMasterCard
      },
      blurredImage: {
        Mastercard: blurredAuraEdgeMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //SIGNATURE LEGEND VISA logoId:654
    ccp_HYPEo3patBsLUEFuOoM: {
      cardType: "legend visa",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: legendVisa
      },
      blurredImage: {
        Visa: blurredLegendVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND BANK DUO logoId:657
    ccp_HYPPGaKq2VvU3cIkhTG: {
      cardType: "duo",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Visa: duoIndusInd
      },
      blurredImage: {
        Visa: blurredDuoIndusInd
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "duo.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA logoId:658
    ccp_HYPZ7eNqibAptEoZlV7: {
      cardType: "iconia amex",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        AmericanExpress: iconiaAmex
      },
      blurredImage: {
        AmericanExpress: blurredIconiaAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ODYSSEY AMEX logoId: 659
    ccp_HYPYoWm4wY74Tg0W2ya: {
      cardType: "odyssey amex",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)", //Intermiles
      image: {
        AmericanExpress: odysseyAmex
      },
      blurredImage: {
        AmericanExpress: blurredOdysseyAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM AURA logoId:662
    ccp_HYPgJjusDs5VlyndQ9v: {
      cardType: "platinum aura",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //NEXXT logoId:667
    ccp_HYPwImL4gGtewfhSJCm: {
      cardType: "nexxt",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)",
      image: {
        Mastercard: nexxtMastercard
      },
      blurredImage: {
        Mastercard: blurredNexxtMastercard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //LEGACY logoId:673
    ccp_HYPrPXwDQUKxMcxr9BX: {
      cardType: "legacy",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Mastercard: pioneerLegacyMastercard
      },
      blurredImage: {
        Mastercard: blurredPioneerLegacyMastercard
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ICONIA VISA CONTACTLESS logoId:687
    ccp_HYPLNKneQtArssSwQ93: {
      cardType: "iconia visa",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Visa: iconiaVisa
      },
      blurredImage: {
        Visa: blurredIconiaVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //ICONIA AMEX CONTACTLESS logoId:688
    ccp_HYPR2BSAjKQNzHG02fH: {
      cardType: "iconia amex",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        AmericanExpress: iconiaAmex
      },
      blurredImage: {
        AmericanExpress: blurredIconiaAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //LEGEND MASTERCARD logoId:698
    ccp_HYPcOFnZQ4FXPeBTxBR: {
      cardType: "legend mastercard",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: legendMastercard
      },
      blurredImage: {
        Mastercard: blurredLegendMastercard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PIONNER HERITAGE VISA logoId:752
    ccp_HYPD4c5zS7Vg5eDqxwR: {
      cardType: "heritage",
      gradient:
        "linear-gradient(97.35deg, rgba(187, 188, 178, 0.9) 11.21%, rgba(217, 216, 208, 0.9) 31.01%, rgba(247, 245, 236, 0.9) 50.96%, rgba(214, 212, 203, 0.9) 69.92%, rgba(183, 181, 174, 0.9) 87.96%)",
      image: {
        Visa: pioneerHeritageVisa
      },
      blurredImage: {
        Visa: blurredPioneerHeritageVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //BAJAJ CAPITAL logoId:755
    ccp_HYPLLp9cGZKRLdQYWoH: {
      cardType: "tiger",
      gradient:
        "linear-gradient(106.27deg, rgba(40, 40, 40, 0.7) 18.05%, rgba(40, 40, 40, 0.7) 43.5%, rgba(32, 31, 31, 0.7) 74.73%)",
      image: {
        Visa: bajajCapitalVisa
      },
      blurredImage: {
        Visa: blurredBajajCapitalVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //All remaining programs
    //INDUSIND PLATINUM EXECUTIVE logoId:609
    ccp_HYPHss4YXzLucrvfxRz: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //INDUSIND VISA PLATINUM AURA logoId:622
    ccp_HYPTDh4fDzA613Wa9Mn: {
      cardType: "platinum aura",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //ODYSSEY logoId: 633
    ccp_HYPjofTrZ61V6t55vx3: {
      cardType: "odyssey amex",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)", //Intermiles
      image: {
        AmericanExpress: odysseyAmex
      },
      blurredImage: {
        AmericanExpress: blurredOdysseyAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //VOYAGE logoId:635
    ccp_HYPOQjesgOnNw80yifw: {
      cardType: "voyage amex",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)", //Intermiles
      image: {
        AmericanExpress: voyageAmex
      },
      blurredImage: {
        AmericanExpress: blurredVoyageAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //VOYAGE logoId:636
    ccp_HYPt1UdFHIiUDmBTRn9: {
      cardType: "voyage visa",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)", //Intermiles
      image: {
        Visa: voyageVisa
      },
      blurredImage: {
        Visa: blurredVoyageVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //INDUSIND VISA PLATINUM AURA  logoId:637
    ccp_HYPl9OkR5jFx7e85b9I: {
      cardType: "platinum aura",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: genericPlatinum
      },
      blurredImage: {
        Visa: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //PLATINUM AURA MASTERCARD logoId:643
    ccp_HYPizRLK7gBmTlzvkPy: {
      cardType: "platinum aura",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: auraMaster
      },
      blurredImage: {
        Mastercard: blurredAuraMaster
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //PINNACLE WORLD MASTERCARD logoId:645
    ccp_HYPQw6A0fDxUcnniOf7: {
      cardType: "pinnacle",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },

    //VOYAGE logoId:660
    ccp_HYPpWDvWlWOvb3BcUrX: {
      cardType: "voyage amex",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        AmericanExpress: voyageAmex
      },
      blurredImage: {
        AmericanExpress: blurredVoyageAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PIONNER HERITAGE MASTER logoId:669
    ccp_HYPxl8itKZsux8Dh0oc: {
      cardType: "pioneer heritage",
      gradient:
        "linear-gradient(97.35deg, rgba(187, 188, 178, 0.9) 11.21%, rgba(217, 216, 208, 0.9) 31.01%, rgba(247, 245, 236, 0.9) 50.96%, rgba(214, 212, 203, 0.9) 69.92%, rgba(183, 181, 174, 0.9) 87.96%)",
      image: {
        Mastercard: pioneerHeritageMastercard
      },
      blurredImage: {
        Mastercard: blurredPioneerHeritageMastercard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(187, 188, 178, 0.64) 11.21%, rgba(217, 216, 208, 0.64) 31.01%, rgba(246, 245, 236, 0.64) 50.96%, rgba(214, 212, 203, 0.64) 69.92%, rgba(183, 181, 174, 0.64) 87.96%)",
        color: "#212121"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //LEGACY logoId:671
    ccp_HYP2O1GkpTJY4U0t3G9: {
      cardType: "legacy",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Mastercard: pioneerLegacyMastercard
      },
      blurredImage: {
        Mastercard: blurredPioneerLegacyMastercard
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PLATINUM MASTER CONTACTLESS logoId:684
    ccp_HYPJv7aUItrYHpmcYNh: {
      cardType: "platinum",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: genericPlatinum
      },
      blurredImage: {
        Mastercard: blurredGenericPlatinum
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PLATINUM AURA EDGE VISA logoId:686
    ccp_HYP9RaLjJvjDRph6vCu: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INTERMILES VOYAGE VISA logoId:689
    ccp_HYPSUbZOQlJCh28JzbQ: {
      cardType: "intermiles voyage",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)", //To be updated
      image: {
        Visa: voyageVisa
      },
      blurredImage: {
        Visa: blurredVoyageVisa
      },
      embossedName: {
        gradient: "rgba(58, 5, 20, 0.64)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INTERMILES VOYAGE AMEX logoId:692
    ccp_HYPDyZecABEBRUuErrg: {
      cardType: "intermiles voyage",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)", //To be updated
      image: {
        AmericanExpress: voyageAmex
      },
      blurredImage: {
        AmericanExpress: blurredVoyageAmex
      },
      embossedName: {
        gradient: "rgba(58, 5, 20, 0.64)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INTERMILES ODYSSEY VISA logoId:690
    ccp_HYPxyIndJOnH2PI64Wp: {
      cardType: "intermiles odyssey",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)",
      image: {
        Visa: odysseyVisa
      },
      blurredImage: {
        Visa: blurredOdysseyVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INTERMILES ODYSSEY AMEX logoId:691
    ccp_HYPAwg5QS5wj8ImF8A6: {
      cardType: "intermiles odyssey",
      gradient:
        "linear-gradient(111.31deg, #192148 19.6%, #122853 44.42%, #2A4BA7 86.22%)",
      image: {
        AmericanExpress: odysseyAmex
      },
      blurredImage: {
        AmericanExpress: blurredOdysseyAmex
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //PIONEER HERITAGE CREDIT CARD logoId:699
    ccp_HYPTXdQgRLbuL4I3A4X: {
      cardType: "heritage",
      gradient:
        "linear-gradient(97.35deg, rgba(187, 188, 178, 0.9) 11.21%, rgba(217, 216, 208, 0.9) 31.01%, rgba(247, 245, 236, 0.9) 50.96%, rgba(214, 212, 203, 0.9) 69.92%, rgba(183, 181, 174, 0.9) 87.96%)",
      image: {
        Mastercard: pioneerHeritageMastercard
      },
      blurredImage: {
        Mastercard: blurredPioneerHeritageMastercard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(187, 188, 178, 0.64) 11.21%, rgba(217, 216, 208, 0.64) 31.01%, rgba(246, 245, 236, 0.64) 50.96%, rgba(214, 212, 203, 0.64) 69.92%, rgba(183, 181, 174, 0.64) 87.96%)",
        color: "#212121"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //IBL BANK LEGACY VISA SIGNATURE logoId:751
    ccp_HYPaqatfGbrOybA4936: {
      cardType: "legacy visa",
      gradient:
        "linear-gradient(109.25deg, #390413 21.86%, #4A111B 43.22%, #6C2226 79.2%)",
      image: {
        Visa: pioneerLegacyVisa
      },
      blurredImage: {
        Visa: blurredPioneerLegacyVisa
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "pioneerrmassistance@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //AVIOS CREDIT CARD QATAR AIRWAYS logoId:757
    ccp_HYPPudJM1pDnBXkejIV: {
      cardType: "avios",
      gradient: "", //To be updated
      image: {
        Visa: avoisBritishAirways
      },
      blurredImage: {
        Visa: blurredAvoidBritishAirways
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //AURA EDGE GOVT CARD RUPAY logoId:801
    ccp_HYPBAhNuvlXxljRDDNL: {
      cardType: "samman",
      gradient:
        "linear-gradient(97.35deg, rgba(204, 187, 183, 0.8) 23.59%, rgba(210, 108, 84, 0.8) 49.46%, rgba(204, 187, 183, 0.8) 89.87%)",
      image: {
        Rupay: sammanRupay
      },
      blurredImage: {
        Rupay: blurredSammanRupay
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(255, 234, 228, 0.64) 23.59%, rgba(255, 245, 243, 0.64) 49.46%, rgba(255, 243, 240, 0.64) 89.87%)",
        color: "#870016"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //AURA EDGE GOVT CARD RUPAY logoId:702
    ccp_HYP1GOgsPhFEXEZL2IB: {
      cardType: "govt aura",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    //INDUSIND PLATINUM RUPAY logoId:802
    ccp_HYPuVCrl85BJPj4ZaXb: {
      cardType: "platinum rupay",
      gradient:
        "linear-gradient(97.25deg, #102232 6.83%, #0A1B26 59.05%, #07121A 88.75%)",
      image: {
        Rupay: platinumRupay
      },
      blurredImage: {
        Rupay: blurredPlatinumRupay
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        PROD_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    }
  },
  qa: {
    //INDUSIND PLATINUM RUPAY logoId:802
    ccp_HYPk6Zp83qR1EeigCpC: {
      cardType: "platinum rupay",
      gradient:
        "linear-gradient(97.25deg, #102232 6.83%, #0A1B26 59.05%, #07121A 88.75%)",
      image: {
        Rupay: platinumRupay
      },
      blurredImage: {
        Rupay: blurredPlatinumRupay
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "premium.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        QA_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPhKAsMz4BEpcEKc4N: {
      cardType: "aura edge",
      gradient:
        "linear-gradient(107.55deg, #311457 25.64%, #191758 50.71%, #06090E 92.94%)",
      image: {
        Visa: auraVisa,
        Mastercard: auraMasterCard
      },
      blurredImage: {
        Visa: blurredAuraEdgeVisa,
        Mastercard: blurredAuraEdgeMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(26, 34, 73, 0.64) 23.3%, rgba(19, 42, 86, 0.64) 48.84%, rgba(43, 76, 167, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        QA_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYP04oD7UhscvIeyjaT: {
      cardType: "pinnacle",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Visa: pinnacleVisa,
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Visa: blurredPinnacleVisa,
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport,
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        QA_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    },
    ccp_HYPvGzOU2rSmKGzVOkA: {
      cardType: "pinnacle world",
      gradient:
        "linear-gradient(106.27deg, #3B3F40 18.05%, #393E3F 43.5%, #201F1F 74.73%)",
      image: {
        Mastercard: pinnacleMasterCard
      },
      blurredImage: {
        Mastercard: blurredPinnacleMasterCard
      },
      embossedName: {
        gradient:
          "linear-gradient(97deg, rgba(60, 64, 65, 0.64) 23.3%, rgba(48, 51, 51, 0.64) 48.84%, rgba(33, 32, 32, 0.64) 88.75%)",
        color: "#FFF"
      },
      customerSupport: {
        email: {
          text: "Email us at",
          link: "priority.care@indusind.com"
        }
      },
      smsOtp,
      sessionInactivityTimeoutInMinutes:
        QA_SESSION_INACTIVITY_TIMEOUT_IN_MINUTES
    }
  }
};

export const getConfig = async () => {
  const state = store.getState();
  const program = getSelectedProgram(state);
  const card = state.user.customer?.accounts[0].cards[0];

  const configData = await AppApi.getConfigData({ programId: card?.programId! })

  if(configData.status !==200) {
    return null;
  }
  return process.env.REACT_APP_NAME && program
    ? // eslint-disable-next-line
    configData.data.response
    : null;
};

export const isProgramPresent = async (programId: string): Promise<boolean> => {
  const configData = await AppApi.getConfigData({ programId: programId! })

  if(configData.status !==200) {
    return false;
  }
  return true;
};

type IssuerId = {
  [key: string]: string;
};

export const issuerId: IssuerId = {
  [Env.DEV]: "bank_HYPwDQejsmi52Fl6r1R",
  [Env.DEV_MOBILE]: "bank_HYPwDQejsmi52Fl6r1R",
  [Env.UAT]: "bank_HYPwDQejsmi52Fl6r1R",
  [Env.SANDBOX]: "bank_HYPovDrzvt1nZwpim6E",
  [Env.PROD]: "bank_HYP3sfp7CGmt1KL5QSW",
  [Env.STRESS]: "bank_HYP6U2HbdWvSbYV7Hhj"
};

export const getIssuerId = () => {
  return process.env.REACT_APP_NAME ? issuerId[process.env.REACT_APP_NAME] : "";
};

type SupportedBrowsers = {
  [key: string]: string[];
};
export const supportedBrowsers: SupportedBrowsers = {
  iOS: ["Chrome", "Mobile Safari"],
  Android: ["Chrome"]
};

type SupportedBrowserVersions = {
  [key: string]: {
    [key: string]: number;
  };
};
export const supportedBrowserVersions: SupportedBrowserVersions = {
  iOS: {
    Chrome: 115,
    Firefox: 80,
    "Mobile Safari": 13
  },
  Android: {
    Chrome: 116,
    Firefox: 80
  }
};
