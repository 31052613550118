import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import sessionReducer from "../features/session/sessionSlice";
import userReducer from "../features/user/userSlice";
import authReducer from "../features/auth/authSlice";
import benefitsReducer from "../features/benefits/benefitsSlice";
import miscellaneousReducer from "../features/miscellaneous/miscellaneousSlice";
import eventReducer from "../features/event/eventSlice";
import outstandingToEmiReducer from "../features/outstandingToEmi/outstandingToEmiSlice";
import { Env } from "../utils/enums";

const enableReduxDevTools =
  process.env.REACT_APP_NAME !== Env.PROD &&
  process.env.REACT_APP_NAME !== Env.SANDBOX &&
  process.env.REACT_APP_NAME !== Env.STRESS;

const store = configureStore({
  reducer: {
    counter: counterReducer,
    session: sessionReducer,
    user: userReducer,
    auth: authReducer,
    benefits: benefitsReducer,
    miscellaneous: miscellaneousReducer,
    event: eventReducer,
    outstandingToEmi: outstandingToEmiReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // Disable serializable state invariant middleware
    }),
  devTools: enableReduxDevTools
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
