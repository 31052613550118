import styles from "./zeroStateCactus.module.scss";
import cactus from "../../assets/svgs/cactus.svg";
import { Button2 } from "../button/Button";

interface ZeroStateCactusProps {
  onButtonClick?: () => void;
  btnText1?: string;
  btnText2?: string;
  title1: string;
  title2: string;
  subTitle: string;
}

const ZeroStateCactus = (props: ZeroStateCactusProps) => {
  const { onButtonClick, btnText1, btnText2, title1, title2, subTitle } = props;
  return (
    <div className={styles.zeroState}>
      <div className={styles.zeroStateImage}>
        {" "}
        <img src={cactus} alt="cactus" />
      </div>
      <div className={styles.zeroStateTitle}>
        {title1}
        <span>{title2}</span>
      </div>
      <div className={styles.zeroStateText}>{subTitle}</div>
      {onButtonClick && (
        <Button2
          text1={btnText1 || "go to"}
          text2={btnText2 || "offers"}
          text1Style={{ fontWeight: "bold" }}
          customClassName={styles.zeroStateButton}
          onClick={onButtonClick}
        />
      )}
      <div className={styles.zeroStateBackground}></div>
    </div>
  );
};

export default ZeroStateCactus;
