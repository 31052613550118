/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import styles from "./viewTransactionsStyles.module.scss";
import {
  BottomSheet2,
  Header,
  Selector,
  toast,
  ToastType,
  Transaction as TransactionComponent
} from "../../components";
import { calendar } from "../../assets/svgs";
import cactus from "../../assets/svgs/cactus.svg";
import {
  getCustomer,
  getOtherCards,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import type { BillingCycle } from "../viewStatement/viewStatementTypes";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import logger from "../../utils/logger";
import {
  API_REQUEST_DATE_FORMAT,
  CORRELATION_ID_HEADER,
  TRANSACTIONS_PER_PAGE_COUNT
} from "../../utils/constants";
import {
  CARD_SUMMARY_ROUTE,
  CONVERT_TO_EMI_ROUTE
} from "../../routes/ScreenRoutes";
import {
  GetTransactionsRequest,
  GetUnbilledTransactionsRequest,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes";
import TransactionDetails from "./TransactionDetails";
import Loader from "../../components/loader/loader";
import {
  getSortedTransactions,
  getUniqueURLFromCookie
} from "../../utils/functions";
import { RootState } from "../../app/store";
import { RequestSources } from "../../utils/enums";

const ViewTransactions = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const statementSelectedCycle = location
    ? location?.statementSelectedCycle
    : {};

  const [statemetScreen] = useState<boolean>(
    !!statementSelectedCycle?.from && !!statementSelectedCycle?.to
  );

  const customer = useSelector(getCustomer)!;
  const account = useSelector(getSelectedAccount)!;
  const card = useSelector(getSelectedCard)!;
  const otherCardsData = useSelector(getOtherCards);
  const billingCycles = useSelector(
    (state: RootState) => state.miscellaneous.billingCycles
  )!;

  const transacationDate = localStorage.getItem("transactionPostingDate");
  const pathsHistory = !!localStorage.getItem("pathsHistory")
    ? JSON.parse(localStorage.getItem("pathsHistory")!)
    : null;

  let emiCycle: {
    from: string;
    to: string;
  } = { from: "", to: "" };

  const [isTransactionsLoading, setIsTransactionsLoading] =
    useState<boolean>(false);
  const [isCycleSelectionOpen, setIsCycleSelectionOpen] =
    useState<boolean>(false);
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] =
    useState<boolean>(false);
  const [txnBillingCycles, setTxnBillingCycles] = useState<Array<BillingCycle>>(
    []
  );
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle | null>(
      statemetScreen
        ? statementSelectedCycle
        : !!transacationDate && !!emiCycle
        ? emiCycle
        : null
    );
  const [transactionSelectedForDetails, setTransactionSelectedForDetails] =
    useState<Transaction | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // ref to synchronously keep track of loading and prevent concurrent api calls from infinite scroll
  // hence keep initial value false
  const isTransactionsLoadingRef = useRef<boolean>(false);

  const offset = useRef<number>(0);
  const totalCount = useRef<number>(0);

  // const parsedDate = dayjs(billingCycles[0]?.to).add(1, "day");

  // Get the current month as a number (1-12)
  // const currentMonth = dayjs().month();

  // Replace the month in the parsed date with the current month
  // const updatedDate = parsedDate.month(currentMonth);

  // Format the updated date as needed (e.g., with day and time)
  // const formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm:ssZ");

  const isCurrentBillingCycleSelected =
    dayjs(selectedBillingCycle?.to).format("DD-MM-YYYY") ===
    dayjs().format("DD-MM-YYYY");

  const areTransactionsLeftToFetch =
    offset.current === 0 || offset.current < totalCount.current;

  useEffect(() => {
    if (
      !!transacationDate &&
      !!pathsHistory &&
      pathsHistory?.current === CONVERT_TO_EMI_ROUTE
    ) {
      dayjs.extend(isSameOrAfter);
      dayjs.extend(isSameOrBefore);
      const date = dayjs(transacationDate);
      for (let billingCycle of billingCycles) {
        const from = billingCycle.from;
        const to = billingCycle.to;
        if (date.isSameOrAfter(dayjs(from)) && date.isSameOrBefore(dayjs(to))) {
          emiCycle = billingCycle;
          setSelectedBillingCycle(billingCycle);
          break;
        }
      }
    }
    return () => localStorage.removeItem("transactionPostingDate");
  }, []);

  useEffect(() => {
    const cycles = [...billingCycles];

    setTxnBillingCycles(cycles);
    if (!statemetScreen) {
      if (!emiCycle?.from && !emiCycle?.to) {
        setSelectedBillingCycle(cycles[0]);
      }
    }
  }, [account.currentCycleStartDate]);

  const getTransactions = useCallback(async () => {
    if (!selectedBillingCycle?.from || !selectedBillingCycle?.to) {
      return;
    }
    if (!selectedBillingCycle) return;
    if (isTransactionsLoadingRef.current) return;

    setIsTransactionsLoading(true);
    isTransactionsLoadingRef.current = true;

    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        actionText: "to view transactions"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get transactions api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      let data: any = {
        count: TRANSACTIONS_PER_PAGE_COUNT,
        offset: offset.current
      };

      if (!isCurrentBillingCycleSelected) {
        data = {
          ...data,
          from: dayjs(selectedBillingCycle.from).format(
            API_REQUEST_DATE_FORMAT
          ),
          to: dayjs(selectedBillingCycle.to).format(API_REQUEST_DATE_FORMAT),
          customerIdList: card.isPrimary ? [] : [customer.id],
          checkEmiEligibility: true
        };
      }
      const cardData = otherCardsData?.find((card1) => card1.id === card.id);
      const unbilledCardLastFour = !cardData?.isVariantUpgradeActive
        ? card.maskedCardNumber.slice(-4)
        : otherCardsData
            ?.find(
              (card1) =>
                card1.accountId === account.id &&
                !card1?.isVariantUpgradeActive &&
                card1.isPrimary === card.isPrimary
            )
            ?.maskedCardNumber.slice(-4);

      const response = await (isCurrentBillingCycleSelected
        ? AccountsApi.getUnbilledTransactions(account.id, authResult.apiToken, {
            isPrimaryCard: card.isPrimary,
            uniqueURL: getUniqueURLFromCookie() || "",
            cardLastFourDigits: unbilledCardLastFour,
            customerId: customer.id,
            checkEmiEligibility: true
          } as GetUnbilledTransactionsRequest)
        : AccountsApi.getTransactions(
            account.id,
            authResult.apiToken,
            data as GetTransactionsRequest
          ));

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting ${
            isCurrentBillingCycleSelected ? "unbilled" : "billed"
          } transactions`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display transaction details. Please try again"
        );
        navigate(CARD_SUMMARY_ROUTE);
        return;
      }

      setTransactions((transactions) => {
        const tempTransactions = [
          ...transactions,
          ...response.data.transactions
        ];

        // unbilled transactions also have auth transactions that are fetched separately
        // chronologically auth transactions may be spread across multiple api calls of unbilled transactions
        // since frontend persists the state, sorting will be done in frontend
        return isCurrentBillingCycleSelected
          ? getSortedTransactions(tempTransactions)
          : tempTransactions;
      });
      offset.current += response.data.count;
      totalCount.current = response.data.totalCount;
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting ${
          isCurrentBillingCycleSelected ? "unbilled" : "billed"
        } transactions`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display transaction details. Please try again"
      );
      navigate(CARD_SUMMARY_ROUTE);
    }
    setIsLoading(false);
    setIsTransactionsLoading(false);
    isTransactionsLoadingRef.current = false;
  }, [
    account.id,
    isCurrentBillingCycleSelected,
    navigate,
    selectedBillingCycle
  ]);

  // infinite scroll works only after scrolling, hence initially getting transactions once with sufficient length to introduce scrolling
  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  // get transactions after scrolling
  const loadMoreTransactions = () => {
    getTransactions();
  };

  const openCycleSelection = () => {
    setIsCycleSelectionOpen(true);
  };

  const closeCycleSelection = () => {
    setIsCycleSelectionOpen(false);
  };

  const onTransactionClick = (transaction: Transaction) => {
    setTransactionSelectedForDetails(transaction);
    setIsTransactionDetailsOpen(true);
  };

  const renderBillingCycleSelectorLabel = (
    billingCycle: BillingCycle,
    index: number
  ) =>
    index === 0
      ? "Unbilled Transactions"
      : `${dayjs(billingCycle.from).format("DD MMMM")} - ${dayjs(
          billingCycle.to
        ).format("DD MMMM")}`;

  const onBillingCycleSelect = (billingCycle: BillingCycle) => {
    // reset state
    setTransactions([]);
    offset.current = 0;
    totalCount.current = 0;

    // close selection
    closeCycleSelection();

    // set billing cycle
    setSelectedBillingCycle(billingCycle);
  };

  const closeTransactionDetailsBottomSheet = () => {
    setIsTransactionDetailsOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <div style={{ height: "100vh" }}>
          <Loader text1="loading" text2="transactions" />
        </div>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <Header
              bgColor="#141414"
              customClassName={styles.headerStyle}
              label={statemetScreen ? "Transactions" : "All Transactions"}
            />
            {!statemetScreen && selectedBillingCycle && (
              <div onClick={openCycleSelection} className={styles.calendar}>
                <div className={styles.calendarDate}>
                  {isCurrentBillingCycleSelected
                    ? "Unbilled"
                    : `${dayjs(selectedBillingCycle.from).format(
                        "MMM DD"
                      )} - ${dayjs(selectedBillingCycle.to).format("MMM DD")}`}
                </div>
                <img
                  src={calendar}
                  style={{ width: "18px", height: "18px" }}
                  alt="calendar"
                />
              </div>
            )}
          </div>
          <hr className={styles.horizontalLine}></hr>
          {statemetScreen ? (
            <div className={styles.forTextContainer}>
              <span>for </span>
              <span className={styles.dateTextContainer}>{`${dayjs(
                selectedBillingCycle?.from
              ).format("MMM DD")} - ${dayjs(selectedBillingCycle?.to).format(
                "MMM DD"
              )}`}</span>
            </div>
          ) : null}
          <div
            id="transactionsContainer"
            style={{ overflow: "auto", height: "90vh" }}
            className={styles.transactionsContainer}
          >
            <InfiniteScroll
              dataLength={transactions.length}
              next={loadMoreTransactions}
              hasMore={areTransactionsLeftToFetch}
              loader={
                isTransactionsLoading && (
                  <div style={{ height: "50vh" }}>
                    <Loader text1="loading" text2="transactions" />
                  </div>
                )
              }
              scrollableTarget="transactionsContainer"
            >
              {transactions.map((transaction) => {
                return (
                  <div
                    key={transaction.id}
                    onClick={() => onTransactionClick(transaction)}
                  >
                    <TransactionComponent
                      transaction={transaction}
                      requestSource={
                        isCurrentBillingCycleSelected
                          ? RequestSources.UNBILLED_TRANSACTIONS_PAGE
                          : RequestSources.BILLED_TRANSACTIONS_PAGE
                      }
                    />
                  </div>
                );
              })}
            </InfiniteScroll>
            {transactions.length === 0 && !isTransactionsLoading && (
              <div className={styles.noTransactions}>
                <div>
                  <img src={cactus} alt="cactus" />
                </div>
                <div>
                  <span>you have</span> <br /> no transactions for this cycle
                </div>
              </div>
            )}
          </div>
          <Selector<BillingCycle>
            isOpen={isCycleSelectionOpen}
            title="Select a Billing Cycle"
            items={txnBillingCycles}
            renderLabel={renderBillingCycleSelectorLabel}
            isItemSelected={(billingCycle) =>
              billingCycle.from === selectedBillingCycle?.from
            }
            onSelect={onBillingCycleSelect}
            handleClose={closeCycleSelection}
            footer="Please login to IndusMobile/IndusNet to view your transactions prior to 6 months"
          />
          <BottomSheet2
            isOpen={isTransactionDetailsOpen}
            handleClose={closeTransactionDetailsBottomSheet}
          >
            {transactionSelectedForDetails && (
              <TransactionDetails
                handleCloseButton={closeTransactionDetailsBottomSheet}
                transaction={transactionSelectedForDetails}
              />
            )}
          </BottomSheet2>
        </>
      )}
    </>
  );
};

export default ViewTransactions;
