import styles from "./styles.module.scss";
interface DisplayText {
  text1: string;
  text2: string;
  icon?: string;
}

const index = (props: DisplayText) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { text1, text2, icon } = props;
  return (
    <div className={styles["benefits-header"]}>
      <div className={styles.text2}>
        <span className={styles.text}>{text1}</span>
      </div>
      <div className={styles.text1}>{text2}</div>
    </div>
  );
};

export default index;
