/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../header/header";
import styles from "./styles.module.scss";
import GradientCircleProgressbar from "../../pages/home/campaignOffersSection/campaignOffer/circleProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedAccount } from "../../features/user/userSlice";
import { navigateFromNudge } from ".";

const AllNudges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector(getSelectedAccount)!;
  const accountId = account?.id;
  const nudges = location.state?.allNudges || [];

  const handleNudgeClick = (
    id: string,
    options: any,
    transactionId: string | null
  ) => {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery")!);
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId!]: {
          ...(nudgesDelivery?.[accountId!] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId!]?.[id] || {}),
            clickCount:
              (nudgesDelivery?.[accountId!]?.[id]?.clickCount || 0) + 1
          }
        }
      })
    );
    navigateFromNudge({
      dispatch,
      navigate,
      account,
      redirectionUrl: options?.redirectionUrl,
      transactionId
    });
  };
  return (
    <>
      <Header bgColor="#141414" label="Notifications" />
      <div className={styles.horizontalLine} />
      <div className={styles.mainContainer}>
        {nudges?.map((nudge: any) => {
          const options = JSON.parse(nudge.displayOptions.nudgeDetails);
          return options.title && options.description ? (
            <>
              <div
                onClick={() =>
                  handleNudgeClick(nudge.id, options, nudge.transactionId)
                }
                className={styles.content}
              >
                {nudge.offerType === "MILESTONE" ? (
                  <div className={styles.progressContainer}>
                    <GradientCircleProgressbar
                      percentage={(nudge.currentValue / nudge.threshold) * 100}
                      width={60}
                    >
                      <img
                        className={styles.pendingIllustrationImg}
                        src="/images/Sparkle.svg"
                        alt="sparkle"
                      />
                    </GradientCircleProgressbar>
                  </div>
                ) : (
                  <div className={styles.nudgeImgContainer}>
                    <img
                      className={styles.completedIllustrationImg}
                      src={options.illustrationUrl}
                      alt="svg"
                    />
                  </div>
                )}
                <div className={styles["text-container"]}>
                  <div className={styles["text-1"]}>{options.title}</div>
                  <div className={styles["text-2"]}>{options.description}</div>
                </div>
              </div>
              <div className={styles.borderLine} />
            </>
          ) : (
            <></>
          );
        })}
      </div>
    </>
  );
};

export default AllNudges;
