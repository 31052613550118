import { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import type { RootState } from "../../app/store";
import Header from "../../components/header/header";
import IntermediateText from "../home/intermediateText";
import { ToggleButton } from "../../components/button/Button";
import styles from "./styles.module.scss";
import CompletedCampaignOffer from "../home/campaignOffersSection/campaignOffer/CompletedCampaignOffer";
import PendingCampaignOffer from "../home/campaignOffersSection/campaignOffer/PendingCampaignOffer";
import { CampaignBenefitVariant } from "../../utils/enums";
import type { GetBenefitsForAccountResponseElement } from "../../apis/benefitsApi/benefitsApiTypes";
import { BenefitType } from "../../apis/benefitsApi/benefitsApiTypes";
import { getBenefitTexts } from "../../utils/BenefitUtil";
import {
  EXPIRING_SOON_OFFERS_DIFF_IN_DAYS,
  NEW_OFFERS_DIFF_IN_DAYS
} from "../../utils/constants";

enum FilterName {
  NONE = "NONE",
  NEW = "NEW",
  EXPIRING_SOON = "EXPIRING_SOON"
}

const AllCampaignOffers = () => {
  const benefits = useSelector((state: RootState) => state.benefits);

  const [filteredBenefits, setFilteredBenefits] = useState<
    Array<GetBenefitsForAccountResponseElement>
  >([]);
  const [selectedFilterName, setSelectedFilterName] = useState<FilterName>(
    FilterName.NONE
  );

  const campaignBenefits = benefits.campaignBenefits.benefits;

  const clearFilter = () => {
    setSelectedFilterName(FilterName.NONE);
    setFilteredBenefits([]);
  };

  const applyFilter = (filterName: FilterName) => {
    clearFilter();

    let filterResult: GetBenefitsForAccountResponseElement[] = [];

    switch (filterName) {
      case FilterName.NEW:
        filterResult = campaignBenefits.filter(
          (benefit) =>
            dayjs().diff(dayjs(benefit.activeFrom), "day") <
            NEW_OFFERS_DIFF_IN_DAYS
        );
        break;

      case FilterName.EXPIRING_SOON:
        filterResult = campaignBenefits.filter(
          (benefit) =>
            dayjs(benefit.benefitExpiry).diff(dayjs(), "day") <
            EXPIRING_SOON_OFFERS_DIFF_IN_DAYS
        );
        break;
    }

    // select filter only when it is to be applied
    setSelectedFilterName(filterName);
    setFilteredBenefits(filterResult);
  };

  const handleFilterNameClick = (filterName: FilterName) => {
    if (filterName === selectedFilterName) {
      // if filter is already applied
      clearFilter();
      return;
    }

    switch (filterName) {
      case FilterName.NEW:
        applyFilter(FilterName.NEW);
        break;

      case FilterName.EXPIRING_SOON:
        applyFilter(FilterName.EXPIRING_SOON);
        break;
    }
  };

  let benefitsToDisplay =
    selectedFilterName === FilterName.NONE
      ? campaignBenefits
      : filteredBenefits;

  return (
    <>
      <div className={styles.topSection}>
        <Header label={`Exclusive Benefits`} />
        <div className={styles.container}>
          <IntermediateText text1="all benefits" text2="on your card" />
        </div>
        <div className={styles.btnContainer}>
          <style>{`.btnStyle {border:solid red;}`}</style>
          <div className={styles.btnStyle}>
            <ToggleButton
              text="New"
              isSelected={selectedFilterName === FilterName.NEW}
              onClick={() => handleFilterNameClick(FilterName.NEW)}
              icon="/images/sample/starr.svg"
            />
          </div>
          <div className={styles.btnStyle}>
            <ToggleButton
              text="Expiring Soon"
              isSelected={selectedFilterName === FilterName.EXPIRING_SOON}
              onClick={() => handleFilterNameClick(FilterName.EXPIRING_SOON)}
              icon="/images/HourglassHigh.svg"
            />
          </div>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {benefitsToDisplay?.map((benefit) => {
          const offerDetails = JSON.parse(benefit.offerDetails);
          const {
            daysLeft,
            progress,
            title1,
            title2,
            description,
            ctaTextColor,
            ctaLink
          } = getBenefitTexts(benefit);

          const isPendingMilestone =
            benefit.benefitType === BenefitType.MILESTONE && progress < 100;

          return isPendingMilestone ? (
            <PendingCampaignOffer
              id={benefit.benefitId}
              background={benefit.displayColor}
              title1={title1}
              title2={title2}
              description={description}
              progress={progress}
              daysLeft={daysLeft}
              variant={CampaignBenefitVariant.ALL_BENEFITS_PAGE}
              hasDetails={
                offerDetails.redeemSteps || offerDetails.tncConditions
              }
              logoUrl={benefit.logoUrl}
            />
          ) : (
            <CompletedCampaignOffer
              id={benefit.benefitId}
              background={benefit.displayColor}
              title1={title1}
              title2={title2}
              description={description}
              ctaTextColor={ctaTextColor}
              ctaLink={ctaLink}
              variant={CampaignBenefitVariant.ALL_BENEFITS_PAGE}
              hasDetails={
                offerDetails.redeemSteps || offerDetails.tncConditions
              }
              logoUrl={benefit.logoUrl}
              daysLeft={daysLeft}
              benefitType={benefit.benefitType}
            />
          );
        })}
      </div>
    </>
  );
};

export default AllCampaignOffers;
