import { useNavigate } from "react-router-dom";
import { Arrow } from "../../../../components";
import { MERCHANT_OFFER_DETAILS_ROUTE } from "../../../../routes/ScreenRoutes";
import styles from "./merchantOfferStyles.module.scss";
import { wave3 } from "../../../../assets/svgs";
import { isFestiveOffersUIEnabled } from "../../../../utils/internalFlags";

import { Festivals } from "../../../../utils/enums";
import { captureEvent } from "../../../../utils/functions";
import { EVENT_NAME } from "../../../../apis/appApi/appApiTypes";

interface MerchantOfferProps {
  id: string;
  background: string;
  offerImg: string;
  merchantLogoImg: string;
  title: string;
  description: string;
  hasDetails: boolean;
}

const MerchantOffer = ({
  id,
  background,
  offerImg,
  merchantLogoImg,
  title,
  description,
  hasDetails
}: MerchantOfferProps): React.ReactElement => {
  const navigate = useNavigate();

  const handleMerchantnOfferCtaClick = () => {
    if (hasDetails) {
      navigate(
        MERCHANT_OFFER_DETAILS_ROUTE.replace(":benefitId", id.toString())
      );
    }
    captureEvent({
      eventName: EVENT_NAME.MERCHANT_OFFER_CLICK,
      eventData: { merchantOfferId: id }
    });
  };

  const FestivalData = Festivals?.CURRENT_FESTIVAL;

  return (
    <div className={styles.offerContainer}>
      <div
        className={`${styles.offerIllustrationOutline} ${
          isFestiveOffersUIEnabled ? styles.festiveOfferIllustrationOutline : ""
        }`}
      >
        <div className={styles.backgroundContainer}>
          <div className={styles.background} style={{ background }}></div>
          {!isFestiveOffersUIEnabled && (
            <img
              className={styles.backgroundPattern}
              src={wave3}
              alt="wavePattern"
            />
          )}
          {isFestiveOffersUIEnabled && (
            <img
              src={FestivalData?.images?.merchantOffers?.cardBackground?.url}
              alt={FestivalData?.images?.merchantOffers?.cardBackground?.alt}
              className={styles.topMerchantPattern}
            />
          )}
        </div>
        <div className={styles.imgContainer}>
          <div
            className={`${styles.offerImg} ${
              isFestiveOffersUIEnabled ? styles.festiveOfferImage : ""
            }`}
            style={{ backgroundImage: `url(${offerImg})` }}
          ></div>
          <div
            className={`${styles.merchantLogo} ${
              isFestiveOffersUIEnabled ? styles.festiveMerchantLogo : ""
            }`}
            style={{ backgroundImage: `url(${merchantLogoImg})` }}
          ></div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <div onClick={() => handleMerchantnOfferCtaClick()}>
          <Arrow className={styles.cta} />
        </div>
      </div>
    </div>
  );
};

export default MerchantOffer;
