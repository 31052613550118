import type { AxiosResponse } from "axios";
import type { GetNudgesForAccountResponse } from "./nudgesApiTypes";
import Api from "../Api";
import { GET_NUDGES_FOR_ACCOUNT_ROUTE } from "../ApiRoutes";

const NudgesApi = {
  getNudgesForAccount: (
    accountId: string
  ): Promise<AxiosResponse<GetNudgesForAccountResponse>> =>
    Api.get(GET_NUDGES_FOR_ACCOUNT_ROUTE.replace(":accountId", accountId))
};

export default NudgesApi;
