/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useSelector } from "react-redux";
import type { RootState } from "../../app/store";
import Header from "../../components/header/header";
import CVPOffer from "../../components/cvpOffers";
import CVPOfferCarousel from "../../components/CVPCarousel";
import styles from "./styles.module.scss";
import { getCardDetails } from "../../features/user/userSlice";
import { capitalizeFirsLetter } from "../../utils/functions";
import {
  CvpOffersIntermediateText,
  getBenefitTexts
} from "../../utils/BenefitUtil";

const AllCVPOffers = () => {
  const benefits = useSelector((state: RootState) => state.benefits);
  const cardDetails = useSelector(getCardDetails);
  const bannerCvpBenefits = benefits.cvpBenefits.benefits?.filter(
    (benefit) => JSON.parse(benefit.offerDetails).isBanner
  );

  const nonBannerCvpBenefits = benefits.cvpBenefits.benefits?.filter(
    (benefit) => !JSON.parse(benefit.offerDetails).isBanner
  );

  return (
    <>
      <Header
        label={capitalizeFirsLetter(`Your ${cardDetails?.cardType} Benefits`)}
        customClassName={styles.header}
        bgColor="rgba(20, 20, 20)"
      />
      <div className={styles.mainContainer}>
        <CvpOffersIntermediateText
          text1="featured"
          text2="benefits for"
          text3="you"
          icon="/images/SunHorizon.svg"
        />
        <CVPOfferCarousel
          items={bannerCvpBenefits?.map((benefit) => {
            const offerDetails = JSON.parse(benefit.offerDetails);
            const { description } = getBenefitTexts(benefit!);
            const milestoneProgress = Math.min(
              (benefit?.currentValue! / benefit?.threshold!) * 100,
              100
            );
            return (
              <CVPOffer
                id={benefit.benefitId}
                title={benefit.displayTitle}
                illustrationUrl={benefit.illustrationUrl}
                logo={benefit.logoUrl}
                background={benefit.displayColor}
                expanded={true}
                ctaLink={offerDetails.ctaLink}
                description={description}
                progress={milestoneProgress}
                benefitType={benefit.benefitType}
                hasDetails={
                  offerDetails.redeemSteps || offerDetails.tncConditions
                }
                key={benefit.benefitId}
              />
            );
          })}
          slidesPerView={1}
          showButton={false}
          banner
        />
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.textWrapper}>
          <CvpOffersIntermediateText
            text1="all"
            text2="benefits on your card"
            icon="/images/diamondOne.svg"
            customClassname={styles.intermediateText}
          />
        </div>
        <div className={styles.items}>
          {nonBannerCvpBenefits?.map((benefit) => {
            const offerDetails = JSON.parse(benefit.offerDetails);

            return (
              <CVPOffer
                id={benefit.benefitId}
                title={benefit.displayTitle}
                illustrationUrl={benefit.illustrationUrl}
                logo={benefit.logoUrl}
                background={benefit.displayColor}
                expanded={false}
                ctaLink={offerDetails.ctaLink}
                hasDetails={
                  offerDetails.redeemSteps || offerDetails.tncConditions
                }
                key={benefit.benefitId}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AllCVPOffers;
