/*  eslint-disable react/jsx-key , react/no-unstable-nested-components, @typescript-eslint/no-non-null-assertion */

import { useEffect, useRef, useState } from "react";
import LottieView from "lottie-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Button2 } from "../../button/Button";
import Carousel from "./carousel";
import OtpInput from "react-otp-input";
import styles from "./preLogin.module.scss";
import BottomsheetComponent from "../../bottomSheet/bottomsheet";
import tag from "../../../assets/svgs/tag.svg";
import LoginApi from "../../../apis/loginApi/LoginApi";
import {
  GenerateLoginOtpSuccessResponse,
  VerifyLoginOtpSuccessResponse
} from "../../../apis/loginApi/loginApiTypes";
import { ToastType, toast } from "../../../components";
import { setSession } from "../../../features/session/sessionSlice";
import {
  setUserIds,
  setIsDeviceIdVerified
} from "../../../features/user/userSlice";
import { type SetSessionActionPayload } from "../../../features/session/sessionSliceTypes";
import { type SetUserIdsActionPayload } from "../../../features/user/userSliceTypes";
import PreferencesApi from "../../../apis/prefrencesApi/PreferencesApi";
import {
  setAccountPreferences,
  setProgramPreferences
} from "../../../features/benefits/benefitsSlice";
import { type RootState } from "../../../app/store";
import {
  ProgramPreferences,
  type Tag
} from "../../../apis/prefrencesApi/preferencesApiTypes";
import {
  aesDecryptData,
  aesEncryptData,
  getPciEncryptionKeyAndIv
} from "../../../utils/encryptionUtil";
import { TextWithLinks } from "../../textWithLinks/TextWithLinks";
import {
  CONVERT_OUTSTANDING_TO_EMI,
  EMI_ELIGIBLE_TRANSACTIONS,
  HOME_ROUTE,
  PREFETCH_FAILURE,
  CARD_BLOCKED,
  INSTALL_SCREEN
} from "../../../routes/ScreenRoutes";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import { getIssuerId } from "../../../data/config";
import {
  autoReadOtp,
  focusOnInput,
  parseUnicodeInString,
  getPWADisplayMode,
  getDeviceIdCookie,
  createCookie,
  isCardUnblockedCookie,
  cookieCheck,
  deleteAllCookies,
  getUniqueURLFromCookie,
  getDeepLinkCookieValue
} from "../../../utils/functions";

import AppApi from "../../../apis/appApi/AppApi";
import { EVENT_NAME } from "../../../apis/appApi/appApiTypes";
import IndusInd_Logo from "../../../assets/svgs/indusind-bank-logo.svg";
import InterMediateText from "./intermediateText";
import Bill from "../../../assets/svgs/bill.svg";
import Banner from "../../../assets/svgs/banner.svg";
import Bill2 from "../../../assets/svgs/billCarousalImagePrelogin.svg";
import SmsVerify from "../../smsVerify/smsVerify";
import CardsLottie from "../../../assets/LottieImages/card-floating-animation-optimised.json";
import {
  CookieType,
  DeviceApiErrorCode,
  EventType,
  UserAction
} from "../../../utils/enums";
import DeviceApi from "../../../apis/deviceApi/deviceApi";
import { getUserOSAndVersion } from "../../../utils/trackingFunctions";
import {
  CONTACT_MOBILE_NUMBER,
  RESEND_OTP_TIME,
  UNIQUE_URL_LENGTH
} from "../../../utils/constants";
import Contact from "../../contact/contact";
import callContact from "../../../assets/svgs/callContact.svg";
import emailContact from "../../../assets/svgs/emailContact.svg";
import { isManualOtpAllowed } from "../../../utils/internalFlags";
import Loader from "../../loader/loader";
import InvalidUniqueUrl from "../../../components/invalidUniqueUrl/invalidUniqueUrl";
import { sendNotification } from "../../../utils/notifyCustomer";

const numberRegex = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
const isInputValid = (num: string) => {
  if (num.includes(".")) return false;
  return numberRegex.test(num);
};
declare const window: any;
const textRender = (
  text1: string,
  text2: string,
  text3?: string,
  text4?: string
) => {
  return (
    <div className={styles.textContainer}>
      <span className={styles.text1}>{text1}</span>
      <span className={styles.text2}>{" " + text2}</span>
      {text3 ? <span className={styles.text3}>{" " + text3}</span> : null}
      {text4 ? <span className={styles.text4}>{" " + text4}</span> : null}
    </div>
  );
};

const swiperContainer = (text: string, icon: string) => {
  return (
    <div className={styles.swiperContainer}>
      <div className={styles.swiperIconContainer}>
        <img src={icon} alt="icon" className={styles.swiperIcon} />
      </div>
      <div className={styles.swiperTextContainer}>
        <div className={styles.swiperText}>{text}</div>
      </div>
    </div>
  );
};

const FavouriteBottomSheet = ({
  programPreferences,
  handleTagClick,
  selectedTagIds,
  handleSkipPreferencesClick,
  isSavePreferencesLoading,
  handleSavePreferencesClick
}: any) => {
  return (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetOfferHeader}>
          <img src={tag} alt="Tag" />
          <div className={styles.bottomSheetOfferHeaderText}>
            pick your favorites
          </div>
        </div>
        <div className={styles.bottomSheetOfferText}>
          Personalized offers based on your favorite categories
        </div>
        <div className={styles.favouriteOfferContainer}>
          {programPreferences?.tags?.map((tag: Tag, index: number) => {
            return (
              <div
                key={index}
                onClick={() => handleTagClick(tag)}
                className={
                  selectedTagIds.includes(tag.id)
                    ? styles.offerBtnSelected
                    : styles.offerBtnNotSelected
                }
              >
                <div className={styles.offerText}>
                  {parseUnicodeInString(tag.name)}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.offerBtnContainer}>
          <Button2
            isLoading={isSavePreferencesLoading}
            onClick={handleSavePreferencesClick}
            customClassName={styles.saveBtn}
            text1Style={{ fontWeight: "bold" }}
            text1="save"
            text2="preferences"
          />
          <div onClick={handleSkipPreferencesClick} className={styles.skipBtn}>
            skip
          </div>
        </div>
      </div>
    </div>
  );
};

const PreLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const uniqueURL =
    params.get("uniqueURL")?.replaceAll(" ", "+") ||
    getUniqueURLFromCookie() ||
    null;
  let step = params.get("step") || null;

  const programPreferences = useSelector(
    (state: RootState) => state.benefits.programPreferences
  );

  const otpInputRef = useRef<OtpInput | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const cardLottieDiv = useRef<HTMLInputElement>(null);
  const mobileVerificationRef = useRef<any>(null);
  const [osVersionForCheckDevice, setOsVersionForCheckDevice] =
    useState<string>("");
  const [isContactSupportOpen, setIsContactSupportOpen] =
    useState<boolean>(false);
  const [isGenerateLoginOtpLoading, setIsGenerateLoginOtpLoading] =
    useState<boolean>(false);
  const [isVerifyLoginOtpLoading, setIsVerifyLoginOtpLoading] =
    useState<boolean>(false);
  const [smsVerify, setSmsVerify] = useState<boolean>(false);
  const [isSavePreferencesLoading, setSavePreferencesLoading] =
    useState<boolean>(false);
  const [resendCount, setResendCount] = useState(0);

  const [isDetailsBottomSheetOpen, setIsDetailsBottomSheetOpen] =
    useState<boolean>(false);
  const [isOtpBottomSheetOpen, setIsOtpBottomSheetOpen] =
    useState<boolean>(false);
  const [isFavouriteBottomSheetOpen, setIsFavouriteBottomSheetOpen] =
    useState<boolean>(false);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [otp, setOtp] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [otpVerificationRefId, setOtpVerificationRefId] = useState<string>("");
  const [sessionState, setSessionState] =
    useState<SetSessionActionPayload | null>(null);
  const [userIdsState, setUserIdsState] =
    useState<SetUserIdsActionPayload | null>(null);
  const [isCardDetailsNoMatch, setIsCardDetailsNoMatch] =
    useState<boolean>(false);
  const [timeOut, setTimeOut] = useState<number>(RESEND_OTP_TIME);
  const [cardIncorrectAttemptLeft, setCardIncorrectAttemptLeft] =
    useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockedUntil, setBlockedUntil] = useState<string>("");
  const [isDeviceChecking, setIsDeviceChecking] = useState<boolean>(false);
  const [showSecond, setShowSecond] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUniqueUrlCorrect, setIsUniqueUrlCorrect] = useState<boolean>(true);
  const [deepRoute, setDeepRoute] = useState<string>(
    getDeepLinkCookieValue() || ""
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let timer: any;

  const [os, version] = getUserOSAndVersion(navigator.userAgent);
  const handleChange = (code: string) => {
    // window.alert(code);
    handleInputResponsive();
    setOtp(code);
  };

  const handleInputResponsive = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const inputWidth = inputElement.offsetWidth;
      // Calculate the effective character width considering the font size
      const charWidth = inputWidth / 6;
      // Adjust the letterSpacing based on the calculated charWidth and desired spacing
      const letterSpacing = Math.max(Math.floor(charWidth * 0.7), 1); // Adjust multiplier (0.8 here) based on your needs
      inputElement.style.letterSpacing = letterSpacing + "px";
    }
  };

  const handleKeyDown = (event: any) => {
    if (/\d/g.test(event.key) && !isManualOtpAllowed) {
      // Prevent the default action (entering the number)
      event.preventDefault();
      setOtp("");
    }
  };

  const handleCard = (num: string) => {
    if (isInputValid(num)) setCardNumber(num);
  };
  const showToast = () => {
    toastLib.dismiss();
    if (mobileNumber.length === 0 && cardNumber.length === 0) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter login details");
      }, 1000);
    } else if (
      mobileNumber.length >= 0 &&
      cardNumber.length >= 0 &&
      cardNumber.length < 4
    ) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter complete login details");
      }, 1000);
    } else if (cardNumber.length === 0) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter last 4 digits of Card Number");
      }, 1000);
    } else if (cardNumber.length > 0 && cardNumber.length < 4) {
      setTimeout(() => {
        toast(ToastType.WARNING, "Please enter last 4 digits of Card Number");
      }, 1000);
    } 
  };
  let divElement = document.getElementById("scroll");
  let divElement1 = document.getElementById("scroll1");
  const tncText =
    "By entering OTP and proceeding, I agree to the (Terms & Conditions)[https://www.indusind.com/in/en/personal/terms-and-conditions.html] and (Privacy Policy)[https://www.indusind.com/in/en/personal/privacy-policy.html] associated with IndusInd Bank Credit Card setup";

  const getMobileOperatingSystem = () => {
    // eslint-disable-next-line no-var
    let userAgent = navigator.userAgent || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  };

  const handleCheckDeviceId = async (deviceId: string) => {
    setIsDeviceChecking(true);

    try {
      const response = await DeviceApi.checkDevice({
        deviceId: deviceId,
        uniqueURL: uniqueURL || getUniqueURLFromCookie(),
        osVersion: osVersionForCheckDevice
      });

      if (response?.data?.status === "SUCCESS") {
        if (getPWADisplayMode() === "app") {
          setIsDeviceIdVerified(true);
          setIsDetailsBottomSheetOpen(true);
        } else {
          navigate(INSTALL_SCREEN);
        }
      } else if (
        response?.data?.errorCode === DeviceApiErrorCode.DEVICE_VERSION_CHANGE
      ) {
        deleteAllCookies();
        setSmsVerify(true);
      } else if (
        response?.data?.errorCode === DeviceApiErrorCode.DEVICE_NOT_REGISTERED
      ) {
        setSmsVerify(true);
      } else if (
        response?.data?.errorCode ===
        DeviceApiErrorCode.DEVICE_ALREADY_REGISTERED
      ) {
        setSmsVerify(true);
      }
    } catch (error) {
      console.error(
        `An exception occurred while adding device. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
    setIsDeviceChecking(false);
  };
  const handleGetStartedButtonClick = async () => {
    let cookieArray = document.cookie.split("; ");
    let deviceId = "";

    const deviceIDCookie = cookieArray.find((ele) =>
      ele.includes(CookieType.DEVICE_ID)
    );
    const blockCodeCookie = cookieArray.find((ele) =>
      ele.includes(CookieType.BLOCKED_CARD_TIME)
    );

    if (blockCodeCookie) {
      if (isCardUnblockedCookie(blockCodeCookie.split("blockedCardTime=")[1])) {
        navigate(CARD_BLOCKED);
        return;
      }
    }

    if (deviceIDCookie) {
      const deviceIdValue = deviceIDCookie.split("deviceId=")[1];
      deviceId = deviceIdValue;
    }

    if (cookieCheck(uniqueURL ?? "")) {
      await handleCheckDeviceId(deviceId);
      return;
    }
    setSmsVerify(true);
  };

  const handleTagClick = (tag: Tag) => {
    if (selectedTagIds.includes(tag.id)) {
      setSelectedTagIds(
        selectedTagIds.filter((selectedTagId) => selectedTagId !== tag.id)
      );
    } else {
      setSelectedTagIds([...selectedTagIds, tag.id]);
    }
  };

  const afterPreferencesSelection = () => {
    // set session data and user data in store
    dispatch(setSession(sessionState!));
    dispatch(setUserIds(userIdsState!));
    navigate(HOME_ROUTE);
  };

  const handleSkipPreferencesClick = () => {
    afterPreferencesSelection();
  };

  useEffect(() => {
    // clear session expiry flags
    localStorage.removeItem("visitedHome");
  }, []);

  useEffect(() => {
    if (uniqueURL) {
      createCookie("uniqueURL", uniqueURL, "Tue, 19 Jan 2038 04:14:07 IST");
      uniqueURL.length === UNIQUE_URL_LENGTH
        ? handleUniqueUrlCheck(uniqueURL)
        : setIsUniqueUrlCorrect(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (os === "Android") {
      (navigator as any).userAgentData
        .getHighEntropyValues(["platformVersion"])
        .then((values: any) => {
          setOsVersionForCheckDevice(values?.platformVersion?.split(".")[0]);
          // console.log(osVersion,typeof osVersion);
        });
    }
  });

  useEffect(() => {
    return () => {
      if (cardLottieDiv.current) {
        cardLottieDiv.current.innerHTML = ""; // destroy animation instance
      }
    };
  }, [smsVerify]);

  useEffect(() => {
    if (step) {
      setSmsVerify(true);
    }
  }, []);

  const handleUniqueUrlCheck = async (uniqueURL: string) => {
    try {
      const response = await DeviceApi.checkUrl({
        uniqueURL: uniqueURL
      });
      if (response?.data?.status === "SUCCESS") {
        setIsUniqueUrlCorrect(true);
        setIsLoading(false);
      } else {
        setIsUniqueUrlCorrect(false);
      }
    } catch (error) {
      setIsUniqueUrlCorrect(false);
      setIsLoading(false);
      console.error("UniqueURL check failed.");
      setTimeout(() => {
        toast(ToastType.ERROR, "UniqueURL check failed.");
      }, 1000);
    }
  };

  const handleAutoRead = () => {
    if (os !== "Android") {
      autoReadOtp((otpValue) => {
        setOtp(otpValue);
        handleInputResponsive();
        focusOnInput("loginOtpInputContainer", 5);
      });
    }
  };

  const handleGenerateOtpClick = async () => {
    if (divElement) {
      divElement.style.margin = "0px";
    }
    sessionStorage.removeItem("/");
    setIsGenerateLoginOtpLoading(true);

    try {
      // mobileNumberRef.current = mobileNumber;
      // sessionStorage.setItem(
      //   "primaryCardMobileNumber",
      //   aesEncryptData(
      //     process.env.REACT_APP_PII_ENCRYPTION_KEY!,
      //     process.env.REACT_APP_PII_ENCRYPTION_IV!,
      //     mobileNumber
      //   )
      // );
      const response = await LoginApi.generateLoginOtp({
        uniqueURL: getUniqueURLFromCookie() || "",
        cardLastFour: cardNumber
      });

      captureOtpGenerateEvent(
        mobileNumber,
        cardNumber,
        true,
        (response.data as any)?.code ||
          (response.data as any)?.status ||
          "OTP is sent successfully"
      );

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);
        const result = response.data as GenerateLoginOtpSuccessResponse;
        setOtpVerificationRefId(result.mobileVerificationRefId);
        setOtpVerificationRefId(result.mobileVerificationRefId);
        mobileVerificationRef.current = result.mobileVerificationRefId;
        setIsDetailsBottomSheetOpen(false);
        sessionStorage.setItem(
          "mobileNumberlastFour",
          response?.data?.lastFour
        );
        setMobileNumber(response?.data?.lastFour);
        setIsOtpBottomSheetOpen(true);
        toastLib.dismiss();

        autoReadOtp((otpValue) => {
          setOtp(otpValue);
          handleInputResponsive();
          // handleOtpVerifyClick(otpValue);
          focusOnInput("loginOtpInputContainer", 5);
        });
      } else if ((response.data as any)?.code === "IBL_001") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for add on card.");
        setTimeout(() => {
          toast(
            ToastType.INFO,
            "Please login with last 4 digits of Primary Card"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_002") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this account.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_003") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this customer.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_004") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this card.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if (
        (response.data as any)?.status === "RETRIES_EXCEEDED" ||
        (response.data as any)?.status === "BLOCKED_TEMPORARILY"
      ) {
        setIsCardDetailsNoMatch(true);
        setBlockedUntil((response.data as any)?.blockedUntil);
        createCookie(
          "blockedCardTime",
          (response.data as any)?.blockedUntil,
          "Tue, 19 Jan 2038 04:14:07 IST"
        );
        navigate(CARD_BLOCKED);

        const time = (response.data as any)?.failureReason
          ?.match(/Please retry after (.*)./)?.[1]
          ?.split(",");

        const isMinutesPresent =
          (response.data as any)?.failureReason?.includes("Minutes") ||
          (response.data as any)?.failureReason?.includes("minutes");

        const retryTime =
          !!time && isMinutesPresent ? time?.[0].split(" ")?.[0] : "1";

        console.error(
          `Failed to generate login OTP. Retries exceeded, ${
            (response.data as any)?.status
          }`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            (response.data as any)?.failureReason.includes("temporarily")
              ? `Maximum number of OTP attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
              : `Maximum number of login attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
          );
        }, 1000);
      } else {
        console.error("An error occurred while generating login OTP");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
        }, 1000);
      }
    } catch (error: any) {
      // logger.error({
      //   message: `An exception occurred while generating login otp`,
      //   error: error.message,
      //     mobileNumber: getMaskedMobileNumberForLogs(phone),
      //   stackTrace: error.stack
      // });
      console.error(
        `An exception occurred while generating login OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }

    setIsGenerateLoginOtpLoading(false);
  };

  const debounceSendOtp = _debounce(handleGenerateOtpClick, 500);

  const captureOtpGenerateEvent = (
    mobileNumber: string,
    cardNumber: string,
    status: boolean,
    message: string
  ) => {
    let eventName = EVENT_NAME.OTP_GENERATE;
    if (deepRoute) {
      if (deepRoute === CONVERT_OUTSTANDING_TO_EMI) {
        eventName = EVENT_NAME.OTP_GENERATE_OSTOEMI_DEEPLINK;
      } else if (deepRoute === EMI_ELIGIBLE_TRANSACTIONS) {
        eventName = EVENT_NAME.OTP_GENERATE_TXNTOEMI_DEEPLINK;
      }
    }
    AppApi.captureEvent({
      eventName: eventName,
      primarySource: window.location.href,
      eventData: {
        otpGeneratedStatus: status,
        ...(status && { ["responseMessage"]: message }),
        mobileNumber: mobileNumber,
        cardNumber: cardNumber,
        eventType: EventType.GENERATE_OTP
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureResendOtpEvent = (
    mobileNumber: string,
    cardNumber: string,
    resendCount: number
  ) => {
    let eventName = EVENT_NAME.RESEND_OTP;
    if (deepRoute) {
      if (deepRoute === CONVERT_OUTSTANDING_TO_EMI) {
        eventName = EVENT_NAME.RESEND_OTP_OSTOEMI_DEEPLINK;
      } else if (deepRoute === EMI_ELIGIBLE_TRANSACTIONS) {
        eventName = EVENT_NAME.RESEND_OTP_TXNTOEMI_DEEPLINK;
      }
    }
    AppApi.captureEvent({
      eventName: eventName,
      primarySource: window.location.href,
      eventData: {
        resendOtpCount: resendCount,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureOtpVerifyClick = (mobileNumber: string, cardNumber: string) => {
    let eventName = EVENT_NAME.OTP_VERIFY;
    if (deepRoute) {
      if (deepRoute === CONVERT_OUTSTANDING_TO_EMI) {
        eventName = EVENT_NAME.OTP_VERIFY_OSTOEMI_DEEPLINK;
      } else if (deepRoute === EMI_ELIGIBLE_TRANSACTIONS) {
        eventName = EVENT_NAME.OTP_VERIFY_TXNTOEMI_DEEPLINK;
      }
    }
    AppApi.captureEvent({
      eventName: eventName,
      primarySource: window.location.href,
      eventData: {
        mobileNumber: mobileNumber,
        cardNumber: cardNumber,
        eventType: EventType.VERIFY_OTP
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginFailureEvent = (
    loginFailureReason: string,
    mobileNumber: string,
    cardNumber: string
  ) => {
    let eventName = EVENT_NAME.LOGIN_FAILURE;
    if (deepRoute) {
      if (deepRoute === CONVERT_OUTSTANDING_TO_EMI) {
        eventName = EVENT_NAME.LOGIN_FAILURE_OSTOEMI_DEEPLINK;
      } else if (deepRoute === EMI_ELIGIBLE_TRANSACTIONS) {
        eventName = EVENT_NAME.LOGIN_FAILURE_TXNTOEMI_DEEPLINK;
      }
    }
    AppApi.captureEvent({
      eventName: eventName,
      primarySource: window.location.href,
      eventData: {
        loginFailureReason: loginFailureReason,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginSuccessEvent = (
    customerId: string,
    selectedCardId: string,
    accountId: string
  ) => {
    let eventName = EVENT_NAME.LOGIN_SUCCESS;
    if (deepRoute) {
      if (deepRoute === CONVERT_OUTSTANDING_TO_EMI) {
        eventName = EVENT_NAME.LOGIN_SUCCESS_OSTOEMI_DEEPLINK;
      } else if (deepRoute === EMI_ELIGIBLE_TRANSACTIONS) {
        eventName = EVENT_NAME.LOGIN_SUCCESS_TXNTOEMI_DEEPLINK;
      }
    }
    AppApi.captureEvent({
      eventName: eventName,
      hfAccountId: accountId,
      hfCardId: selectedCardId,
      hfCustomerId: customerId,
      primarySource: window.location.href,
      eventData: {
        loginMode: getPWADisplayMode() === "app" ? "App" : "URL",
        eventType: EventType.LOGIN_SUCCESS
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const handleOtpVerifyClick = async (otp: string) => {
    setIsVerifyLoginOtpLoading(true);
    if (divElement1) {
      divElement1.style.margin = "0px";
    }

    // if (otp.length !== 6) {
    //   setIsVerifyLoginOtpLoading(false);
    //   autoReadOtp((otpValue) => {
    //     setOtp(otpValue);
    //     handleOtpVerifyClick(otpValue);
    //     focusOnInput("loginOtpInputContainer", 5);
    //   });
    //   return;
    // }
    captureOtpVerifyClick(mobileNumber, cardNumber); // capturing otp verify click
    try {
      const verifyLoginOtpresponse = (await LoginApi.verifyLoginOtp({
        mobileVerificationRefId: mobileVerificationRef.current,
        otp: "" + otp
      })) as any;
      toastLib.dismiss();
      if (verifyLoginOtpresponse.status !== 200) {
        captureLoginFailureEvent(
          verifyLoginOtpresponse?.data?.failureReason,
          mobileNumber,
          cardNumber
        ); //capturing Login failure event
        if (verifyLoginOtpresponse.data.failureReason === "INVALID_OTP") {
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP entered is incorrect. Please try again"
            );
          }, 1000);
          setTimeout(() => {
            setOtp("");
            if (otpInputRef.current) {
              otpInputRef.current.focusInput(0);
            }
          }, 1500);
        } else if (
          verifyLoginOtpresponse.data.failureReason === "OTP_EXPIRED"
        ) {
          setTimeout(() => {
            toast(ToastType.ERROR, "The OTP has expired. Please try again");
          }, 1000);
        } else {
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP verification has failed. Please try again"
            );
          }, 1000);
        }
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      const verifyLoginOtpResult =
        verifyLoginOtpresponse.data as VerifyLoginOtpSuccessResponse;

      if (
        !verifyLoginOtpResult.customerId ||
        !verifyLoginOtpResult.programId ||
        !verifyLoginOtpResult.accountId ||
        !verifyLoginOtpResult.cardId
      ) {
        console.log(
          "An error occurred while verifying login OTP. Required ids missing in response."
        );
        setIsVerifyLoginOtpLoading(false);
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP verification has failed. Please try again"
          );
        }, 1000);
        return;
      }

      sendNotification(UserAction.LOGIN, {
        uniqueURL: getUniqueURLFromCookie(),
        deviceId: getDeviceIdCookie()
      });

      const { token, metaData } = verifyLoginOtpResult;

      const { pciEncryptionKey, pciEncryptionIv } = getPciEncryptionKeyAndIv(
        token,
        metaData
      );

      const sessionData = {
        token,
        metaData,
        pciEncryptionKey,
        pciEncryptionIv
      };

      captureLoginSuccessEvent(
        verifyLoginOtpResult.customerId,
        verifyLoginOtpResult.cardId,
        verifyLoginOtpResult.accountId
      ); // capturing login success event

      if (
        !verifyLoginOtpresponse.data.lagTimeForInstallPrompt ||
        !verifyLoginOtpresponse.data.showPrompt
      ) {
        sessionStorage.setItem("showPrompt", "false");
      } else {
        sessionStorage.setItem(
          "showPrompt",
          verifyLoginOtpresponse.data.showPrompt
        );
        sessionStorage.setItem(
          "lagTimeForInstallPrompt",
          verifyLoginOtpresponse.data.lagTimeForInstallPrompt
        );
      }

      const userIdsData = {
        customerId: verifyLoginOtpResult.customerId,
        selectedProgramId: verifyLoginOtpResult.programId,
        selectedAccountId: verifyLoginOtpResult.accountId,
        selectedCardId: verifyLoginOtpResult.cardId
      };

      if (!verifyLoginOtpResult.isFirstTimeLogin) {
        // if not first login then set data in store instead of showing preferences bottom sheet
        localStorage.setItem("isFirstTimeLogin", "false");
        dispatch(setSession(sessionData!));
        dispatch(setUserIds(userIdsData!));
        if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
          navigate(PREFETCH_FAILURE);
          return;
        }
        if (getPWADisplayMode() === "app") {
          navigate(HOME_ROUTE);
        } else {
          navigate(INSTALL_SCREEN);
        }
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      // save data in state but not yet in store to show favorites screen
      setSessionState(sessionData);
      setUserIdsState(userIdsData);
      if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
        navigate(PREFETCH_FAILURE);
        return;
      }

      // get program preferences
      const getProgramPreferencesResponse =
        await PreferencesApi.getProgramPreferences(verifyLoginOtpResult.token, {
          programId: verifyLoginOtpResult.programId,
          issuerId: getIssuerId()
        });

      if (getProgramPreferencesResponse.status !== 200) {
        console.log("An error occurred while getting program preferences");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to display your favourites. Please try again"
          );
        }, 1000);
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      let programPreferenceObj: ProgramPreferences = {
        id: "",
        programId: "",
        txnPostingDelayForComputation: 0,
        computeAndReverse: false,
        webHookEndPoint: "",
        webhookSecretKey: "",
        tags: getProgramPreferencesResponse?.data,
        createdAt: "",
        updatedAt: ""
      };

      dispatch(setProgramPreferences(programPreferenceObj));

      setIsOtpBottomSheetOpen(false);
      setIsFavouriteBottomSheetOpen(true);
    } catch (error: any) {
      toastLib.dismiss();
      console.error(
        `An exception occurred while verifying login OTP. Error:\n${error}`
      );
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to display your favourites. Please try again"
        );
      }, 1000);
    }

    setIsVerifyLoginOtpLoading(false);
  };

  const handleResendOtpClick = () => {
    setTimeOut(RESEND_OTP_TIME);
    toastLib.dismiss();
    if (resendCount < 3) {
      setResendCount((resendCount) => resendCount + 1);
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Resending OTP");
      }, 500);
    }

    captureResendOtpEvent(mobileNumber, cardNumber, resendCount + 1);
    debounceSendOtp();
  };

  useEffect(() => {
    // Start the interval
    let interval: any;
    if (isOtpBottomSheetOpen && timeOut > 0) {
      interval = setTimeout(() => {
        setTimeOut(timeOut - 1);
      }, 1000);
    }

    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut, isOtpBottomSheetOpen]);

  useEffect(() => {
    const handleBackButton = () => {
      if (isDetailsBottomSheetOpen || isOtpBottomSheetOpen) {
        setIsDetailsBottomSheetOpen(false);
        setIsOtpBottomSheetOpen(false);
      }
    };
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (isOtpBottomSheetOpen) {
          !isVerifyLoginOtpLoading &&
            otp.length === 6 &&
            handleOtpVerifyClick(otp);
        } else if (isDetailsBottomSheetOpen) {
          !isGenerateLoginOtpLoading &&
            cardNumber.length === 4 &&
            mobileNumber.length === 10 &&
            debounceSendOtp();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [
    otp,
    cardNumber,
    mobileNumber,
    isDetailsBottomSheetOpen,
    isOtpBottomSheetOpen
  ]);

  const handleSavePreferencesClick = async () => {
    setSavePreferencesLoading(true);

    try {
      const selectedTagNames = programPreferences!.tags
        .filter((tag: Tag) => selectedTagIds.includes(tag.id))
        .map((selectedTag: Tag) => selectedTag.name);

      const response = await PreferencesApi.addAccounTags(sessionState!.token, {
        accountId: userIdsState!.selectedAccountId,
        programId: userIdsState!.selectedProgramId,
        tagNames: selectedTagNames,
        issuerId: getIssuerId()
      });

      if (response.status !== 200) {
        console.log("An error occurred while adding account tags");
        setSavePreferencesLoading(false);
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We could not save your favourites. Please try again"
          );
        }, 1000);
        return;
      }

      dispatch(setAccountPreferences(response.data));
      afterPreferencesSelection();
    } catch (error: any) {
      console.error("An exception occurred adding account tags");
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We could not save your favourites. Please try again"
        );
      }, 1000);
    }

    setSavePreferencesLoading(false);
  };

  const handleContactClick = () => {
    setIsContactSupportOpen(!isContactSupportOpen);
  };

  //virtual keyboard & confirm button overlap fix
  useEffect(() => {
    if (getMobileOperatingSystem() === "Android") {
      if (
        document.activeElement ===
        (document.getElementById("inputPhone") ||
          document.getElementById("otp"))
      ) {
        if (divElement) {
          divElement.style.margin = "35px";
        }
      }
    }
    if (divElement1) {
      divElement1.style.margin = "50px";
    }
  }, [isOtpBottomSheetOpen, document.activeElement]);

  const DetailsBottomSheet = () => (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetHeader}>
          enter last 4 digits of your card
        </div>

        <div className={styles.phoneNumberContainer}>
          <div className={styles.phoneNumberInputContainer}>
            <OtpInput
              value={cardNumber}
              onChange={handleCard}
              numInputs={4}
              separator={<span style={{ width: "30px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "0",
                borderBottom: `2px solid ${
                  isCardDetailsNoMatch ? "red" : "#353535"
                }`,
                color: "white",
                width: "40px",
                padding: "0 5px",
                marginRight: "10px",
                textAlign: "center",
                fontSize: "2.5em",
                cursor: "pointer",
                willChange: "border",
                transition: "border .3s ease-in-out",
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                background: "transparent",
                outline: "none"
              }}
              focusStyle={{
                borderBottom: `1px solid ${
                  isCardDetailsNoMatch ? "red" : "#353535"
                }`,
                outline: "none"
              }}
              ref={otpInputRef}
            />
          </div>
        </div>
        {isCardDetailsNoMatch && (
          <span className={styles.noMatchText}>
            Details did not match. You have {cardIncorrectAttemptLeft} attempts
            left.
          </span>
        )}
        <div
          onClick={showToast}
          className={styles.confirmBtn1Container}
          style={{ margin: "20px" }}
        >
          <Button2
            text1="confirm"
            // text2="started"
            customClassName={styles.confirmBtn1}
            isLoading={isGenerateLoginOtpLoading}
            onClick={() => debounceSendOtp()}
            disabled={cardNumber.length !== 4 || isGenerateLoginOtpLoading}
          />
        </div>
      </div>
      <div id="scroll"></div>
    </div>
  );

  const ContactSupport = () => (
    <>
      <div className={styles.contactbottomSheet}>
        <div className={styles.contactbottomSheetContainer}>
          <div className={styles.contactBar}>
            <div className={styles.contactphoneNumberBar}>
              <Contact
                icon={callContact}
                text1={"Call us here (24x7)"}
                text2={`${CONTACT_MOBILE_NUMBER}`}
                type="Phone"
              />
            </div>
            <div className={styles.contactbarSeperator}>
              <hr className={styles.contacthrLine} />
              <div className={styles.contacttextInSeperator}>or</div>
              <hr className={styles.contacthrLine} />
            </div>
            <div className={styles.contactphoneNumberBar}>
              <Contact
                icon={emailContact}
                text1={"Email us at"}
                text2={"premium.care@indusind.com"}
                type="Email"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactconfirmBtn1Container}>
        <Button2
          text1="close"
          customClassName={styles.contactconfirmBtn1}
          onClick={handleContactClick}
        />
      </div>
    </>
  );

  const OTPBottomSheet = () => (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.bottomSheetHeader}>verify with OTP</div>
        <div className={styles.bottomSheetText}>
          Please enter the 6 digit OTP sent to +91 ***** **
          {mobileNumber.slice(-3)}
        </div>
        <div id="loginOtpInputContainer" className={styles.otpContainer}>
          <div className={styles.phoneNumberInputContainer1}>
            <input
              id="inputPhone"
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              placeholder="------"
              className={styles.phoneNumberInput1}
              style={{ opacity: otp.length > 0 ? 1 : 0.5 }}
              value={otp}
              maxLength={6}
              onChange={(e: any) => handleChange(e.target.value)}
              onKeyDown={handleKeyDown}
              readOnly={os === "Android" && !isManualOtpAllowed}
              ref={inputRef}
              autoFocus={os !== "Android"}
              onFocus={handleAutoRead}
            />
          </div>
          <div className={styles.resendContainer}>
            <div>Didn't get the OTP?</div>
            <div className={styles.resendText}>
              {timeOut !== 0 ? (
                <span className={styles.retryText}>
                  Retry in 00:{timeOut.toString().padStart(2, "0")}s
                </span>
              ) : (
                <>
                  <span onClick={handleResendOtpClick}>Resend OTP</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.tncContainer}>
            <div className={styles.tncText}>
              <TextWithLinks text={tncText} linkClassName={styles.linkText} />
            </div>
          </div>
        </div>
        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="confirm"
            // text2="started"
            customClassName={styles.confirmBtn1}
            isLoading={isVerifyLoginOtpLoading}
            onClick={() => handleOtpVerifyClick(otp)}
            disabled={otp.length !== 6}
          />
        </div>
      </div>
      <div id="scroll1"></div>
    </div>
  );

  const items = [
    <div style={{ height: "120px" }}>
      <div className={styles.swipperCard}>
        <img src={Bill} alt="Bill" className={styles.carousalImage} />
        <div>
          <div className={styles["benefits-header"]}>
            <div className={styles.text2}>
              <span className={styles.text}>Discover</span> benefits
            </div>
            <div className={styles.text2}>waiting for you</div>
          </div>
        </div>
      </div>
    </div>,
    <div className={styles.swipperCard}>
      <img src={Banner} alt="Banner" className={styles.carousalImage} />
      <div>
        <div className={styles["benefits-header"]}>
          <div className={styles.text2}>
            <span className={styles.text}>Explore</span> a world of
          </div>
          <div className={styles.text2}>exclusive offers</div>
        </div>
      </div>
    </div>,
    <div className={styles.swipperCard}>
      <img src={Bill2} alt="Bills" className={styles.carousalImage} />
      <div>
        <div className={styles["benefits-header"]}>
          <div className={styles.text2}>
            <span className={styles.text}>Manage</span> your card
          </div>
          <div className={styles.text2}>like never before</div>
        </div>
      </div>
    </div>
  ];
  if (!isUniqueUrlCorrect) {
    return <InvalidUniqueUrl />;
  }

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="your IndusInd CardsHub" noTextStyle />
    </div>
  ) : (
    <>
      {smsVerify === true ? (
        <SmsVerify />
      ) : (
        <>
          <div className={styles.preLoginContainer}>
            <div className={styles.header}>
              <div>
                <img src={IndusInd_Logo} alt="IndusInd Bank" />
              </div>
            </div>

            <div className={styles.welcomeContainer}>
              <InterMediateText
                text1="Welcome"
                text3="to"
                text2="IndusInd Bank CardsHub"
              />
            </div>

            <div className={styles.cardContainer} ref={cardLottieDiv}>
              {showSecond && (
                <LottieView
                  animationData={CardsLottie}
                  autoPlay
                  loop={true}
                  className={styles.cardLottie}
                />
              )}
            </div>

            {uniqueURL || getDeviceIdCookie() ? (
              <div className={styles.preLoginCarousal}>
                <Carousel items={items} />
              </div>
            ) : (
              <>
                <div className={styles.accessText}>
                  Access CardsHub via a secured login link
                  <span className={styles.accessText2}>
                    sent by IndusInd Bank on your registered
                  </span>
                  <span className={styles.accessText2}>
                    e-mail ID or mobile number
                  </span>
                </div>
              </>
            )}

            {uniqueURL || getDeviceIdCookie() ? (
              <div className={styles.btnContainer}>
                <Button2
                  text1="login"
                  text1Style={{ fontWeight: "bold" }}
                  isLoading={isDeviceChecking}
                  customClassName={styles.getStartedButton}
                  onClick={handleGetStartedButtonClick}
                  disabled={isDeviceChecking}
                />
              </div>
            ) : (
              <>
                <div className={styles.contactcantFindText}>
                  Can’t find the link?
                </div>
                <div className={styles.contactbtnContainer}>
                  <Button
                    text1="contact support"
                    text1Style={{ fontWeight: "bold" }}
                    isLoading={isDeviceChecking}
                    customClassName={styles.getStartedButton}
                    onClick={handleContactClick}
                  />
                </div>
              </>
            )}
          </div>
          <BottomsheetComponent
            isBottomSheetOpen={isDetailsBottomSheetOpen}
            setIsBottomSheetOpen={setIsDetailsBottomSheetOpen}
            render={DetailsBottomSheet()}
          />
          <BottomsheetComponent
            isBottomSheetOpen={isOtpBottomSheetOpen}
            setIsBottomSheetOpen={setIsOtpBottomSheetOpen}
            render={OTPBottomSheet()}
          />
          <BottomsheetComponent
            isBottomSheetOpen={isFavouriteBottomSheetOpen}
            setIsBottomSheetOpen={setIsFavouriteBottomSheetOpen}
            render={FavouriteBottomSheet({
              programPreferences,
              handleTagClick,
              selectedTagIds,
              handleSkipPreferencesClick,
              isSavePreferencesLoading,
              handleSavePreferencesClick
            })}
          />

          <BottomsheetComponent
            isBottomSheetOpen={isContactSupportOpen}
            setIsBottomSheetOpen={setIsContactSupportOpen}
            render={ContactSupport()}
          />
        </>
      )}
    </>
  );
};

export default PreLogin;

export { FavouriteBottomSheet };
