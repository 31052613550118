/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { Dispatch, useRef } from "react";
import Carousel from "../carousel";
import styles from "./styles.module.scss";
import arrowCircle1 from "../../assets/svgs/ArrowCircleUpRight1.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import cross from "../../assets/svgs/cross.svg";
import GradientCircleProgressbar from "../../pages/home/campaignOffersSection/campaignOffer/circleProgressBar";
import { NudgeRequireType } from "../../apis/nudgesApi/nudgesApiTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedAccount,
  getCustomer,
  getSelectedCard
} from "../../features/user/userSlice";
import { useIntersection } from "../../hooks/useIntersection";
import {
  CONVERT_OUTSTANDING_TO_EMI,
  CONVERT_TO_EMI_ROUTE,
  SPEND_ANALYTICS_ROUTE,
  VIEW_STATEMENTS_ROUTE,
  EMI_ELIGIBLE_TRANSACTIONS
} from "../../routes/ScreenRoutes";
import { API_REQUEST_DATE_FORMAT } from "../../utils/constants";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import dayjs from "dayjs";
import { GetAccountResponse, EVENT_NAME } from "../../apis/appApi/appApiTypes";
import store from "../../app/store";

import AppApi from "../../apis/appApi/AppApi";
import { RequestSources } from "../../utils/enums";
interface NudgesProps {
  items: any;
}
interface ItemSampleProps {
  illustrationUrl: string;
  title: string;
  description: string;
  redirectionUrl: string;
  id: string;
  onRemoveClick: any;
  nudge: any;
  index: number;
}

interface NavigateFromNudgeProps {
  dispatch: Dispatch<any>;
  account: GetAccountResponse;
  navigate: NavigateFunction;
  redirectionUrl: string;
  transactionId?: any;
}

interface HandleNudgeClickEventCapture {
  id: string;
  redirectionUrl: any;
  title: string;
  selectedCardId: string;
  customerId: string;
  index: number;
}

const ItemSample = ({
  illustrationUrl,
  title,
  description,
  redirectionUrl,
  id,
  onRemoveClick,
  nudge,
  index
}: ItemSampleProps): React.JSX.Element => {
  const account = useSelector(getSelectedAccount)!;
  const accountId = account?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customer = useSelector(getCustomer)!;
  const selectedCard = useSelector(getSelectedCard)!;
  const ref = useRef<any>(null);
  const inViewport = useIntersection(ref, "0px");
  if (inViewport && !!ref?.current?.id) {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery")!);
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId!]: {
          ...(nudgesDelivery?.[accountId!] || {}),
          [ref?.current?.id]: {
            ...(nudgesDelivery?.[accountId!]?.[ref?.current?.id] || {}),
            sessions: (
              nudgesDelivery?.[accountId!]?.[ref?.current?.id]?.sessions || []
            ).includes(sessionStorage.getItem("sessionToken")!)
              ? nudgesDelivery?.[accountId!]?.[ref?.current?.id]?.sessions || []
              : [
                  ...(nudgesDelivery?.[accountId!]?.[ref?.current?.id]
                    ?.sessions || []),
                  sessionStorage.getItem("sessionToken")!
                ]
          }
        }
      })
    );
  }

  const deleteNudge = (id: string) => {
    const jsonString = localStorage.getItem("removedNudges");
    const nudgeObj = JSON.parse(jsonString!) || {};
    if (!nudgeObj?.[accountId!]) {
      nudgeObj[accountId!] = [id];
    } else {
      nudgeObj[accountId!]?.push(id);
    }
    const updatedJsonString = JSON.stringify(nudgeObj);
    localStorage.setItem("removedNudges", updatedJsonString);
    onRemoveClick(id);
  };
  const requireType = nudge.displayOptions.requireType;

  const handleNudgeClickEventCapture = ({
    id,
    redirectionUrl,
    title,
    selectedCardId,
    customerId,
    index
  }: HandleNudgeClickEventCapture) => {
    sessionStorage.setItem("requestSource", RequestSources.NUDGE);
    // reading data for event capture
    AppApi.captureEvent({
      eventName: EVENT_NAME.NUDGE_CLICK,
      hfAccountId: accountId,
      hfCardId: selectedCardId,
      hfCustomerId: customerId,
      primarySource: window.location.href,
      eventData: {
        nudgeId: id,
        nudge_title: title,
        nudge_redirection_url: redirectionUrl,
        nudge_position: index + 1
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const handleNudgeClick = (id: string) => {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery")!);
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId!]: {
          ...(nudgesDelivery?.[accountId!] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId!]?.[id] || {}),
            clickCount:
              (nudgesDelivery?.[accountId!]?.[id]?.clickCount || 0) + 1
          }
        }
      })
    );
  };
  return (
    <div className={styles.container}>
      {nudge.offerType === "MILESTONE" ? (
        <div className={styles.nudgeProgressContainer}>
          <GradientCircleProgressbar
            percentage={(nudge.currentValue / nudge.threshold) * 100}
            width={50}
          >
            <img
              className={styles.nudgePendingIllustrationImg}
              src="/images/Sparkle.svg"
              alt="sparkle"
            />
          </GradientCircleProgressbar>
        </div>
      ) : (
        <img
          className={styles["logo"]}
          src={illustrationUrl}
          alt="illustration"
        />
      )}
      <div className={styles.nudgeTextContainer}>
        <div className={styles["text-1"]}>{title}</div>
        <div className={styles["text-2"]}>{description}</div>
      </div>
      <img
        ref={ref}
        id={id}
        className={styles["sample-logo1"]}
        src={arrowCircle1}
        alt="Arrow"
        onClick={() => {
          handleNudgeClickEventCapture({
            id,
            redirectionUrl,
            title,
            selectedCardId: selectedCard?.id,
            customerId: customer?.id,
            index
          });
          handleNudgeClick(id);
          if (redirectionUrl) {
            navigateFromNudge({
              dispatch,
              navigate,
              account,
              redirectionUrl,
              transactionId: nudge?.transactionId
            });
          }
        }}
      />
      {requireType === NudgeRequireType.OPTIONAL && (
        <div
          onClick={() => deleteNudge(id)}
          className={styles.crossBtnContainer}
        >
          <img src={cross} width={"10px"} height={"10px"} />
        </div>
      )}
    </div>
  );
};

export const navigateFromNudge = ({
  dispatch,
  navigate,
  account,
  redirectionUrl,
  transactionId
}: NavigateFromNudgeProps) => {
  const billingCyclesSelector = store.getState().miscellaneous.billingCycles;
  if (redirectionUrl.startsWith("http")) {
    window.open(redirectionUrl, "_blank");
  } else if (redirectionUrl === CONVERT_TO_EMI_ROUTE && transactionId) {
    navigate(redirectionUrl, {
      state: { transactionId: transactionId }
    });
  } else if (redirectionUrl === SPEND_ANALYTICS_ROUTE) {
    dispatch(setMiscellaneousState({ spendAnalyticsBillingCycle: null }));
    navigate(redirectionUrl, {
      state: {
        billingCycle: {
          from: dayjs(account?.currentCycleStartDate).format(
            API_REQUEST_DATE_FORMAT
          ),
          to: dayjs(account?.currentCycleEndDate).format(
            API_REQUEST_DATE_FORMAT
          )
        }
      }
    });
  } else if (redirectionUrl === VIEW_STATEMENTS_ROUTE) {
    dispatch(
      setMiscellaneousState({ statementBillingCycle: billingCyclesSelector[1] })
    );
    // dispatch(setMiscellaneousState({ statementBillingCycle: null }));
    navigate(redirectionUrl);
  } else if (redirectionUrl?.includes(EMI_ELIGIBLE_TRANSACTIONS)) {
    navigate(redirectionUrl);
  } else if (redirectionUrl === CONVERT_OUTSTANDING_TO_EMI) {
    navigate(redirectionUrl);
  } else {
    navigate(redirectionUrl);
  }
};

const index = (props: NudgesProps) => {
  const { items } = props;
  const itemsArr = items?.map((item: any, index: number) => {
    if (!item.title || !item.description) {
      return;
    }
    return (
      <ItemSample
        illustrationUrl={item.illustrationUrl}
        title={item.title}
        description={item.description}
        redirectionUrl={item.redirectionUrl}
        id={item.id}
        onRemoveClick={() => item.onRemoveClick(item.id)}
        nudge={item.nudge}
        index={index}
        key={index}
      />
    );
  });

  const cleanedUpItemsArr = itemsArr.filter(Boolean);
  if (cleanedUpItemsArr.length === 0) {
    return <></>;
  }

  return (
    <Carousel items={cleanedUpItemsArr} initialSlide={0} slidesPerView={1.2} />
  );
};

export default index;
