import React from "react";
import Styles from "./MaxSpendTextStyles.module.scss";
import starStruck from "../../assets/svgs/SpendAnalytics/StarStruck.svg";
import { maxSpendCategoryTextObj } from "../../utils/SpendAnalyticsUtil";

type Props = {
  maxCategory: string;
};

const MaxSpendText = ({ maxCategory }: Props) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <div>
          {" "}
          <img src={starStruck} alt="star-struk" />{" "}
        </div>
        <div>{maxSpendCategoryTextObj[maxCategory]}</div>
        <div></div>
      </div>
      <div className={Styles.hiddenContainer}></div>
    </div>
  );
};

export default MaxSpendText;
