import plug from "../../assets/svgs/plug.svg";
import { ripplePattern3 } from "../../assets/svgs";
import styles from "./prefetchFailure.module.scss";
import { Button } from "../../components";
import reloadIcon from "../../assets/svgs/refreshWhite.svg";

const PrefetchFailure = () => {
  const handleReload = () => {
    window.location.href = "./";
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.imgContainer}>
        <img
          className={styles.rippleBackground}
          src={ripplePattern3}
          alt="pattern"
        />
        <img className={styles.plug} src={plug} alt="plug" />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text1}>Oops! Something’s not right</div>
        <div className={styles.text2}>
          We’re facing an issue loading your space at the moment Please try
          again or visit back in sometime
        </div>
        <Button
          icon={reloadIcon}
          text1="Try Again"
          customClassName={styles.button}
          onClick={handleReload}
        />
      </div>
    </div>
  );
};

export default PrefetchFailure;
