import hero from "../../assets/svgs/festiveUI/Hero.json";
import cardAndNudgeDivider from "../../assets/svgs/festiveUI/cardAndNudgeDivider.svg";
import nudgeAndCampaignDividerLeft from "../../assets/svgs/festiveUI/nudgeAndCampaignDividerLeft.svg";
import nudgeAndCampaignDividerRight from "../../assets/svgs/festiveUI/nudgeAndCampaignDividerRight.svg";
import campaignAndCVPDividerTop from "../../assets/svgs/festiveUI/campaignCvpDividerOne.svg";
import campaignAndCVPDividerBottom from "../../assets/svgs/festiveUI/campaignCvpDivider.svg";
import cvpAndMerchantDivider from "../../assets/svgs/festiveUI/cvpAndMerchantDivider.svg";
import footer from "../../assets/svgs/festiveUI/footer.svg";
import campaignCardProgressBg from "../../assets/svgs/festiveUI/campaignCardProgressBg.svg";
import campaignBackgroundPattern from "../../assets/svgs/festiveUI/campaignBackgroundPattern.svg";
import cvpOffersSpotlightLeft from "../../assets/svgs/festiveUI/cvpOffersSpotlightLeft.svg";
import cvpOffersSpotlightRight from "../../assets/svgs/festiveUI/cvpOfferSpotlightRight.svg";
import merchantOffersCardBackground from "../../assets/svgs/festiveUI/merchantOfferBackgroundPattern.svg";
import airplane from "../../assets/svgs/festiveUI/AirplaneTilt.svg";
import headphones from "../../assets/svgs/festiveUI/Headphones.svg";
import beauty from "../../assets/svgs/festiveUI/Beauty.svg";
import sunglasses from "../../assets/svgs/festiveUI/Sunglasses.svg";
import pizza from "../../assets/svgs/festiveUI/Pizza.svg";

export const XmasData = {
  startDate: "2023-12-20T00:00:00+05:30",
  endDate: "2024-01-10T23:59:59+05:30",
  images: {
    header: {
      url: hero,
      alt: "Header Background"
    },
    cardAndNudgeDivider: {
      url: cardAndNudgeDivider,
      alt: "Divider"
    },
    nudgeAndCampaignDivider: {
      left: {
        url: nudgeAndCampaignDividerLeft,
        alt: "left divider"
      },
      right: {
        url: nudgeAndCampaignDividerRight,
        alt: "right divider"
      }
    },
    campaignAndCVPDivider: {
      top: {
        url: campaignAndCVPDividerTop,
        alt: "campaign divider top"
      },
      bottom: {
        url: campaignAndCVPDividerBottom,
        alt: "campaign divider bottom"
      }
    },
    cvpAndMerchantDivider: {
      url: cvpAndMerchantDivider,
      alt: "divider"
    },
    footer: {
      url: footer,
      alt: "footer"
    },
    campaignOffers: {
      cardProgressBackground: {
        url: campaignCardProgressBg,
        alt: "progress background"
      },
      cardBackground: {
        url: campaignBackgroundPattern,
        alt: "card background"
      }
    },
    cvpOffers: {
      spotlightLeft: {
        url: cvpOffersSpotlightLeft,
        alt: "spotlight left"
      },
      spotlightRight: {
        url: cvpOffersSpotlightRight,
        alt: "spotlight right"
      }
    },
    merchantOffers: {
      cardBackground: {
        url: merchantOffersCardBackground,
        alt: "background"
      },
      tagIcons: {
        travel: {
          url: airplane,
          alt: "airplane"
        },
        electronics: {
          url: headphones,
          alt: "headphones"
        },
        beauty: {
          url: beauty,
          alt: "beauty"
        },
        apparel: {
          url: sunglasses,
          alt: "sunglasses"
        },
        food: {
          url: pizza,
          alt: "food"
        }
      }
    }
  },
  text: {
    header: {
      line1: "celebrate",
      line2: "New Year with IndusInd"
    },
    merchantOffers: {
      line1: "festive offers",
      line2: "curated for you"
    }
  },
  colors: {
    campaignOffers: {
      outline: "#88CDFF",
      progressColor1: "#60ECFF",
      progressColor2: "#FFFF",
      cardChipTextColor: "#88CDFF",
      cardChipBackground: "rgba(163, 197, 222, 0.08)"
    }
  }
};
