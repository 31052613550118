import dayjs from "dayjs";
import styles from "./benefitUtilStyles.module.scss";
import {
  BenefitType,
  type GetBenefitsForAccountResponseElement
} from "../apis/benefitsApi/benefitsApiTypes";
import { TextWithLinks } from "../components/textWithLinks/TextWithLinks";
import { truncateMilestoneTextDecimal } from "./functions";

const parseMilestoneTextPlaceholders = (
  benefit: GetBenefitsForAccountResponseElement,
  text: string,
  daysLeft: number
) => {
  if (!text) return "";

  const currentValue = benefit.currentValue;
  const thresholdValue = benefit.threshold;
  const pendingValue = Math.ceil(thresholdValue - currentValue);
  const pendingPercentage = truncateMilestoneTextDecimal(
    (pendingValue * 100) / thresholdValue
  );
  const completionPercentage = truncateMilestoneTextDecimal(
    (currentValue * 100) / thresholdValue
  );

  let textWithPlaceholders = text;

  // replace placeholders in text
  textWithPlaceholders = textWithPlaceholders.replace(
    "{thresholdValue}",
    thresholdValue.toString()
  );
  textWithPlaceholders = textWithPlaceholders.replace(
    "{pendingValue}",
    pendingValue.toString()
  );
  textWithPlaceholders = textWithPlaceholders.replace(
    "{pendingPercentage}",
    pendingPercentage.toString()
  );
  textWithPlaceholders = textWithPlaceholders.replace(
    "{completionPercentage}",
    completionPercentage.toString()
  );
  textWithPlaceholders = textWithPlaceholders.replace(
    "{daysLeft}",
    daysLeft.toString()
  );

  return textWithPlaceholders;
};

export const getBenefitTexts = (
  benefit: GetBenefitsForAccountResponseElement
) => {
  const offerDetails = JSON.parse(benefit.offerDetails);
  const ctaTextColor = offerDetails.ctaTextColor;
  const ctaLink = offerDetails.ctaLink;
  const daysLeft = dayjs(benefit.activeTo).diff(dayjs(), "day");
  const progress =
    benefit.benefitType === BenefitType.MILESTONE
      ? (benefit.currentValue / benefit.threshold) * 100
      : 0;
  const isPendingMilestone =
    benefit.benefitType === BenefitType.MILESTONE && progress < 100;
  const isCompletedMilestone =
    benefit.benefitType === BenefitType.MILESTONE && progress >= 100;
  const isNonMilestone = benefit.benefitType !== BenefitType.MILESTONE;

  let titleArray = [];
  let title1 = "";
  let title2 = "";
  let description = "";

  switch (true) {
    case isPendingMilestone:
      titleArray = benefit.displayTitle?.split("]");
      title1 = parseMilestoneTextPlaceholders(
        benefit,
        titleArray[0]?.split("[")?.[1]?.trim(),
        daysLeft
      );
      title2 = parseMilestoneTextPlaceholders(
        benefit,
        titleArray[1]?.trim(),
        daysLeft
      );
      description = parseMilestoneTextPlaceholders(
        benefit,
        benefit.displayDescription,
        daysLeft
      );
      break;

    case isCompletedMilestone:
      title1 = parseMilestoneTextPlaceholders(
        benefit,
        offerDetails.completedText2,
        daysLeft
      );
      title2 = parseMilestoneTextPlaceholders(
        benefit,
        offerDetails.completedText3,
        daysLeft
      );
      description = parseMilestoneTextPlaceholders(
        benefit,
        offerDetails.completedText1,
        daysLeft
      );
      break;

    case isNonMilestone:
      titleArray = benefit.displayTitle?.split("]");
      title1 = titleArray[0]?.split("[")?.[1]?.trim();
      title2 = titleArray[1]?.trim();
      description = benefit.displayDescription;
      break;

    default:
      break;
  }

  return {
    daysLeft,
    progress,
    title1,
    title2,
    description,
    ctaTextColor,
    ctaLink
  };
};

export const getOfferDetailsListSection = (
  title: string,
  listItems: string | string[]
) =>
  listItems && (
    <>
      <div className={styles.divider}></div>
      <div className={styles.tncContainer}>
        <div className={styles.tncTitleText}>
          {typeof listItems === "string" && listItems.startsWith("http") ? (
            <div
              className={styles.tncLinkHeading}
              onClick={() => window.open(listItems, "_blank")}
            >
              {title}
            </div>
          ) : (
            title
          )}
        </div>
        {Array.isArray(listItems) && (
          <div className={styles.tncStepText}>
            <ul>
              {listItems?.map((item: string, index) => {
                return (
                  <li className={styles.redeemListItem} key={index}>
                    <TextWithLinks
                      text={item}
                      linkClassName={styles.linkText}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

type IntermediateTextProps = {
  text1: string;
  text2: string;
  text3?: string;
  icon?: string;
  customClassname?: string;
};

export const CvpOffersIntermediateText = ({
  text1,
  text2,
  text3,
  icon,
  customClassname
}: IntermediateTextProps) => {
  return (
    <div className={`${styles.cvpIntText} ${customClassname}`}>
      <div>
        <span className={styles.cvpIntText1}>{text1}</span>{" "}
        <span className={styles.cvpIntText2}>{text2}</span>{" "}
        <span className={styles.cvpIntText3}>{text3}</span>{" "}
      </div>
      {!!icon ? (
        <img src={icon} className={styles.cvpIntIcon} alt="icon" />
      ) : null}
    </div>
  );
};
