import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  miscellaneousState,
  setMiscellaneousStateActionPayload
} from "./miscellaneousSliceTypes";

const initialState: miscellaneousState = {
  statementBillingCycle: null,
  spendAnalyticsBillingCycle: null,
  statements: [],
  billingCycles: [],
  selectedBillingCycle: {},
  prefetchLoadFailure: false,
  showActivationJourney: true
};

export const miscellaneousSlice = createSlice({
  name: "miscellaneous",
  initialState,
  reducers: {
    setMiscellaneousState: (
      state,
      action: PayloadAction<setMiscellaneousStateActionPayload>
    ) => {
      Object.assign(state, action.payload);
    }
  }
});

export const { setMiscellaneousState } = miscellaneousSlice.actions;

export default miscellaneousSlice.reducer;
