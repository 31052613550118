/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { type RootState } from "./app/store";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import Routes from "./routes/Routes";
import {
  API_REQUEST_DATE_FORMAT,
  CORRELATION_ID_HEADER,
  INVALID_SESSION_STATUS_CODES,
  PWA_MAX_WIDTH_IN_PX
} from "./utils/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  getSelectedCard,
  setCardDetails,
  setPrimaryCustomerId,
  setTrackingDetails,
  setUserData,
  setUserIds
} from "./features/user/userSlice";
import { setEvent } from "./features/event/eventSlice";
import {
  OfferType,
  BenefitType,
  BenefitStatus,
  BenefitSortBy,
  BenefitSortOrder,
  GetBenefitsForAccountRequest
} from "./apis/benefitsApi/benefitsApiTypes";
import BenefitsApi from "./apis/benefitsApi/BenefitsApi";
import {
  setCvpBenefits,
  setCampaignBenefits,
  setMerchantBenefits,
  setProgramPreferences,
  setAccountPreferences,
  setWelcomeBenefits,
  setAllOffersApiFailure
} from "./features/benefits/benefitsSlice";
import AppApi from "./apis/appApi/AppApi";
import ApiIds from "./auth/ApiIds";
import { setAuthConfig } from "./features/auth/authSlice";
import AuthScreens from "./auth/screens/AuthScreens";
import {
  PREFETCH_FAILURE,
  PRE_LOGIN_ROUTE,
  BROWSER_NOT_SUPPORTED_SCREEN,
  BROWSER_OLDER_VERSION,
  HOME_ROUTE,
  INCOGNITO_DETECTED,
  INSTALL_SCREEN
} from "./routes/ScreenRoutes";
import logger from "./utils/logger";
import { setSession, setWasLoggedIn } from "./features/session/sessionSlice";
import PreferencesApi from "./apis/prefrencesApi/PreferencesApi";
import Loader from "./components/loader/loader";
import { getCustomerBlockCodeStatus } from "./data/blockCodes/BlockCodeUtil";
import {
  aesEncryptData,
  getPciEncryptionKeyAndIv
} from "./utils/encryptionUtil";
import { Env } from "./utils/enums";
import useIdle from "./hooks/useIdle";
import {
  CardType,
  getConfig,
  getIssuerId,
  isProgramPresent,
  supportedBrowsers,
  supportedBrowserVersions
} from "./data/config";
import { ProgramPreferences } from "./apis/prefrencesApi/preferencesApiTypes";
import { setMiscellaneousState } from "./features/miscellaneous/miscellaneousSlice";
import AccountsApi from "./apis/accountsApi/AccountsApi";
import dayjs from "dayjs";
import CardsApi from "./apis/cardsApi/CardsApi";
import { Journey } from "./apis/appApi/appApiTypes";
import { getParameterByName, getGeoLocation } from "./utils/trackingFunctions";
import DesktopScreen from "./components/desktopScreen/desktopScreen";
import IpBlockedScreen from "./components/ipBlockedScreen/ipBlockedScreen";
import UAParser from "ua-parser-js";
import { setEligibilityForOutstandingToEmi } from "./features/outstandingToEmi/outstandingToEmiSlice";
import { EmiType } from "./apis/accountsApi/accountsApiTypes";
import { detectIncognito } from "./utils/detectIncognito";
import { createDeepLinkCookie, isReload } from "./utils/functions";

const env = process.env.REACT_APP_NAME;

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let parser = new UAParser(navigator.userAgent);
  const os = String(parser.getOS().name);

  const selectedCardInstore = useSelector(getSelectedCard);

  const token = useSelector((state: RootState) => state.session.token);
  const wasLoggedIn = useSelector(
    (state: RootState) => state.session.wasLoggedIn
  );
  const customerId = useSelector((state: RootState) => state.user.customerId);
  const programId = useSelector(
    (state: RootState) => state.user.selectedProgramId
  );
  const accountId = useSelector(
    (state: RootState) => state.user.selectedAccountId
  );
  const cardId = useSelector((state: RootState) => state.user.selectedCardId);
  const programPreferences = useSelector(
    (state: RootState) => state.benefits.programPreferences
  );
  const isCardTrackingEligible = useSelector(
    (state: RootState) => state.user.showCardTracking
  );

  const accountPreferences = useSelector(
    (state: RootState) => state.benefits.accountPreferences
  );

  const prefetchLoadSelector = useSelector(
    (state: RootState) => state.miscellaneous.prefetchLoadFailure
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("");
  const [isIPLoading, setIsIPLoading] = useState<boolean>(true);
  const [browserCheck, setBrowserCheck] = useState<boolean>(false);

  const [config, setConfig] = useState<any>();
  const card = useSelector(getSelectedCard)!;
  useEffect(() => {
    getConfig().then(res => setConfig(res));
  }, [card]);


  // to track user activity
  useIdle(
    (config?.sessionInactivityTimeoutInMinutes ?? 15) * 60 * 1000,
    Boolean(token),
    accountId!,
    customerId!,
    cardId!
  );

  // sentry
  useEffect(() => {
    if (env === Env.SANDBOX || env === Env.PROD) {
      Sentry.init({
        dsn: "https://96b5ca34c55580874ad1afcd27ec9aac@o4505131282006016.ingest.sentry.io/4505623314104320",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: env
      });
    }
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event: any) => {
      event.preventDefault();
      dispatch(
        setEvent({
          event
        })
      );
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", (event: any) => {
        event.preventDefault();
      });
    };
  }, []);

  const location = useLocation();
  useEffect(() => {
    const utm_source = getParameterByName("utm_source", window.location.href);
    sessionStorage.setItem("utm_source", utm_source ?? "");
    const utm_campaign = getParameterByName(
      "utm_campaign",
      window.location.href
    );
    const path = location.pathname.replace(/\/$/, "");
    if (
      path !== "" &&
      path !== HOME_ROUTE &&
      path !== INSTALL_SCREEN &&
      path !== PRE_LOGIN_ROUTE &&
      !isReload()
    ) {
      createDeepLinkCookie(path);
    }
    sessionStorage.setItem("utm_campaign", utm_campaign ?? "");
    const utm_medium = getParameterByName("utm_medium", window.location.href);
    sessionStorage.setItem("utm_medium", utm_medium ?? "");
    const isManualOtpAllowed = getParameterByName(
      "allowed",
      window.location.href
    );
    sessionStorage.setItem("allowed", isManualOtpAllowed ?? "");
    const country = getParameterByName("country", window.location.href);
    sessionStorage.setItem("country", country ?? "");
  }, []);

  useEffect(() => {
    if ((env === Env.SANDBOX || env === Env.PROD) && token) {
      Sentry.setUser({ sessionToken: token });
    }
  }, [token]);

  useEffect(() => {
    const pathsHistory = !!localStorage.getItem("pathsHistory")
      ? JSON.parse(localStorage.getItem("pathsHistory")!)
      : {};

    const prev = pathsHistory.current || "";
    const current = location.pathname;
    localStorage.setItem("pathsHistory", JSON.stringify({ prev, current }));
    if (prev !== current) {
      setBrowserCheck(!browserCheck);
    }
  }, [location]);

  useEffect(() => {
    // get existing session from local storage, if present

    const sessionToken = sessionStorage.getItem("sessionToken");
    const sessionMetadata = sessionStorage.getItem("sessionMetadata");

    const customerId = sessionStorage.getItem("customerId");
    const selectedProgramId = sessionStorage.getItem("selectedProgramId");
    const selectedAccountId = sessionStorage.getItem("selectedAccountId");
    const selectedCardId = sessionStorage.getItem("selectedCardId");

    if (
      sessionToken &&
      sessionMetadata &&
      customerId &&
      selectedProgramId &&
      selectedAccountId &&
      selectedCardId
    ) {
      // use session from local storage only if all fields are available

      const { pciEncryptionKey, pciEncryptionIv } = getPciEncryptionKeyAndIv(
        sessionToken,
        sessionMetadata
      );

      dispatch(
        setSession({
          token: sessionToken,
          metaData: sessionMetadata,
          pciEncryptionKey,
          pciEncryptionIv
        })
      );

      dispatch(
        setUserIds({
          customerId,
          selectedProgramId,
          selectedAccountId,
          selectedCardId
        })
      );
    }
  }, [dispatch]);

  const getData = useCallback(async () => {
    if (!token) return;

    if (!customerId || !programId || !accountId || !cardId) {
      logger.error({
        customerId,
        programId,
        accountId,
        cardId,
        message: `An error occurred while getting app level data. Required ids missing.`
      });
      navigate(PRE_LOGIN_ROUTE);
      return;
    }

    setIsLoading(true);
    dispatch(
      setMiscellaneousState({
        prefetchLoadFailure: false
      })
    );

    try {
      // get app level data

      // call apis parallely
      const getCustomerPromise = AppApi.getCustomer(customerId!);
      const getAccountPromise = AppApi.getAccount(accountId!, cardId!);
      const getCardPromise = AppApi.getCard(cardId!);
      // design config is unused as of now
      // const designConfigPromise = AppApi.getDesignConfig();

      const customerResponse = await getCustomerPromise;
      const accountResponse = await getAccountPromise;
      const cardResponse = await getCardPromise;
      // const designConfigResponse = await designConfigPromise;

      if (INVALID_SESSION_STATUS_CODES.includes(customerResponse.status)) {
        setIsLoading(false);
        navigate(PRE_LOGIN_ROUTE);
        return;
      }

      if (customerResponse.status !== 200) {
        logger.error({
          correlationId: customerResponse.headers[CORRELATION_ID_HEADER],
          customerId,
          responseData: customerResponse.data,
          message: `An error occurred while getting customer for app`
        });
        dispatch(
          setMiscellaneousState({
            prefetchLoadFailure: true
          })
        );
        setIsLoading(false);
        navigate(PREFETCH_FAILURE);
        return;
      }

      if (accountResponse.status !== 200) {
        logger.error({
          correlationId: accountResponse.headers[CORRELATION_ID_HEADER],
          accountId,
          responseData: accountResponse.data,
          message: `An error occurred while getting account for app`
        });
        dispatch(
          setMiscellaneousState({
            prefetchLoadFailure: true
          })
        );
        setIsLoading(false);
        navigate(PREFETCH_FAILURE);
        return;
      }

      if (cardResponse.status !== 200) {
        logger.error({
          correlationId: cardResponse.headers[CORRELATION_ID_HEADER],
          cardId,
          responseData: cardResponse.data,
          message: `An error occurred while getting card for app`
        });
        dispatch(
          setMiscellaneousState({
            prefetchLoadFailure: true
          })
        );
        setIsLoading(false);
        navigate(PREFETCH_FAILURE);
        return;
      }

      if (cardResponse.data?.primaryCustomerId! !== customerResponse.data.id) {
        const getPrimaryCustomerPromise = AppApi.getCustomer(
          cardResponse.data?.primaryCustomerId!
        );
        const primaryCustomerResponse = await getPrimaryCustomerPromise;
        if (primaryCustomerResponse.status !== 200) {
          logger.error({
            correlationId:
              primaryCustomerResponse.headers[CORRELATION_ID_HEADER],
            customerId,
            responseData: primaryCustomerResponse.data,
            message: `An error occurred while getting details of primary customer for app`
          });
          setIsLoading(false);
          return;
        } else {
          sessionStorage.setItem(
            "mobileNumberlastFour",
            aesEncryptData(
              process.env.REACT_APP_PII_ENCRYPTION_KEY!,
              process.env.REACT_APP_PII_ENCRYPTION_IV!,
              primaryCustomerResponse?.data?.lastFour
            )
          );
        }
      } else {
        sessionStorage.setItem(
          "mobileNumberlastFour",
          aesEncryptData(
            process.env.REACT_APP_PII_ENCRYPTION_KEY!,
            process.env.REACT_APP_PII_ENCRYPTION_IV!,
            customerResponse?.data?.lastFour
          )
        );
      }

      const otherCardsPromise = AppApi.getOtherCards({
        customerId: cardResponse.data?.primaryCustomerId!
      });
      const otherCardsResponse = await otherCardsPromise;

      if (otherCardsResponse.status !== 200) {
        logger.error({
          correlationId: otherCardsResponse.headers[CORRELATION_ID_HEADER],
          cardId,
          responseData: otherCardsResponse.data,
          message: `An error occurred while getting other card for app`
        });
      }

      const customer = customerResponse.data;
      const account = accountResponse.data;
      const card = cardResponse.data;
      dispatch(
        setCardDetails(
          CardType[process.env.REACT_APP_NAME as Env][card.programId!]
        )
      );
      dispatch(setPrimaryCustomerId(card.primaryCustomerId));
      dispatch(
        setUserData({
          customer: {
            ...customer,
            accounts: [
              {
                ...account,
                cards: [card],
                otherCards:
                  otherCardsResponse.status === 200
                    ? otherCardsResponse.data?.filter(async (program) =>
                       await isProgramPresent(program.programId)
                    )
                    : [{ ...card, failure: true }]
              }
            ]
          },
          customerBlockCodeStatus: getCustomerBlockCodeStatus({
            accountBlockCodes: account.blockCodes || null,
            cardBlockCodes: card.blockCodes || null
          }),
          programs: [{ id: programId }],
          journey: card.journey,
          deliveredDate: card?.deliveredDate,
          showCardTracking: dayjs(
            !!card?.deliveredDate ? card?.deliveredDate : undefined
          )
            .add(Number(card?.activationDays) + 1, "day")
            .isAfter(dayjs()),
          showWelcomeOffers: dayjs(
            !!card?.cardCreatedOn ? card?.cardCreatedOn : undefined
          )
            .add(Number(card?.welcomeDays) + 1, "day")
            .isAfter(dayjs()),
          cardActivationDays: Number(card?.activationDays),
          cardWelcomeDays: Number(card?.welcomeDays)
        })
      );

      if (isCardTrackingEligible && card.journey === Journey.NEW_ACTIVATION) {
        const cardTrackingPromise = CardsApi.getcardTracking(card.id);
        const cardTrackingResponse = await cardTrackingPromise;
        if (cardTrackingResponse.status !== 200) {
          logger.error({
            correlationId: cardTrackingResponse.headers[CORRELATION_ID_HEADER],
            cardId,
            responseData: cardTrackingResponse.data,
            message: `An error occurred while getting tracking details`
          });
        }
        dispatch(
          setTrackingDetails({
            ...cardTrackingResponse.data?.data,
            status: cardTrackingResponse?.data?.status
          })
        );
      }

      // get auth config
      const authConfigResponse = await AppApi.getAuthConfig({
        mpinMetaData: {
          customerId
        },
        apiTags: Object.values(ApiIds)
      });

      if (authConfigResponse.status !== 200) {
        logger.error({
          correlationId: authConfigResponse.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: authConfigResponse.data,
          message: `An error occurred while getting auth config`
        });
        setIsLoading(false);

        return;
      }
      dispatch(setAuthConfig(authConfigResponse.data));

      // get preferences
      if (!programPreferences) {
        const programPreferencesResponse =
          await PreferencesApi.getProgramPreferences(null, {
            programId: programId,
            issuerId: getIssuerId()
          });

        if (programPreferencesResponse.status !== 200) {
          logger.error({
            correlationId:
              programPreferencesResponse.headers[CORRELATION_ID_HEADER],
            programId: programId,
            responseData: programPreferencesResponse.data,
            message: `An error occurred while getting program preferences`
          });
        }

        let programPreferenceObj: ProgramPreferences = {
          id: "",
          programId: "",
          txnPostingDelayForComputation: 0,
          computeAndReverse: false,
          webHookEndPoint: "",
          webhookSecretKey: "",
          tags: programPreferencesResponse?.data,
          createdAt: "",
          updatedAt: ""
        };

        dispatch(setProgramPreferences(programPreferenceObj));
      }

      if (!accountPreferences) {
        const accountPreferencesResponse =
          await PreferencesApi.getAccountPreferences({
            accountId: accountId,
            programId: programId
          });

        if (accountPreferencesResponse.status !== 200) {
          logger.error({
            correlationId:
              accountPreferencesResponse.headers[CORRELATION_ID_HEADER],
            accountId: account.id,
            responseData: accountPreferencesResponse.data,
            message: `An error occurred while getting account preferences`
          });
        }

        dispatch(setAccountPreferences(accountPreferencesResponse.data));
      }

      // get statements and billing cycles for last 210 days
      const statementsResponse = await AccountsApi.getStatements(
        account.id,
        card.id,
        null,
        {
          from: dayjs().subtract(210, "day").format(API_REQUEST_DATE_FORMAT),
          to: dayjs().format(API_REQUEST_DATE_FORMAT)
        }
      );

      if (statementsResponse.status !== 200) {
        logger.error({
          correlationId: statementsResponse.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: statementsResponse.data,
          message: `An error occurred while getting statements at app level`
        });
        setIsLoading(false);
        return;
      }

      if (statementsResponse.data?.statements) {
        dispatch(
          setMiscellaneousState({
            statementBillingCycle: statementsResponse.data?.cycles[1]
          })
        );
        dispatch(
          setMiscellaneousState({
            selectedBillingCycle: statementsResponse.data?.cycles[0]
          })
        );

        dispatch(
          setMiscellaneousState({
            statements: statementsResponse.data.statements
          })
        );
        dispatch(
          setMiscellaneousState({
            billingCycles: statementsResponse.data?.cycles
          })
        );
      }

      //check eligibility for outstanding to emi
      const getEligibility =
        await AccountsApi.getEligibilityForOutstandingToEmi(accountId, {
          emiType: EmiType.TOTAL_OUTSTANDING
        });

      if (getEligibility.status !== 200) {
        logger.error({
          correlationId: getEligibility.headers[CORRELATION_ID_HEADER],
          accountId: accountId,
          responseData: getEligibility.data,
          message: `An error occurred while getting eligibility for outstanding to emi`
        });
        setIsLoading(false);
        return;
      }

      dispatch(setEligibilityForOutstandingToEmi(getEligibility.data));
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting app level data`,
        error: error.message,
        accountId: accountId,
        stackTrace: error.stack
      });
      setIsLoading(false);
      return;
    }

    try {
      // get benefits
      const commonBenefitsRequestProps: GetBenefitsForAccountRequest = {
        accountId: accountId,
        programId: programId,
        status: BenefitStatus.ACTIVE,
        preferredTagsSort: true,
        sortBy: BenefitSortBy.ACTIVE_TO,
        order: BenefitSortOrder.ASC
      };

      // as of now parallel api calls are not supported for some apis in benefits engine, hence calling apis sequentially

      const cvpResponse = await BenefitsApi.getBenefitsForAccount({
        offerType: OfferType.PROGRAM_OFFERS,
        ...commonBenefitsRequestProps
      });

      const campaignResponse = await BenefitsApi.getBenefitsForAccount({
        offerType: OfferType.CAMPAIGN_OFFERS,
        ...commonBenefitsRequestProps
      });
      const welcomeResponse = await BenefitsApi.getBenefitsForAccount({
        offerType: OfferType.WELCOME_OFFERS,
        benefitType: BenefitType.WELCOME_OFFERS,
        ...commonBenefitsRequestProps
      });

      const merchantResponse = await BenefitsApi.getBenefitsForAccount({
        offerType: OfferType.MERCHANT_OFFERS,
        benefitType: BenefitType.MERCHANT_OFFERS,
        ...commonBenefitsRequestProps
      });

      // not showing error if any benefits call fails
      if (cvpResponse.status === 200) {
        dispatch(setCvpBenefits(cvpResponse.data));
      }
      if (welcomeResponse.status === 200) {
        dispatch(setWelcomeBenefits(welcomeResponse.data));
      }

      if (campaignResponse.status === 200) {
        dispatch(setCampaignBenefits(campaignResponse.data));
      }

      if (merchantResponse.status === 200) {
        dispatch(setMerchantBenefits(merchantResponse.data));
      }

      if (
        cvpResponse.status !== 200 &&
        welcomeResponse.status !== 200 &&
        campaignResponse.status !== 200 &&
        merchantResponse.status !== 200
      ) {
        dispatch(setAllOffersApiFailure(true));
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting benefits`,
        error: error.message,
        accountId: accountId,
        stackTrace: error.stack
      });
    }

    // set that user was logged in
    // setting here instead of login as user may reload the page in between an active session
    dispatch(setWasLoggedIn());

    setIsLoading(false);
  }, [dispatch, token, accountId, cardId]);

  useEffect(() => {
    const getLoca = async () => {
      const location = await getGeoLocation();
      setIsIPLoading(false);
      setCountry(location);
    };
    getLoca();
  }, []);

  useEffect(() => {
    const [browser, version] = [
      String(parser.getBrowser().name),
      String(parser.getBrowser().version?.split(".")?.[0])
    ];
    if (!browser || !version) {
      return;
    }
    if (
      os === "Android" &&
      browser === "Chrome" &&
      !(!!(window as any)?.chrome && "onbeforeinstallprompt" in window)
    ) {
      navigate(BROWSER_NOT_SUPPORTED_SCREEN, {
        state: supportedBrowsers[os]
      });
      return;
    }
    if (!supportedBrowsers[os]?.includes(browser)) {
      navigate(BROWSER_NOT_SUPPORTED_SCREEN, {
        state: supportedBrowsers[os]
      });
    } else if (Number(version) < supportedBrowserVersions[os][browser]) {
      navigate(BROWSER_OLDER_VERSION, { state: browser });
    }

    detectIncognito().then((result) => {
      if (result?.isPrivate) {
        navigate(INCOGNITO_DETECTED);
      }
    });
  }, [browserCheck]);

  useEffect(() => {
    getData();
  }, [getData, accountId, cardId]);

  if (isIPLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <Loader text1="loading" text2="your IndusInd CardsHub" noTextStyle />
      </div>
    );
  } else {
    return (os === "Android" || os === "iOS") && country === "INDIA" ? (
      <div
        style={{
          backgroundColor: "#FFFFFF"
        }}
      >
        <div
          style={{
            backgroundColor: "#141414",
            width: "100%",
            minHeight: `100dvh`,
            maxWidth: `${PWA_MAX_WIDTH_IN_PX}px`,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden"
          }}
        >
          {!prefetchLoadSelector &&
          (isLoading || (cardId && !selectedCardInstore)) ? (
            // if is loading or card to which user switched is still being fetched
            <div style={{ height: "100vh" }}>
              <Loader
                text1="loading"
                text2="your IndusInd CardsHub"
                noTextStyle
              />
            </div>
          ) : (
            <Routes />
          )}
          <ToastContainer />
          {!!token && !!wasLoggedIn && <AuthScreens />}
        </div>
      </div>
    ) : (
      <>{country !== "INDIA" ? <IpBlockedScreen /> : <DesktopScreen />}</>
    );
  }
};

export default Sentry.withProfiler(App);
