import styles from "./SessionExpired.module.scss";
import cactus from "../../assets/svgs/cactus.svg";
import { Button2 } from "../../components";
import { useNavigate } from "react-router-dom";
import { PRE_LOGIN_ROUTE } from "../../routes/ScreenRoutes";
import { toast as toastLib } from "react-toastify";

const SessionExpired = () => {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate(PRE_LOGIN_ROUTE);
  };
  toastLib.dismiss();
  return (
    <div className={styles.container}>
      <img src={cactus} alt="cactus" className={styles.image} />
      <div className={styles.text1Container}>
        <span className={styles.text1}>session </span>
        <span className={styles.text2}>expired</span>
      </div>
      <div className={styles.text3}>
        To start a new session, login to IndusInd again
      </div>
      <Button2
        text1="login"
        customClassName={styles.btn}
        onClick={handleLoginClick}
      />
    </div>
  );
};

export default SessionExpired;
