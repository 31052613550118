export interface GetTransactionsRequest {
  count: number;
  offset: number;
  from: string;
  to: string;
  customerIdList: string[];
  checkEmiEligibility: boolean;
}

export enum EmiStatus {
  NOT_CREATED = "NOT_CREATED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  FORECLOSED = "FORECLOSED",
  CLOSE = "CLOSED"
}

export interface Transaction {
  id: string;
  amount: number;
  description: string;
  transactionAmount: number;
  transactionCurrency: string;
  txnType: string;
  postedToLedger: boolean;
  merchantCategoryCode: string;
  mid: string;
  tid: string;
  identifiedMerchantLogo: null | string;
  transactionDate: string;
  postingDate: null | string;
  txnNature: string;
  txnSource: string;
  emiAllowed: boolean;
  emiStatus: EmiStatus;
  emiRefID: null | string;
  cardLastFour: null | string;
  txnReferenceNumber: string;
}

export interface GetTransactionsResponse {
  count: number;
  hasMore: boolean;
  offset: number;
  totalCount: number;
  transactions: Transaction[];
}

export interface GetUnbilledTransactionsRequest {
  uniqueURL: string;
  cardLastFourDigits: string;
  isPrimaryCard: boolean;
  customerId: string;
  checkEmiEligibility: boolean;
}

export type GetUnbilledTransactionsResponse = GetTransactionsResponse;

export interface GetStatementsRequest {
  from: string;
  to: string;
}

export type BalanceGroupType =
  | "TAX"
  | "PROGRAM_FEES"
  | "TRANSACTION_FEES"
  | "RETAIL"
  | "CASH"
  | "EMI"
  | "OTHERS";

export interface BalanceGroup {
  type: BalanceGroupType;
  balance: number;
}

export interface Statement {
  id: string;
  billingCycle: number;
  fromDate: string;
  toDate: string;
  openingBalance: {
    totalBalance: number;
    balanceGroups: BalanceGroup[];
  };
  payments: number;
  refundsAndCredits: number;
  purchasesAndDebits: number;
  fees: number;
  financeCharges: number;
  taxes: number;
  cashback: number;
  emi: number;
  closingBalance: {
    totalBalance: number;
    balanceGroups: BalanceGroup[];
  };
  totalAmountDue: number;
  minimumAmountDue: number;
  initialMAD: number;
  initialTAD: number;
  dueDate: string;
  graceDate: string;
  lateFeeIncurred: number;
  taxOnLateFeeIncurred: number;
  lateFeeDue: number;
  taxOnLateFeeDue: number;
  balanceAmountDue: number;
  balanceAmountDueGrouped: {
    totalBalance: number;
    balanceGroups: BalanceGroup[];
  };
  totalDebits: number;
}

export interface GetStatementsResponse {
  count: number;
  statements: Statement[];
  cycles: {
    from: string;
    to: string;
  }[];
}

export interface GetEmiConfigRequest {
  accountId: string;
  amount: number;
  txnRefId?: string;
  emiType?: string; //TOTAL_OUTSTANDING
}

export interface EmiSchedule {
  installmentNumber: number;
  paymentDueDate: string;
  principal: number;
  interest: number;
  remainingBalance: number;
}

export interface EmiPlan {
  emi: number;
  tenureInMonths: number;
  interestRateBps: number;
  processingFees: number;
  foreclosureFees: number;
  schedules: EmiSchedule[];
  emiConfigId?: string;
  emiStrategyId?: string;
}

export interface GetEmiConfigResponse {
  emiPlans: EmiPlan[];
}

export interface ConvertToEmiRequest {
  tenureInMonths: number;
  txnRefId?: string;
  amount?: number;
  accountId: string;
  emiStrategyId?: string;
  emiPostingDate?: string;
  scope?: string; //CURRENT_STATEMENT
}

export interface ConvertToEmiResponse {
  emiRefId: string;
  emi: number;
  schedules: EmiSchedule;
  extRefId?: string;
}

export interface GetSpendGroupTransactionsRequest {
  startDate: string;
  endDate: string;
  spendGroupIds: string[];
  dateType: "POSTING_DATE";
  checkEmiEligibility: boolean;
}

export interface GetSpendGroupsRequest {
  period: {
    type: string;
    startDate: string;
    endDate: string;
    dateType: "POSTING_DATE";
  };
}

export interface CheckEmiEligibility {
  emiType: string;
}

export interface UserActivityForOutstandingEmi {
  emiRefId?: string;
  createdOn?: string;
  requestType: string; //ADD_USER_ACTIVITY, UPDATE_USER_ACTIVITY
}

export enum OutstandingToEmiStatus {
  PENDING = "PENDING",
  SUCCESS = "ACTIVE",
  FAILED = "FAILED"
}

export enum UserActivityRequestType {
  ADD_USER_ACTIVITY = "ADD_USER_ACTIVITY",
  UPDATE_USER_ACTIVITY = "UPDATE_USER_ACTIVITY"
}

export enum EmiType {
  TOTAL_OUTSTANDING = "TOTAL_OUTSTANDING"
}