/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useNavigate } from "react-router-dom";
import { CVP_OFFER_DETAILS_ROUTE } from "../../routes/ScreenRoutes";
import { CvpCtaButton } from "../button/Button";
import styles from "./styles.module.scss";
import {
  handleOfferRedirectionLinkClick,
  captureEvent
} from "../../utils/functions";
import { BenefitType } from "../../apis/benefitsApi/benefitsApiTypes";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

interface CvpOffersProps {
  id: string;
  background: string;
  title: string;
  logo?: string;
  illustrationUrl?: string;
  expanded?: boolean;
  ctaLink?: string;
  description?: string;
  progress?: number;
  benefitType?: string;
  hasDetails: boolean;
}

const Index = (props: CvpOffersProps) => {
  const {
    id,
    background,
    title,
    logo,
    illustrationUrl,
    expanded = false,
    ctaLink,
    description,
    progress,
    benefitType,
    hasDetails
  } = props;

  const navigate = useNavigate();
  const handleCVPOfferCtaClick = () => {
    captureEvent({
      eventName: EVENT_NAME.CVP_OFFER_CLICK,
      eventData: { cvpOfferId: id }
    });
    if (hasDetails) {
      navigate(CVP_OFFER_DETAILS_ROUTE.replace(":benefitId", id.toString()));
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        width: expanded ? "345px" : "170px",
        height: expanded ? "138px" : "195px"
      }}
      onClick={() => handleCVPOfferCtaClick()}
    >
      <div className={styles.containerFill} style={{ background }}>
        {!expanded && (
          <img
            src="/images/patterns/ripple.svg"
            alt="ripplePattern"
            className={styles.pattern}
          />
        )}
        {logo && (
          <img
            src={logo}
            alt="logo"
            className={expanded ? styles.logoExpanded : styles.logo}
          />
        )}
        {title && (
          <div
            className={expanded ? styles.titleExpanded : styles.title}
            style={{ top: expanded ? (logo ? "40%" : "18%") : "9%" }}
          >
            {title}
          </div>
        )}
        {expanded && benefitType === BenefitType.MILESTONE && (
          <div className={styles.progressBar}>
            <div className={styles.milestoneText}>
              {progress! >= 100 ? "All done!" : description}
            </div>
            <span
              style={{ width: `${progress}%` }}
              className={styles.milestoneCompleted}
            ></span>
            <span
              style={{
                borderTopLeftRadius: progress === 0 ? "6px" : "0px",
                borderBottomLeftRadius: progress === 0 ? "6px" : "0px",
                width: `${100 - progress!}%`
              }}
              className={styles.milestoneRemaining}
            ></span>
          </div>
        )}
        {illustrationUrl && (
          <img
            src={illustrationUrl}
            alt="illustration-image"
            className={
              expanded
                ? styles.illustrationImageExpanded
                : styles.illustrationImage
            }
          />
        )}
        {ctaLink && (
          <CvpCtaButton
            className={expanded ? styles.ctaButtonExpanded : styles.ctaButton}
            expanded={expanded}
            onClick={(e) => {
              captureEvent({
                eventName: EVENT_NAME.CVP_OFFER_CLICK,
                eventData: { redirectionLink: ctaLink }
              });
              handleOfferRedirectionLinkClick(e, ctaLink);
            }}
          />
        )}
        {/* {!expanded && id && (
          <img src={arrowCircle1} alt="Arrow" className={styles.arrow} />
        )} */}
      </div>
    </div>
  );
};

export default Index;
