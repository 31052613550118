import { useNavigate } from "react-router-dom";
import { MERCHANT_OFFER_DETAILS_ROUTE } from "../../routes/ScreenRoutes";
import styles from "./singleOffer.module.scss";

interface SingleOfferProps {
  id: string;
  bgImage: string;
  logo: string;
  title: string;
  description: string;
  validity: string;
  hasDetails: boolean;
}

const SingleOffer = (props: SingleOfferProps) => {
  const { id, bgImage, logo, title, description, validity, hasDetails } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (hasDetails) {
      navigate(
        MERCHANT_OFFER_DETAILS_ROUTE.replace(":benefitId", id.toString())
      );
    }
  };

  return (
    <div className={styles.SMerContainer} onClick={handleClick}>
      <div className={styles.SMerImageContainer}>
        <img src={bgImage} alt="offer" className={styles.SMerImage} />
      </div>

      <div className={styles.SMerTextContainer}>
        <div className={styles.SMerLogoContainer}>
          <img src={logo} alt="logo" className={styles.SMerLogo} />
        </div>
        <span className={styles.SMerTitle}>{title}</span>
        <span className={styles.SMerDescription}>{description}</span>
        <div className={styles.validityContainer}>
          <img src="/images/HourglassGold.svg" alt="time" />
          <span className={styles.SMerValidityText}>Valid till {validity}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleOffer;
