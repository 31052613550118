/* eslint-disable @typescript-eslint/no-unused-vars*/
import styles from "./completedCampaignOfferStyles.module.scss";
import { CampaignBenefitVariant, Festivals } from "../../../../utils/enums";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_OFFER_DETAILS_ROUTE } from "../../../../routes/ScreenRoutes";
import { wave2 } from "../../../../assets/svgs";
import CircleProgressbar from "./circleProgressBar";
import { isFestiveOffersUIEnabled } from "../../../../utils/internalFlags";
import { BenefitType } from "../../../../apis/benefitsApi/benefitsApiTypes";
import { pluralize } from "../../../../utils/BenefitUtil";
import { captureEvent } from "../../../../utils/functions";
import { EVENT_NAME } from "../../../../apis/appApi/appApiTypes";

interface CompletedCampaignOfferProps {
  id: string;
  background: string;
  title1: string;
  title2: string;
  description: string;
  variant?: CampaignBenefitVariant;
  ctaTextColor?: string;
  ctaLink?: string;
  hasDetails?: boolean;
  logoUrl: string;
  benefitType: string;
  daysLeft: number;
}

const CompletedCampaignOffer = ({
  id,
  background,
  title1,
  title2,
  description,
  variant = CampaignBenefitVariant.HOME_PAGE,
  ctaTextColor = "",
  ctaLink,
  hasDetails = false,
  logoUrl,
  benefitType,
  daysLeft
}: CompletedCampaignOfferProps): React.ReactElement => {
  const navigate = useNavigate();

  const handleCampaignOfferCtaClick = () => {
    if (hasDetails) {
      // completed milestones don't have details
      navigate(
        CAMPAIGN_OFFER_DETAILS_ROUTE.replace(":benefitId", id.toString())
      );
    }
    captureEvent({
      eventName: EVENT_NAME.CAMPAIGN_OFFER_CLICK,
      eventData: { campaignOfferId: id }
    });
  };

  const FestiveImages = Festivals?.CURRENT_FESTIVAL?.images?.campaignOffers;
  const FestiveColors = Festivals?.CURRENT_FESTIVAL?.colors?.campaignOffers;

  return (
    <div
      className={styles.offerContainer}
      onClick={() => handleCampaignOfferCtaClick()}
    >
      <div
        className={`${styles.offerIllustrationOutline} ${
          isFestiveOffersUIEnabled ? styles.festiveOfferIllustrationOutline : ""
        }`}
      ></div>
      <div
        className={styles.offerIllustrationBackground}
        // style={{
        //   background
        // }}
      >
        <img
          className={styles.backgroundPattern}
          src={
            isFestiveOffersUIEnabled
              ? FestiveImages?.cardBackground?.url
              : wave2
          }
          alt="wave"
        />

        {daysLeft > 0 && benefitType !== BenefitType.MILESTONE && (
          <div className={styles.daysLeft}>
            {pluralize(daysLeft, "day")} left
          </div>
        )}
        <div className={styles.contentContainer}>
          {isFestiveOffersUIEnabled && (
            <img
              src={FestiveImages?.cardProgressBackground?.url}
              alt="bgPattern"
              className={styles.festiveCampaignCardBgPattern}
            />
          )}
          {benefitType === BenefitType.MILESTONE ? (
            <CircleProgressbar
              percentage={100}
              width={93}
              primaryColor={
                isFestiveOffersUIEnabled
                  ? [
                      FestiveColors?.progressColor1,
                      FestiveColors?.progressColor2
                    ]
                  : ["white", "white"]
              }
            >
              <div className={styles.offerImg}>
                <img src={logoUrl} alt="logo" />
              </div>
            </CircleProgressbar>
          ) : (
            <>
              <div className={styles.transactionalOfferImg}>
                {" "}
                <img src={logoUrl} alt="logo" />
              </div>
              {!isFestiveOffersUIEnabled && (
                <div className={styles.concentricFirst}>
                  <div className={styles.concentricSecond}>
                    <div className={styles.concentricThird}></div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className={styles.textContainer}>
            <div
              className={`${styles.description} ${
                benefitType !== BenefitType.MILESTONE
                  ? styles.nonMilestoneDescription
                  : ""
              }`}
              style={{
                color: isFestiveOffersUIEnabled
                  ? FestiveColors?.cardChipTextColor
                  : "#fff",
                background: isFestiveOffersUIEnabled
                  ? FestiveColors?.cardChipBackground
                  : "#0c7e28",
                marginTop: isFestiveOffersUIEnabled ? "5px" : ""
              }}
            >
              {benefitType === BenefitType.MILESTONE
                ? "Completed"
                : description}
            </div>
            <div className={styles.title1}>{title1}</div>
            <div className={styles.title2}>{title2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedCampaignOffer;
