import { useEffect } from "react";
import Picker from "react-mobile-picker";
import styles from "./TimeSelector.module.scss";

function TimeSelector(props) {
  const { monthGroups, interestRates, setValueGroups, valueGroups } = props;

  const optionGroups = {
    months: monthGroups
  };

  useEffect(() => {
    if (monthGroups?.length > 0) {
      const initialMonth = monthGroups[0];
      setValueGroups((prevValueGroups) => ({
        months: initialMonth
      }));
    }
  }, []);

  const handleChange = (name, value) => {
    setValueGroups((prevValueGroups) => ({
      months: name.months
    }));
  };

  return (
    <div className={styles.pickerContainer}>
      <Picker
        value={valueGroups}
        onChange={handleChange}
        wheel="normal"
        height={210}
        itemHeight={70}
        style={{ zIndex: "11" }}
      >
        <Picker.Column name="months">
          {optionGroups?.months?.map((months, index) => (
            <Picker.Item
              key={index}
              value={months}
              className={styles.pickerDivSelected}
            >
              {({ selected }) => (
                <div
                  className={
                    selected
                      ? styles.pickerDivSelected
                      : styles.pickerDivUnselected
                  }
                >
                  <div
                    className={selected ? styles.selected : styles.unselected}
                  >
                    {months}
                  </div>
                  {interestRates?.length > 0 && (
                    <div
                      className={
                        selected
                          ? styles.selectedInterest
                          : styles.unselectedInterest
                      }
                    >
                      @{interestRates[index]}% p.a
                    </div>
                  )}
                </div>
              )}
            </Picker.Item>
          ))}
        </Picker.Column>
      </Picker>
      <div className={styles.backgroundGradient}></div>
      <div className={styles.lineHiderTop}></div>
      <div className={styles.lineHiderBottom}></div>
    </div>
  );
}

export default TimeSelector;
