/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button2 } from "../button/Button";
import styles from "./styles.module.scss";
import interLockedCircle from "./InterlockedCircle.svg";
import { getSelectedCard } from "../../features/user/userSlice";
import { getConfig } from "../../data/config";
import { isFestiveOffersUIEnabled } from "../../utils/internalFlags";
import { Festivals } from "../../utils/enums";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { captureEvent } from "../../utils/functions";
import { useEffect, useState } from "react";

interface cardObject {
  cardType: string;
  gradient: string;
  image: {
    [key: string]: any;
  };
}

interface HiddenCardProps {
  card: cardObject;
}

const Index = (props: HiddenCardProps) => {
  const navigate = useNavigate();

  const card = useSelector(getSelectedCard)!;
  const cardScheme = card?.scheme || "Visa";

  const cardNumber = card?.maskedCardNumber;
  const gradient = props?.card?.gradient;


  const [config, setConfig] = useState<any>();
  useEffect(() => {
    getConfig().then(res => setConfig(res));
  }, [card]);

  const cardLogo = "";

  const navigateToPath = () => {
    captureEvent({ eventName: EVENT_NAME.CARD_PAGE_CLICK });
    navigate("/cardSummary");
  };

  // flags for different options of blurred card rendering
  const isImage = true;
  const isGradient = false;
  const isText = false;

  //Festive offers divider
  const divider = Festivals?.CURRENT_FESTIVAL?.images?.cardAndNudgeDivider;

  return (
    <div className={styles.hiddenCardContainer}>
      {isImage && (
        <div className={styles.imgContainer}>
          <img
            src={config?.blurredImage?.[cardScheme]}
            alt="Hidden card"
            className={styles.hiddenCard}
          />
          <div className={styles["div-4"]}>
            <Button2
              onClick={navigateToPath}
              text1="your"
              text2="card"
              customClassName={styles.btn}
            />
            <div className={styles.cardNumberText}>
              ending **** {cardNumber?.slice(-4)}
            </div>
          </div>
        </div>
      )}
      {isGradient && (
        <div className={styles["div-1"]}>
          <div className={styles["div-2"]}>
            <div
              className={styles["div-3"]}
              style={{
                background: gradient
              }}
            ></div>
          </div>
          <div className={styles["div-4"]}>
            <Button2
              onClick={navigateToPath}
              text1="your"
              text2="card"
              customClassName={styles.btn}
            />
            <div className={styles.cardNumberText}>
              ending **** {cardNumber.slice(-4)}
            </div>
          </div>
        </div>
      )}
      {isText && (
        <div className={styles["div-1"]}>
          <div className={styles["div-2"]}>
            <div
              className={styles["div-3"]}
              style={{
                background: gradient
              }}
            >
              <div className={styles.cardBankInfo}>
                <div>Aura Edge</div>
                <div>Hyperface Bank</div>
              </div>
              <div className={styles.cardNumber}>
                <div>6219</div>
                <div>8610</div>
                <div>****</div>
                <div>****</div>
              </div>
              <div className={styles.cardHolderInfo}>
                <div>
                  <div>Card Holder</div>
                  <div>Daniel Austin</div>
                </div>
                <div>
                  <div>Expiry</div>
                  <div>02/30</div>
                </div>
                <img
                  src={cardLogo}
                  alt="cardLogo"
                  className={styles.cardLogo}
                />
              </div>
            </div>
          </div>
          <div className={styles["div-4"]}>
            <Button2
              onClick={navigateToPath}
              text1="your"
              text2="card"
              customClassName={styles.btn}
            />
            <div className={styles.cardNumberText}>
              ending **** {cardNumber.slice(-4)}
            </div>
          </div>
        </div>
      )}
      {isFestiveOffersUIEnabled ? (
        <img
          src={divider?.url}
          alt={divider?.alt}
          className={styles.circleLogoFestive}
        />
      ) : (
        <img
          src={interLockedCircle}
          alt={"Interlocked Circles"}
          className={styles.circleLogo}
        />
      )}
    </div>
  );
};

export default Index;
