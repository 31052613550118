import CryptoJs from "crypto-js";
import { PCI_ENCRYPTION_IV_LENGTH } from "./constants";

export const aesEncryptData = (
  encryptionKey: string,
  encryptionIv: string,
  data: any,
  isStringify:boolean = true 
) => {
  const key = CryptoJs.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJs.enc.Utf8.parse(encryptionIv);

  return CryptoJs.AES.encrypt(isStringify ? JSON.stringify(data) : data , key, {
    iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7
  }).toString();
};

export const aesDecryptData = (
  encryptionKey: string,
  encryptionIv: string,
  data: any
) => {
  const key = CryptoJs.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJs.enc.Utf8.parse(encryptionIv);
  const encoder = CryptoJs.enc.Utf8;

  let decryptedDataString = null;

  try {
    decryptedDataString = CryptoJs.AES.decrypt(data, key, {
      iv,
      mode: CryptoJs.mode.CBC,
      padding: CryptoJs.pad.Pkcs7
    }).toString(encoder);
  } catch (error) {
    throw new Error(`Failed to decrypt data. ${error}`);
  }

  if (!decryptedDataString) {
    throw new Error(`Failed to decrypt data.`);
  }

  try {
    return JSON.parse(decryptedDataString);
  } catch (error) {
    throw new Error(`Failed to parse decrypted data string as JSON. ${error}`);
  }
};

export const aesDecryptDeviceData = (
  encryptionKey: string,
  encryptionIv: string,
  data: any
) => {
  const key = CryptoJs.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJs.enc.Utf8.parse(encryptionIv);
  const encoder = CryptoJs.enc.Utf8;

  let decryptedDataString = null;

  try {
    decryptedDataString = CryptoJs.AES.decrypt(data, key, {
      iv,
      mode: CryptoJs.mode.CBC,
      padding: CryptoJs.pad.Pkcs7
    }).toString(encoder);
  } catch (error) {
    console.error(`Failed to decrypt data. ${error}`);
  }

  if (!decryptedDataString) {
    console.error(`Failed to decrypt data.`);
  }

  try {
    return JSON.parse(decryptedDataString!);
  } catch (error) {
    console.error(`Failed to parse decrypted data string as JSON. ${error}`);
    return data;
  }
};

export const getPciEncryptionKeyAndIv = (
  sessionToken: string,

  // string containing pci encryption key encrypted using session token as key
  // and pci encryption iv as iv, appended with pci encryption iv
  encryptionString: string
): { pciEncryptionKey: string; pciEncryptionIv: string } => {
  const encryptedPciEncryptionKey = encryptionString.slice(
    0,
    -1 * PCI_ENCRYPTION_IV_LENGTH
  );
  const pciEncryptionIv = encryptionString.slice(-1 * PCI_ENCRYPTION_IV_LENGTH);

  const pciEncryptionKey = aesDecryptData(
    sessionToken,
    pciEncryptionIv,
    encryptedPciEncryptionKey
  );

  return { pciEncryptionKey, pciEncryptionIv };
};
