/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import styles from "./manageCardStyles.module.scss";
import cardControl from "../../assets/svgs/cardControl.svg";
import statement from "../../assets/svgs/statement.svg";
import resetPin from "../../assets/svgs/resetPin.svg";
import customerCare from "../../assets/svgs/customer.svg";
import registeredDevices from "../../assets/svgs/Devices.svg"
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/svgs/arrow.svg";
import {
  CARD_SUMMARY_ROUTE,
  HOME_ROUTE,
  VIEW_STATEMENTS_ROUTE
} from "../../routes/ScreenRoutes";
import { Action } from "../../data/blockCodes/BlockCodeTypes";
import { isBlockCodeActionEnabled } from "../../data/blockCodes/BlockCodeUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { ToastType, toast } from "../../components";
import { RootState } from "../../app/store";

interface Item {
  id?: string;
  text1: string;
  text2: string;
  icon: string;
  disabled?: boolean;
  isPrimaryCardCheck: boolean;
}

const SwiperContainer = ({
  id,
  text1,
  text2,
  icon,
  disabled,
  isPrimaryCardCheck
}: Item) => {
  const navigate = useNavigate();
  const selectedCard = useSelector(getSelectedCard)!;
  const account = useSelector(getSelectedAccount)!;
  const billingCyclesSelector = useSelector(
    (state: RootState) => state.miscellaneous.billingCycles
  )!;
  const dispatch = useDispatch();

  useEffect(() => {
    const fun = async () => {
      try {
        const transactionsAuthResult = await authenticateApi({
          apiId: ApiIds.FETCH_TRANSACTIONS,
          actionText: "to get transactions"
        });
        if (transactionsAuthResult.status === AuthStatus.FAILURE) {
          logger.error({
            accountId: account?.id,
            error: transactionsAuthResult.error,
            message: `An error occurred while authenticating get transactions api`
          });
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
          navigate(HOME_ROUTE);
          // eslint-disable-next-line
          return;
        } else if (transactionsAuthResult.status === AuthStatus.CANCELLED) {
          navigate(HOME_ROUTE);
          // eslint-disable-next-line
          return;
        }
      } catch (error: any) {
        logger.error({
          message: `An exception occurred while getting account summary`,
          error: error.message,
          accountId: account?.id,
          stackTrace: error.stack
        });
        toast(
          ToastType.ERROR,
          "We are unable to display your Statement details. Please try again"
        );
        navigate(HOME_ROUTE);
      }
    };
    id === "viewStatement" && fun();
  }, []);

  const handleNavigatePath = () => {
    if (id === "viewStatement") {
      if (billingCyclesSelector.length === 1) {
        toast(
          ToastType.INFO,
          "Come back once your first statement is generated"
        );
        return;
      }
      dispatch(
        setMiscellaneousState({
          statementBillingCycle: billingCyclesSelector[1]
        })
      );
      dispatch(setMiscellaneousState({ selectedBillingCycle: null }));
      navigate(VIEW_STATEMENTS_ROUTE);
    } else {
      navigate(`/manageCard/${id}`);
    }
  };

  if (
    isPrimaryCardCheck &&
    (!selectedCard?.isPrimary || selectedCard?.isVariantUpgradeActive)
  ) {
    return null;
  }

  return (
    <div
      onClick={!disabled ? handleNavigatePath : undefined}
      className={styles.swiperContainer}
    >
      <div
        className={`${styles.swiperIconContainer} ${
          disabled && styles.disabled
        }`}
      >
        <img src={icon} alt="icon" className={styles.swiperIcon} />
      </div>
      <div
        className={`${styles.swiperTextContainer} ${
          disabled && styles.disabled
        }`}
      >
        <div className={styles.swiperText1}>{text1}</div>
        <div className={styles.swiperText2}>{text2}</div>
      </div>
      <div className={`${styles.btn} ${disabled && styles.disabled}`}>
        <img src={arrowIcon} alt="icon" className={styles.btnIcon} />
      </div>
      {/* <Button3 customClassName={styles.btn}/> */}
    </div>
  );
};

const ManageCard = () => {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    setItems([
      {
        id: "cardControls",
        text1: "Card Controls",
        text2: "Manage transaction limits and preferences",
        icon: cardControl,
        disabled: false,
        isPrimaryCardCheck: false
      },
      {
        id: "resetPin",
        text1: "Change Card PIN",
        text2: "Change your credit card PIN",
        icon: resetPin,
        disabled: !isBlockCodeActionEnabled(Action.RESET_CARD_PIN),
        isPrimaryCardCheck: false
      },
      {
        id: "viewStatement",
        text1: "View Statement",
        text2: "Summary of all transactions",
        icon: statement,
        isPrimaryCardCheck: true
      },
      {
        id: "customerCare",
        text1: "Customer Care",
        text2: "Contact IndusInd Bank Support",
        icon: customerCare,
        isPrimaryCardCheck: false
      },
      {
        id: "registeredDevices",
        text1: "Registered Devices",
        text2: "View devices with CardsHub logins",
        icon: registeredDevices,
        isPrimaryCardCheck: false
      }
    ]);
  }, []);

  return (
    <>
      <Header
        label="Manage Card"
        navigationPath={CARD_SUMMARY_ROUTE}
        bgColor="rgba(20, 20, 20, 0.8)"
        customClassName={styles.header}
      />
      <div className={styles.manageCard}>
        {items.map((item, index) => {
          return (
            <SwiperContainer
              id={item.id}
              text1={item.text1}
              text2={item.text2}
              icon={item.icon}
              disabled={item.disabled}
              isPrimaryCardCheck={item.isPrimaryCardCheck}
              key={index}
            />
          );
        })}
      </div>
    </>
  );
};
export default ManageCard;
