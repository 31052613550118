import type { AxiosResponse } from "axios";
import type {
  GetProgramPreferencesRequest,
  GetProgramPreferencesResponse,
  AddAccountTagsRequest,
  AddAccountTagsResponse,
  GetAccountPreferencesRequest,
  GetAccountPreferencesResponse
} from "./preferencesApiTypes";
import Api from "../Api";
import {
  GET_PROGRAM_PREFERENCES_ROUTE,
  ADD_ACCOUNT_TAGS_ROUTE,
  GET_ACCOUNT_PREFERENCES_ROUTE
} from "../ApiRoutes";
import { SESSION_TOKEN_HEADER } from "../../utils/constants";

const PreferencesApi = {
  getProgramPreferences: (
    sessionToken: string | null,
    data: GetProgramPreferencesRequest
  ): Promise<AxiosResponse<GetProgramPreferencesResponse>> =>
    Api.post(
      GET_PROGRAM_PREFERENCES_ROUTE,
      data,
      sessionToken
        ? {
            headers: {
              [SESSION_TOKEN_HEADER]: sessionToken
            }
          }
        : undefined
    ),

  addAccounTags: (
    sessionToken: string | null,
    data: AddAccountTagsRequest
  ): Promise<AxiosResponse<AddAccountTagsResponse>> =>
    Api.post(
      ADD_ACCOUNT_TAGS_ROUTE,
      data,
      sessionToken
        ? {
            headers: {
              [SESSION_TOKEN_HEADER]: sessionToken
            }
          }
        : undefined
    ),

  getAccountPreferences: (
    data: GetAccountPreferencesRequest
  ): Promise<AxiosResponse<GetAccountPreferencesResponse>> =>
    Api.post(GET_ACCOUNT_PREFERENCES_ROUTE, data)
};

export default PreferencesApi;
