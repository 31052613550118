/* eslint-disable  react/no-unstable-nested-components , @typescript-eslint/no-non-null-assertion*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./convertToEmiStyles.module.scss";
import { lockKey } from "../../assets/svgs";
import { Button2, ToastType, toast, Header } from "../../components";
import { captureEvent, formatAmount } from "../../utils/functions";
import {
  ConvertToEmiRequest,
  type EmiPlan,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes";
import {
  CARD_SUMMARY_ROUTE,
  CONVERT_TO_EMI_ROUTE
} from "../../routes/ScreenRoutes";
import { NavigateToConvertToEmiState } from "./convertToEmi";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { getSelectedAccount } from "../../features/user/userSlice";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import info from "../../assets/svgs/info.svg";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { EventType } from "../../utils/enums";

const EmiOverview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const account = useSelector(getSelectedAccount)!;

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isTncChecked, setIsTncChecked] = useState<boolean>(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false);

  const transaction: Transaction | null = location.state?.transaction;
  const emiPlan: EmiPlan | null = location.state?.emiPlan;
  // eslint-disable-next-line no-prototype-builtins
  const hasStrategyId = emiPlan?.hasOwnProperty("emiStrategyId");

  useEffect(() => {
    if (!location.state) {
      navigate(CARD_SUMMARY_ROUTE);
    }

    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.VIEW_TXNTOEMI_OVERVIEW_PAGE,
      eventData: {
        requestSource,
        eventType: EventType.PAGE_VIEW
      }
    });
  }, []);

  const handleBackButtonClick = () => {
    const navigateToConvertToEmiState: NavigateToConvertToEmiState = {
      transaction: transaction!
    };

    navigate(CONVERT_TO_EMI_ROUTE, {
      state: navigateToConvertToEmiState
    });
  };

  const handleTncCheck = () => {
    setIsTncChecked((isTncChecked) => !isTncChecked);
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    const requestSource = sessionStorage.getItem("requestSource") || "";
    captureEvent({
      eventName: EVENT_NAME.TXNTOEMI_OVERVIEW_PAGE_VERIFY_OTP_CLICK,
      eventData: {
        requestSource,
        eventType: EventType.BUTTON_CLICK
      }
    });
    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.CREATE_EMI,
        actionText: "to convert to EMI"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating create EMI api`
        });
        toast(ToastType.ERROR, authResult.message);
        setIsSubmitLoading(false);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        setIsSubmitLoading(false);
        return;
      }
      const emiRequestData: ConvertToEmiRequest = {
        tenureInMonths: emiPlan!.tenureInMonths,
        txnRefId: transaction!.id,
        accountId: account.id
      };
      if (hasStrategyId && emiPlan?.emiStrategyId) {
        emiRequestData.emiStrategyId = emiPlan.emiStrategyId;
      }

      const response = await AccountsApi.convertToEmi(
        account.id,
        authResult.apiToken,
        emiRequestData
      );

      if (response.status !== 200) {
        captureEvent({
          eventName: EVENT_NAME.TXNTOEMI_REQUEST_PLACED_FAILURE,
          eventData: {
            requestSource,
            eventType: EventType.TXNTOEMI_FAILURE
          }
        })
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while creating emi`
        });
        toast(
          ToastType.ERROR,
          "Your request for EMI conversion could not be processed. Please try again"
        );
        setIsSubmitLoading(false);
        return;
      }

      captureEvent({
        eventName: EVENT_NAME.TXNTOEMI_REQUEST_PLACED_SUCCESSFULLY,
        eventData: {
          requestSource,
          eventType: EventType.TXNTOEMI_SUCCESS
        }
      });

      toast(ToastType.SUCCESS, "Converted to EMI successfully");
      navigate(CARD_SUMMARY_ROUTE);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while creating emi`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "Your request for EMI conversion could not be processed. Please try again"
      );
    }

    setIsSubmitLoading(false);
  };

  const handleTnCClick = () => {
    window.open(
      "https://www.indusind.com/in/en/personal/indus-easy-emi-tnc.html",
      "_blank"
    );
  };

  const BottomSheetDetails = () => (
    <div className={styles.bottomSheetContainer}>
      <div className={styles.bottomSheetHeader}>information</div>
      <div className={styles.bottomSheetText}>
        If you wish to pre-close the EMI before the tenure, a Pre-Closure Fee
        will be applicable on the Principal Outstanding Amount
      </div>
      <Button2
        text1="got it"
        text1Style={{ fontWeight: "bold" }}
        customClassName={styles.gotItButton}
        onClick={handleBottomSheetButtonClick}
      />
    </div>
  );

  const handleInfoButtonClick = () => {
    setIsBottomSheetOpen(true);
  };

  const handleBottomSheetButtonClick = () => {
    setIsBottomSheetOpen(false);
  };

  return (
    <div>
      <Header
        label="EMI Overview"
        bgColor="#141414"
        onBackButtonClick={handleBackButtonClick}
      />
      <div className={styles.divider}></div>
      <div className={styles.container}></div>
      <div className={styles.detailsContainer}>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>Amount</span>
          <span className={styles.columnValue}>
            {formatAmount(transaction?.amount || 0)}
          </span>
        </div>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>Tenure</span>
          <span className={styles.columnValue}>
            {emiPlan?.tenureInMonths} Months
          </span>
        </div>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>Interest Rate Per Annum</span>
          <span className={styles.columnValue}>
            {((emiPlan?.interestRateBps || 0) / 100).toFixed(2)}%
          </span>
        </div>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>EMI Amount</span>
          <span className={styles.columnValue}>
            {formatAmount(emiPlan?.emi || 0)}
          </span>
        </div>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>Processing Fee</span>
          <span className={styles.columnValue}>
            {formatAmount(emiPlan?.processingFees || 0)}
          </span>
        </div>
        <div className={styles.columnContainer}>
          <span className={styles.columnText}>
            Pre-closure Fee{" "}
            <img
              src={info}
              alt="info"
              className={styles.iButton}
              onClick={handleInfoButtonClick}
            />{" "}
          </span>
          <span className={styles.columnValue}>
            {(emiPlan?.foreclosureFees || 0).toFixed(2)}%
          </span>
        </div>
        <div className={styles.divider}></div>

        <div className={styles.tncContainer}>
          <div className={styles.tncText}>
            I have read and agree to{" "}
            <span onClick={handleTnCClick}>Terms & Conditions</span> and give
            consent to convert this transaction to EMI
          </div>
          <input
            type="checkbox"
            name="tnc"
            id="tnc"
            className={styles.tncCheckbox}
            checked={isTncChecked}
            onChange={handleTncCheck}
          />
        </div>
        <span className={styles.lowerText}>
          Govt. taxes will be applicable on the Interest Component of the EMI,
          Processing Fee and Pre-Closure Fee as notified by the Govt. of India.
        </span>
      </div>
      <div className={styles.btnContainer1}>
        <Button2
          text1="verify"
          text2="OTP"
          isLoading={isSubmitLoading}
          customClassName={styles.button1}
          onClick={handleSubmit}
          disabled={!isTncChecked}
        />
        <div className={styles.btnBottomText}>
          <img src={lockKey} alt="lock" />
          <span>Information in sent over a secure connection</span>
        </div>
      </div>
      <BottomsheetComponent
        isBottomSheetOpen={isBottomSheetOpen}
        setIsBottomSheetOpen={setIsBottomSheetOpen}
        render={BottomSheetDetails()}
      />
    </div>
  );
};

export default EmiOverview;
