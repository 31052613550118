import { type ReactElement } from "react";
import styles from "./transactionDetailsStyles.module.scss";
import {
  capitalizeFirsLetter,
  fallbackCopyTextToClipboard,
  formatAmount,
  getTransactionDate1,
  textEllipsis
} from "../../utils/functions";
import { Button, ToastType, toast } from "../../components";
import { type Transaction } from "../../apis/accountsApi/accountsApiTypes";
import TransactionLogo from "../../components/transaction/TransactionLogo";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";

interface TransactionDetailsProps {
  handleCloseButton: () => void;
  transaction: Transaction;
}

const TransactionDetails = ({
  transaction,
  handleCloseButton
}: TransactionDetailsProps): ReactElement => {
  const handleCopy = (txnRefNo: string) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(txnRefNo);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Reference No. is copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(txnRefNo);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Reference No. is copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  return (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetHeader}>transaction details</div>
      <div className={styles.bottomSheetContainer}>
        {transaction.identifiedMerchantLogo ? (
          <img
            className={styles.transactionLogo}
            src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
            alt="logo"
          />
        ) : (
          <TransactionLogo txnType={transaction?.txnType} />
        )}
        <div className={styles.transactionHeader}>
          {capitalizeFirsLetter(transaction.description)}
        </div>
        <div className={styles.transactionContainer1}>
          {transaction?.cardLastFour ? (
            <div className={styles.transactionContent}>
              <div className={styles.transactionText1}>Card Number</div>
              <div className={styles.transactionText2}>
                xxxxxxxx{transaction.cardLastFour.slice(-4)}
              </div>
            </div>
          ) : null}
          <div className={styles.transactionContent}>
            <div className={styles.transactionText1}>Amount</div>
            <div
              className={styles.transactionText2}
              style={{ letterSpacing: "0.04em" }}
            >
              {formatAmount(transaction.amount)}
            </div>
          </div>
        </div>
        <div className={styles.transactionContainer1}>
          <div className={styles.transactionContent}>
            <div className={styles.transactionText1}>Status</div>
            <div className={styles.transactionText2}>
              {transaction.txnType === "AUTHORIZE" ? "Not settled" : "Settled"}
            </div>
          </div>
          <div className={styles.transactionContent}>
            <div className={styles.transactionText1}>Transaction Type</div>
            <div className={styles.transactionText2}>
              {capitalizeFirsLetter(transaction.txnNature)}
            </div>
          </div>
          <div className={styles.transactionContent}>
            <div className={styles.transactionText1}>Date and Time</div>
            <div className={styles.transactionText2}>
              {getTransactionDate1(
                transaction.transactionDate,
                transaction.postingDate || ""
              )}
            </div>
          </div>
          <div className={styles.transactionContent}>
            <div className={styles.transactionText1}>Reference No.</div>
            <div className={styles.transactionText2}>
              {textEllipsis(transaction?.txnReferenceNumber)}
              {!!transaction.txnReferenceNumber && (
                <img
                  onClick={() =>
                    debounceHandleCopy(transaction?.txnReferenceNumber)
                  }
                  src="/images/copy.svg"
                  alt="copy"
                />
              )}
            </div>
          </div>
        </div>
        <Button
          customClassName={styles.transactionCloseBtn}
          text1="close"
          text1Style={{ fontWeight: "bold" }}
          onClick={handleCloseButton}
        />
      </div>
    </div>
  );
};

export default TransactionDetails;
