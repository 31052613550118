import type { ReactElement } from "react";
import styles from "./buttonStyles.module.scss";
import {
  ButtonProps,
  Button2Props,
  ToggleButtonProps,
  Button3Props,
  CvpCtaButtonProps,
  AnalyseButtonProps
} from "./buttonTypes";
import button2Icon from "../../assets/svgs/button2Icon.svg";
import buttonIcon from "../../assets/svgs/buttonIcon.svg";
import cvpCtaButtonIcon from "../../assets/svgs/cvpCtaButtonIcon.svg";
import Graph from "../../assets/svgs/SpendAnalytics/Graph.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";

const Button = ({
  customClassName = "",
  text1Style,
  children,
  text1,
  text2,
  onClick,
  disabled,
  icon,
  isLoading = false,
  showArrow = true
}: ButtonProps): ReactElement => {
  return (
    <div
      className={`${styles.btn} ${customClassName} ${
        disabled ? styles.disabledBtn : ""
      } ${isLoading ? styles.loadingBtn1 : ""}
      `}
      // eslint-disable-next-line
      onClick={!disabled && !isLoading ? onClick : () => {}}
    >
      {text1 && (
        <span style={text1Style} className={styles.btnText1}>
          {text1}
        </span>
      )}
      {text2 && <span className={styles.btnText2}>{text2}</span>}
      {children && <div className={styles.btnContent}>{children}</div>}
      {showArrow ? (
        <img
          className={styles.btnIcon}
          src={!!icon ? icon : buttonIcon}
          alt="btnIcon"
        />
      ) : (
        ""
      )}
    </div>
  );
};

const Button2 = ({
  customClassName = "",
  children,
  text1Style,
  text1,
  text2,
  onClick,
  disabled,
  icon,
  isLoading = false
}: Button2Props): React.ReactElement => {
  return (
    <div
      className={`${styles.btn2} ${customClassName} ${
        disabled ? styles.disabledBtn : ""
      }
      ${isLoading ? styles.loadingBtn2 : ""}
      `}
      // eslint-disable-next-line
      onClick={!disabled && !isLoading ? onClick : () => {}}
    >
      {text1 && (
        <span style={text1Style} className={styles.btn2Text1}>
          {text1}
        </span>
      )}
      {text2 && <span className={styles.btn2Text2}>{text2}</span>}
      {children && <div className={styles.btn2Content}>{children}</div>}
      <img
        className={styles.btn2Icon}
        src={!!icon ? icon : button2Icon}
        alt="btn2Icon"
      />
    </div>
  );
};

const Button3 = ({
  childContainerClassName = "",
  children,
  label,
  onClick
}: Button3Props): React.ReactElement => {
  return (
    <div className={styles.btn3}>
      <div
        className={`${styles.btn3ChildContainer} ${childContainerClassName}`}
        onClick={onClick}
      >
        {children && <div className={styles.btn3Content}>{children}</div>}
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

const ToggleButton = ({
  customClassName = "",
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  children,
  text,
  icon,
  iconRight,
  isSelected,
  onClick
}: ToggleButtonProps): React.ReactElement => {
  return (
    <div
      className={`${isSelected ? styles.btn2 : styles.btn} ${customClassName}`}
      onClick={onClick}
    >
      {icon && (
        <img
          className={isSelected ? styles.btn2Icon : styles.btnIcon}
          src={icon}
          alt="btnIcon"
        />
      )}
      {text && (
        <span
          className={isSelected ? styles.toggleBtnText2 : styles.toggleBtnText1}
        >
          {text}
        </span>
      )}
      {iconRight && (
        <img className={styles.btnIcon} src={iconRight} alt="btnIconRight" />
      )}
    </div>
  );
};

const ToggleButton2 = ({
  customClassName = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  text,
  icon,
  isSelected,
  onClick
}: ToggleButtonProps): React.ReactElement => {
  return !isSelected ? (
    <div className={`${styles.btn} ${customClassName}`} onClick={onClick}>
      {text && <span className={styles.toggleBtnText1}>{text}</span>}
      <img className={styles.btnIcon} src={icon} alt="btnIcon" />
    </div>
  ) : (
    <div className={`${styles.btn2} ${customClassName}`} onClick={onClick}>
      {text && <span className={styles.toggleBtnText2}>{text}</span>}
      <img className={styles.btn2Icon} src={icon} alt="btn2Icon" />
    </div>
  );
};

const CvpCtaButton = ({
  className,
  expanded,
  onClick
}: CvpCtaButtonProps): React.ReactElement => {
  return (
    <div className={`${styles.cvpCtaBtn} ${className}`} onClick={onClick}>
      <span className={styles.cvpCtaBtnText1}>details</span>
      {expanded && <span></span>}
      <img
        className={styles.cvpCtaBtnIcon}
        src={cvpCtaButtonIcon}
        alt="cvp action"
      />
    </div>
  );
};

const AnalyseButton = ({
  className,
  billingCycle
}: AnalyseButtonProps): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setMiscellaneousState({ spendAnalyticsBillingCycle: null }));
    if (billingCycle) {
      navigate("/spendAnalytics", { state: { billingCycle } });
    } else {
      navigate("/spendAnalytics");
    }
  };
  return (
    <div className={`${styles.analyse} ${className}`} onClick={handleClick}>
      <div>
        <img src={Graph} alt="Graph" />
        <div>Analyse</div>
      </div>
    </div>
  );
};

export {
  Button,
  Button2,
  Button3,
  ToggleButton,
  ToggleButton2,
  CvpCtaButton,
  AnalyseButton
};
