import { type AxiosResponse } from "axios";
import Api from "../Api";
import {
  ADD_DEVICE,
  CHECK_DEVICE,
  REMOVE_DEVICE,
  LIST_DEVICES,
  CHECK_URL,
  SEND_ACCOUNT_NOTIFICATION_VIA_SMS
} from "../ApiRoutes";

const DeviceApi = {
  addDevice: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(ADD_DEVICE, data, {
      headers: {
        "User-Agent": navigator.userAgent
      }
    }),
  checkDevice: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(CHECK_DEVICE, data),
  RemoveDevice: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(REMOVE_DEVICE, data),
  listDevice: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(LIST_DEVICES, data),
  checkUrl: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(CHECK_URL, data),
  sendBifrostNotificationViaSms: (data: any): Promise<AxiosResponse<any>> =>
    Api.post(SEND_ACCOUNT_NOTIFICATION_VIA_SMS, data)
};

export default DeviceApi;
