import React from "react";
import styles from "./styles.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

interface CarouselProps {
  items: React.JSX.Element[];
}

export default function App(props: CarouselProps) {
  const { items } = props;

  return (
    <>
      {/* <Swiper
        slidesPerView={1}
        spaceBetween={1}
        initialSlide={1}
        centeredSlides={true}
        pagination={false}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 50
          }
        }}
        modules={[Pagination]}
        className={styles.mySwiper}
      > */}
      <Swiper
        //slidesPerView={3}
        spaceBetween={30}
        initialSlide={0}
        centeredSlides={false}
        pagination={false}
        modules={[Pagination]}
        className={styles.mySwiper}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          390: {
            slidesPerView: 2.2,
            spaceBetween: 30
          },
          450: {
            slidesPerView: 2.4,
            spaceBetween: 30
          },
          490: {
            slidesPerView: 2.8,
            spaceBetween: 30
          }
          // Removing Breakpoints beyond 0.6 as maximum width of application is 550px.
        }}
      >
        {items?.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              paddingLeft: index === 0 ? "10px" : ""
            }}
          >
            {item}
          </SwiperSlide>
        ))}
        <SwiperSlide
          style={{
            width: "50px",
            height: "210px",
            marginLeft: "2px"
          }}
        ></SwiperSlide>
      </Swiper>
    </>
  );
}
