import React from "react";
import styles from "./appInstallSuccess.module.scss";
import IndusInd_Logo from "../../assets/svgs/indusind-bank-logo.svg";
import pattern from "../../assets/svgs/patterns/circularPattern.svg";
import AppInstalledLogo from "../../assets/svgs/appInstallLogo.svg";

const AppInstallSuccess = () => {
  return (
    <>
      <>
        <div className={styles.preLoginContainer}>
          <div className={styles.header}>
            <div>
              <img src={IndusInd_Logo} alt="IndusInd Bank" />
            </div>
          </div>

          <div className={styles.cardContainer}>
            <img src={pattern} alt="App Install Image" />
          </div>

          <div className={styles.bannerBody}>
            <div className={styles.banner}>
              <img src={AppInstalledLogo} alt="" />
            </div>
            <div className={styles.bannerText}>
              <div className={styles.text1}>Shortcut added successfully 🎉</div>
              <hr className={styles.horizontalLine} />
              <div className={styles.text2}>
                You can access the IndusInd Bank CardsHub from the saved web app
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AppInstallSuccess;
