// Import Swiper React components
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";

interface CarouselProps {
  items: React.JSX.Element[];
}
const App = (props: CarouselProps) => {
  const { items } = props;

  return (
    <>
      <Swiper
        effect={"fade"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        initialSlide={0}
        loop={false}
        pagination={{
          clickable: true
        }}
        modules={[Autoplay, Pagination]}
        cssMode={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true
        }}
        // className="mySwiper swiper2"
      >
        {items?.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide3">
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default App;
