import { useNavigate } from "react-router-dom";
import styles from "./transactionStyles.module.scss";
import { Button } from "../button/Button";
import { CONVERT_TO_EMI_ROUTE } from "../../routes/ScreenRoutes";
import {
  EmiStatus,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes";
import {
  capitalizeFirsLetter,
  captureEvent,
  formatAmount,
  getTransactionDate
} from "../../utils/functions";
import { NavigateToConvertToEmiState } from "../../pages/convertToEMI/convertToEmi";
import TransactionLogo from "./TransactionLogo";
import { isBlockCodeActionEnabled } from "../../data/blockCodes/BlockCodeUtil";
import { Action } from "../../data/blockCodes/BlockCodeTypes";
import { useSelector } from "react-redux";
import { getSelectedCard } from "../../features/user/userSlice";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { EventType, RequestSources } from "../../utils/enums";

interface TransactionProps {
  transaction: Transaction;
  requestSource?: string;
}

const TransactionComponent = ({
  transaction,
  requestSource
}: TransactionProps): React.ReactElement => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const selectedCard = useSelector(getSelectedCard)!;
  const enableAction =
    selectedCard?.isPrimary && !selectedCard?.isVariantUpgradeActive;

  const handleConvertToEmiClick = () => {
    sessionStorage.setItem("requestSource", requestSource || "");
    const eventName =
      requestSource === RequestSources.BILLED_TRANSACTIONS_PAGE
        ? EVENT_NAME.TXNTOEMI_CONVERT_TO_EMI_BILLED_TRANSACTIONS_PAGE_CLICK
        : requestSource === RequestSources.UNBILLED_TRANSACTIONS_PAGE
        ? EVENT_NAME.TXNTOEMI_CONVERT_TO_EMI_UNBILLED_TRANSACTIONS_PAGE_CLICK
        : requestSource === RequestSources.CARD_SUMMARY_PAGE
        ? EVENT_NAME.TXNTOEMI_CONVERT_TO_EMI_CARD_SUMMARY_PAGE_CLICK
        : requestSource === RequestSources.SPEND_GROUP_TRANSACTIONS_PAGE
        ? EVENT_NAME.TXNTOEMI_CONVERT_TO_EMI_SPEND_GROUP_TRANSACTIONS_PAGE_CLICK
        : "";

    if (eventName) {
      captureEvent({
        eventName,
        eventData: { eventType: EventType.BUTTON_CLICK }
      });
    }

    const navigateToConvertToEmiState: NavigateToConvertToEmiState = {
      transaction: transaction
    };

    // this state persists at the tab level even after a reload
    navigate(CONVERT_TO_EMI_ROUTE, {
      state: navigateToConvertToEmiState
    });
  };

  return (
    <>
      <div className={styles.transaction}>
        <div className={styles.transactionLabelAndLogoWrapper}>
          {transaction.identifiedMerchantLogo ? (
            <img
              className={styles.transactionLogo}
              src={`data:image/jpg;base64,${transaction?.identifiedMerchantLogo}`}
              alt="logo"
            />
          ) : (
            <TransactionLogo txnType={transaction?.txnType} />
          )}
          <div className={styles.transactionLabelAndDateWrapper}>
            <div className={styles.transactionLabel}>
              {capitalizeFirsLetter(transaction.description)}
            </div>
            <div className={styles.transactionDate}>
              {getTransactionDate(
                transaction.transactionDate,
                transaction.postingDate || ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.amountContainer}>
          <div
            className={styles.transactionAmount}
            style={{
              color: transaction.txnNature === "CREDIT" ? "#80C791" : ""
            }}
          >
            {transaction.txnNature === "CREDIT" ? "+" : ""}
            {formatAmount(transaction.transactionAmount)}
          </div>
          {enableAction &&
            transaction.emiAllowed &&
            transaction.emiStatus === EmiStatus.NOT_CREATED &&
            isBlockCodeActionEnabled(Action.CONVERT_TRANSACTION_TO_EMI) && (
              <Button
                text1Style={{ fontSize: "10px" }}
                customClassName={styles.emiBtn}
                text1="convert to EMI"
                onClick={handleConvertToEmiClick}
              />
            )}
          {transaction.emiStatus === EmiStatus.ACTIVE && (
            <div className={styles.convertedEmiText}>Converted to EMI</div>
          )}
          {transaction.txnType === "AUTHORIZE" && (
            <div className={styles.pendingTransactionText}>Pending</div>
          )}
        </div>
      </div>
      <div className={styles.transactionBorder}></div>
    </>
  );
};

export { TransactionComponent as Transaction };
