/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Header from "../header/cardHeader";
import styles from "./cardSummaryStyles.module.scss";
import {
  AnalyseButton,
  BottomSheet2,
  Button,
  Button2,
  Button3,
  toast,
  ToastType,
  Transaction as TransactionComponent
} from "../../components";
import Loader from "../../components/loader/loader";
import { ripplePattern } from "../../assets/svgs/";
import arrowRightUp from "../../assets/svgs/arrow-right-up-line.svg";
import Lock from "../../assets/svgs/Lock.svg";
import {
  CORRELATION_ID_HEADER,
  OS_TO_EMI_SUCCESS_NUDGE_NUMBER_OF_IMPRESSIONS
} from "../../utils/constants";
import {
  capitalizeFirsLetter,
  fallbackCopyTextToClipboard,
  formatAmount,
  formatAmount4,
  formatAmount5,
  formatDate,
  formatNegativeAmount,
  getSortedTransactions,
  captureEvent,
  getUniqueURLFromCookie
} from "../../utils/functions";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import {
  getCardDetails,
  getCustomer,
  getOtherCards,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";
import ApiIds from "../../auth/ApiIds";
import { authenticateApi } from "../../auth/auth";
import {
  CARD_CONTROLS_ROUTE,
  CONVERT_OUTSTANDING_TO_EMI,
  HOME_ROUTE,
  MANAGE_CARD_ROUTE,
  VIEW_STATEMENTS_ROUTE,
  VIEW_TRANSACTIONS_ROUTE
} from "../../routes/ScreenRoutes";
import logger from "../../utils/logger";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import UnmaskedCard from "./UnmaskedCard";
import {
  EmiType,
  OutstandingToEmiStatus,
  UserActivityForOutstandingEmi,
  UserActivityRequestType,
  type Statement,
  type Transaction
} from "../../apis/accountsApi/accountsApiTypes";
import statementLogo from "../../assets/svgs/document-text.svg";
import manageCardLogo from "../../assets/svgs/setting.svg";
import cactus from "../../assets/svgs/cactus.svg";
import rocket from "../../assets/svgs/rocket.svg";
import cardWithHand from "../../assets/svgs/cardWithHand.svg";
import { RootState } from "../../app/store";
import BillDetails from "../../components/cardSummary/BillDetails";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import * as InternalFlags from "../../utils/internalFlags";
import TransactionDetails from "../viewTransactions/TransactionDetails";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import diskImage from "../../assets/svgs/convertOutstandingToEmi/disk.svg";
import clockSvg from "../../assets/svgs/convertOutstandingToEmi/clock.svg";
import lightBulb from "../../assets/svgs/convertOutstandingToEmi/LightBulb.svg";
import BottomsheetComponent from "../../components/bottomSheet/bottomsheet";
import emiConverted from "../../assets/svgs/convertOutstandingToEmi/emiConverted.svg";
import copySvg from "../../assets/svgs/convertOutstandingToEmi/CopySimple.svg";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";
import {
  setEligibilityForOutstandingToEmi,
  setEmiStatusForOutstandingToEmi
} from "../../features/outstandingToEmi/outstandingToEmiSlice";
import { EventType, RequestSources } from "../../utils/enums";
import diskReflection from "../../assets/svgs/convertOutstandingToEmi/diskReflection.svg";
import clockReflection from "../../assets/svgs/convertOutstandingToEmi/clockReflection.svg";
import reflectionDisk from "../../assets/svgs/convertOutstandingToEmi/reflectionDisk.svg";

const CardSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customer = useSelector(getCustomer)!;
  const account = useSelector(getSelectedAccount)!;
  const card = useSelector(getSelectedCard)!;
  const enableAction = card?.isPrimary && !card?.isVariantUpgradeActive;
  const cardDetails = useSelector(getCardDetails);
  const otherCardsData = useSelector(getOtherCards);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const programId = useSelector(
    (state: RootState) => state.user.selectedProgramId
  );
  const cardScheme = card?.scheme || "Visa";
  const statements = useSelector(
    (state: RootState) => state.miscellaneous.statements
  )!;

  const billingCyclesSelector = useSelector(
    (state: RootState) => state.miscellaneous.billingCycles
  )!;

  const eligibilityForOutstandingToEmi = useSelector(
    (state: RootState) => state.outstandingToEmi.eligibility
  )!;

  const statusForOutstandingToEmi = useSelector(
    (state: RootState) => state.outstandingToEmi.emiStatus
  )!;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isParentLoading, setIsParentLoading] = useState<boolean>(false);
  const [unbilledTransactions, setUnbilledTransactions] = useState<
    Transaction[]
  >([]);
  const [lastStatement, setLastStatement] = useState<Statement | null>(null);
  const [isUnmaskedCardOpen, setIsUnmaskedCardOpen] = useState<boolean>(false);
  const [isCardDetailsOpen, setIsCardDetailsOpen] = useState<boolean>(false);
  const [isCustomerEnablesTransaction, setIsCustomerEnabledTransaction] =
    useState<boolean>(false);
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] =
    useState<boolean>(false);
  const [transactionSelectedForDetails, setTransactionSelectedForDetails] =
    useState<Transaction | null>(null);
  const [isConversionToEmiInProcess, setIsConversionToEmiInProcess] =
    useState<boolean>(false);
  const [isCardViewDisabled, setIsCardViewDiabled] = useState<boolean>(false);

  const transactions = unbilledTransactions;

  let cardDisabledText1 = "";
  let cardDisabledText2 = "";

  if (card?.isLocked) {
    cardDisabledText1 = "Card Temporarily Blocked";
    cardDisabledText2 = "Unblock Card";
  }

  const setUserActivity = async (data: UserActivityForOutstandingEmi) => {
    try {
      const response = await AccountsApi.setUserActivityForOutstandingToEmi(
        account.id,
        data
      );
      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while setting user activity for os to emi`
        });
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while setting user activity for os to emi`,
        error: error?.message,
        accountId: account.id,
        stackTrace: error.stack
      });
    }
  };

  const getStatusForOutstandingToEmi = useCallback(async () => {
    try {
      const getStatus = await AccountsApi.getStatusForOutstandingToEmi(
        account.id
      );

      if (getStatus.status !== 200) {
        logger.error({
          correlationId: getStatus.headers[CORRELATION_ID_HEADER],
          accountId: account?.id,
          responseData: getStatus.data,
          message: `An error occurred while getting status for outstanding to emi`
        });
        return;
      }

      if (
        getStatus?.data.status === OutstandingToEmiStatus.SUCCESS &&
        getStatus?.data?.userActivity?.loginCount - 1 <=
          OS_TO_EMI_SUCCESS_NUDGE_NUMBER_OF_IMPRESSIONS
      ) {
        setUserActivity({
          emiRefId: getStatus?.data.emiRefId,
          requestType: UserActivityRequestType.UPDATE_USER_ACTIVITY
        });
      }
      dispatch(setEmiStatusForOutstandingToEmi(getStatus.data));
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting status for outstanding to emi`,
        error: error?.message,
        accountId: account?.id,
        stackTrace: error?.stack
      });
    }
  }, []);

  const getEligibilityForOutstandingToEmi = useCallback(async () => {
    try {
      const getEligibility =
        await AccountsApi.getEligibilityForOutstandingToEmi(account.id, {
          emiType: EmiType.TOTAL_OUTSTANDING
        });

      if (getEligibility.status !== 200) {
        logger.error({
          correlationId: getEligibility.headers[CORRELATION_ID_HEADER],
          accountId: account?.id,
          responseData: getEligibility.data,
          message: `An error occurred while getting eligibility for outstanding to emi`
        });
        return;
      }

      dispatch(setEligibilityForOutstandingToEmi(getEligibility.data));
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting eligibility for outstanding to emi`,
        error: error?.message,
        accountId: account?.id,
        stackTrace: error?.stack
      });
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      if (card?.isPrimary === undefined) {
        return;
      }
      const transactionsAuthResult = await authenticateApi({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        actionText: "to get transactions"
      });

      if (transactionsAuthResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account?.id,
          error: transactionsAuthResult.error,
          message: `An error occurred while authenticating get transactions api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(HOME_ROUTE);
        return;
      } else if (transactionsAuthResult.status === AuthStatus.CANCELLED) {
        navigate(HOME_ROUTE);
        return;
      }

      const statementAuthResult = await authenticateApi({
        apiId: ApiIds.FETCH_STATEMENTS,
        actionText: "to get statements"
      });

      if (statementAuthResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account?.id,
          error: statementAuthResult.error,
          message: `An error occurred while authenticating get statements api`
        });
        toast(
          ToastType.ERROR,
          "The OTP generation has failed. Please try again"
        );
        navigate(HOME_ROUTE);
        return;
      } else if (statementAuthResult.status === AuthStatus.CANCELLED) {
        navigate(HOME_ROUTE);
        return;
      }

      const cardData = otherCardsData?.find((card1) => card1.id === card.id);
      const unbilledCardLastFour = !cardData?.isVariantUpgradeActive
        ? card.maskedCardNumber.slice(-4)
        : otherCardsData
            ?.find(
              (card1) =>
                card1.accountId === account.id &&
                !card1?.isVariantUpgradeActive &&
                card1.isPrimary === card.isPrimary
            )
            ?.maskedCardNumber.slice(-4);

      const getUnbilledTransactionsPromise =
        AccountsApi.getUnbilledTransactions(
          account?.id,
          transactionsAuthResult.apiToken,
          {
            uniqueURL: getUniqueURLFromCookie() || "",
            cardLastFourDigits: unbilledCardLastFour!,
            isPrimaryCard: card?.isPrimary,
            customerId: customer.id,
            checkEmiEligibility: true
          }
        );

      // const getStatementsPromise = AccountsApi.getStatements(
      //   account?.id,
      //   transactionsAuthResult.apiToken,
      //   {
      //     from: dayjs().format(API_REQUEST_DATE_FORMAT),
      //     to: dayjs()
      //       .add(1, "month")
      //       .subtract(1, "day")
      //       .format(API_REQUEST_DATE_FORMAT)
      //   }
      // );

      const getUnbilledTransactionsResponse =
        await getUnbilledTransactionsPromise;

      if (getUnbilledTransactionsResponse.status !== 200) {
        logger.error({
          correlationId:
            getUnbilledTransactionsResponse.headers[CORRELATION_ID_HEADER],
          accountId: account?.id,
          responseData: getUnbilledTransactionsResponse.data,
          message: `An error occurred while getting unbilled transactions`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display transactions. Please try again"
        );
        navigate(HOME_ROUTE);
        return;
      }

      getEligibilityForOutstandingToEmi();
      getStatusForOutstandingToEmi();
      // if (getStatementsResponse.status !== 200) {
      //   logger.error({
      //     correlationId: getStatementsResponse.headers[CORRELATION_ID_HEADER],
      //     accountId: account?.id,
      //     responseData: getStatementsResponse.data,
      //     message: `An error occurred while getting statements`
      //   });
      //   toast(ToastType.ERROR, "An error occurred");
      //   navigate(HOME_ROUTE);
      //   return;
      // }

      // unbilled transactions also have auth transactions that are fetched separately
      // chronologically auth transactions may be spread across multiple api calls of unbilled transactions
      // since frontend persists the state, sorting will be done in frontend
      setUnbilledTransactions(
        getSortedTransactions(getUnbilledTransactionsResponse.data.transactions)
      );

      // if latest statement is of current month or previous month
      if (
        (statements.length > 0 &&
          dayjs(statements[0]?.toDate).format("MM-YYYY") ===
            dayjs().format("MM-YYYY")) ||
        dayjs(statements[0]?.toDate).format("MM-YYYY") ===
          dayjs().subtract(1, "month").format("MM-YYYY")
      ) {
        setLastStatement(statements[0]);
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting account summary`,
        error: error.message,
        accountId: account?.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to process your request. Please try again later"
      );
      navigate(HOME_ROUTE);
    }

    setIsLoading(false);
  }, [navigate, account?.id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    transactions.forEach((el: Transaction) => {
      if (el?.txnSource === "CUSTOMER_INITIATED") {
        setIsCustomerEnabledTransaction(true);
      }
    });
  }, [transactions]);

  const handleStatementClick = () => {
    if (billingCyclesSelector.length === 1) {
      toast(ToastType.INFO, "Come back once your first statement is generated");
      return;
    }

    dispatch(
      setMiscellaneousState({
        selectedBillingCycle: billingCyclesSelector[1]
      })
    );
    captureEvent({ eventName: EVENT_NAME.VIEW_STATEMENT_CLICK });
    navigate(VIEW_STATEMENTS_ROUTE);
  };

  const handleManageClick = () => {
    captureEvent({ eventName: EVENT_NAME.VIEW_MANAGE_CARD_CLICK });
    navigate(MANAGE_CARD_ROUTE);
  };

  const viewAllTransactions = () => {
    captureEvent({ eventName: EVENT_NAME.SEE_ALL_TRANSACTIONS_CLICK });
    navigate(VIEW_TRANSACTIONS_ROUTE, {
      state: { statementSelectedCycle: {} }
    });
  };
  const handleOutstandingToEmiClick = () => {
    sessionStorage.setItem(
      "requestSource",
      RequestSources.NOTIFICATION_CARD_PAGE
    );
    captureEvent({
      eventName: EVENT_NAME.OSTOEMI_NOTIFICATION_TO_AMOUNT_SELECTOR_CLICK,
      eventData: {
        eventType: EventType.BUTTON_CLICK
      }
    });
    navigate(CONVERT_OUTSTANDING_TO_EMI);
  };

  const handleViewCardClick = () => {
    // setIsUnmaskedCardOpen(true);
    // setIsCardDetailsOpen(true);
    setIsCardViewDiabled(true);
    setTimeout(() => {
      setIsCardViewDiabled(false);
    }, 3000);
    captureEvent({ eventName: EVENT_NAME.VIEW_VIRTUAL_CARD_CLICK });
  };

  const handleCardDisabledCtaClick = () => {
    if (card.isLocked) {
      navigate(CARD_CONTROLS_ROUTE);
    }
  };

  const closeTransactionDetailsBottomSheet = () => {
    setIsTransactionDetailsOpen(false);
  };

  const onTransactionClick = (transaction: Transaction) => {
    setTransactionSelectedForDetails(transaction);
    setIsTransactionDetailsOpen(true);
  };

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(statusForOutstandingToEmi.extRefId);
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Reference ID Copied");
      }, 1000);
      return;
    }
    navigator?.clipboard?.writeText(statusForOutstandingToEmi.extRefId);
    toastLib.dismiss();
    setTimeout(() => {
      toast(ToastType.SUCCESS, "Reference ID Copied");
    }, 1000);
  };

  const debounceHandleCopy = _debounce(handleCopy, 500);

  const handleConversionToEmiProcess = () => {
    captureEvent({
      eventName: EVENT_NAME.OSTOEMI_CONVERSION_IN_PROCESS_NOTIFICATION_CLICK,
      eventData: {
        eventType: EventType.BUTTON_CLICK
      }
    });
    setIsConversionToEmiInProcess(true);
  };

  const requestToConvertEmiInProcess = () => {
    const interest = statusForOutstandingToEmi.schedules?.reduce((acc, ele) => {
      return acc + ele.interest;
    }, 0);

    return (
      <div className={styles.convertToEmiResult}>
        <div className={styles.clockImgDiv}>
          <img src={clockSvg} alt="clocksvg" />
        </div>
        <p className={styles.conversionToEmiText1}>
          Conversion to EMI is in process
        </p>
        <p className={styles.conversionToEmiText2}>
          <span>Ref No:{statusForOutstandingToEmi.extRefId}</span>
          <span onClick={debounceHandleCopy}>
            <img src={copySvg} alt="copy svg" />
          </span>
        </p>
        <hr className={styles.horizontalLine3}></hr>
        <p className={styles.conversionToEmiText3}>EMI Overview</p>
        <div className={styles.emiOverviewContainer2}>
          <div className={styles.emiAmount}>
            <span>EMI Amount</span>
            <span>
              {formatAmount(statusForOutstandingToEmi.monthlyEmi)}
              <span> / mo</span>
            </span>
          </div>
          <div>
            <span>Amount</span>
            <span>{formatAmount(statusForOutstandingToEmi.principal)}</span>
          </div>
          <div>
            <span>Tenure</span>
            <span>{statusForOutstandingToEmi.tenureInMonths} Months</span>
          </div>
          <div>
            <span className={styles.interestRate}>
              <span>Interest</span>
              <span>@{statusForOutstandingToEmi.interestRate} p.a</span>
            </span>
            <span>{formatAmount(interest)}</span>
          </div>
          <div>
            <span>Processing Fee</span>
            <span>
              {formatAmount(statusForOutstandingToEmi.processingFees)}
            </span>
          </div>
          <div>
            <span className={styles.preClosure}>
              <span>Requested On</span>
            </span>
            <span>{formatDate(statusForOutstandingToEmi.createdOn)}</span>
          </div>
        </div>
        <div className={styles.bottomPart}>
          <hr className={styles.horizontalLine3}></hr>
          <div className={styles.maintainLimit}>
            <div className={styles.bulbImage}>
              <img src={lightBulb} alt="lightBulb" />
            </div>
            <div className={styles.maintainLimitDiv}>
              <p>
                Maintain available credit limit of{" "}
                <span>
                  {formatAmount(
                    statusForOutstandingToEmi.monthlyEmi +
                      statusForOutstandingToEmi.processingFees
                  )}
                </span>
              </p>
              <p>
                <span>
                  ({formatAmount(statusForOutstandingToEmi.monthlyEmi)} emi +{" "}
                  {formatAmount5(statusForOutstandingToEmi.processingFees)}{" "}
                  processing fee)
                </span>{" "}
                to ensure conversion
              </p>
            </div>
          </div>
          <div>
            <div className={styles.btnContainer3}>
              <Button2
                text1="got it"
                customClassName={styles.repaymentScheduleBtn}
                onClick={() => setIsConversionToEmiInProcess(false)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="Card details" />
    </div>
  ) : isParentLoading ? (
    <div style={{ height: "100vh" }}>
      <Loader text1="loading" text2="your IndusInd CardsHub" noTextStyle />
    </div>
  ) : (
    <div className={styles.container} style={{ minHeight: "95vh" }}>
      <img
        className={styles.rippleBackground}
        src={ripplePattern}
        alt="pattern"
      />
      <Header
        card={cardDetails}
        backBtnEnabled={card.isPrimary && !card?.isVariantUpgradeActive}
        setIsParentLoading={setIsParentLoading}
        customClassname={styles.header}
        text={"Your Card"}
      />
      <div
        className={`${styles.cardWrapper} ${
          cardDetails?.cardType === "tiger" ? styles.tigerCardWrapper : ""
        }`}
      >
        <img
          className={styles.card}
          src={cardDetails?.image[cardScheme]}
          alt="Card"
          onClick={handleViewCardClick}
        />
        {cardDetails?.embossedName && (
          <div
            className={styles.embossedName}
            onClick={handleViewCardClick}
            style={{
              background: cardDetails?.embossedName?.gradient,
              color: cardDetails?.embossedName?.color
            }}
          >
            {capitalizeFirsLetter(card?.cardDisplayName)}
          </div>
        )}

        {isCardViewDisabled && (
          <div
            className={`${styles.cardMask2} ${
              cardDetails?.cardType === "tiger" ? styles.tigerCardMask : ""
            }`}
          >
            <span>
              Access your Card Details by logging into IndusMobile App
            </span>
          </div>
        )}
        {cardDisabledText1 && (
          <div
            className={`${styles.cardMask} ${
              cardDetails?.cardType === "tiger" ? styles.tigerCardMask : ""
            }`}
          >
            <div>
              <div
                className={`${styles.lock} ${
                  cardDetails?.cardType === "tiger" ? styles.tigerLock : ""
                }`}
              >
                <img src={Lock} alt="lock" />
              </div>
              <span>{cardDisabledText1}</span>
            </div>
            {cardDisabledText2 && (
              <div onClick={handleCardDisabledCtaClick}>
                <span>{cardDisabledText2}</span>
                <img src={arrowRightUp} alt="arrow" />
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div
        className={`${styles.tapCardContainer} ${
          cardDetails?.cardType === "tiger" ? styles.tigerCardContainer : ""
        }`}
      >
        <img src={fingerUp} alt="up" /> Tap on card to view details
      </div> */}
      <hr className={styles.horizontalLine} style={{ marginTop: "40px" }}></hr>
      <div className={styles.cardSummary}>
        <div style={{ padding: "0px 16px" }}>
          {/* <div className={styles.cardSummaryBorder} /> */}
          <div className={styles.cardSummaryDiv}>
            <div
              className={`${styles.creditContainer} ${
                statements.length <= 0 ? styles.elongatedContainer : ""
              }`}
            >
              <div className={styles.creditDiv}>
                <div className={styles.amount1}>
                  {formatNegativeAmount(
                    formatAmount4(account?.availableCreditLimit)
                  )}{" "}
                  &nbsp;
                </div>
                <div className={styles.amount2}>
                  / {formatAmount4(account?.approvedCreditLimit)}
                </div>
              </div>
              <div className={styles.creditText}>Available Credit</div>
            </div>
            {enableAction && (
              <Button3 label="Statements" onClick={handleStatementClick}>
                <img
                  className={styles.buttonIcon}
                  src={statementLogo}
                  alt="Statements"
                />
              </Button3>
            )}
            <Button3 onClick={handleManageClick} label="Manage">
              <img
                className={styles.buttonIcon}
                src={manageCardLogo}
                alt="Manage"
              />
            </Button3>
          </div>
          {enableAction &&
            lastStatement &&
            dayjs(lastStatement?.toDate).format("YYYY-MM-DD") <=
              dayjs().format("YYYY-MM-DD") && (
              <BillDetails
                totalDue={lastStatement.balanceAmountDue}
                minimumDue={lastStatement.minimumAmountDue}
                dueDate={lastStatement.dueDate}
              />
            )}
          {/* <hr className={styles.horizontalLine}></hr> */}
          {eligibilityForOutstandingToEmi?.isEligible && (
            <div className={styles.outStandingEmiContainer}>
              <div className={styles.outStandingEmi}>
                <div className={styles.imageDiv}>
                  <img src={diskImage} alt="diskImage" />
                  <img src={diskReflection} alt="diskReflection" />
                </div>

                <div className={styles.outStandingEmiDiv}>
                  <p>
                    <span className={styles.text6}>High spends?</span>{" "}
                    <span className={styles.text7}>Slice them</span> 🍕
                  </p>
                  <p className={styles.text8}>EMI-fy your outstanding amount</p>
                </div>
                <div className={styles.outstandingButtonContainer}>
                  <Button2
                    customClassName={styles.outstandingEmiButton}
                    onClick={handleOutstandingToEmiClick}
                  />
                </div>
              </div>
            </div>
          )}

          {(statusForOutstandingToEmi?.status ===
            OutstandingToEmiStatus.PENDING ||
            (statusForOutstandingToEmi?.status ===
              OutstandingToEmiStatus.SUCCESS &&
              statusForOutstandingToEmi?.userActivity?.loginCount - 1 <=
                OS_TO_EMI_SUCCESS_NUDGE_NUMBER_OF_IMPRESSIONS)) &&
            !eligibilityForOutstandingToEmi?.isEligible && (
              <div className={styles.outStandingEmiInProcessContainer}>
                <div className={styles.outStandingEmiContainer2}>
                  <div className={styles.outStandingEmi}>
                    <div
                      className={
                        statusForOutstandingToEmi?.status ===
                        OutstandingToEmiStatus.PENDING
                          ? styles.imageDiv
                          : styles.imageDiv2
                      }
                    >
                      {statusForOutstandingToEmi?.status ===
                        OutstandingToEmiStatus.PENDING && (
                        <>
                          <img src={clockSvg} alt="clocksvg" />
                          <img src={clockReflection} alt="clockReflection" />
                        </>
                      )}
                      {statusForOutstandingToEmi?.status ===
                        OutstandingToEmiStatus.SUCCESS && (
                        <>
                          <img src={emiConverted} alt="emiConverted" />
                          <img src={reflectionDisk} alt="reflectionDisk" />
                        </>
                      )}
                    </div>
                    <div className={styles.outStandingEmiDiv}>
                      {statusForOutstandingToEmi?.status ===
                        OutstandingToEmiStatus.PENDING && (
                        <>
                          <p className={styles.text4}>
                            Conversion to EMI is in process
                          </p>
                          <p className={styles.formatedAmount}>
                            Maintain available credit limit of{" "}
                            <span>
                              {formatAmount(
                                statusForOutstandingToEmi.monthlyEmi +
                                  statusForOutstandingToEmi.processingFees
                              )}
                            </span>
                          </p>
                        </>
                      )}
                      {statusForOutstandingToEmi?.status ===
                        OutstandingToEmiStatus.SUCCESS && (
                        <span className={styles.text5}>
                          Outstanding converted to EMI
                        </span>
                      )}
                    </div>
                    {statusForOutstandingToEmi?.status ===
                      OutstandingToEmiStatus.PENDING && (
                      <div className={styles.outstandingButtonContainer}>
                        <Button2
                          customClassName={styles.outstandingEmiButton}
                          onClick={handleConversionToEmiProcess}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <p>
                  If the Purchase Outstanding converted to EMI is less than
                  Total Outstanding Due in the previous statement, the
                  Cardholder is required to{" "}
                  <span>
                    make payments toward the Balance Outstanding to avoid
                    interest charges.
                  </span>
                </p>
              </div>
            )}
          {transactions &&
            transactions.length > 0 &&
            !card?.isVariantUpgradeActive && (
              <div className={styles.transactionContainer}>
                <div className={styles.transactionsHeader}>
                  <div className={styles.headerText1}>your&nbsp;</div>
                  <div className={styles.headerText2}>transactions</div>
                  {InternalFlags.P1 &&
                    card?.isPrimary &&
                    isCustomerEnablesTransaction && (
                      <AnalyseButton
                        className={styles.analyse}
                        billingCycle={billingCyclesSelector[0]}
                      />
                    )}
                </div>
                {transactions.map((transaction: any, index: number) => {
                  if (index > 9) return null;
                  return (
                    <div
                      key={transaction.id}
                      onClick={() => onTransactionClick(transaction)}
                    >
                      <TransactionComponent
                        transaction={transaction}
                        requestSource={RequestSources.CARD_SUMMARY_PAGE}
                      />
                    </div>
                  );
                })}
              </div>
            )}
        </div>

        {/* <div className={styles.extraDiv} /> */}
        {(!transactions || (transactions && transactions.length === 0)) &&
          !card?.isVariantUpgradeActive && (
            <div className={styles.noTransactions}>
              <hr
                className={styles.horizontalLine}
                style={{ marginTop: "20px" }}
              ></hr>
              <div>
                <div className={styles.noTransactionsText}>
                  <span>your</span> Transactions
                </div>
                <div className={styles.noTransactionImageContianer}>
                  <div>
                    <img src={cactus} alt="cactus" />
                  </div>
                  <div>
                    <span>you have</span> <br /> no unbilled transactions
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* card in process of variant upgrade can't see transactions */}
        {!card?.isVariantUpgradeActive && (
          <div className={styles.btnContainer}>
            {/* show button only when there are transactions */}
            <Button
              onClick={viewAllTransactions}
              text1="see all"
              text2={
                transactions && transactions.length > 0 ? "" : "transactions"
              }
              customClassName={styles.seeAllBtn}
            />
          </div>
        )}
        {card?.isVariantUpgradeActive && (
          <div className={styles.makeFirstTransaction}>
            <hr
              className={styles.horizontalLine}
              style={{ marginTop: "20px" }}
            ></hr>
            <div className={styles.rocketContainer}>
              <div className={styles.rocketImageContianer}>
                <img src={rocket} alt="rocket" />
              </div>
              <div className={styles.rocketTextContainer}>
                <div>make your first transaction</div>{" "}
                <div>& unlock your IndusInd Space</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <UnmaskedCard
        isOpen={isUnmaskedCardOpen}
        setIsOpen={setIsUnmaskedCardOpen}
      />
      <BottomSheet2
        isOpen={isTransactionDetailsOpen}
        handleClose={closeTransactionDetailsBottomSheet}
      >
        {transactionSelectedForDetails && (
          <TransactionDetails
            handleCloseButton={closeTransactionDetailsBottomSheet}
            transaction={transactionSelectedForDetails}
          />
        )}
      </BottomSheet2>
      <BottomSheet2
        isOpen={isCardDetailsOpen}
        handleClose={() => setIsCardDetailsOpen(false)}
      >
        <div style={{ height: "260px" }}>
          <div className={styles.cardDetailContainer}>
            <div className={styles.mainTextContainer}>
              <div>
                <span className={styles.text1}>card</span>
                <span className={styles.text2}>details</span>
              </div>
              <div className={styles.subText}>
                Check your physical card for detailed information
              </div>
              <div className={styles.hideCardImgContainer}>
                <img src={cardWithHand} alt="cardWithHand" />
              </div>
              <div className={styles.confirmBtn1Container}>
                <Button2
                  text1="got"
                  text2="it"
                  customClassName={styles.confirmBtn1}
                  onClick={() => setIsCardDetailsOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </BottomSheet2>
      <BottomsheetComponent
        isBottomSheetOpen={isConversionToEmiInProcess}
        setIsBottomSheetOpen={setIsConversionToEmiInProcess}
        render={requestToConvertEmiInProcess()}
      />
    </div>
  );
};

export default CardSummary;
