const getTextWithLinkObjects = (text: string) => {
  const objs = [];
  let textProcessedTillIndex = 0;
  let lastOpeningParanthesisIndex = 0;
  let closingParanthesisIndex = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    const prevChar = i > 0 ? text.charAt(i - 1) : null;

    if (char === "(") lastOpeningParanthesisIndex = i;
    if (char === "[" && prevChar === ")") closingParanthesisIndex = i - 1;

    if (char === "]" && closingParanthesisIndex !== 0) {
      console.log("ttt", text);
      // a link has ended
      const plainText = text.slice(
        textProcessedTillIndex,
        lastOpeningParanthesisIndex
      );
      if (plainText) {
        // if text starts with link or link follows another link we would get empty text
        objs.push({
          type: "TEXT",
          text: plainText
        });
      }

      const linkText = text.slice(
        lastOpeningParanthesisIndex + 1,
        closingParanthesisIndex
      );
      const link = text.slice(closingParanthesisIndex + 2, i);
      objs.push({
        type: "LINK",
        text: linkText,
        link
      });

      // set vars for next iteration
      textProcessedTillIndex = i + 1;
      lastOpeningParanthesisIndex = 0;
      closingParanthesisIndex = 0;
    }
  }

  if (textProcessedTillIndex < text.length) {
    // text did not end with a link
    const plainText = text.slice(textProcessedTillIndex, text.length);
    objs.push({
      type: "TEXT",
      text: plainText
    });
  }

  return objs;
};

const TextWithLinks = ({
  text,
  textClassName = "",
  textStyle = {},
  linkClassName = "",
  linkStyle = {},
  openInNewTab = true
}: {
  text: string;
  textClassName?: string;
  textStyle?: any;
  linkClassName?: string;
  linkStyle?: any;
  openInNewTab?: boolean;
}) => {
  return (
    <>
      {getTextWithLinkObjects(text).map((s, index) =>
        s.type === "TEXT" ? (
          <span className={textClassName} style={textStyle} key={index}>
            {/* {s.text?.replace(/\s/g, "\u00A0")}{" "} */}
            {s.text}
          </span>
        ) : (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={s.link}
            target={openInNewTab ? "_blank" : undefined}
            className={linkClassName}
            style={linkStyle}
            key={index}
          >
            {/* {s.text?.replace(/\s/g, "\u00A0")} */}
            {s.text}
          </a>
        )
      )}
    </>
  );
};

export { TextWithLinks, getTextWithLinkObjects };
