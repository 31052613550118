import React from "react";
import { useSelector } from "react-redux";
import styles from "./iosInstallPrompt.module.scss";
import Frame from "../../assets/svgs/Frame.svg";
import InstrutionBar1 from "../../assets/svgs/InstructionBar1.svg";
import InstructionBar2 from "../../assets/svgs/InstructionBar2.svg";
import InstructionBar3 from "../../assets/svgs/InstructionBar3.svg";
import { Button2 } from "../button/Button";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import AppApi from "../../apis/appApi/AppApi";
import dayjs from "dayjs";
import { API_REQUEST_DATE_FORMAT } from "../../utils/constants";
import {
  getCustomer,
  getSelectedAccount,
  getSelectedCard
} from "../../features/user/userSlice";

interface IosInstallAppPromptComponentProps {
  setIsIosBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IosInstallPrompt = (props: IosInstallAppPromptComponentProps) => {
  const { setIsIosBottomSheetOpen } = props;

  const account = useSelector(getSelectedAccount)!;
  const customer = useSelector(getCustomer)!;
  const selectedCard = useSelector(getSelectedCard)!;

  const handleCloseIosPrompt = () => {
    setIsIosBottomSheetOpen(false);
    let type = "app_install_prompt_details_close",
      lagTimeForInstallPrompt = Number(
        sessionStorage.getItem("lagTimeForInstallPrompt")
      );
    let currentDate = dayjs().format(API_REQUEST_DATE_FORMAT);
    AppApi.captureEvent({
      eventName: EVENT_NAME.APP_INSTALL_PROMPT,
      hfAccountId: account?.id,
      hfCardId: selectedCard?.id,
      hfCustomerId: customer?.id,
      primarySource: window.location.href,
      eventData: {
        eventType: type,
        lagTimeForInstallPrompt: lagTimeForInstallPrompt,
        date: currentDate
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  return (
    <div>
      <div className={styles.closePrompt} onClick={handleCloseIosPrompt}>
        X
      </div>
      <div className={styles.header}>
        <div className={styles.headerText}>
          Follow the below step to add{" "}
          <span style={{ fontWeight: "600" }}>IndusInd CardsHub </span>to your
          home
        </div>
        <div>
          <img src={Frame} alt="Mobile Frame" />
        </div>
      </div>

      <hr className={styles.horizontalLine} />

      <div className={styles.instructions}>
        <div className={styles.instructionContainer}>
          <div className={styles.instructionText}>
            <div>1. &nbsp;</div>
            <div>Tap on the share icon in your browser.</div>
          </div>
          <div className={styles.instructionImageContainer}>
            <img src={InstrutionBar1} alt="Instruction Image" />
          </div>
        </div>

        <div className={styles.instructionContainer}>
          <div className={styles.instructionText}>
            <div>2. &nbsp;</div>
            <div>
              In the pop-up menu actions, scroll down and tap on the Add to Home
              Screen option.
            </div>
          </div>
          <div className={styles.instructionImageContainer}>
            <img src={InstructionBar2} alt="Instruction Image" />
          </div>
        </div>

        <div className={styles.instructionContainer}>
          <div className={styles.instructionText}>
            <div>3. &nbsp;</div>
            <div>
              Tap on the Add button located at the top-right corner to finish
              adding IndusInd CardsHub.
            </div>
          </div>
          <div className={styles.instructionImageContainer}>
            <img src={InstructionBar3} alt="Instruction Image" />
          </div>
        </div>

        <div className={styles.confirmBtn1Container}>
          <Button2
            text1="Close"
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.confirmBtn1}
            isLoading={false}
            onClick={handleCloseIosPrompt}
          />
        </div>
      </div>
    </div>
  );
};

export default IosInstallPrompt;
