/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./cardSummaryStyles.module.scss";
import styles1 from "../../components/preLogin/preLogin.module.scss";
import { BottomSheet2, Button2 } from "../../components";
import {
  eyeSimple,
  eyeClosed,
  copyDisabled,
  copySimple
} from "../../assets/svgs";
import { CORRELATION_ID_HEADER } from "../../utils/constants";
import { toast } from "../../components/toast/toast";
import CardsApi from "../../apis/cardsApi/CardsApi";
import { getSelectedCard } from "../../features/user/userSlice";
import ApiIds from "../../auth/ApiIds";
import { authenticateApi } from "../../auth/auth";
import logger from "../../utils/logger";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import { ToastType } from "../../components/toast/toastTypes";
import type { GetUnmaskedCardResponse } from "../../apis/cardsApi/cardsApiTypes";
import {
  fallbackCopyTextToClipboard,
  formatCardNumber
} from "../../utils/functions";
import Loader from "../../components/loader/loader";
import { toast as toastLib } from "react-toastify";
import _debounce from "lodash/debounce";

interface UnmaskedCardProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnmaskedCard = ({ isOpen, setIsOpen }: UnmaskedCardProps) => {
  const navigate = useNavigate();

  // const customer = useSelector((state: RootState) => state.user.customer);
  const card = useSelector(getSelectedCard)!;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unmaskedCard, setUnmaskedCard] =
    useState<GetUnmaskedCardResponse | null>(null);
  const [showCardNumber, setShowCardNumber] = useState<boolean>(true);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setUnmaskedCard(null);
      setShowCardNumber(true);
    }
  }, [isOpen]);

  const getUmaskedCard = useCallback(async () => {
    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.FETCH_UNMASKED_CARD,
        actionText: "to show card"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          cardId: card.id,
          error: authResult.error,
          message: `An error occurred while authenticating fetch unmasked card api`
        });
        toast(ToastType.ERROR, authResult.message);
        setIsOpen(false);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        setIsOpen(false);
        return;
      }

      const response = await CardsApi.getUnmaskedCard(
        card.id,
        authResult.apiToken
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          cardId: card.id,
          responseData: response.data,
          message: `An error occurred while fetching unmasked card`
        });
        toast(
          ToastType.ERROR,
          "We are unable to display your Card details. Please try again"
        );
        setIsOpen(false);
        return;
      }
      setIsDetailsOpen(true);
      setUnmaskedCard(response.data);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while fetching unmasked card`,
        error: error.message,
        cardId: card.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to display your Card details. Please try again"
      );
      setIsOpen(false);
    }

    setIsLoading(false);
  }, [navigate, card?.id]);

  const onOpen = useCallback(async () => {
    setIsLoading(true);
    await getUmaskedCard();
    setIsLoading(false);
  }, [getUmaskedCard]);

  useEffect(() => {
    if (isOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  const handleShowCardClick = () => {
    setShowCardNumber(!showCardNumber);
  };

  const handleCopyClick = (e: any, allowCopy = true) => {
    if (allowCopy) {
      if (navigator?.clipboard) {
        navigator.clipboard?.writeText(unmaskedCard!.unmaskedCardNumber);
      } else {
        fallbackCopyTextToClipboard(unmaskedCard!.unmaskedCardNumber);
      }

      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Card Number is copied");
      }, 1000);
    } else {
      toastLib.dismiss();
      setTimeout(() => {
        toast(ToastType.WARNING, "Card Number is Masked");
      }, 1000);
    }
  };

  const debounceHandleCopyClick = _debounce(handleCopyClick, 500);

  // const cardDisplayName =
  //   customer!.firstName +
  //   (customer!.middleName ? " " + customer!.middleName : "") +
  //   (customer!.lastName ? " " + customer!.lastName : "");

  const cardDisplayName = card.cardDisplayName;

  const handleCloseClick = () => {
    setIsOpen(false);
    setIsDetailsOpen(false);
  };

  return (
    <BottomSheet2 isOpen={isDetailsOpen} handleClose={handleCloseClick}>
      <div style={{ height: "400px" }}>
        {isLoading ? (
          <div style={{ height: "400px" }}>
            <Loader text1="loading" text2="card details" />
          </div>
        ) : Boolean(unmaskedCard) ? (
          <div className={styles1.bottomSheet}>
            <div className={styles1.bottomSheetContainer}>
              <div className={styles1.bottomSheetHeader}>Card Details</div>
              <div className={styles1.phoneNumberContainer}>
                <span className={styles1.bottomSheetText}>Cardholder Name</span>
                <div className={styles1.phoneNumberInputContainer}>
                  <div className={styles.nameInput}>{cardDisplayName}</div>
                </div>
              </div>
              <div className={styles1.phoneNumberContainer}>
                <span className={styles1.bottomSheetText}>Card Number</span>
                <div className={styles1.phoneNumberInputContainer}>
                  <div className={styles.nameInput}>
                    {showCardNumber
                      ? formatCardNumber(unmaskedCard!.unmaskedCardNumber)
                      : "**** **** **** " +
                        unmaskedCard!.unmaskedCardNumber.slice(12)}
                    <div
                      className={styles.copyContainer}
                      onClick={
                        showCardNumber
                          ? debounceHandleCopyClick
                          : (e) => debounceHandleCopyClick(e, false)
                      }
                    >
                      <img
                        src={showCardNumber ? copySimple : copyDisabled}
                        alt="view"
                        className={styles.eye}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.expiryCvvContainer}>
                {/* <span className={styles.expiryText}>Expiry Date </span> */}
                <div className={styles.phoneNumberContainer}>
                  <span className={styles1.bottomSheetText}>Expiry Date</span>
                  <div className={styles1.phoneNumberInputContainer}>
                    <div className={styles.nameInput}>
                      {showCardNumber ? unmaskedCard!.cardExpiry : "**/**"}
                    </div>
                  </div>
                </div>
                <div className={styles.phoneNumberContainer}>
                  <span className={styles1.bottomSheetText}>CVV</span>
                  <div className={styles1.phoneNumberInputContainer}>
                    <div className={styles.nameInput}>
                      {showCardNumber ? "***" : unmaskedCard!.cvv}
                    </div>
                    <div
                      className={styles.eyeContainer}
                      onClick={handleShowCardClick}
                    >
                      <img
                        src={showCardNumber ? eyeSimple : eyeClosed}
                        alt="view"
                        className={styles.eye}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles1.confirmBtn1Container}>
                <Button2
                  text1="close"
                  // text2="started"
                  customClassName={styles1.confirmBtn1}
                  onClick={handleCloseClick}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </BottomSheet2>
  );
};

export default UnmaskedCard;
