import refund from "../../assets/svgs/transactionLogos/Refund.svg"
import cashback from "../../assets/svgs/transactionLogos/cashback.svg"
import cashWithdrawal from "../../assets/svgs/transactionLogos/cashWithdrawal.svg"
import fee from "../../assets/svgs/transactionLogos/fee.svg"
import payment from "../../assets/svgs/transactionLogos/payment.svg"
import customerTransaction from "../../assets/svgs/transactionLogos/transaction.svg"
import interest from "../../assets/svgs/transactionLogos/interest.svg"

import styles from "./transactionStyles.module.scss"
interface TransactionLogo{
    txnType: string;
}
const TransactionLogo = (props:TransactionLogo)=>{
    const image = (type:string) => {
        switch (type) {
          case "REFUND":
            return refund
          case "REFUND_REVERSAL":
            return refund
          case "REPAYMENT":
            return payment
          case "PAYMENT_REVERSAL":
            return refund
          case "CASHBACK":
            return cashback
          case "CASHBACK_REVERSAL":
            return refund
          case "REPAYMENT_REVERSAL":
            return refund
          case "FEE":
            return fee
          case "FEE_REVERSAL":
            return refund
          case "TAX":
            return fee
          case "TAX_REVERSAL":
            return refund
          case "INTEREST":
            return interest
          case "INTEREST_REVERSAL":
            return refund
          case "SURCHARGE_":
            return refund
          case "SURCHARGE_REVERSAL":
            return refund
          case "CASH_WITHDRAWAL_REVERSAL":
            return refund
          case "CASH_WITHDRAWAL":
            return cashWithdrawal
          case "SETTLEMENT_DEBIT_CASH":
            return cashWithdrawal
          case "SETTLEMENT_CREDIT_CASH":
            return cashWithdrawal
          case "EMI_PRINCIPLE":
            return interest
          case "EMI_INTEREST":
            return interest
          case "CHARGEBACK":
            return refund
          default:
            return customerTransaction
        }
      }
      return (
        <div className={styles.logoContainer } >
            <div className={styles.logoChildContainer}>
                <img src={image(props.txnType)} />
            </div>
        </div>
          
      )
    
}

export default TransactionLogo;